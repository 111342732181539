export const activityButtonStyles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px #F3F4F9',
    borderRadius: '8px',
    maxHeight: '36px',
    '&:hover': {
      boxShadow: '0px 0px 2px #4D6EF5',
    },
  },
  buttonContainerOpened: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px #F3F4F9',
    borderRadius: '8px',
    maxHeight: '36px',
    boxShadow: '0px 0px 2px #4D6EF5',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'black',
    border: 'unset',
    padding: '8px 12px 8px 8px',
    minWidth: 'auto',
    maxHeight: '36px',

    '&:hover': {
      border: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '12px',
      marginLeft: '0px',

      '& svg, img': {
        width: '20px',
        height: '20px',
        borderRadius: '8px',
      },
    },
  },
  grow: { transformOrigin: 'left top' },
  buttonContent: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20.44px',
    alignItems: 'start',
    textAlign: 'right',
    color: '#23252F',
    maxWidth: '117px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },
  popper: {
    width: '450px',
    zIndex: 10,
  },
  paper: {
    width: '450px',
    marginTop: '8px',
    marginBottom: '8px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },
  paperContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  paperTitleContainer: {
    display: 'flex',
    padding: '20px',
    borderBottom: '1px solid #F3F4F9',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paperTitleWrapper: {
    display: 'flex',
  },
  paperTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '148%',
  },
  seeMoreContainer: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    columnGap: '4px',
    alignItems: 'center',
  },
  seeMore: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#4D6EF5',
  },
  chevronIcon: {
    width: '20px',
    height: '20px',
    color: '#4D6EF5',
  },
  paperItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    rowGap: '20px',
    width: '100%',
    '& .infinite-scroll-component__outerdiv': {
      width: '98%',
      display: 'flex',
      '& .infinite-scroll-component': {
        '&::-webkit-scrollbar': {
          border: 'none',
          width: '11px',
          height: '11px',
          padding: '5px 0',
        },

        '&::-webkit-scrollbar-track': {
          backgroundColor: '#fff',
          border: 'none',
          height: '10px',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D4D6E0',
          width: '2px',
          borderRadius: '6px',
          cursor: 'pointer',
        },
      },
    },
  },
  paperItemsContainerFixed: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  emptyStateContainer: {
    display: 'flex',
    width: '100%',
    padding: '150px 20px',
  },
  emptyStateWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '60px',
    alignItems: 'center',
  },
  emptyStateTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },
  emptyStateSubtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#424550',
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  dateContainer: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#8C8F9D',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  paperItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '20px',
    padding: '0px 20px',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: '6px',
  },
  postContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: '12px',
  },
  customAvatarContainer: { display: 'flex', width: '24px', height: '24px', position: 'relative' },
  avatarContainer: {
    display: 'flex',
    backgroundColor: '#EDEFF7',
    borderRadius: '4px',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    fontFamily: 'Inter',
    color: '#6E707A',
    fontSize: '12px',
    fontStyle: 'normal',
    lineHeight: '146%',
    fontWeight: '500',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  postItemContainer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  postItemProfileContainer: {
    display: 'flex',
    width: '100%',
  },
  postItemProfile: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '146%',
    color: '#23252F',
    '& span': {
      color: '#6E707A',
      fontWeight: '400',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  postItemContent: {
    width: '100%',
    display: 'flex',
    fontFamily:
      "'Inter', 'Apple Color Emoji', 'Segoe UI Emoji', 'Noto Color Emoji', 'Twemoji', sans-serif",
    '& p, a': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      fontSize: '14px',
      lineHeight: '146%',
      fontWeight: 400,
      margin: '0',
      WebkitLineClamp: '2',
      'line-clamp': '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-all',
    },
    '& p': {
      color: '#424550',
    },
    '& span': {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '146%',
      color: '#4D6EF5',
    },
  },
  infiniteScroll: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxHeight: '400px',
    overflow: 'auto',
    height: 'auto',
    paddingTop: '20px',
    marginBottom: '24px',
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },
  imageGreenDot: {
    position: 'absolute',
    display: 'flex',
    width: '10px',
    height: '10px',
    backgroundColor: '#379552',
    border: '2px solid #FFFFFF',
    left: '-4px',
    top: '-4px',
    borderRadius: '50%',
    zIndex: '10',
  },
};
