import { FC } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

const styles = {
  '&.MuiTooltip-tooltip': {
    backgroundColor: '#23252F',
    borderRadius: '4px',
    padding: '4px 8px',
    fontFamily: 'Inter',
    fontSize: '10px',
    textTransform: 'capitalize',
    maxWidth: 'unset',
    '&.MuiTooltip-tooltipPlacementTop': {
      marginBottom: '4px',
    },
  },
};

interface IProps extends TooltipProps {}

const CustomTooltip: FC<IProps> = ({ children, ...props }) => {
  return (
    <Tooltip placement="top-start" componentsProps={{ tooltip: { sx: styles } }} {...props}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
