import React, { Suspense } from 'react';
import { useLocation, Routes, Route, useSearchParams, RouteProps } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_LICENSE_KEY } from 'constants/mui';
// components
import RouteWrapper from 'components/route-wrapper';
import Spinner from 'components/spinner';
import AppHeader from './components/app-header';
// custom hooks
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useSocketListener } from 'hooks/useSocketListener';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
// api
import { useGetMeQuery } from 'redux/api/auth';
import { useGetGeolocationQuery } from 'redux/api/geolocation';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
import { useGetCompanyQuery } from 'redux/api/company';
import { useGetProfilePreferenceQuery } from '../redux/api/profile-preference';
// config
import { IRoute, baseRoutesConfig, modalRoutesConfig } from './route-config';
// selectors
import { authSelector } from 'redux/selector';

import 'react-phone-input-2/lib/high-res.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import 'dhtmlx-gantt/dhtmlxgantt.css';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const App = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const { isAuth, companyId } = useAppSelector(authSelector);
  const [searchParams] = useSearchParams();

  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

  const {
    data: userMe,
    isLoading: isUserMeLoading,
    error: userMeError,
  } = useGetMeQuery(undefined, {
    skip: !isAuth || searchParams.has('entryToken'),
  });

  const {
    data: userPermissions,
    isLoading: isUserPermissionsLoading,
    error: userPermissionsError,
  } = useGetCompanyRolePermissionsMeQuery(undefined, {
    skip: !userMe || !companyId,
  });

  const { isLoading: isCompanyLoading, error: companyError } = useGetCompanyQuery(undefined, {
    skip: !userPermissions?.COMPANY_MANAGEMENT?.canRead,
  });

  useGetGeolocationQuery(undefined, { skip: !userMe });

  const { isLoading: isProfilePreferenceLoading, error: profilePreferenceError } =
    useGetProfilePreferenceQuery(undefined, { skip: !userMe });

  const pending =
    (isUserMeLoading && !userMeError) ||
    (isUserPermissionsLoading && !userPermissionsError) ||
    (isCompanyLoading && !companyError) ||
    (isProfilePreferenceLoading && !profilePreferenceError);

  const createRoutes = (routes: IRoute[]) => {
    return routes.map(
      (
        {
          component: Component,
          accessPermissions,
          role,
          childRoutes,
          index,
          path,
          withWrapper,
          protectedRoute,
          withBackgroundLocation,
        },
        idx
      ) => {
        if ((protectedRoute && !isAuth) || (withBackgroundLocation && !state?.backgroundLocation)) {
          return null;
        }

        const hasAccess =
          !accessPermissions?.length ||
          accessPermissions.some(
            (permissionSubject) => userPermissions?.[permissionSubject]?.canRead
          );

        if (hasAccess) {
          const renderComponent = withWrapper ? (
            <RouteWrapper component={Component} role={role} path={path} />
          ) : Component ? (
            <Component />
          ) : null;

          const routeProps: RouteProps = {
            ...(path && { path }),
            ...(index && { index }),
          };

          return (
            // @ts-ignore
            <Route key={path ?? idx} element={renderComponent} {...routeProps}>
              {childRoutes?.length ? createRoutes(childRoutes) : null}
            </Route>
          );
        }

        return null;
      }
    );
  };

  useSocketListener();
  useBroadcastChannel();

  if (pending) {
    return <Spinner />;
  }

  return (
    <>
      <AppHeader userMe={userMe} />
      <Suspense fallback={<Spinner />}>
        <Routes location={state?.backgroundLocation || location}>
          {createRoutes(Object.values(baseRoutesConfig))}
        </Routes>
        <Routes>{createRoutes(Object.values(modalRoutesConfig))}</Routes>
      </Suspense>
    </>
  );
};

export default App;
