import { api } from 'redux/api';
import { IProfilePreference, ScheduleViewType } from './';
import { handleOpenSidePanel } from 'redux/slices/side-panel-slice';
import { handleSetGanttTab } from '../../slices/manage-gantt-slice';

enum Endpoints {
  PROFILE_PREFERENCE = 'profile-preference',
}

export const profilePreferenceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfilePreference: build.query<IProfilePreference, void>({
      query: () => ({
        url: Endpoints.PROFILE_PREFERENCE,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          dispatch(handleOpenSidePanel(response?.data?.isActivityFeedExpanded ?? false));
          dispatch(handleSetGanttTab(response?.data?.scheduleViewType === ScheduleViewType.GANTT));
        } catch {}
      },
      providesTags: ['Preference'],
    }),

    updateProfilePreference: build.mutation<null, Partial<IProfilePreference>>({
      query: (data) => ({
        url: Endpoints.PROFILE_PREFERENCE,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Preference'],
    }),
  }),
});

export const {
  useGetProfilePreferenceQuery,
  useLazyGetProfilePreferenceQuery,
  useUpdateProfilePreferenceMutation,
} = profilePreferenceApi;
