// Popper component cause a type error in production. This is the MUI bug.
// @ts-nocheck
import React, { useState, useRef, FC, useMemo, CSSProperties } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
// mui components
import {
  ClickAwayListener,
  Grow,
  Paper,
  Typography,
  Grid,
  Button,
  Popper,
  CircularProgress,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// components
import NotificationMessage from './notification-message';
// custom hooks
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useSidePanel } from 'hooks/useSidePanel';
// api
import {
  useCreateNotificationViewsBulkMutation,
  useGetUnviewedNotificationsQuery,
} from 'redux/api/activity-feed';
import { useGetProjectsQuery } from 'redux/api/projects';
// icons
import { ReactComponent as NotificationsBell } from 'assets/icons/Bell_new_icon.svg';
// utils
import { getTimeSpend } from 'utils/helpers';
// selectors
import { queryArgsSelector } from 'redux/selector';
// styles
import { notificationButtonStyles } from './styles/styles';

interface INotificationButtonProps {
  projectId: number;
  unviewedItemsCount?: number;
  handleSeeMore?: () => void;
}

const NotificationButton: FC<INotificationButtonProps> = ({
  projectId,
  unviewedItemsCount,
  handleSeeMore,
}) => {
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const { handleSidePanelOpen, handleSidePanelContent } = useSidePanel();
  const { projects: projectsArgs } = useAppSelector(queryArgsSelector);

  const [open, setOpen] = useState(false);
  const [cursor, setCursor] = useState<number | undefined>(undefined);

  const [bulkUpdate] = useCreateNotificationViewsBulkMutation();

  const { isLoading, isFetching, data } = useGetUnviewedNotificationsQuery(
    {
      limit: 15,
      projectId,
      cursor,
    },
    { skip: !open }
  );

  const { data: projectsData } = useGetProjectsQuery(projectsArgs);

  const getMore = () => {
    if (data?.meta?.nextCursor) {
      setCursor(data?.meta?.nextCursor);
    }
  };

  const unviewedCount = useMemo(
    () => (typeof unviewedItemsCount === 'number' ? unviewedItemsCount : 0),
    [unviewedItemsCount]
  );

  const markNotificationsAsViewed = async () => {
    bulkUpdate({
      projectId: Number(projectId),
      unviewedCountArgs:
        window.location.pathname.includes('account-dashboard') && projectsData?.items?.length
          ? projectsData.items.map(({ id }) => id)
          : undefined,
    });
  };

  const handleToggle = () => {
    if (open && unviewedCount > 0) {
      markNotificationsAsViewed();
    }
    setOpen((prevOpen) => !prevOpen);
    setCursor(undefined);
  };

  const handleClose = () => {
    if (unviewedCount > 0) {
      markNotificationsAsViewed();
    }
    setOpen(false);
    setCursor(undefined);
  };

  const onSeeMore = () => {
    if (handleSeeMore) {
      handleClose();
      handleSeeMore();
      return;
    }
    handleClose();
    handleSidePanelContent('notifications_feed');
    handleSidePanelOpen(true);
  };

  const onItemClick = async (id: number) => {
    if (open && unviewedCount > 0) {
      markNotificationsAsViewed();
    }
    if (!window.location.pathname.split('/')?.[2]) {
      navigate(`/project-dashboard/${projectId}?#${id}`);
    } else {
      const element = document.getElementById(id.toString());
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setOpen(false);
      }
    }
  };

  return (
    <Grid
      sx={
        !open
          ? notificationButtonStyles.buttonContainer
          : notificationButtonStyles.buttonContainerOpened
      }
    >
      <Button
        ref={anchorRef}
        variant="outlined"
        id="companies-list-button"
        aria-controls={open ? 'companies-list-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={<NotificationsBell />}
        sx={notificationButtonStyles.container}
      >
        <Typography sx={notificationButtonStyles.buttonContent}>{unviewedCount}</Typography>
      </Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        sx={notificationButtonStyles.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={notificationButtonStyles.popper}>
            <Paper sx={notificationButtonStyles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid sx={notificationButtonStyles.paperContentContainer}>
                  <Grid sx={notificationButtonStyles.paperTitleContainer}>
                    <Grid sx={notificationButtonStyles.paperTitleWrapper}>
                      <Typography sx={notificationButtonStyles.paperTitle}>
                        Recent Notifications
                      </Typography>
                    </Grid>
                    <Grid sx={notificationButtonStyles.seeMoreContainer} onClick={onSeeMore}>
                      <Typography sx={notificationButtonStyles.seeMore}>See all</Typography>
                      <ChevronRightIcon sx={notificationButtonStyles.chevronIcon} />
                    </Grid>
                  </Grid>
                  <Grid
                    sx={
                      !data?.items?.length
                        ? notificationButtonStyles.paperItemsContainerFixed
                        : notificationButtonStyles.paperItemsContainer
                    }
                  >
                    {data?.items?.length === 0 && open && !isLoading && !isFetching ? (
                      <Grid sx={notificationButtonStyles.emptyStateContainer}>
                        <Grid sx={notificationButtonStyles.emptyStateWrapper}>
                          <Typography sx={notificationButtonStyles.emptyStateTitle}>
                            No Notifications
                          </Typography>
                          <Typography sx={notificationButtonStyles.emptyStateSubtitle}>
                            Looks like you haven’t received any notifications
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (!data?.items?.length && open && isLoading) ||
                      (!cursor && open && (isFetching || isLoading)) ? (
                      <Grid sx={notificationButtonStyles.loaderContainer}>
                        <CircularProgress />
                      </Grid>
                    ) : data?.items?.length && open ? (
                      <InfiniteScroll
                        next={getMore}
                        hasMore={!!data?.meta && !!data?.meta?.nextCursor}
                        dataLength={data?.items?.length}
                        loader={
                          <Grid sx={notificationButtonStyles.loaderContainer}>
                            <CircularProgress />
                          </Grid>
                        }
                        style={notificationButtonStyles.infiniteScroll as CSSProperties}
                        height={400}
                        children={data?.items?.map((post) => {
                          return (
                            <Grid sx={notificationButtonStyles.paperItem} key={post.id}>
                              <Grid sx={notificationButtonStyles.paperTextContainer}>
                                <Grid sx={notificationButtonStyles.greenDotContainer}>
                                  {!post.isViewed ? (
                                    <Grid sx={notificationButtonStyles.greenDot}></Grid>
                                  ) : null}
                                </Grid>
                                <NotificationMessage post={post} onItemClick={onItemClick} />
                              </Grid>
                              <Grid sx={notificationButtonStyles.dateContainer}>
                                <Typography sx={notificationButtonStyles.dateText}>
                                  {getTimeSpend(
                                    formatDistanceToNowStrict(new Date(post.createdAt))
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default NotificationButton;
