import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { endpoint }) => {
      if (endpoint === 'getGeolocation' || endpoint === 'postFileToAWS') {
        return;
      }
      if (endpoint === 'getCountryRegions') {
        headers.set('x-rapidapi-host', 'wft-geo-db.p.rapidapi.com');
        headers.set('x-rapidapi-key', process.env.REACT_APP_RAPID_API_KEY || '');
        return;
      }
      headers.set('authorization', localStorage.getItem('Authorization') ?? '');
      if (localStorage.getItem('company-id')) {
        headers.set('company-id', localStorage.getItem('company-id'));
      }
    },
  }),
  keepUnusedDataFor: 10,
  tagTypes: [
    'Post Audience',
    'Specific Users',
    'User',
    'Company',
    'Template',
    'Projects',
    'Preference',
    'Current Plan',
    'Payment Plans',
    'Payment Methods',
    'Company Roles',
    'Profile Push Notifications',
    'Profile Email Notifications',
    'Company Push Notifications',
    'Company Email Notifications',
    'Invites',
    'Profiles',
    'Role Permissions',
    'Project Changes Count',
    'Selection Category',
    'Selections Summary',
    'Project Cost Codes',
    'File Cabinet Folder',
    'File Cabinet',
    'Folder Permissions',
    'Default Cost Codes',
    'Default Cost Categories',
    'Checklists Templates',
    'Checklists Template Items',
    'Schedule Checklists',
  ],
  endpoints: () => ({}),
});
