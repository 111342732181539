import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
// mui components
import { Grid } from '@mui/material';
// components
import Checkbox from 'components/checkbox';
// types
import { TFileCabinetRole } from 'redux/api/file-cabinet';
// helpers
import { capitalizeFirstLetter } from 'utils/helpers/capitalizeFirstLetterHelper';
// styles
import { styles } from './styles';

type RoleProps = {
  index: number;
  role: TFileCabinetRole;
};

const Role: FC<RoleProps> = ({ index, role }) => {
  const form = useFormContext();

  const viewChangeHandler = useCallback(
    (event) => {
      const { id, name } = role;

      if (event.target.checked === true) {
        form.setValue(`roles.${index}`, {
          id,
          name,
          permissions: { ...role.permissions, canRead: event.target.checked },
        });
      }

      if (event.target.checked === false) {
        form.setValue(`roles.${index}`, {
          id,
          name,
          permissions: {
            canRead: event.target.checked,
            canAddUpdate: event.target.checked,
            canDelete: event.target.checked,
          },
        });
      }
    },
    [role]
  );

  const addEditChangeHandler = useCallback(
    (event) => {
      const { id, name } = role;

      form.setValue(`roles.${index}`, {
        id,
        name,
        permissions: { ...role.permissions, canAddUpdate: event.target.checked },
      });
    },
    [role]
  );

  const deleteChangeHandler = useCallback(
    (event) => {
      const { id, name } = role;

      form.setValue(`roles.${index}`, {
        id,
        name,
        permissions: { ...role.permissions, canDelete: event.target.checked },
      });
    },
    [role]
  );

  return (
    <Grid sx={styles.tableRow} className="role-row">
      <Grid sx={styles.tableRoleName}>{capitalizeFirstLetter(role?.name) || '-'}</Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          onChange={viewChangeHandler}
          defaultChecked={role?.name === 'ADMIN' ? true : role?.permissions?.canRead}
          value={role.permissions.canRead}
          disabled={role?.name === 'ADMIN'}
        />
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          onChange={addEditChangeHandler}
          defaultChecked={role?.name === 'ADMIN' ? true : role?.permissions?.canAddUpdate}
          value={role.permissions.canAddUpdate}
          disabled={!role?.permissions?.canRead || role?.name === 'ADMIN'}
        />
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          onChange={deleteChangeHandler}
          defaultChecked={role?.name === 'ADMIN' ? true : role?.permissions?.canDelete}
          value={role.permissions.canDelete}
          disabled={!role?.permissions?.canRead || role?.name === 'ADMIN'}
        />
      </Grid>
    </Grid>
  );
};

export default Role;
