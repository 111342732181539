export const selectPlanBoxStyles = {
  planBox: {
    borderRadius: '12px',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
      borderTop: '1px solid transparent',
      borderBottom: '1px solid transparent',
    },
  },
  innerBox: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  planName: {
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 500,
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
  },
  selectPlanButton: {
    padding: '8px 10px',
    height: '20px',
    fontFamily: 'Inter, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '146%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    background: 'linear-gradient(93.74deg, #FF7051 1.23%, #D3AC1E 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillcolor: 'transparent',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    textTransform: 'none',
  },
  daysLeftText: {
    fontSize: '12px',
    lineHeight: '150%',
    fontWeight: 400,
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
  },
  expiredText: {
    fontSize: '12px',
    lineHeight: '150%',
    fontWeight: 400,
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
  },
};
