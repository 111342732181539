import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// mui components
import { Grid, Button } from '@mui/material';
// components
import ProfilePopperButton from '../popper-button';
import CompaniesButton from '../companies-button';
import ProjectsButton from '../projects-button/index';
import AccountsButton from '../accounts-button/index';
import SettingsButton from '../settings-button';
import AlertButton from 'components/alert-button';
import NotificationButton from 'components/notification-button';
import ActivityButton from 'components/activity-button';
import ProjectNav from '../project-nav';
import AuthButtons from '../auth-buttons';
import AppPrompt from 'components/app-prompt';
import ProjectFileCabinetModal from 'components/file-cabinet-folders/modals/project-file-cabinet-modal';
// icons
import { ReactComponent as HomeIcon } from 'assets/icons/header/home.svg';
import { ReactComponent as BuilderPadLogo } from 'assets/icons/header/logo-builder-pad.svg';
import { ReactComponent as FileCabinetIcon } from 'assets/icons/cabinet/folder-open.svg';
// api
import { useGetProfilePreferenceQuery, DonwloadMobileAppEnum } from 'redux/api/profile-preference';
import { ICurrentPaymentPlan } from 'redux/api/billing';
import { IPhaseItem, IPhaseLinkedItem, IRole, IUserMe } from 'redux/types';
import { ICompanyAll } from 'redux/api/company';
import { IUnviewedItem, useGetProjectByIdQuery } from 'redux/api/projects';
import { handleSetBreadCrumbs, handleSetSearchValue } from 'redux/slices/file-cabinet-slice';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
//styles
import { headerStyles } from '../styles/styles';

interface IHeaderNewUIProps {
  isShowBillingInfo: boolean;
  currentRole: IRole;
  currentPaymentPlan: ICurrentPaymentPlan;
  companyData: ICompanyAll[];
  unviewedItemCounts: IUnviewedItem;
  anchorRef: React.MutableRefObject<HTMLButtonElement>;
  userMe: IUserMe;
}

const HeaderNewUI: FC<IHeaderNewUIProps> = ({
  isShowBillingInfo,
  currentRole,
  currentPaymentPlan,
  companyData,
  unviewedItemCounts,
  anchorRef,
  userMe,
}) => {
  const [isOpenFileCabinetModal, setIsOpenFileCabinetModal] = useState(false);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const projectId = window.location.pathname.split('/')?.[2];

  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { data: preference } = useGetProfilePreferenceQuery();
  const { data: project } = useGetProjectByIdQuery(
    { id: Number(projectId) },
    { skip: !(Number(projectId) && window.location.pathname.includes('project-dashboard')) }
  );

  const props = { isShowBillingInfo, currentRole, currentPaymentPlan };

  const handleResetBreadCrumbs = useCallback(() => {
    if (location.pathname.includes('file-cabinet')) {
      dispatch(handleSetBreadCrumbs([]));
      localStorage.removeItem('fileCabinetBreadCrumbs');
    }
  }, [location]);

  useEffect(() => {
    if (!isOpenFileCabinetModal) {
      if (!!hash) {
        navigate(window.location.pathname + window.location.search, { replace: true });
      }
    }
  }, []);

  const handleOpenFileCabinet = () => {
    setIsOpenFileCabinetModal(true);
  };

  const handleCloseFileCabinetModal = useCallback(() => {
    setIsOpenFileCabinetModal(false);
    navigate(`${location.pathname}`);
    dispatch(handleSetBreadCrumbs([]));
    dispatch(handleSetSearchValue(null));
    localStorage.removeItem('fileCabinetBreadCrumbs');
  }, [location]);

  const showButtons =
    location.pathname.includes('project-dashboard') ||
    (location.pathname.includes('selections') &&
      !location.pathname.includes('selections-library')) ||
    location.pathname.includes('feedbacks') ||
    location.pathname.includes('gallery');

  return (
    <Grid
      container
      component="header"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={headerStyles.header}
    >
      <Grid sx={headerStyles.buttonsContainer}>
        {(location.pathname.includes('account-dashboard') ||
          location.pathname.includes('schedule-template') ||
          location.pathname.includes('project-settings')) && (
          <Grid item sx={headerStyles.logoContainer}>
            <Link to="/account-dashboard">
              <BuilderPadLogo />
            </Link>
          </Grid>
        )}

        {location.pathname.includes('guest-gallery') && (
          <Grid item sx={headerStyles.logoContainer}>
            <Link to={!!userMe ? '/account-dashboard' : '/sign-in'}>
              <BuilderPadLogo />
            </Link>
          </Grid>
        )}

        {!location.pathname.includes('account-dashboard') &&
          !location.pathname.includes('schedule-template') &&
          !location.pathname.includes('project-settings') &&
          !location.pathname.includes('guest-gallery') && (
            <Grid item sx={headerStyles.logoContainerHome}>
              <Link to="/project-dashboard" onClick={handleResetBreadCrumbs}>
                <HomeIcon />
              </Link>
            </Grid>
          )}

        {companyData.length > 1 && <CompaniesButton companyData={companyData} userMe={userMe} />}

        {showButtons && (
          <>
            <ProjectsButton />
            {userPermissions?.MANAGE_SELECTIONS?.canRead && <ProjectNav />}
            <AccountsButton project={project} />

            <SettingsButton />
          </>
        )}
      </Grid>

      <Grid item sx={headerStyles.popperButtonContainer}>
        <Grid sx={headerStyles.actionButtons}>
          {showButtons && (
            <>
              {userPermissions?.VIEW_PROJECT_FOLDERS?.canRead && (
                <Grid sx={headerStyles.buttonContainer}>
                  <Button
                    ref={anchorRef}
                    variant="outlined"
                    startIcon={<FileCabinetIcon />}
                    onClick={handleOpenFileCabinet}
                    sx={headerStyles.fileCabinetButton}
                  />
                </Grid>
              )}

              <ActivityButton
                projectId={Number(projectId)}
                unviewedItemsCount={unviewedItemCounts?.messages}
              />
              <NotificationButton
                projectId={Number(projectId)}
                unviewedItemsCount={unviewedItemCounts?.notifications}
              />
              <AlertButton
                itemsCount={unviewedItemCounts?.overdue ?? 0}
                unviewedItemsCount={unviewedItemCounts?.unviewedOverdue ?? 0}
                projectId={Number(projectId)}
              />
            </>
          )}
        </Grid>

        {(location.pathname.includes('guest-gallery') && !!userMe) ||
        !location.pathname.includes('guest-gallery') ? (
          <ProfilePopperButton {...props} />
        ) : (
          <AuthButtons />
        )}
      </Grid>

      {preference?.downloadMobileApp === DonwloadMobileAppEnum.OFFER_DOWNLOAD && (
        <AppPrompt anchorRef={anchorRef} isProgressTrackOpen={preference?.isProgressTrackOpen} />
      )}

      {isOpenFileCabinetModal && (
        <ProjectFileCabinetModal
          open={isOpenFileCabinetModal}
          handleClose={handleCloseFileCabinetModal}
          title={project?.name}
          projectId={projectId}
          projectFolderId={project?.folderId}
        />
      )}
    </Grid>
  );
};

export default HeaderNewUI;
