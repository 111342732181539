export const firstAddressHelper = (address: string) => {
  if (!address) {
    return address;
  }

  const elements = address?.split(/,\s|,/);
  const result = elements?.slice(1)?.join(',');

  if (elements?.length <= 1) {
    return address;
  }

  return result;
};
