import React, { FC, useCallback } from 'react';
// mui components
import { Grid } from '@mui/material';
// components
import NotSupportedFiles from '../not-supported-files';
import Spinner from 'components/spinner';
// styles
import { styles } from './styles';

type TViewDocxProps = {
  src: string;
  name: string;
};

const ViewDocx: FC<TViewDocxProps> = ({ src }) => {
  const previewURL = useCallback(() => {
    if (!src) {
      return null;
    }

    if (src) {
      const splitedURL = src?.split('?');

      if (!splitedURL?.[0]) {
        return false;
      }

      return splitedURL?.[0];
    }
  }, [src]);

  const build = useCallback(() => {
    return 'https://view.officeapps.live.com/op/embed.aspx?src=' + previewURL();
  }, [previewURL]);

  if (!previewURL()) {
    return <Spinner />;
  }

  if (previewURL() === false) {
    return <NotSupportedFiles src={src} />;
  }

  return (
    <Grid sx={styles.container}>
      <iframe src={build()} style={{ outline: 'none' }}></iframe>
    </Grid>
  );
};

export default ViewDocx;
