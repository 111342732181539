export const fontStyles = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
};

export const typoSM = {
  ...fontStyles,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
};

export const typoM = {
  ...fontStyles,
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '136%',
  color: '#23252F',
};

export const actionBtn = {
  ...typoSM,
  textTransform: 'capitalize',
  border: '1px solid #4D6EF5',
  borderRadius: '8px',
  height: 'unset',
  padding: '12px 16px',
  '&.Mui-disabled': {
    opacity: '0.7',
  },
};

export const styles = {
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '750px',
      width: '100%',
      borderRadius: '12px',
      maxHeight: '95%',
    },
    '&.storage-modal .MuiPaper-root': {
      maxWidth: '466px',
    },
    '&.fullwidth .MuiPaper-root': {
      maxWidth: '95%',
      height: '95%',
    },
  },
  dialogTitleWrapper: {
    padding: '40px',
    paddingBottom: '20px',
    borderBottom: '1px solid #f3f4f9',
    position: 'relative',
    '&.storage-modal': {
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  dialogTitle: {
    ...typoM,
    textTransform: 'capitalize',
  },
  dialogSubtitle: {
    ...typoSM,
    color: '#23252F',
  },
  iconBtn: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    padding: 0,
  },

  dialogContent: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    overflowX: 'hidden',
    height: '100%',

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '&.storage-modal': {
      padding: '20px 0',
    },
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },

  dialogContentWithoutPadding: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    overflowX: 'hidden',
    height: '100%',

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '&.storage-modal': {
      padding: '20px 0',
    },
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },

  actions: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px',
    '& .btn': {
      ...actionBtn,
      color: '#4D6EF5',
      backgroundColor: '#fff',
      '&:hover': {
        opacity: '0.8',
      },
    },
    '& .filled-btn': {
      color: '#fff',
      backgroundColor: '#4D6EF5',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4D6EF5',
        opacity: '0.8',
      },
    },
  },
};
