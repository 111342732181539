import { IEmailNotificationSetting, IPushNotificationSetting } from 'redux/types';
import { companyRolePermissionsMapping } from 'redux/mappings/company-role-permissions';
import { TFileCabinetRole } from '../file-cabinet';

export interface IPermissionsScope {
  canRead: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export enum PERMISSION_CATEGORIES_ENUM {
  ACTIVITY_FEED = 'ACTIVITY_FEED',
  SCHEDULE = 'SCHEDULE',
  SELECTIONS = 'MANAGE_SELECTIONS',
  INVOICES = 'INVOICES',
  FEEDBACK = 'FEEDBACK',
  ACCOUNT = 'ACCOUNT',
  FILE_CABINET = 'FILE_CABINET',
}

export enum PERMISSION_UI_TYPES_ENUM {
  TOGGLE = 'TOGGLE',
  CHECKBOX = 'CHECKBOX',
}

export enum PERMISSIONS_SUBJECT_ENUM {
  ACTIVITY_FEED_ALL_POSTS = 'ACTIVITY_FEED_ALL_POSTS',
  ACTIVITY_FEED_MY_POSTS = 'ACTIVITY_FEED_MY_POSTS',
  ACTIVITY_FEED_AUDIENCE = 'AUDIENCES',
  MANAGE_SCHEDULE = 'MANAGE_SCHEDULE',
  WORK_DAYS = 'WORK_DAYS',
  SCHEDULE_TEMPLATE = 'SCHEDULE_TEMPLATES',
  SCHEDULE_PHASE_DATES = 'SCHEDULE_PHASE_DATES',
  SCHEDULE_ITEM_DATES = 'SCHEDULE_ITEM_DATES',
  SELECTION_MANAGE = 'MANAGE_SELECTIONS',
  SELECTION_APPROVE = 'APPROVE_SELECTIONS',
  UNAPPROVED_SELECTIONS = 'UNAPPROVED_SELECTIONS',
  INVOICES = 'INVOICES',
  INVOICES_PAY = 'PAY_INVOICES',
  INVOICES_MARK_AS_PAID = 'MARK_AS_PAID',
  FEEDBACK = 'FEEDBACK',
  FEEDBACK_PROVIDE = 'PROVIDE_FEEDBACK',
  MANAGE_PROJECT = 'PROJECT',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  COMPANY_MANAGEMENT = 'COMPANY_MANAGEMENT',
  BILLING = 'BILLING',
  CONTRACTS = 'CONTRACTS',
  INSPECTIONS = 'INSPECTIONS',
  PERMIT = 'PERMITS',
  PLANS = 'PLANS',
  QUOTES = 'QUOTES',
  SPEC_SHEETS = 'SPEC_SHEETS',
  SURVEYS = 'SURVEYS',
  CREATE_FOLDERS = 'CREATE_FOLDERS',
  INVOICES_FILE_CABINET = 'INVOICES_FILE_CABINET',
  SELECTIONS_FILE_CABINET = 'SELECTIONS_FILE_CABINET',
  ESTIMATES = 'ESTIMATES',
  FILE_CABINET_DEFAULT_FOLDERS = 'FILE_CABINET_DEFAULT_FOLDERS',
  VIEW_PROJECT_FOLDERS = 'VIEW_PROJECT_FOLDERS',
  MANAGE_FILE_CABINET = 'MANAGE_FILE_CABINET',
  COST_CODE = 'COST_CODE',
  SHOW_GANTT_VIEW = 'SHOW_GANTT_VIEW',
  ASSIGN_CHECKLIST_ITEMS = 'ASSIGN_CHECKLIST_ITEMS',
  MANAGE_CHECKLIST_ITEMS = 'MANAGE_CHECKLIST_ITEMS',
}

export interface ICompanyRole {
  id: number;
  name: string;
}

export enum AccessOptionsEnum {
  CanRead = 'canRead',
  CanAddUpdate = 'canAddUpdate',
  CanDelete = 'canDelete',
}

export interface ICompanyRolePermission extends IPermissionsScope {
  id: number;
  permissionSubject: PERMISSIONS_SUBJECT_ENUM;
  type: PERMISSION_UI_TYPES_ENUM;
  group: PERMISSION_CATEGORIES_ENUM;
  index: number;
  companyId: number;
  role: {
    id: number;
    name: string;
    companyId: number;
  };
}

export interface ICreateCompanyRolesBody {
  name: string;
  items: Pick<
    ICompanyRolePermission,
    'permissionSubject' | 'canRead' | 'canCreate' | 'canUpdate' | 'canDelete'
  >[];
  pushNotifications: (Omit<IPushNotificationSetting, 'id' | 'notificationEvent'> & {
    pushNotificationEvent: IPushNotificationSetting['notificationEvent'];
  })[];
  emailNotifications: Omit<IEmailNotificationSetting, 'id'>[];
}

export interface IDeleteCompanyRolesBody {
  id: number;
  items: {
    profileId: number;
    roleId: number;
  }[];
}

export interface IUpdateCompanyRolesBody {
  id: number;
  data: {
    name: string;
  };
}

export interface IGetCompanyRolePermissionsArgs {
  roleId?: number;
}

export type TCompanyRolePermissionsMe = Record<PERMISSIONS_SUBJECT_ENUM, IPermissionsScope>;

export interface ICreateRolePermissionsBody extends IPermissionsScope {
  companyId: number;
  roleId: number;
  permissionsSubject: PERMISSIONS_SUBJECT_ENUM;
}

export interface IUpdateRolePermissionItem extends IPermissionsScope {
  id: number;
}

export interface IUpdateRolePermissionsBody {
  items: IUpdateRolePermissionItem[];
  fileCabinetItems: IUpdateRolePermissionItem[];
}

export interface IUpdateRoleFolderPermissionsBody {
  itemId: string | number;
  roles: TFileCabinetRole[];
  withOptimisticUpdate: boolean;
  roleId?: string | number;
  access?: AccessOptionsEnum;
  value?: boolean;
}

export interface IUpdateRolePermissionsResponse {
  items: ICompanyRolePermission[];
}

export type TCompanyRolePermissionsResponse = ReturnType<typeof companyRolePermissionsMapping>;

export enum PERMISSIONS_CHECKBOXES_SUBJECTS_ENUM {
  VIEW = 'View',
  ADD_EDIT = 'Add/Edit',
  DELETE = 'Delete',
}

export type TDisabledSubjects = Partial<
  Record<PERMISSIONS_SUBJECT_ENUM, Partial<IPermissionsScope>>
>;

export type TPermissionSubjectTitle = Record<PERMISSIONS_SUBJECT_ENUM, string>;
