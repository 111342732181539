import React, { useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
// mui components
import { ClickAwayListener, Grow, Paper, MenuList, Button, Typography, Grid } from '@mui/material';
import Popper from '@mui/material/Popper';
// api
import { api } from 'redux/api';
// custom hooks
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
// icons
import { ReactComponent as CompaniesIcon } from 'assets/icons/header/companies.svg';
import { ReactComponent as ChevronsIcon } from 'assets/icons/buttons/chevrons.svg';
// slices
import { handleSetCompanyId } from 'redux/slices/auth-slice';
//styles
import { companiesButtonStyles } from '../styles/styles';

const CompaniesButton = ({ companyData, userMe }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const dispatch = useAppDispatch();

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const setCompanyHandler = (id) => {
    localStorage['company-id'] = id;
    dispatch(handleSetCompanyId(String(id)));
    //@ts-ignore
    cache.clear();
    dispatch(api.util.resetApiState());
    navigate('/');
  };

  return (
    <Grid sx={companiesButtonStyles.buttonContainer}>
      <Button
        ref={anchorRef}
        variant="outlined"
        id="companies-list-button"
        aria-controls={open ? 'companies-list-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={<CompaniesIcon />}
        endIcon={<ChevronsIcon />}
        sx={companiesButtonStyles.container}
      >
        <Typography sx={companiesButtonStyles.buttonContent}>
          {companyData?.find((company) => String(company?.id) === localStorage['company-id'])?.name}
        </Typography>
      </Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        sx={companiesButtonStyles.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={companiesButtonStyles.popper}>
            <Paper sx={companiesButtonStyles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="companies-list"
                  aria-labelledby="companies-list"
                  sx={companiesButtonStyles.companiesListContainer}
                >
                  <Grid container sx={companiesButtonStyles.paperContentContainer}>
                    <Typography sx={companiesButtonStyles.popperTitle}>Companies</Typography>
                    {companyData
                      ?.filter((company) => String(company?.id) !== localStorage['company-id'])
                      ?.map((company) => (
                        <Grid
                          key={company?.id}
                          sx={companiesButtonStyles.companiesListContent}
                          onClick={() => {
                            setCompanyHandler(company?.id);
                          }}
                        >
                          <Grid container sx={companiesButtonStyles.popperItem}>
                            <Typography sx={companiesButtonStyles.popperItemTitle}>
                              {company?.name}
                            </Typography>
                            <Typography sx={companiesButtonStyles.popperItemSubtitle}>
                              {company?.projectsCount} projects
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default CompaniesButton;
