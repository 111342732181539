import React, { memo, FC, useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
// pages
import ErrorPage from 'pages/error-page';
// components
import Spinner from './spinner';
// custom hooks
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useUserInfo } from 'hooks/useUserInfo';
// slices
import { setDefaultArgs } from 'redux/slices/query-args-slice';
// selectors
import { accountStepperSelector, authSelector } from 'redux/selector';
// api
import { useGetMeQuery } from 'redux/api/auth';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
// helpers
import { isErrorWithData } from 'redux/helpers/is-error-with-data';
import DeploymentPage from 'pages/deployment-page';
import InactiveAccountPage from 'pages/inactive-account-page';

interface RouteWrapperProps {
  path?: string;
  component: React.ComponentType;
  role: string | 'common' | 'unauthorized' | 'authorized';
}

const TO_ACCOUNT_DASHBOARD = ['sign-up', 'sign-in', 'forgot-password', 'reset-password'];

const TO_SIGN_IN = [
  'sign-up',
  '404',
  'forgot-password',
  'reset-password',
  'sign-up-invite',
  'invite-canceled',
];

const RouteWrapper: FC<RouteWrapperProps> = ({ component: RouteComponent, role, path }) => {
  const dispatch = useAppDispatch();
  const { isAuth, companyId, isInactive } = useAppSelector(authSelector);
  const { isDeploying } = useAppSelector(authSelector);
  const { completed } = useAppSelector(accountStepperSelector);
  const { currentRole, isOwner } = useUserInfo();
  const [searchParams] = useSearchParams();

  const {
    data: userMe,
    error,
    isSuccess,
    isLoading,
  } = useGetMeQuery(undefined, {
    skip: !isAuth || searchParams.has('entryToken'),
  });

  const {
    data: userPermissions,
    isLoading: isUserPermissionsLoading,
    error: userPermissionsError,
  } = useGetCompanyRolePermissionsMeQuery(undefined, {
    skip: !userMe || !companyId,
  });

  const routePath = window.location.pathname.split('/')[1];

  const isAuthorized =
    isSuccess || (isErrorWithData(error) && error.data.message === `User doesn't have profile`);

  const navigateToAccountDashboard =
    ((userMe && TO_ACCOUNT_DASHBOARD.find((item) => item === routePath)) ||
      (userMe &&
        routePath === 'sign-up-invite' &&
        (!searchParams.has('token') || !searchParams.has('companyId'))) ||
      (routePath.includes('account-setup') && completed)) &&
    userPermissions;

  const navigateToSignIn =
    !isAuth &&
    !(window.location.pathname.includes('sign-in') && searchParams.has('token')) &&
    // !window.location.pathname.includes('shared-gallery') &&
    TO_SIGN_IN.every((item) => !window.location.pathname.includes(item)) &&
    routePath !== 'sign-in';

  const navigateToBilling =
    currentRole &&
    currentRole?.isSubscription === false &&
    isOwner &&
    !routePath.includes('billing') &&
    !userPermissionsError;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const promoCode = queryParams.get('promoCode');

  const navigateToAccountSetup = completed === false && !routePath.includes('account-setup');

  useEffect(() => {
    dispatch(setDefaultArgs());
  }, [path]);

  if (isInactive) {
    return <InactiveAccountPage />;
  }

  if (isDeploying) {
    return <DeploymentPage />;
  }

  if (navigateToSignIn) {
    return <Navigate to="/sign-in" />;
  }

  if ((isLoading && !error) || (isUserPermissionsLoading && !userPermissionsError)) {
    return <Spinner />;
  }

  if (navigateToBilling) {
    return <Navigate to="/billing" />;
  }

  if (navigateToAccountSetup) {
    if (promoCode) {
      localStorage.setItem('promoCode', JSON.stringify(queryParams.get('promoCode')));
    }

    return <Navigate to="/account-setup" />;
  }

  if (navigateToAccountDashboard) {
    return <Navigate to="/account-dashboard" />;
  }

  if (
    (isAuthorized && role === 'authorized') ||
    (!isAuthorized && role === 'unauthorized') ||
    role === 'common'
  ) {
    return <RouteComponent />;
  }

  return <ErrorPage />;
};

export default memo(RouteWrapper);
