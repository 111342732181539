import { useCallback, useState } from 'react';

export const useOnScreen = (options?: {
  root: Element | null;
  rootMargin: string;
  threshold: number;
}) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const [observer, setObserver] = useState<IntersectionObserver>(null);

  const bottomRef = useCallback((node: HTMLDivElement | HTMLLIElement) => {
    if (node) {
      const intersectionObserver = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, options ?? { root: null, rootMargin: '0px', threshold: 0.5 });

      intersectionObserver.observe(node);
      setObserver(intersectionObserver);
    }
  }, []);

  return { bottomRef, isIntersecting, observer };
};
