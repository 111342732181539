import { ReactComponent as FolderIcon } from 'assets/icons/cabinet/folder-icon-sm.svg';
import { ReactComponent as DOCIcon } from 'assets/icons/cabinet/DOC.svg';
import { ReactComponent as CSVIcon } from 'assets/icons/cabinet/CSV.svg';
import { ReactComponent as PDFIcon } from 'assets/icons/cabinet/PDF.svg';
import { ReactComponent as JPGIcon } from 'assets/icons/cabinet/JPG.svg';
import { ReactComponent as PNGIcon } from 'assets/icons/cabinet/PNG.svg';
import { ReactComponent as GIFIcon } from 'assets/icons/cabinet/GIF.svg';
import { ReactComponent as WEBPIcon } from 'assets/icons/cabinet/WEBP.svg';
import { ReactComponent as TiffIcon } from 'assets/icons/cabinet/TIFF.svg';
import { ReactComponent as AvifIcon } from 'assets/icons/cabinet/AVIF.svg';
import { ReactComponent as HEICIcon } from 'assets/icons/cabinet/HEIC.svg';
import { ReactComponent as HEIFIcon } from 'assets/icons/cabinet/HEIF.svg';
import { ReactComponent as XlsIcon } from 'assets/icons/cabinet/XLS.svg';

import { FileCabinetLocation, FileCabinetUserPermission, IFolder } from 'redux/api/file-cabinet';
import { ICompanyRole, PERMISSION_UI_TYPES_ENUM } from 'redux/api/company-roles';
import { IProjectProfiles } from 'redux/api/projects';
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form';
import { IRolePermissionsForm } from 'utils/types';

export const getRowIcon = (type: string, mimeType: string) => {
  switch (type) {
    case 'file':
      switch (mimeType) {
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.ms-powerpoint':
        case 'application/msword':
          return DOCIcon;

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          return XlsIcon;

        case 'image/jpeg':
        case 'image/jpg':
          return JPGIcon;

        case 'image/png':
          return PNGIcon;

        case 'image/gif':
          return GIFIcon;

        case 'image/webp':
          return WEBPIcon;

        case 'image/tiff':
          return TiffIcon;

        case 'image/avif':
          return AvifIcon;

        case 'image/heic':
          return HEICIcon;

        case 'image/heif':
          return HEIFIcon;

        case 'text/csv':
          return CSVIcon;

        case 'application/pdf':
          return PDFIcon;

        default:
          return DOCIcon;
      }

    case 'folder':
      return FolderIcon;

    default:
      return DOCIcon;
  }
};

export const PERMISSION_SUBJECT_CONSTANTS = {
  Surveys: 'SURVEYS',
  Selections: 'SELECTIONS_FILE_CABINET',
  Plans: 'PLANS',
  Permits: 'PERMITS',
  Invoices: 'INVOICES_FILE_CABINET',
  Inspections: 'INSPECTIONS',
  Estimates: 'ESTIMATES',
  Contracts: 'CONTRACTS',
};

export const destinationFoldersOptions = (data: IFolder[]) => {
  if (!data?.length) {
    return [];
  }

  const foldersList = data?.map((folder) => ({
    label: folder.name,
    value: folder.id,
  }));

  return foldersList;
};

export const defaultRolesHelper = (roles: ICompanyRole[]) => {
  if (!!roles?.length) {
    const roles1 = roles?.map((role) => ({
      id: role?.id,
      name: role?.name,
      permissions: {
        canRead: false,
        canAddUpdate: false,
        canDelete: false,
      },
    }));

    return roles1;
  }

  return [];
};

export const handleFileSizeCheck = (size: string | number) => {
  if (size) {
    const inMegabytes = Number(size) / (1024 * 1024);
    const inGigabytes = Number(size) / (1024 * 1024 * 1024);

    if (inGigabytes > 1) {
      return `${inGigabytes} GB`;
    }

    if (inMegabytes > 1) {
      return `${inMegabytes} MB`;
    }

    if (inMegabytes < 1 && inMegabytes > 0) {
      return `${inMegabytes?.toFixed(4)} MB`;
    }

    return `${size} B`;
  }

  return `0 B`;
};

export const projectCabinetUsersList = (profiles) => {
  if (!profiles || !profiles?.length) {
    return [];
  }

  const usersList = profiles?.map((user: IProjectProfiles) => ({
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    avatar: user?.avatar || null,
    role: user?.role,
    jobTitle: user?.jobTitle,
    isOwner: user?.isOwner,
    permissions: {
      canRead: false,
      canAddUpdate: false,
      canDelete: false,
    },
  }));

  return usersList;
};

export const postProjectCabinetUsersList = (profiles) => {
  if (!profiles || !profiles?.length) {
    return [];
  }

  const usersList = profiles?.map((user) => {
    return {
      profileId: user?.id,
      permissions: {
        canRead: user?.permissions?.canRead,
        canAddUpdate: user?.permissions?.canAddUpdate,
        canDelete: user?.permissions?.canDelete,
      },
    };
  });

  return usersList;
};

export const checkUserAddAccess = (
  publicFolders: boolean,
  cabinetLocation: string,
  canCreate: boolean,
  myPermissions: FileCabinetUserPermission
) => {
  if (publicFolders) {
    return false;
  }

  if (!canCreate) {
    return false;
  }

  if (canCreate) {
    if (cabinetLocation !== FileCabinetLocation?.INSIDE_FOLDER) {
      return true;
    }

    if (cabinetLocation === FileCabinetLocation?.INSIDE_FOLDER && !!myPermissions) {
      return myPermissions?.canAddUpdate;
    }
  }

  return false;
};

export const fileCabinetPermissionsField = (
  fields: FieldArrayWithId<IRolePermissionsForm, 'permissions', 'id'>[]
) => {
  const cabinetPermissionsField = fields?.find(({ title }) => title === 'File cabinet');

  if (!cabinetPermissionsField) {
    return [];
  }

  const sortedPermissions = cabinetPermissionsField?.items?.sort((a, b) => {
    if (
      a.type === PERMISSION_UI_TYPES_ENUM?.TOGGLE &&
      b.type === PERMISSION_UI_TYPES_ENUM?.CHECKBOX
    ) {
      return -1;
    } else if (
      a.type === PERMISSION_UI_TYPES_ENUM?.CHECKBOX &&
      b.type === PERMISSION_UI_TYPES_ENUM?.TOGGLE
    ) {
      return 1;
    } else if (
      a.type === PERMISSION_UI_TYPES_ENUM?.TOGGLE &&
      b.type === PERMISSION_UI_TYPES_ENUM?.TOGGLE
    ) {
      const titleOrder = {
        CREATE_FOLDERS: 1,
        MANAGE_FILE_CABINET: 2,
        FILE_CABINET_DEFAULT_FOLDERS: 3,
        VIEW_PROJECT_FOLDERS: 4,
      };

      return titleOrder[a.permissionSubject] - titleOrder[b.permissionSubject];
    } else {
      return 0;
    }
  });

  return sortedPermissions;
};
