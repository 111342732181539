import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
// layouts
import { IAddUsersForm } from 'layouts/manage-users-form';
// components
import AddUsersNewUI from './new-ui';
// custom hooks
import { useProjectStepper } from 'hooks/useProjectStepperState';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// api
import { useGetMeQuery } from 'redux/api/auth';
import {
  useUpdateProjectMutation,
  IProject,
  useGetProjectsProfilesManagementQuery,
} from 'redux/api/projects';
import { useInvitesBulkMutation } from 'redux/api/invites';
// validations
import { AddUsersSchema } from 'layouts/manage-users-form/validation';
// selectors
import { authSelector } from 'redux/selector';

interface IProps {
  redirect?: boolean;
  handleCancel: () => void;
  handleMutate: (resultProject: IProject) => void;
}

const AddUsers: React.FC<IProps> = ({ redirect, handleCancel, handleMutate }) => {
  const [updateProject, { isLoading: isUpdateProjectLoading }] = useUpdateProjectMutation();
  const [invitesBulk, { isLoading: isInvitesBulkLoading }] = useInvitesBulkMutation();

  const navigate = useNavigate();

  const { projectId, isLastStep } = useProjectStepper();

  const { isAuth } = useAppSelector(authSelector);

  const { data: userMe } = useGetMeQuery(undefined, {
    skip: !isAuth,
  });

  const { data: profilesManagement } = useGetProjectsProfilesManagementQuery(
    { id: Number(projectId) },
    { skip: !Number(projectId) }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const form = useForm<IAddUsersForm>({
    resolver: yupResolver(AddUsersSchema),
  });

  const formProfileIds = useWatch({
    control: form.control,
    name: 'profilesIds',
  });

  const formProfileInvites = useWatch({
    control: form.control,
    name: 'invites',
  });

  const formIsEmpty = useMemo(
    () =>
      (formProfileIds?.length === 0 && formProfileInvites?.length === 0) ||
      (formProfileIds?.length === 0 && !formProfileInvites) ||
      (!formProfileIds && formProfileInvites?.length === 0) ||
      (!formProfileIds && !formProfileInvites),
    [formProfileIds, formProfileInvites]
  );

  const handleClose = () => {
    handleCancel();
  };

  const onSubmit = useCallback(async () => {
    if (projectId) {
      const [profilesIds, invites] = form.getValues(['profilesIds', 'invites']);

      if (invites.length && profilesIds.length) {
        invitesBulk({
          invites: invites.map(({ id, email, companyRole, delete: deleteInvite, title }) => ({
            id,
            email: id ? undefined : email,
            roleId: companyRole.id,
            inviteProjects: [{ projectId, delete: deleteInvite }],
            title,
          })),
        })
          .unwrap()
          .finally(() => {
            updateProject({
              id: projectId,
              data: { profilesIds },
            })
              .unwrap()
              .then((resultProject) => {
                handleMutate(resultProject);
                window.dispatchEvent(new CustomEvent('project_updated'));
                handleClose();
              })
              .catch(() => {});
          });
      } else if (invites.length) {
        invitesBulk({
          invites: invites.map(({ id, email, companyRole, delete: deleteInvite, title }) => ({
            id,
            email: id ? undefined : email,
            roleId: companyRole.id,
            inviteProjects: [{ projectId, delete: deleteInvite }],
            title,
          })),
        });
      } else if (profilesIds.length) {
        updateProject({
          id: projectId,
          data: { profilesIds },
        })
          .unwrap()
          .then((resultProject) => {
            handleMutate(resultProject);
            window.dispatchEvent(new CustomEvent('project_updated'));
            handleClose();

            if (redirect) {
              navigate(`/project-dashboard/${projectId}`);
            }
          })
          .catch(() => {});
      }
    }
  }, [projectId, form, navigate, updateProject, invitesBulk]);

  const profiles = useMemo(
    () =>
      profilesManagement
        ? [
            ...profilesManagement.assignedProfiles,
            ...profilesManagement.otherProfiles,
            ...profilesManagement.otherInvites,
            ...profilesManagement?.assignedInvites,
          ]
        : [],
    [profilesManagement]
  );

  const initialValues = useMemo(
    () => ({
      invites:
        profilesManagement?.assignedInvites.map(({ id, email, companyRole, title }) => ({
          id,
          companyRole,
          email: email,
          title,
        })) ?? [],
      profilesIds:
        profilesManagement?.assignedProfiles.map(({ id }) => id) ?? isLastStep ? [userMe.id] : [],
    }),
    [profilesManagement, userMe, isLastStep]
  );

  const props = {
    form,
    profiles,
    formIsEmpty,
    loading: isUpdateProjectLoading || isInvitesBulkLoading,
    onSubmit,
    handleClose,
    userMe,
    initialValues,
    projectId,
  };

  return <AddUsersNewUI {...props} />;
};

export default AddUsers;
