import { FormProvider } from 'react-hook-form';
// layouts
import CreateProjectForm from 'layouts/create-project-form';
// components
import StepperButtons from '../../../components/stepper-buttons';
import StyledForm from 'components/styled-form';

const CreateProjectNewUI = ({
  form,
  onSubmit,
  country,
  coordinates,
  isFirstProject,
  updateCoordinates,
  updateAddress,
  loading,
  updateLoading,
  handleCountryChange,
}) => {
  return (
    <FormProvider {...form}>
      <StyledForm sx={{ width: '100%' }} onSubmit={form.handleSubmit(onSubmit)}>
        <CreateProjectForm
          country={country}
          coordinates={coordinates}
          isFirstProject={isFirstProject}
          updateCoordinates={updateCoordinates}
          updateAddress={updateAddress}
          handleCountryChange={handleCountryChange}
        />
        <StepperButtons loading={loading || updateLoading} />
      </StyledForm>
    </FormProvider>
  );
};

export default CreateProjectNewUI;
