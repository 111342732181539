import React, { useContext, FC, useCallback } from 'react';
// mui components
import { Card, Grid, Typography } from '@mui/material';
// components
import CustomRolesGroup from 'components/custom-roles-group';
import CustomAvatarGroup from 'components/custom-avatar-group';
import CustomTooltip from 'components/custom-tooltip';
import ActionsPopper from '../../poppers/actions-popper';
import LinkContainer from '../link-container';
import ActionsModals from '../actions-modals';
import { ActionsContext } from '../folder-view';
// types
import { FileCabinetTypeEnum, IFolder } from 'redux/api/file-cabinet';
// utils
import { getRowIcon } from 'utils/helpers/fileCabinetHelpers';
// icons
import { ReactComponent as DotIcon } from 'assets/icons/cabinet/dot.svg';
// styles
import { styles } from './styles';
import { convertBytes } from 'utils/helpers';

type Props = {
  folder?: IFolder;
  fromModal: boolean;
  projectFolderId?: string | number;
  type: string;
  projectId?: string | number;
  publicFolders?: boolean;
};

const CustomCard: FC<Props> = ({
  folder,
  fromModal,
  type,
  projectFolderId,
  projectId,
  publicFolders,
}) => {
  const { handleShowImage } = useContext(ActionsContext);
  const Icon = getRowIcon(type, folder?.mimeType);

  const handleOpenFile = useCallback(() => {
    if (type === FileCabinetTypeEnum.File) {
      handleShowImage(folder?.id);
    }
  }, [type, folder]);

  return (
    <>
      <Grid sx={styles.container}>
        <LinkContainer
          handleOpenFile={handleOpenFile}
          publicFolders={publicFolders}
          style={styles.cardWrapper}
          folder={folder}
          type={type}
        >
          <Card sx={styles.card}>
            <Grid sx={styles.cardHeader}>
              <Icon
                className={folder?.type === FileCabinetTypeEnum?.Folder ? 'l-icon' : 'file-icon'}
              />

              {!publicFolders && (
                <ActionsPopper
                  folder={folder}
                  type={type}
                  fromModal={fromModal}
                  projectId={projectId}
                />
              )}
            </Grid>
            <Grid sx={styles.cardContent}>
              <Grid sx={styles.contentContainer}>
                <CustomTooltip title={folder?.name}>
                  <Typography sx={styles.folderName}>{folder?.name}</Typography>
                </CustomTooltip>

                {(fromModal || publicFolders) && (
                  <Grid sx={styles.details}>
                    <Typography sx={styles.typo}>
                      {convertBytes(Number(folder?.size), 2)}
                    </Typography>

                    {folder?.type === FileCabinetTypeEnum?.Folder && <DotIcon />}

                    {folder?.type === FileCabinetTypeEnum?.Folder && (
                      <Typography sx={styles.typo}>
                        {!!folder?.itemsCount ? folder?.itemsCount : '0'}{' '}
                        {!!folder?.itemsCount && Number(folder?.itemsCount) === 1
                          ? 'file'
                          : 'files'}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>

              {!!fromModal && !publicFolders && <CustomAvatarGroup users={folder?.users || []} />}
            </Grid>
          </Card>
        </LinkContainer>

        {!fromModal && !publicFolders && (
          <CustomRolesGroup roles={folder?.roles || []} folder={folder} fromCard />
        )}
      </Grid>

      <ActionsModals
        type={type}
        folder={folder}
        fromModal={fromModal}
        projectFolderId={projectFolderId}
        projectId={projectId}
        publicFolders={publicFolders}
      />
    </>
  );
};

export default CustomCard;
