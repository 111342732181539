import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILinkPopper } from 'redux/types';

export interface IManageGanttSlice {
  allowRefetch: boolean;
  refetchSucceeded: boolean;
  tab: boolean;
  refetchLoader: boolean;
  allowUpdateLink: boolean;
  showLinkPopper: boolean;
  linkPopperProps: ILinkPopper;
  changedFromDatePicker: boolean;
}

const initialState: IManageGanttSlice = {
  allowRefetch: false,
  refetchSucceeded: false,
  changedFromDatePicker: false,
  tab: false,
  refetchLoader: false,
  allowUpdateLink: false,
  showLinkPopper: false,
  linkPopperProps: {
    source: null,
    target: null,
    valid: false,
  },
};

export const manageGanttSlice = createSlice({
  name: 'manage_gantt',
  initialState,
  reducers: {
    handleAllowGanttRefetch: (state, action: PayloadAction<boolean>) => {
      state.allowRefetch = action.payload;
    },

    handleSetSucceeded: (state, action: PayloadAction<boolean>) => {
      state.refetchSucceeded = action.payload;
    },

    handleSetGanttTab: (state, action: PayloadAction<boolean>) => {
      state.tab = action.payload;
    },

    handleShowRefetchLoader: (state, action: PayloadAction<boolean>) => {
      state.refetchLoader = action.payload;
    },

    handleUpdateLink: (state, action: PayloadAction<boolean>) => {
      state.allowUpdateLink = action.payload;
    },

    handleShowLinkPopper: (state, action: PayloadAction<boolean>) => {
      state.showLinkPopper = action.payload;
    },

    handleUpdateLinkProps: (state, action: PayloadAction<ILinkPopper>) => {
      state.linkPopperProps = action.payload;
    },

    handleChangedFromDatePicker: (state, action: PayloadAction<boolean>) => {
      state.changedFromDatePicker = action.payload;
    },
  },
});

export const {
  handleAllowGanttRefetch,
  handleSetSucceeded,
  handleSetGanttTab,
  handleShowRefetchLoader,
  handleUpdateLink,
  handleShowLinkPopper,
  handleUpdateLinkProps,
  handleChangedFromDatePicker,
} = manageGanttSlice.actions;

export default manageGanttSlice.reducer;
