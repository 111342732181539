import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IChecklistSlice {
  showImportTemplate: boolean;
}

const initialState: IChecklistSlice = {
  showImportTemplate: false,
};

export const checklistSlice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {
    handleShowImportChecklistTemplate: (state, action: PayloadAction<boolean>) => {
      state.showImportTemplate = action.payload;
    },
  },
});

export const { handleShowImportChecklistTemplate } = checklistSlice.actions;

export default checklistSlice.reducer;
