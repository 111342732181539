import { useRef } from 'react';

export const useScrollToBottom = (
  cb: () => void,
  condition: boolean = true,
  projects?: boolean
) => {
  const scrollAreaRef = useRef(null);

  const handleScroll = () => {
    if (scrollAreaRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollAreaRef.current;

      if (projects) {
        if (scrollTop + clientHeight + 10 >= scrollHeight && condition) {
          cb();
        }
      } else {
        if (scrollTop + clientHeight === scrollHeight && condition) {
          cb();
        }
      }
    }
  };

  return { handleScroll, scrollAreaRef };
};
