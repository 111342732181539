import { api } from 'redux/api';
import {
  IChangeDefaultFoldersPermissionsBody,
  IChangeDefaultFoldersPermissionsResposne,
  ICreateDefaultFolderBody,
  ICreateFileBody,
  ICreateFolderBody,
  ICreateFolderResposnse,
  IEditFileCabinetItemBody,
  IEditFileCabinetItemResposne,
  IFileCabinetSelectionUploadURLArgs,
  IFileCabinetSelectionUploadURLResponse,
  IFileCabinetUploadURLArgs,
  IFileCabinetUploadURLResponse,
  IGetDefaultFoldersArgs,
  IGetDefaultFoldersResponse,
  IGetDestinationFoldersArgs,
  IGetFileCabinetByIdArgs,
  IGetFileCabinetByIdResponse,
  IGetFolderByIdFilesArgs,
  IGetFolderDetailsByIdArgs,
  IGetFolderDetailsResponse,
  IPostFileBody,
  IUploadFileCabinetFileResposnse,
  TAddCabinetUserBody,
  TEditCabinetUserBody,
  TGetCostCodeFilesBody,
  TGetCostCodeFilesResponse,
  TGetFolderBreadCrumbsBody,
  TGetFolderBreadCrumbsResponse,
  TGetNewFolderPublicKeyResponse,
  TGetPublicFoldersBody,
  TGetPublicFoldersResponse,
  TGetShowCabinetFileBody,
  TGetShowCabinetFileResponse,
  TGetShowPublicCabinetFileBody,
  TGetShowPublicCabinetFileResponse,
  TMoveFolderOrFileBody,
  TUpdateFoldersVisibilityBody,
  TUpdateFoldersVisibilityResponse,
} from './file-cabinet.types';

import { selectionsApi } from '../selections';

enum Endpoints {
  FILE_CABINET = 'file-cabinet/',
  PERMISSIONS = 'profile-permissions/',
  TEMPLATE = 'template/',
}

export const FILE_CABINET_DEFAULT_FOLDERS = 1;

export const fileCabinetApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFileCabinet: build.query<IGetDefaultFoldersResponse, IGetDefaultFoldersArgs>({
      query: () => ({
        url: `${Endpoints.FILE_CABINET}`,
      }),
      providesTags: ['File Cabinet Folder', 'File Cabinet'],
    }),

    getFileCabinetDefaultFolders: build.query<IGetDefaultFoldersResponse, undefined>({
      query: () => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.TEMPLATE}default-folders`,
      }),
      providesTags: ['File Cabinet Folder', 'File Cabinet'],
      serializeQueryArgs: () => FILE_CABINET_DEFAULT_FOLDERS,
    }),

    getProjectFileCabinetById: build.query<IGetFileCabinetByIdResponse, IGetFileCabinetByIdArgs>({
      query: ({ projectId }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}`,
      }),
      providesTags: ['File Cabinet Folder', 'File Cabinet'],
    }),

    getFileCabinetFolderItems: build.query<IGetDefaultFoldersResponse, IGetFolderByIdFilesArgs>({
      query: ({ folderId, projectId, onlyFiles }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/folder/${folderId}/items${
          onlyFiles ? '?type=file' : ''
        }`,
      }),
      providesTags: ['File Cabinet Folder'],
    }),

    getFileCabinetDefaultItems: build.query<IGetDefaultFoldersResponse, IGetFolderByIdFilesArgs>({
      query: ({ folderId }) => ({
        url: `${Endpoints.FILE_CABINET}default-folders/${folderId}/items`,
      }),
      providesTags: ['File Cabinet Folder'],
    }),

    getFileCabinetFolderDetails: build.query<IGetFolderDetailsResponse, IGetFolderDetailsByIdArgs>({
      query: ({ folderId, projectId }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/folder/${folderId}`,
      }),
      providesTags: ['File Cabinet Folder'],
    }),

    getFileCabinetDefaultFolderDetails: build.query<
      IGetFolderDetailsResponse,
      IGetFolderByIdFilesArgs
    >({
      query: ({ folderId }) => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.TEMPLATE}default-folders/${folderId}`,
      }),
      providesTags: ['File Cabinet Folder'],
    }),

    getFileCabinetUploadURl: build.query<IFileCabinetUploadURLResponse, IFileCabinetUploadURLArgs>({
      query: (params) => ({
        url: `${Endpoints.FILE_CABINET}upload-url`,
        params: params,
      }),
    }),

    getFileCabinetSelectionUploadURl: build.query<
      IFileCabinetSelectionUploadURLResponse,
      IFileCabinetSelectionUploadURLArgs
    >({
      query: (params) => ({
        url: `${Endpoints.FILE_CABINET}selection/upload-url`,
        params: params,
      }),
    }),

    postFileToAWS: build.mutation<IFileCabinetUploadURLResponse, IPostFileBody>({
      query: ({ URL, file, fileType }) => ({
        url: `${URL}`,
        body: file,
        method: 'PUT',
        headers: {
          'Content-Type': fileType,
        },
      }),
    }),

    createFileCabinetFolder: build.mutation<ICreateFolderResposnse, ICreateFolderBody>({
      query: ({ projectId, ...data }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/folder`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['File Cabinet Folder'],
    }),

    createFileCabinetDefaultFolder: build.mutation<
      ICreateFolderResposnse,
      ICreateDefaultFolderBody
    >({
      query: (data) => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.TEMPLATE}default-folder`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['File Cabinet Folder', 'Folder Permissions'],
    }),

    uploadFileCabinetFile: build.mutation<IUploadFileCabinetFileResposnse, ICreateFileBody>({
      query: ({ projectId, fileId, folderId }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/file`,
        body: { fileId: fileId, folderId: folderId },
        method: 'post',
      }),

      onQueryStarted: async (
        { withOptimisticUpdate, projectId, folderId },
        { dispatch, queryFulfilled }
      ) => {
        if (withOptimisticUpdate) {
          const fetch = dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId: Number(projectId) },
              (draft) => {
                const folderIndex = draft?.items?.findIndex(
                  ({ fileCabinetId }) => fileCabinetId === folderId
                );

                if (folderIndex >= 0) {
                  draft.items[folderIndex].itemsCount =
                    Number(draft?.items?.[folderIndex]?.itemsCount) + 1;
                }
              }
            )
          );

          try {
            await queryFulfilled;
          } catch (error) {
            fetch?.undo();
          }
        }
      },

      invalidatesTags: ['File Cabinet Folder'],
    }),

    editFileCabinetItems: build.mutation<IEditFileCabinetItemResposne, IEditFileCabinetItemBody>({
      query: ({ itemId, projectId, ...data }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/item/${itemId}`,
        body: data,
        method: 'put',
      }),
      invalidatesTags: ['File Cabinet Folder'],
    }),

    deleteFileCabinetItem: build.mutation({
      query: ({ itemId, projectId }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/item/${itemId}`,
        method: 'delete',
      }),

      onQueryStarted: async (
        { withOptimisticUpdate, projectId, folderId },
        { dispatch, queryFulfilled }
      ) => {
        if (withOptimisticUpdate) {
          const fetch = dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId: Number(projectId) },
              (draft) => {
                const folderIndex = draft?.items?.findIndex(
                  ({ fileCabinetId }) => fileCabinetId === folderId
                );

                if (folderIndex >= 0) {
                  draft.items[folderIndex].itemsCount =
                    Number(draft?.items?.[folderIndex]?.itemsCount) - 1;
                }
              }
            )
          );

          try {
            await queryFulfilled;
          } catch (error) {
            fetch?.undo();
          }
        }
      },

      invalidatesTags: ['File Cabinet Folder'],
    }),

    editDefaultFolderItems: build.mutation<IEditFileCabinetItemResposne, IEditFileCabinetItemBody>({
      query: ({ itemId, ...data }) => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.TEMPLATE}default-folders/${itemId}`,
        body: data,
        method: 'put',
      }),
      invalidatesTags: ['File Cabinet Folder'],
    }),

    deleteDefaultFolderItem: build.mutation({
      query: (itemId) => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.TEMPLATE}default-folders/${itemId}`,
        method: 'delete',
      }),

      invalidatesTags: ['File Cabinet Folder'],
    }),

    getDestinationFolders: build.query<IGetDefaultFoldersResponse, IGetDestinationFoldersArgs>({
      query: ({ projectId, excludeIds }) => ({
        url: `${Endpoints.FILE_CABINET}destination-folders/${projectId}${!!excludeIds ? '?' : ''}${
          !!excludeIds ? excludeIds?.map((excludeId) => `excludeIds=${excludeId}`) : ''
        }`,
      }),
    }),

    getDestinationDefaultFolders: build.query<
      IGetDefaultFoldersResponse,
      IGetDestinationFoldersArgs
    >({
      query: ({ excludeIds }) => ({
        url: `${Endpoints.FILE_CABINET}default-folders/destination-folders?${excludeIds?.map(
          (excludeId) => `excludeIds=${!!excludeId ? excludeId : 0}`
        )}`,
      }),
    }),

    changeDefaultFolderPermissions: build.mutation<
      IChangeDefaultFoldersPermissionsResposne,
      IChangeDefaultFoldersPermissionsBody
    >({
      query: ({ itemId, roles }) => ({
        url: `${Endpoints.FILE_CABINET}default-folders/permissions/${itemId}`,
        body: roles,
        method: 'put',
      }),

      invalidatesTags: ['File Cabinet Folder'],
    }),

    addFileCabinetUser: build.mutation<undefined, TAddCabinetUserBody>({
      query: ({ ...data }) => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.PERMISSIONS}`,
        body: data,
        method: 'post',
      }),

      invalidatesTags: ['File Cabinet Folder'],
    }),

    updateFileCabinetUser: build.mutation<undefined, TEditCabinetUserBody>({
      query: (data) => ({
        url: `${Endpoints.FILE_CABINET}${Endpoints.PERMISSIONS}`,
        body: { items: data },
        method: 'put',
      }),

      invalidatesTags: ['File Cabinet Folder'],
    }),

    getShowCabinetFileURL: build.query<TGetShowCabinetFileResponse, TGetShowCabinetFileBody>({
      query: ({ fileId, projectId, ...params }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/file/${fileId}/download`,
        params,
      }),
    }),

    getShowPublicCabinetFileURL: build.query<
      TGetShowPublicCabinetFileResponse,
      TGetShowPublicCabinetFileBody
    >({
      query: ({ accessKey }) => ({
        url: `${Endpoints.FILE_CABINET}public/item/${accessKey}/download`,
      }),
    }),

    getPublicFolders: build.query<TGetPublicFoldersResponse, TGetPublicFoldersBody>({
      query: ({ accessKey }) => ({
        url: `${Endpoints.FILE_CABINET}public/${accessKey}`,
      }),
    }),

    updateFoldersVisibility: build.mutation<
      TUpdateFoldersVisibilityResponse,
      TUpdateFoldersVisibilityBody
    >({
      query: ({ itemId, isPublic, projectId }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/item/${itemId}/public-access`,
        body: { isPublic },
        method: 'put',
      }),

      invalidatesTags: ['File Cabinet Folder'],
    }),

    moveFolderOrFile: build.mutation<undefined, TMoveFolderOrFileBody>({
      query: ({ parentId, projectId, itemId }) => ({
        url: `${Endpoints.FILE_CABINET}${projectId}/item/${itemId}/move`,
        body: { parentId },
        method: 'put',
      }),

      invalidatesTags: ['File Cabinet Folder'],
    }),

    getCostCodeFiles: build.query<TGetCostCodeFilesResponse, TGetCostCodeFilesBody>({
      query: ({ id }) => ({
        url: `${Endpoints.FILE_CABINET}public/${id}`,
      }),
    }),

    getFolderBreadCrumbs: build.query<TGetFolderBreadCrumbsResponse, TGetFolderBreadCrumbsBody>({
      query: ({ itemId, projectId }) => ({
        url: `${Endpoints.FILE_CABINET}parents-info/${projectId}/${itemId}`,
      }),
    }),

    getNewFolderPublicKey: build.query<TGetNewFolderPublicKeyResponse, void>({
      query: () => ({
        url: `${Endpoints.FILE_CABINET}accessKey`,
      }),
    }),
  }),
});

export const {
  useGetFileCabinetDefaultFoldersQuery,
  useGetProjectFileCabinetByIdQuery,
  useGetFileCabinetFolderItemsQuery,
  useGetFileCabinetDefaultItemsQuery,
  useGetFileCabinetUploadURlQuery,
  useLazyGetFileCabinetUploadURlQuery,
  useGetFileCabinetFolderDetailsQuery,
  useGetFileCabinetDefaultFolderDetailsQuery,
  useCreateFileCabinetFolderMutation,
  useCreateFileCabinetDefaultFolderMutation,
  useUploadFileCabinetFileMutation,
  useEditFileCabinetItemsMutation,
  useDeleteFileCabinetItemMutation,
  usePostFileToAWSMutation,
  useGetDestinationFoldersQuery,
  useGetDestinationDefaultFoldersQuery,
  useEditDefaultFolderItemsMutation,
  useDeleteDefaultFolderItemMutation,
  useChangeDefaultFolderPermissionsMutation,
  useAddFileCabinetUserMutation,
  useUpdateFileCabinetUserMutation,
  useGetShowCabinetFileURLQuery,
  useLazyGetShowCabinetFileURLQuery,
  useGetPublicFoldersQuery,
  useLazyGetPublicFoldersQuery,
  useUpdateFoldersVisibilityMutation,
  useMoveFolderOrFileMutation,
  useGetShowPublicCabinetFileURLQuery,
  useGetCostCodeFilesQuery,
  useLazyGetFileCabinetSelectionUploadURlQuery,
  useGetFolderBreadCrumbsQuery,
  useLazyGetNewFolderPublicKeyQuery,
} = fileCabinetApi;
