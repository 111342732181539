import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import OutlinedInput, { IOutlinedInputProps } from 'components/inputs/outlined-input';
import { FormControlProps } from '@mui/material';

interface OwnProps extends IOutlinedInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  withError?: boolean;
  sxControl?: FormControlProps['sx'];
  [x: string]: any;
  readOnly?: boolean;
  disablleMinus?: boolean;
  shouldUnregister?: boolean;
  additionalOnChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const OutlinedInputController: React.FC<OwnProps> = ({
  name = '',
  defaultValue = '',
  withError,
  disablleMinus,
  shouldUnregister = false,
  ...props
}) => {
  const { control } = useFormContext();

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <Controller
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
        return (
          <OutlinedInput
            {...props}
            name={name}
            value={value}
            error={!!error}
            onBlur={onBlur}
            onChange={props?.additionalOnChange ? props?.additionalOnChange : onChange}
            onKeyPress={disablleMinus ? preventMinus : null}
            helperText={withError ? error?.message || ' ' : ' '}
          />
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default memo(OutlinedInputController);
