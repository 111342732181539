import { typoSM } from '../../modals/styles';

export const styles = {
  dragZone: {
    border: '1px dashed #D4D6E0',
    borderRadius: '12px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    '&.isActive': {
      borderColor: '#4D6EF5',
      background: '#F2F5FF',
    },
  },
  dragMainText: {
    fontFamily: 'Inter',
    fontSize: '15px',
    lineHeight: '150%',
    fontWeight: 400,
    color: '#23252F',
    cursor: 'pointer',

    '&.isBlue': {
      color: '#4D6EF5',
    },
  },
  dragText: {
    ...typoSM,
    '&.isGray': {
      color: '#6E707A',
    },
  },
  uploadLabelWrapper: {
    '& input': {
      display: 'none',
    },
  },
  uploadLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    border: '1px solid #4D6EF5',
    borderRadius: '8px',
  },
};
