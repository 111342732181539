export enum RedirectEnum {
  LIFETIME_BILLING_MODAL = 'LIFETIME_BILLING_MODAL',
  CREATE_PROJECT_MODAL = 'CREATE_PROJECT_MODAL',
}

export interface ICompany {
  id: number;
  name: string;
  buildingStatisticId: number;
  phone: string | null;
  address?: string | null;
  secondAddress?: {};
  country?: string | null;
  state?: string | null;
  stateAbbr?: string | null;
  city?: string | null;
  zip?: string | null;
  lat?: number | null;
  lng?: number | null;
  logo?: string | null;
  workingDays: number[];
  utcOffset?: number;
}

export interface ICompanyAll extends ICompany {
  projectsCount: number;
  billingPlanStatus: string | null;
}

export interface ICompanyBuildingStatistic {
  id: number;
  value: string;
}

export interface ICreateCompanyResponse extends ICompany {
  redirect: RedirectEnum;
}

export interface ICreateCompanyBody {
  data: FormData;
}

export interface ICompanyUser {
  profileId: number;
  roleId: number;
  firstName: string;
  lastName: string;
  roleName: string;
  avatar?: string | null;
}

export interface IGetCompanyUsersArgs {
  roleId: number;
}
