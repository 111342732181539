import { StatusValue } from 'react-dropzone-uploader';

export interface IStyles {
  [key: string]: string | IStyles;
}

export interface IDataList<T = unknown> {
  canRead?: boolean;
  items: T[];
  meta: {
    currentPage?: number;
    itemCount?: number;
    itemsPerPage?: number;
    totalItems?: number;
    totalPages?: number;
    nextCursor?: number | null;
  };
}

export interface IRole {
  companyId: number;
  roleId: number;
  deletedAt: string | null;
  name: string | null;
  isOwner: boolean;
  isSubscription: boolean;
}

export interface IUserMe {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  avatar: string | null;
  userId: number;
  createdAt: string;
  deletedAt: string | null;
  timezone: string | null;
  isSuperAdmin: boolean;
  hasAccessToBeta: boolean;
  roles: IRole[] | null;
  isActive?: boolean;
}

export interface IInvite {
  id: number;
  email: string;
  title: string;
  companyRole: {
    id: number;
    name: string;
  };
  inviteProjects: {
    projectId: number;
  }[];
}

export enum PhaseTypesEnum {
  START = 'START',
  DEFAULT = 'DEFAULT',
  COMPLETE = 'COMPLETE',
}

export enum PhaseStatusesEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  OVERDUE = 'OVERDUE',
}

export enum PredecessorsTypesEnum {
  START_TO_START = 'START-TO-START',
  START_TO_FINISH = 'START-TO-FINISH',
  FINISH_TO_START = 'FINISH-TO-START',
  FINISH_TO_FINISH = 'FINISH-TO-FINISH',
}

export const PredecessorsTypes = {
  0: 'FINISH-TO-START',
  1: 'START-TO-START',
  2: 'FINISH-TO-FINISH',
  3: 'START-TO-FINISH',
};

export type TScheduleItemOptions = {
  label: string;
  value: number;
  disabled: boolean;
  disabledItemMessage: string;
  endDate: string;
}[];

export interface IPhaseLinkedItem {
  itemId: number;
  lagDays: number;
  predecessorId: number;
  taskDependency: PredecessorsTypesEnum;
}

export interface IPhaseItemChecklist {
  completedItemsCount: number;
  id: number;
  itemsCount: number;
  name: string;
  phaseItemId: number;
}

export interface IPhaseItem<T = IPhaseLinkedItem> {
  id: number;
  phaseId: number;
  prevPhaseItemId: number;
  title: string;
  startDate: string | null;
  endDate: string | null;
  startDayDelay?: number | null;
  workDays: number | null;
  reminderBefore?: number | null;
  isPublishedToFeed?: boolean;
  sendNotifications?: boolean;
  passedDays: number | null;
  isViewed: boolean;
  status: PhaseStatusesEnum;
  predecessors: T[];
  linkedItems: IPhaseLinkedItem[];
  checklist?: IPhaseItemChecklist | null;
}

export interface IPhase {
  id: number;
  projectId: number;
  prevPhaseId?: number | null;
  title: string;
  color: string | null;
  endDate?: string | null;
  startDate?: string | null;
  workDays?: number;
  type: PhaseTypesEnum;
  status: PhaseStatusesEnum;
  items: IPhaseItem[];
}

export interface ITag {
  id: number;
  title: string;
  color?: string;
}

export interface IPushNotificationSetting {
  id: number;
  dispatchTime: 'DAILY' | 'INSTANTLY' | null;
  hour: number | null;
  notificationEvent:
    | 'NEW_POSTS_OR_COMMENTS_MENTIONS_TO_ACTIVITY_FEED'
    | 'NEW_POSTS_OR_COMMENTS_TO_ACTIVITY_FEED'
    | 'NEW_SCHEDULE_ITEMS'
    | 'CHANGES_IN_SCHEDULE_ITEMS'
    | 'ITEMS_BECOME_OVERDUE'
    | 'PHASE_ITEM_COMPLETION'
    | 'PROJECT_SELECTION_ITEMS_STATUS_REMINDER';
  isHidden: string | boolean;
}

export interface IEmailNotificationSetting {
  id: number;
  type: 'PROGRESS_SUMMARY';
  recurring: 'WEEKLY' | 'DAILY' | null;
  dayOfWeek: number | null;
  hour: number | null;
}

export interface IGanttPhaseItem {
  id: number | string;
  text: string;
  start_date: Date | undefined;
  end_date: Date | undefined;
  parent: number | string;
  constraint_type: string;
  constraint_date: Date | undefined;
  item_status: PhaseStatusesEnum;
  ownStartDate: string;
  ownEndDate: string;
  ownPredecessors: IPhaseLinkedItem[];
  ownLinkedItems: IPhaseLinkedItem[];
}

export interface IGanttUpdatePhaseItem {
  id?: number | string;
  text?: string;
  start_date?: Date | undefined;
  end_date?: Date | undefined;
  parent?: number | string;
  constraint_type?: string;
  constraint_date?: Date | undefined;
  item_status?: PhaseStatusesEnum;
  ownStartDate?: string;
  ownEndDate?: string;
  ownPredecessors?: IPhaseLinkedItem[];
  ownLinkedItems?: IPhaseLinkedItem[];
}

export interface IGanttPhase {
  id: number | string;
  text: string;
  open: boolean;
  type: string;
  color: string;
  end_date: Date | undefined;
  start_date: Date | undefined;
  item_status: PhaseStatusesEnum;
  ownStartDate: string;
  ownEndDate: string;
}

export interface ILinkPopper {
  source?: string;
  target?: string;
  valid: boolean;
}

export interface IUploadedFilesData {
  id: string;
  url: string;
  name: string;
}

export interface IFileUploadingUtilityProps {
  tempId: string;
  status: StatusValue;
  percent?: number;
  size?: number;
  mimeType?: string;
  previewUrl?: string;
  duration?: string;
  location?: string | null;
  timestamp?: string | null;
  description?: string | null;
}
