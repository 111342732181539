import React, { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
// mui components
import { IAddUsersForm } from 'layouts/manage-users-form';
// components
import ManageUsersModalNewUI from './new-ui';
// custom hooks
import useToast from 'hooks/useToast';
// validations
import { AddUsersSchema } from 'layouts/manage-users-form/validation';
// api
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
import { useGetMeQuery } from 'redux/api/auth';
import {
  useGetProjectsProfilesManagementQuery,
  IProject,
  useUpdateProjectMutation,
} from 'redux/api/projects';

interface OwnProps {
  open: boolean;
  project: IProject;
  onCloseUserModal: () => void;
}

const ManageUsersModal: React.FC<OwnProps> = ({ open, project, onCloseUserModal }) => {
  const navigate = useNavigate();

  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { data: companyProfile } = useGetMeQuery();

  const [updateProject, { isLoading: loading }] = useUpdateProjectMutation();

  const showToast = useToast();

  const { data: profilesManagement } = useGetProjectsProfilesManagementQuery({ id: project.id });

  const usersForm = useForm<IAddUsersForm>({
    resolver: yupResolver(AddUsersSchema),
    mode: 'onChange',
  });

  const formProfileIds = useWatch({
    control: usersForm.control,
    name: 'profilesIds',
  });

  const formProfileInvites = useWatch({
    control: usersForm.control,
    name: 'invites',
  });
  const formIsEmpty = useMemo(
    () =>
      (formProfileIds?.length === 0 && formProfileInvites?.length === 0) ||
      (formProfileIds?.length === 0 && !formProfileInvites) ||
      (!formProfileIds && formProfileInvites?.length === 0) ||
      (!formProfileIds && !formProfileInvites),
    [formProfileIds, formProfileInvites]
  );

  const onSubmit = async () => {
    const [profilesIds] = usersForm.getValues(['profilesIds', 'invites']);

    updateProject({
      id: project.id,
      data: {
        profilesIds,
      },
    })
      .unwrap()
      .then(() => {
        onCloseUserModal();
        showToast({
          type: 'success',
          message: 'Users have been updated.',
        });
      })
      .catch((err) => {
        if (err?.status === 404) {
          const isAccountDashboard = window.location.pathname.includes('/account-dashboard');

          if (isAccountDashboard) {
            onCloseUserModal();
            showToast({
              type: 'success',
              message: 'Users have been updated.',
            });
          } else {
            navigate('/account-dashboard');
            showToast({
              type: 'success',
              message: 'Users have been updated.',
            });
          }
        } else {
          showToast({
            type: 'error',
            message:
              typeof err?.data?.message === 'string'
                ? err?.data?.message
                : 'Something went wrong.  Please try again or contact support for help.',
          });
        }
      });
  };

  const profiles = useMemo(
    () =>
      profilesManagement
        ? [
            ...profilesManagement.assignedProfiles,
            ...profilesManagement.otherProfiles,
            ...profilesManagement.assignedInvites,
          ]
        : [],
    [profilesManagement]
  );

  const initialValues = useMemo(
    () => ({
      invites:
        profilesManagement?.assignedInvites.map(({ id, email, companyRole, title }) => ({
          id,
          companyRole,
          email: email,
          title,
        })) ?? [],
      profilesIds: profilesManagement?.assignedProfiles.map(({ id }) => id) ?? [],
    }),
    [profilesManagement]
  );

  const props = {
    open,
    project,
    usersForm,
    formIsEmpty,
    profiles,
    initialValues,
    loading,
    userPermissions,
    companyProfile,
    onSubmit,
    onCloseUserModal,
  };

  return <ManageUsersModalNewUI {...props} />;
};

export default ManageUsersModal;
