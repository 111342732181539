import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AccountStepEnum {
  myProfile = 0,
  companyProfile = 1,
}

export interface IAccountStepper {
  activeStep: number;
  completed: boolean | null;
}

const initialState: IAccountStepper = {
  activeStep: 0,
  completed: null,
};

export const accountStepperSlice = createSlice({
  name: 'account_stepper',
  initialState,
  reducers: {
    handleNext: (state) => {
      if (state.activeStep < AccountStepEnum.companyProfile) {
        state.activeStep += 1;
      } else {
        state.completed = true;
      }
    },
    handleBack: (state) => {
      state.activeStep -= 1;
    },
    handleSetActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    handleSetCompleted: (state, action: PayloadAction<boolean | null>) => {
      state.completed = action.payload;
    },
    resetForm: (state) => {
      state.activeStep = 0;
    },
  },
});

export const { handleBack, handleNext, handleSetActiveStep, handleSetCompleted, resetForm } =
  accountStepperSlice.actions;

export default accountStepperSlice.reducer;
