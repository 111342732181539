import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
// mui components
import { Typography } from '@mui/material';
import { styled, TypographyProps } from '@mui/system';
// componets
import PopperButtonNewUI from './new-ui';
import HelpMenu from './help-menu';
// custom hooks
import { useRequest } from 'hooks/useRequest';
import { useLogOut } from 'hooks/useLogOut';
import { useUserInfo } from 'hooks/useUserInfo';
import useToast from 'hooks/useToast';
// api
import { useGetMeQuery } from 'redux/api/auth';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
import { useLoginAsSuperAdminMutation } from 'redux/api/super-admin';
// slices
import { handleSetCompanyId, handleAuth } from 'redux/slices/auth-slice';
import { getImageBySize } from 'utils/helpers';
// types
import { useGetPaymentMethodsQuery } from 'redux/api/billing';
import { PaymentPlanType } from 'utils/types';

const StyledCompanyName = styled((props: TypographyProps) => (
  <Typography component="span" {...props} />
))({
  maxWidth: '130px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
  color: '#1A1F36',
});

const StyledCompanyCount = styled((props: TypographyProps) => (
  <Typography component="span" {...props} />
))({
  fontSize: '11px',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#667085',
});

const PopperButton = ({ currentPaymentPlan }) => {
  const [open, setOpen] = useState(false);

  const { currentRole, isOwner } = useUserInfo();
  const { data: companyProfile } = useGetMeQuery();
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { onLogOut: logOut } = useLogOut();
  const [loginAsSuperAdmin] = useLoginAsSuperAdminMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showToast = useToast();
  const location = useLocation();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const mainToken = localStorage.getItem('mainToken');
  const isShowBillingInfo = userPermissions?.BILLING?.canRead;
  const isSuperAdminLogged = localStorage.getItem('isSuperAdminLogged');

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  const handleLogOut = useCallback(
    (event: any) => {
      if (isSuperAdminLogged) {
        const mainCompanyId = localStorage.getItem('mainCompanyId');

        if (mainToken) {
          localStorage.setItem('Authorization', mainToken);

          localStorage.removeItem('mainToken');

          dispatch(handleAuth(true));

          if (mainCompanyId) {
            localStorage.setItem('company-id', mainCompanyId);

            dispatch(handleSetCompanyId(mainCompanyId.toString()));

            localStorage.removeItem('mainCompanyId');
          }

          localStorage.removeItem('isSuperAdminLogged');

          window.location.replace('/account-dashboard');
        } else {
          logOut();
        }
      } else {
        logOut();
      }

      handleClose(event);
    },
    [isSuperAdminLogged, mainToken, handleClose, logOut, handleClose]
  );

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  const openSelectPaymentPlanModal = useCallback(() => {
    if (currentPaymentPlan?.type !== PaymentPlanType.billingPlanTrial) {
      navigate(`/billing/subscription-plan`, {
        state: {
          backgroundLocation: location,
          data: currentPaymentPlan,
          companyProfile: companyProfile,
        },
      });
    } else {
      navigate(`/billing/select-payment-plan`, {
        state: {
          backgroundLocation: location,
          data: currentPaymentPlan,
          companyProfile: companyProfile,
        },
      });
    }
  }, [navigate, location, currentPaymentPlan, companyProfile]);

  const handleOpenSuperAdminPage = useCallback(() => {
    if (!!companyProfile?.isSuperAdmin) {
      loginAsSuperAdmin({ email: companyProfile?.email })
        .unwrap()
        .then((res) => {
          if (!!res.entryToken) {
            const URL = `${process.env.REACT_APP_SUPER_ADMIN_URL}sign-in?entryToken=${res?.entryToken}`;

            window.location.href = URL;
          }
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Something went wrong.',
          });
        });
    } else {
      showToast({
        type: 'error',
        message: 'Something went wrong.',
      });
    }
  }, []);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const props = {
    StyledCompanyName,
    StyledCompanyCount,
    isShowBillingInfo,
    currentPaymentPlan,
    userPermissions,
    companyProfile,
    isSuperAdminLogged,
    anchorRef,
    open,
    handleToggle,
    handleClose,
    getImageBySize,
    handleListKeyDown,
    navigate,
    handleLogOut,
    openSelectPaymentPlanModal,
    handleOpenSuperAdminPage,
    currentRole,
    isOwner,
  };

  return (
    <>
      <HelpMenu />
      <PopperButtonNewUI {...props} />
    </>
  );
};

export default PopperButton;
