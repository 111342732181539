import React, { FC, useEffect, useMemo, useState } from 'react';
import Papa from 'papaparse';
// mui components
import { CircularProgress, Grid } from '@mui/material';
// components
import CsvTable from './csv-table';
// hooks
import useToast from 'hooks/useToast';
// styles
import { styles } from './styles';

type TViewCSVProps = {
  src: string;
  name: string;
};

type TCsvData = string[][];

const ViewCSV: FC<TViewCSVProps> = ({ src, name }) => {
  const [csvData, setCsvData] = useState<TCsvData>([]);
  const [parsingError, setParsingError] = useState<boolean>(false);
  const showToast = useToast();

  useEffect(() => {
    try {
      Papa.parse(src, {
        download: true,
        skipEmptyLines: true,

        complete: (result) => {
          setCsvData(result.data as TCsvData);
          setParsingError(false);
        },

        error: (err) => {
          setParsingError(true);
          showToast({
            type: 'error',
            message: 'Something went wrong parsing CSV file!',
          });
        },
      });
    } catch (error) {}
  }, [src]);

  return (
    <Grid sx={styles.container}>
      {!parsingError && !csvData.length ? (
        <Grid sx={styles.loaderContainer}>
          <CircularProgress />
        </Grid>
      ) : csvData.length ? (
        <CsvTable data={csvData} />
      ) : null}
    </Grid>
  );
};

export default ViewCSV;
