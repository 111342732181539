import React, { useCallback, FC, useState } from 'react';
// components
import UploadFileModal from 'components/file-cabinet-folders/modals/upload-file-modal';
// mui components
import { Box, Typography, Grid } from '@mui/material';
// api
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { fileCabinetSelector } from 'redux/selector';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
import { FileCabinetUserPermission } from 'redux/api/file-cabinet';
// helpers
import { checkUserAddAccess } from 'utils/helpers/fileCabinetHelpers';
// icons
import { ReactComponent as FolderIcon } from 'assets/icons/cabinet/file.svg';
// styles
import { styles } from './styles';

type EmptyListProps = {
  fromModal: boolean;
  projectFolderId?: string | number;
  projectId: string | number;
  myPermissions: FileCabinetUserPermission;
};

const EmptyList: FC<EmptyListProps> = ({
  fromModal,
  projectId,
  projectFolderId,
  myPermissions,
}) => {
  const [isOpenUploadFile, setIsOpenUploadFile] = useState(false);
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { cabinetLocation } = useAppSelector(fileCabinetSelector);

  const addAccess = checkUserAddAccess(
    false,
    cabinetLocation,
    userPermissions?.CREATE_FOLDERS?.canRead,
    myPermissions
  );

  const handleOpenModal = useCallback(() => {
    setIsOpenUploadFile(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpenUploadFile(false);
  }, []);

  return (
    <Box sx={styles.container}>
      <FolderIcon />
      <Typography sx={styles.typo}>There are no files yet.</Typography>

      {addAccess && (
        <Grid onClick={handleOpenModal} sx={styles.backLink}>
          Add Files
        </Grid>
      )}

      {isOpenUploadFile && (
        <UploadFileModal
          open={isOpenUploadFile}
          handleClose={handleCloseModal}
          projectFolderId={projectFolderId}
          fromModal={fromModal}
          projectId={projectId}
        />
      )}
    </Box>
  );
};

export default EmptyList;
