export const manageUsersModalStyles = {
  formHeader: {
    padding: '40px 40px 20px 40px',
    borderRadius: '12px 12px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: '1px solid #F3F4F9',
  },

  formHeaderTitle: {
    fontFamily: 'Inter',
    fontSize: '24px',
    lineHeight: '136%',
    fontWeight: 400,
    color: '#23252F',
    marginBottom: '4px',
  },

  formHeaderSubtitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#6E707A',
  },

  buttonsContainer: {
    padding: '40px 0 20px 40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  saveButton: {
    padding: '14px 12px',
    borderRadius: '8px',
    textTransform: 'unset',
    backgroundColor: '#4D6EF5',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#fff',
    boxShadow: 'none',
    marginLeft: '12px',
    transition: '300ms',

    '&:hover': {
      opacity: '0.7',
      backgroundColor: '#4D6EF5',
      color: '#fff',
    },
  },

  cancelButton: {
    padding: '14px 12px',
    border: '1px solid #4D6EF5',
    borderRadius: '8px',
    textTransform: 'unset',
    backgroundColor: '#fff',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#4D6EF5',
    boxShadow: 'none',
  },
};
