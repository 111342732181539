// Popper component cause a type error in production. This is the MUI bug.
// @ts-nocheck
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// mui components
import {
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  Link,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import Popper from '@mui/material/Popper';
// icons
import { ReactComponent as HelpButton } from 'assets/icons/buttons/question.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/buttons/help-button.svg';
import { ReactComponent as FeaturesIcon } from 'assets/icons/buttons/features-button.svg';
import { ReactComponent as UpdatesIcon } from 'assets/icons/buttons/rocket-button.svg';
import { ReactComponent as PromoteIcon } from 'assets/icons/buttons/megaphone-button.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/buttons/route.svg';
// styles
import { helpMenuStyles, settingsButton } from 'components/header/styles/styles';

const HelpMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const anchorRef = useRef(null);

  return (
    <>
      <div>
        <Grid
          ref={anchorRef}
          id="companies-list-button"
          aria-controls={open ? 'companies-list-button' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={helpMenuStyles.questionContainer}
        >
          <HelpButton />
        </Grid>
        <Popper
          transition
          open={open}
          role={undefined}
          placement="bottom-start"
          anchorEl={anchorRef.current}
          sx={helpMenuStyles.popper}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={helpMenuStyles.popper}>
              <Paper sx={helpMenuStyles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="companies-list"
                    aria-labelledby="companies-list"
                    sx={helpMenuStyles.settingsListContainer}
                  >
                    <Grid
                      sx={helpMenuStyles.popperItemContainer}
                      onClick={() => {
                        navigate('/help-center');
                        handleClose();
                      }}
                    >
                      <HelpIcon />
                      <Typography sx={settingsButton.popperItemTitle}>Help Center</Typography>
                    </Grid>
                    <Grid
                      sx={helpMenuStyles.popperItemContainer}
                      onClick={() => {
                        navigate('/feature-requests');
                        handleClose();
                      }}
                    >
                      <FeaturesIcon />
                      <Typography sx={settingsButton.popperItemTitle}>Feature Requests</Typography>
                    </Grid>
                    <Grid
                      sx={helpMenuStyles.popperItemContainer}
                      onClick={() => {
                        navigate('/current-roadmap');
                        handleClose();
                      }}
                    >
                      <RouteIcon />
                      <Typography sx={settingsButton.popperItemTitle}>Current Roadmap</Typography>
                    </Grid>
                    <Grid
                      sx={helpMenuStyles.popperItemContainer}
                      onClick={() => {
                        navigate('/updates');
                        handleClose();
                      }}
                    >
                      <UpdatesIcon />
                      <Typography sx={settingsButton.popperItemTitle}>Product Updates</Typography>
                    </Grid>
                    <Grid sx={helpMenuStyles.popperItemContainer}>
                      <Link
                        href="https://builderpad.com/affiliates/"
                        underline="none"
                        target="_blank"
                        sx={{ display: 'flex' }}
                        onClick={handleClose}
                      >
                        <PromoteIcon />
                        <Typography sx={settingsButton.popperItemTitle}>
                          Promote BuilderPad
                        </Typography>
                      </Link>
                    </Grid>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Divider orientation="vertical" flexItem sx={helpMenuStyles.divider}></Divider>
    </>
  );
};

export default HelpMenu;
