import { AccessOptionsEnum } from 'redux/api/company-roles';

export const accessOptions = [
  {
    label: 'Can View',
    value: AccessOptionsEnum.CanRead,
  },

  {
    label: 'Can Add/Edit',
    value: AccessOptionsEnum.CanAddUpdate,
  },

  {
    label: 'Can Delete',
    value: AccessOptionsEnum.CanDelete,
  },
];

export const fileCabinetTablColumns = (publicFolders?: boolean) => {
  let columns: { id: number; name: string; tooltip?: boolean }[] = [{ id: 1, name: 'Item' }];

  if (!publicFolders) {
    columns = [...columns, { id: 3, name: 'Permissions', tooltip: true }, { id: 4, name: '' }];
  }

  return columns;
};
