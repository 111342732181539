import React, { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
// types
import { IPhase } from 'utils/types';
import { ITemplate } from 'redux/api/templates';
import SetScheduleFormNewUI from './new-ui';

interface OwnProps {
  isFirstProject?: boolean;
  templates?: ITemplate[];
  defaultValue?: number | undefined;
}

const SetScheduleForm: React.FC<OwnProps> = ({ isFirstProject, templates, defaultValue }) => {
  const { control, setValue } = useFormContext();

  const formTemplateId = useWatch({
    control,
    name: 'templateId',
  });

  const completedPhaseIds = useWatch({
    control,
    name: 'completedPhaseIds',
    defaultValue: [],
  });

  const phases = useMemo(
    () => templates?.find(({ id }) => `${id}` === `${formTemplateId}`)?.phases ?? [],
    [templates, formTemplateId]
  );

  const templateSelectValues = useMemo(
    () => [
      { label: 'Blank Template', value: -1 },
      ...(templates?.map(({ id, title }) => ({
        label: title.charAt(0) + title.toLowerCase().slice(1),
        value: id,
      })) ?? []),
    ],
    [templates]
  );

  const handleSwitchPhase = useCallback(
    (phase: IPhase) => (value: boolean) => {
      setValue(
        'completedPhaseIds',
        value
          ? [...completedPhaseIds, phase.id]
          : completedPhaseIds.filter((id: number) => id !== phase.id)
      );
    },
    [setValue, completedPhaseIds]
  );

  const props = {
    isFirstProject,
    phases,
    templates,
    completedPhaseIds,
    templateSelectValues,
    handleSwitchPhase,
    defaultValue,
  };

  return <SetScheduleFormNewUI {...props} />;
};

export default SetScheduleForm;
