import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import CreateProjectNewUI from './new-ui';
import { Coords, Address } from 'components/google-map';
import { SelectChangeEvent } from '@mui/material';
// custom hooks
import { useProjectStepper } from 'hooks/useProjectStepperState';
import useToast from 'hooks/useToast';
import { useValidationNotification } from 'hooks/useValidationNotification';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// api
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
import { useGetCompanyQuery } from 'redux/api/company';
import { useGetGeolocationQuery } from 'redux/api/geolocation';
import { useGetProjectsQuery } from 'redux/api/projects';
import { useCreateProjectMutation } from 'redux/api/projects';
import { useUpdateProjectMutation } from 'redux/api/projects';
// selectors
import { queryArgsSelector } from 'redux/selector';
// validations
import { CreateProjectSchemaNewUI } from 'layouts/create-project-form/validation';

const CreateProject: React.FC = () => {
  const { projects: projectsArgs } = useAppSelector(queryArgsSelector);
  const { handleNext } = useProjectStepper();
  const showToast = useToast();
  const { updateProjectDetails, projectDetails, firstStepSubmited, projectId, handleMutate } =
    useProjectStepper();

  const [createProject, { isLoading: loading }] = useCreateProjectMutation();
  const [updateProject, { isLoading: updateLoading }] = useUpdateProjectMutation();

  const { data: geolocation } = useGetGeolocationQuery();

  const { data: projectsList, isLoading: isProjectsLoading } = useGetProjectsQuery(projectsArgs);
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { data: companySetup } = useGetCompanyQuery(undefined, {
    skip: !userPermissions?.COMPANY_MANAGEMENT?.canRead,
  });

  const isFirstProject = !projectsList?.meta?.totalItems && !isProjectsLoading;

  const googleMapCoords = useMemo(
    () =>
      geolocation
        ? ({ lat: geolocation.latitude, lng: geolocation.longitude } as Coords)
        : companySetup
        ? { lat: companySetup.lat, lng: companySetup.lng }
        : undefined,
    [geolocation, companySetup]
  );

  const [coordinates, setCoordinates] = useState<Coords | undefined>(googleMapCoords);
  const [address, setAddress] = useState<Address | undefined>(null);

  const updateCoordinates = useCallback((currentCoords: { lat: number; lng: number }) => {
    setCoordinates(currentCoords);
  }, []);

  const form = useForm({
    defaultValues: {
      name: projectDetails?.name ?? '',
      country: geolocation?.country_code ?? projectDetails?.country,
      address: projectDetails?.address ?? '',
      state: projectDetails?.state ?? '',
      zip: projectDetails?.zip ?? '',
      city: projectDetails?.city ?? '',
      secondAddress: projectDetails?.secondAddress ?? '',
    },
    resolver: yupResolver(CreateProjectSchemaNewUI),
  });

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    form.setValue('country', e.target.value);
    form.setValue('address', '');
    form.setValue('state', '');
    form.setValue('city', '');
    form.setValue('zip', '');
  };

  useEffect(() => {
    if (address) {
      form.setValue('country', address?.country || '');
      form.setValue('address', address?.formattedAddress || '');
      form.setValue('state', address?.state || '');
      form.setValue('city', address?.city || '');
      form.setValue('zip', address?.postCode || '');
    }
  }, [address]);

  const country = form.watch('country');

  const updateAddress = (newAddress: Address) => {
    setAddress(newAddress);
  };

  const onSubmit = useCallback(
    async (formValues) => {
      updateProjectDetails(formValues);

      if (coordinates) {
        const formData = new FormData();

        Object.keys(formValues).forEach((key) => {
          if (formValues[key]) {
            formData.append(key, formValues[key]);
          }
        });

        formData.append('lat', coordinates.lat.toString());
        formData.append('lng', coordinates.lng.toString());

        if (projectId && firstStepSubmited) {
          updateProject({
            id: projectId,
            withoutRefetching: false,
            data: formData as any,
          })
            .unwrap()
            .then((projectData) => {
              showToast({
                message: 'Updated successfully',
                type: 'success',
              });

              handleMutate(projectData);
              if (projectData) window.dispatchEvent(new CustomEvent('project_created'));
              handleNext(projectData.id);
            })
            .catch((err) => {
              showToast({
                type: 'error',
                message:
                  typeof err?.data?.message === 'string' &&
                  err?.data?.message ===
                    'Project with this (name, address) pair has already existed'
                    ? 'Error: Duplicate project. Please enter a unique project name and address.'
                    : typeof err?.data?.message === 'string'
                    ? err.data?.message
                    : 'Something went wrong.  Please try again or contact support for help.',
              });
            });
        } else {
          createProject(formData)
            .unwrap()
            .then((projectData) => {
              handleMutate(projectData);
              if (projectData) window.dispatchEvent(new CustomEvent('project_created'));
              handleNext(projectData.id);
            })
            .catch((err) => {
              showToast({
                type: 'error',
                message:
                  typeof err?.data?.message === 'string' &&
                  err?.data?.message ===
                    'Project with this (name, address) pair has already existed'
                    ? 'Error: Duplicate project. Please enter a unique project name and address.'
                    : typeof err?.data?.message === 'string'
                    ? err.data?.message
                    : 'Something went wrong.  Please try again or contact support for help.',
              });
            });
        }
      } else {
        showToast({
          type: 'error',
          message: 'Something went wrong.  Please try again or contact support for help.',
        });
      }
    },
    [coordinates, handleNext, handleMutate, updateProjectDetails, createProject]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useValidationNotification(form.formState.isSubmitSuccessful, form.formState.submitCount);

  const props = {
    form,
    country,
    coordinates,
    isFirstProject,
    loading,
    updateLoading,
    onSubmit,
    updateCoordinates,
    updateAddress,
    handleCountryChange,
  };

  return <CreateProjectNewUI {...props} />;
};

export default CreateProject;
