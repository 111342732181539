import { FunctionComponent, SVGProps } from 'react';
import { StatusValue } from 'react-dropzone-uploader';
import { TCompanyRolePermissionsResponse } from 'redux/api/company-roles';
import { IEmailNotificationSetting, IPushNotificationSetting } from 'redux/types';

export enum GanttLinkTypesEnum {
  'FINISH-TO-START' = 0,
  'START-TO-START' = 1,
  'FINISH-TO-FINISH' = 2,
  'START-TO-FINISH' = 3,
}

export enum PredecessorsTypes {
  startToStart = 'START-TO-START',
  startToFinish = 'START-TO-FINISH',
  finishToStart = 'FINISH-TO-START',
  finishToFinish = 'FINISH-TO-FINISH',
}

export interface ICompanyUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  avatar: string | null;
  userId: number;
  roleName: string | null;
  roles: {
    companyId: number;
    roleId: number;
    name: string;
    isOwner: boolean;
    deletedAt: string | null;
  }[];
  createdAt: string | null;
  projects: { id: number }[];
}

export interface IPhaseLinkedItem {
  itemId: number;
  lagDays: number;
  predecessorId: number;
  taskDependency: PredecessorsTypes;
}

export interface IPhaseItem {
  id: number;
  title: string;
  startDayDelay: number;
  startDate: string;
  endDate: string;
  workDays: number;
  reminderBefore: number;
  isPublishedToFeed: boolean;
  sendNotifications: boolean;
  prevPhaseItemId: number;
  phaseId: number;
  status: PhaseStatuses;
  predecessors: IPhaseLinkedItem[];
  linkedItems: IPhaseLinkedItem[];
  isViewed: boolean;
}

export enum PhaseTypes {
  start = 'START',
  default = 'DEFAULT',
  complete = 'COMPLETE',
}

export enum PhaseStatuses {
  notStarted = 'NOT_STARTED',
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED',
  overdue = 'OVERDUE',
}

export interface IPhase {
  id: number;
  title: string;
  projectId: number;
  type: PhaseTypes;
  items: IPhaseItem[];
  status: PhaseStatuses;
  color: string | null;
  endDate: string | null;
  startDate: string | null;
  prevPhaseId: number | null;
  isView: boolean;
  workDays: number;
}

export interface ITemplatePhaseItems {
  id?: number;
  title: string;
  startDayDelay: number;
  workDays: number;
  reminderBefore: number;
  isPublishedToFeed: boolean;
  sendNotifications: boolean;
  phaseId: number;
  color?: string;
  predecessors: {
    id: number;
    taskDependency: PredecessorsTypes;
    lagDays: number;
  }[];
  linkedItems: {
    id: number;
    taskDependency: PredecessorsTypes;
    lagDays: number;
  }[];
}

export interface ITemplatePhase {
  color: string;
  id: number;
  items: ITemplatePhaseItems[];
  prevPhaseId: number | null;
  title: string;
  type: PhaseTypes;
}

export interface ITemplate {
  id: number;
  title: string;
  companyId: number;
  isDefault: boolean;
  isDefaultCreated: boolean;
  phases: ITemplatePhase[];
}

export interface ICommonSelectValues {
  label: string;
  value: number | string;
  color?: string;
  disabled?: boolean;
  disabledItemMessage?: string;
  icon?: string;
  optionColor?: string;
  createOption?: boolean;
  avatar?: string;
  email?: string;
  role?: string;
  avatarInitials?: string;
}

export interface IUploadedFilesData {
  id: string;
  url: string;
  name: string;
}

export interface IPostAudienceMember {
  id: number;
  groupName: string;
  profiles: [
    {
      profileId: number;
      firstName: string;
      lastName: string;
      roleName: string;
      avatar: string;
    }
  ];
}

export interface IPostEveryoneMember {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  avatar: string;
  userId: number;
  roles: {
    companyId: number;
    roleId: number;
    deletedAt: string;
    name: string;
    isOwner: boolean;
    isSubscription: boolean;
  }[];
  createdAt: string;
  deletedAt: string;
  timezone: string;
}

export interface IUploadingFiles extends IUploadedFilesData {
  tempId: string;
  status: StatusValue;
  percent?: number;
  size?: number;
}

export interface IScheduleLinkItem {
  id: number;
  lagDays: number;
  taskDependency: PredecessorsTypes;
}

export interface ISchedulePhaseItem extends Omit<IPhaseItem, 'predecessors' | 'linkedItems'> {
  linkedItems: Array<IScheduleLinkItem & { item: { id: number; title: string } }>;
  predecessors: Array<IScheduleLinkItem & { predecessor: { id: number; title: string } }>;
}

export interface ISchedulePhase extends Omit<IPhase, 'items'> {
  items: ISchedulePhaseItem[];
}

export interface IScheduleTemplate extends Omit<ITemplate, 'phases'> {
  phases: ISchedulePhase[];
}

export type Invite = {
  id: number;
  email: string;
  companyRole: {
    id: number;
    name: string;
  };
  inviteProjects: {
    projectId: number;
  }[];
  title: string;
};

export type PaymentMethod = {
  id: number;
  companyId: number;
  ccExpireMonth: number;
  ccExpireYear: number;
  ccNum: string;
  type: string;
};

export type PaymentPlan = {
  [x: string]: any;
  id: number;
  name: string;
  couponCode: { code: string; discount: number };
  recurring: 'ONE_TIME' | 'MONTHLY' | 'ANNUAL';
  externalProductId: number;
  amount: number;
  data: { maxProjectsAmount: number };
};

export interface IStyles {
  [key: string]: string | IStyles;
}

export interface INotificationSettingsForm {
  pushNotificationSettings: Array<
    Omit<IPushNotificationSetting, 'dispatchTime' | 'hour'> & {
      dispatchTime: IPushNotificationSetting['dispatchTime'] | 'none';
      key?: string;
      hour: string | null;
      isHidden?: string;
    }
  >;
  emailNotificationSettings: Array<
    Omit<IEmailNotificationSetting, 'recurring' | 'hour' | 'dayOfWeek'> & {
      recurring: IEmailNotificationSetting['recurring'] | 'none';
      hour: string | null;
      dayOfWeek: string | null;
      isHidden?: string;
    }
  >;
}

export interface IRoleForm {
  name: string;
}

export interface IRolePermissionsForm {
  permissions: TCompanyRolePermissionsResponse;
}

export enum PaymentPlanType {
  billingPlanTrial = 'BILLING_PLAN_TRIAL',
  billingPlan = 'BILLING_PLAN',
}

export enum PaymentPlanStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  trialing = 'TRIALING',
}

export enum PaymentPlanRecurrings {
  Monthly = 'MONTHLY',
  Annual = 'ANNUAL',
  OneTime = 'ONE_TIME',
}

export enum SaveTemplatesTypes {
  newTemplate = 'new_template',
  existingTemplate = 'old_template',
}

export type TInputArrowClickFunction = (type: 'up' | 'down') => void;

export type TIcon = FunctionComponent<
  SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

export enum FileExtensionEnum {
  // IMAGE
  PNG = 'image/png',
  JPG = 'image/jpeg',
  JPEG = 'image/jpeg',
  HEIF = 'image/heif',
  HEIC = 'image/heic',
  WEBP = 'image/webp',
  TIFF = 'image/tiff',
  AVIF = 'image/avif',
  GIF = 'image/gif',
  // FILE
  PDF = 'application/pdf',
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  KEY = 'application/vnd.apple.keynote',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // VIDEO
  MP4 = 'video/mp4',
  AVI = 'video/x-msvideo',
  MKV = 'video/x-matroska',
  // AUDIO
  M4A = 'audio/mp4',
}
