const df_ac_jsb = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
};

export const styles = {
  container: {
    position: 'relative',
    width: 'calc(25% - 12px)',

    '@media (max-width: 1200px)': {
      width: 'calc(100% / 3 - 11px)',
    },

    '@media (max-width: 900px)': {
      width: 'calc(100% / 2 - 8px)',
    },

    '@media (max-width: 650px)': {
      width: 'calc(100%)',
    },
  },

  cardWrapper: {
    textDecoration: 'none',
    maxHeight: '136px',
    cursor: 'pointer',
  },
  card: {
    border: '1px solid #f3f4f9',
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '24px',
    boxSizing: 'border-box',
    transition: '300ms',

    '&:hover': {
      background: '#FAFBFC',
      border: '1px solid #4D6EF5',
    },

    '&.MuiPaper-root': {
      maxWidth: '100% !important',
      height: '100%',
    },
  },
  cardHeader: {
    ...df_ac_jsb,
    marginBottom: '24px',
    '& .l-icon': {
      transform: 'scale(1.2)',
      '& path': {
        stroke: '#4D6EF5',
      },
    },

    '& .file-icon': {
      width: '24px',
      height: '24px',
    },
  },
  cardContent: {
    ...df_ac_jsb,
  },

  contentContainer: {
    height: '38.4px',
    width: 'calc(100% - 92px)',
  },

  folderName: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '146%',
    color: '#000',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& .dot': {
      width: '4px',
      height: '4px',
      background: '#D4D6E0',
      borderRadius: '50%',
    },
  },
  typo: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '150%',
    color: '#424550',
    display: 'flex',
  },
};
