import { FormProvider } from 'react-hook-form';
// mui components
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// layouts
import ManageUsersForm from 'layouts/manage-users-form';
// components
import DialogModal from 'components/modal/dialog-modal';
import StyledForm from 'components/styled-form';
//styles
import { manageUsersModalStyles } from '../styles/styles';

const ManageUsersModalNewUI = ({
  open,
  project,
  usersForm,
  profiles,
  initialValues,
  userPermissions,
  companyProfile,
  onSubmit,
  onCloseUserModal,
  loading,
}) => {
  return (
    <DialogModal
      open={open}
      withoutPadding
      maxWidth="750px"
      withCloseButton={false}
      handleClose={onCloseUserModal}
    >
      <FormProvider {...usersForm}>
        <StyledForm onSubmit={onSubmit}>
          <Grid container alignItems="center" sx={manageUsersModalStyles.formHeader}>
            <Typography sx={manageUsersModalStyles.formHeaderTitle}>Project Members</Typography>
            <Typography sx={manageUsersModalStyles.formHeaderSubtitle}>
              Here you can manage your list of project members.
            </Typography>
          </Grid>
          <Grid container flexDirection="column" padding="0 40px">
            <ManageUsersForm
              projectId={project?.id}
              profiles={profiles}
              initialValues={initialValues}
            />
            <Grid sx={manageUsersModalStyles.buttonsContainer}>
              <LoadingButton
                variant="outlined"
                loading={loading}
                onClick={onCloseUserModal}
                sx={manageUsersModalStyles.cancelButton}
              >
                Cancel
              </LoadingButton>
              {userPermissions?.USER_MANAGEMENT?.canCreate && (
                <LoadingButton
                  loading={loading}
                  onClick={onSubmit}
                  sx={manageUsersModalStyles.saveButton}
                >
                  Save
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </StyledForm>
      </FormProvider>
    </DialogModal>
  );
};

export default ManageUsersModalNewUI;
