import { memo } from 'react';
// mui components
import { Grid, Typography, InputAdornment, Box, Button } from '@mui/material';
// components
import OutlinedInput from 'components/inputs/outlined-input';
import JobTitleSelect from 'components/job-title-select';
// controllers
import OutlinedInputController from 'controllers/outlined-input-controller';
import SelectController from 'controllers/select-controller';
// icons
import { ReactComponent as SearchIcon } from 'assets/icons/buttons/search-input-icon.svg';
//styles
import { manageUsersFormStyles } from '../styles/styles';

const ManageUsersFormNewUI = ({
  userPermissions,
  renderProfile,
  rolesOptions,
  filteredExistingUsers,
  handleAddUser,
  handleSearchUser,
  isValid,
  activeStep,
}) => {
  return (
    <Box sx={{ flex: 1 }}>
      {(userPermissions?.USER_MANAGEMENT?.canCreate || activeStep) && (
        <Grid sx={manageUsersFormStyles.inputsContainer}>
          <Grid item sx={manageUsersFormStyles.emailContainer}>
            <OutlinedInputController
              fullWidth
              withError
              name="email"
              type="email"
              label="Email Address"
              placeholder="Enter users email"
            />
          </Grid>

          <Grid sx={manageUsersFormStyles.selectContainer}>
            <JobTitleSelect
              fullWidth
              withError
              name="title"
              label="Title"
              placeholder="Select Title"
            />
          </Grid>

          <Grid item sx={manageUsersFormStyles.selectContainer}>
            <SelectController
              fullWidth
              withError
              name="role"
              label="Role"
              options={rolesOptions}
              disabled={rolesOptions.length === 0}
              placeholder={rolesOptions.length === 0 ? 'Loading...' : 'Select Role'}
            />
          </Grid>

          <Button
            variant="contained"
            onClick={handleAddUser}
            sx={
              !isValid
                ? manageUsersFormStyles.inviteButtonDisabled
                : manageUsersFormStyles.inviteButton
            }
          >
            Invite
          </Button>
        </Grid>
      )}

      <Grid sx={manageUsersFormStyles.searchInputContainer}>
        <OutlinedInput
          sx={manageUsersFormStyles.searchInput}
          fullWidth
          withoutHelperText
          placeholder="Search for existing users..."
          startAdornment={
            <InputAdornment position="start" sx={manageUsersFormStyles.inputAdornment}>
              <Grid item sx={manageUsersFormStyles.searchIconContainer}>
                <SearchIcon />
              </Grid>
            </InputAdornment>
          }
          onChange={handleSearchUser}
        />
      </Grid>

      {filteredExistingUsers.length ? (
        <Box sx={manageUsersFormStyles.usersContainer}>
          {filteredExistingUsers?.map(renderProfile)}
        </Box>
      ) : (
        <Typography sx={manageUsersFormStyles.noUsers}>No users here</Typography>
      )}
    </Box>
  );
};

export default memo(ManageUsersFormNewUI);
