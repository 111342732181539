import { styled } from '@mui/system';
import { MenuItem } from '@mui/material';

const StyledMenuItem = styled(MenuItem)(() => ({
  width: '100%',
  padding: '15px 16px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  borderTop: '1px solid transparent',
  borderBottom: '1px solid transparent',
  borderRadius: '8px',
  color: '#667085',
  '& a': {
    display: 'flex',
    alignItems: 'center',
    color: '#535F77',
    textDecoration: 'unset',
  },
  '& svg': {
    width: '16px',
    height: '16px',
    marginRight: '12px',
  },

  '&:hover': {
    backgroundColor: '#F2F5FF',
    //borderTop: '1px solid #F3F4F9',
    //borderBottom: '1px solid #F3F4F9',
  },
}));

export default StyledMenuItem;
