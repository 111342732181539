import React, { FC } from 'react';
// mui components
import { Grid, Typography, IconButton } from '@mui/material';
// components
import DialogModal from 'components/modal/dialog-modal';
import UserCard from '../user-card';
// api
import { IProjectProfiles } from 'redux/api/projects';
// icons
import { ReactComponent as CrossIcon } from 'assets/icons/buttons/cross.svg';
// styles
import { styles } from './styles';

interface IUsersModalProps {
  type: 'single user' | 'multiple users';
  profiles: IProjectProfiles[];
  open: boolean;
  closeModal: () => void;
}

const UsersModal: FC<IUsersModalProps> = ({ type, closeModal, open, profiles }) => {
  return (
    <DialogModal
      open={open}
      handleClose={closeModal}
      maxWidth="700px"
      withoutPadding
      contentSx={{
        overflowY: 'clip',
        maxWidth: 'unset',
      }}
    >
      <Grid sx={styles.container}>
        <Grid
          sx={{
            ...styles.header,
            padding: type === 'multiple users' ? '40px 40px 20px' : 'unset',
            height: type === 'multiple users' ? 'unset' : '52px',
          }}
        >
          {type === 'multiple users' ? (
            <>
              <Typography sx={styles.title}>Project Users</Typography>
              <Typography sx={styles.subTitle}>
                Below are the users included in this project.
              </Typography>
            </>
          ) : null}
          <IconButton sx={styles.crossBtn}>
            <CrossIcon onClick={closeModal} />
          </IconButton>
        </Grid>
        <Grid sx={styles.contentWrapper}>
          <Grid sx={styles.contentContainer}>
            {profiles.map((profile) => (
              <UserCard
                profile={profile}
                key={profile.id}
                customStyles={{
                  width: type === 'multiple users' ? 'calc( (100% - 12px) / 2 )' : '100%',
                }}
                closeModal={closeModal}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

export default UsersModal;
