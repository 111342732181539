import { useState } from 'react';

export const useModalState = (): [boolean, () => void, () => void, (value?: boolean) => void] => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleModal = (value?: boolean) => {
    setIsOpen((prev) => (typeof value === 'boolean' ? value : !prev));
  };

  return [isOpen, openModal, closeModal, toggleModal];
};
