import countries from './countries.json';
// types
import { PredecessorsTypes } from './types';
import { ProjectsSortEnum } from 'redux/api/profile-preference';

export const urlRegex = /(https?:\/\/[^\s]+)/g;

export const countriesSelectValues = countries.map(({ name, code, image }) => ({
  label: name,
  value: code,
  icon: image,
}));

export const colors = [
  '#D12D36',
  '#42A9BF',
  '#FF7051',
  '#9648C6',
  '#D3AC1E',
  '#3652C6',
  '#BD30B7',
  '#15682D',
  '#FCA311',
  '#D4A373',
  '#FF006E',
  '#06D6A0',
  '#7678ED',
  '#C9F299',
  '#F3F956',
  '#97FF30',
];

export const colorsOptions = colors.map((color) => ({ label: '', value: color, color: color }));

export const predecessorsTypesOptions = [
  { label: 'Start to start', value: PredecessorsTypes.startToStart },
  { label: 'Start to finish', value: PredecessorsTypes.startToFinish },
  { label: 'Finish to start', value: PredecessorsTypes.finishToStart },
  { label: 'Finish to finish', value: PredecessorsTypes.finishToFinish },
];

export const GanttLinkTypes = {
  0: 'FINISH-TO-START',
  1: 'START-TO-START',
  2: 'FINISH-TO-FINISH',
  3: 'START-TO-FINISH',
};

export const TIME_OPTIONS = [
  { label: '12 AM', value: '0' },
  { label: '01 AM', value: '1' },
  { label: '02 AM', value: '2' },
  { label: '03 AM', value: '3' },
  { label: '04 AM', value: '4' },
  { label: '05 AM', value: '5' },
  { label: '06 AM', value: '6' },
  { label: '07 AM', value: '7' },
  { label: '08 AM', value: '8' },
  { label: '09 AM', value: '9' },
  { label: '10 AM', value: '10' },
  { label: '11 AM', value: '11' },
  { label: '12 PM', value: '12' },
  { label: '01 PM', value: '13' },
  { label: '02 PM', value: '14' },
  { label: '03 PM', value: `15` },
  { label: '04 PM', value: `16` },
  { label: '05 PM', value: `17` },
  { label: '06 PM', value: `18` },
  { label: '07 PM', value: `19` },
  { label: '08 PM', value: `20` },
  { label: '09 PM', value: `21` },
  { label: '10 PM', value: `22` },
  { label: '11 PM', value: `23` },
];

export const WEEKDAY_OPTIONS = [
  { label: 'Sunday', value: '0' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
];

export const JOB_TITLES = [
  { label: 'Owner', value: 'Owner' },
  { label: 'Client', value: 'Client' },
  { label: 'Project Manager', value: 'Project Manager' },
  { label: 'Superintendent', value: 'Superintendent' },
  { label: 'Agent', value: 'Agent' },
  { label: 'Designer', value: 'Designer' },
  { label: 'Office Manager', value: 'Office Manager' },
  { label: 'Estimator', value: 'Estimator' },
  { label: 'Warranty Manager', value: 'Warranty Manager' },
  { label: 'Purchasing Manager', value: 'Purchasing Manager' },
  { label: 'Architect', value: 'Architect' },
  { label: 'Engineer', value: 'Engineer' },
  { label: 'Subcontractor', value: 'Subcontractor' },
  { label: '+Add New', value: 'Other', optionColor: '#4D6EF5' },
];

export const TEMP_ITEM_ID_COEFFICIENT = 50;

export const DOCUMENT_FORMATS = [
  '.pdf',
  '.txt',
  '.doc',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.csv',
  '.ppt',
  '.pptx',
  '.key',
];

export const FILTER_OPTIONS = [
  { sort: ProjectsSortEnum.CREATED_AT, order: 'DESC', value: 'Newest' },
  { sort: ProjectsSortEnum.CREATED_AT, order: 'ASC', value: 'Oldest' },
  { sort: ProjectsSortEnum.NAME, order: 'ASC', value: 'A-Z' },
  { sort: ProjectsSortEnum.NAME, order: 'DESC', value: 'Z-A' },
  { sort: ProjectsSortEnum.OVERDUE, order: 'DESC', value: 'Overdue' },
  { sort: ProjectsSortEnum.ACTIVITY_COUNT, order: 'DESC', value: 'Activity' },
];

export const IMAGE_TYPES =
  'image/jpg, image/jpeg, image/png, image/webp, image/tiff, image/avif, image/heic, image/heif';

export const FILE_TYPES =
  'application/pdf, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.apple.keynote';

export const CABINET_FILE_TYPES =
  'image/jpg, image/jpeg, image/png, image/webp, image/gif, image/tiff, image/avif, image/heic, image/heif, application/pdf, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.apple.keynote';

export const MAX_FILE_SIZE = 52428800;
export const MAX_FILE_COUNT = 10;

export const OWNER_ROLE_ID = 0;

export const SIDE_PANEL_WIDTH = 447;
export const HEADER_HEIGHT = 73;
export const ACTIVITY_FEED_HEADER_HEIGHT = 52;
