import { FormProvider, useForm } from 'react-hook-form';
// mui components
import { Grid, Typography } from '@mui/material';
// layouts
import CreateProjectForm from 'layouts/create-project-form';
// components
import DialogModal from 'components/modal/dialog-modal';
import StyledForm from 'components/styled-form';
import ActionButton from 'components/buttons';
// Icons
import { ReactComponent as ArchiveIcon } from 'assets/icons/Archive_Blue_icon.svg';
//styles
import { editProjectModalStyles } from '../styles/styles';

const EditProjectModalNewUI = ({
  open,
  project,
  coordinates,
  form,
  country,
  loading,
  updateCoordinates,
  updateAddress,
  closeProjectModal,
  handleUpdateProject,
  handleArchiveProject,
  handleCountryChange,
}) => {
  return (
    <DialogModal
      maxWidth="800px"
      open={open}
      withCloseButton={false}
      handleClose={closeProjectModal}
      withoutPadding
    >
      <FormProvider {...form}>
        <StyledForm
          onSubmit={form.handleSubmit(handleUpdateProject)}
          sx={editProjectModalStyles.form}
        >
          <Grid sx={editProjectModalStyles.formHeader}>
            <Typography sx={editProjectModalStyles.headerTitle}>Edit Project</Typography>
            <Typography sx={editProjectModalStyles.headerSubtitle}>
              Here you can edit your project details.
            </Typography>
          </Grid>
          <Grid sx={editProjectModalStyles.formContainer}>
            <CreateProjectForm
              country={country}
              coordinates={coordinates}
              updateCoordinates={updateCoordinates}
              updateAddress={updateAddress}
              handleCountryChange={handleCountryChange}
            />
          </Grid>
          <Grid sx={editProjectModalStyles.footer}>
            <Grid item>
              <Typography
                sx={editProjectModalStyles.archive}
                onClick={handleArchiveProject}
                loading={loading}
              >
                <ArchiveIcon />
                Archive Project
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', gap: '12px' }}>
              <ActionButton
                variant="secondary"
                label="Cancel"
                type="button"
                onClick={closeProjectModal}
                style={editProjectModalStyles.cancelButton}
              />
              <ActionButton
                type="submit"
                label="Save"
                loading={loading}
                style={editProjectModalStyles.saveButton}
              />
            </Grid>
          </Grid>
        </StyledForm>
      </FormProvider>
    </DialogModal>
  );
};

export default EditProjectModalNewUI;
