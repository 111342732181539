// Popper component cause a type error in production. This is the MUI bug.
// @ts-nocheck
import React, { useCallback, useState, useRef, useEffect } from 'react';
// mui components
import { ClickAwayListener, Grow, Paper, MenuList, Button, Typography, Grid } from '@mui/material';
import Popper from '@mui/material/Popper';
// modals
import ManageUsersModal from 'components/dashboard/modals/manage-users-modal';
import EditProjectModal from 'components/dashboard/modals/edit-project-modal';
// types
import { Coords } from 'components/google-map';
// api
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
// icons
import { ReactComponent as SettingsIcon } from 'assets/icons/buttons/gear.svg';
import { ReactComponent as UserPlusIcon } from 'assets/icons/buttons/user-plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/buttons/edit.svg';
//styles
import { settingsButton } from '../styles/styles';
import { useGetProjectByIdQuery } from 'redux/api/projects';

const SettingsButton = () => {
  const [open, setOpen] = useState(false);
  const [openUsersModal, setOpenUsersModal] = useState(false);
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [coordinates, setCoordinates] = useState<Coords | undefined>();

  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const projectId = window.location.pathname.split('/')?.[2];
  const anchorRef = useRef(null);

  const { data: project } = useGetProjectByIdQuery(
    { id: Number(projectId) },
    { skip: !Number(projectId) }
  );

  useEffect(() => {
    if ((project?.lat, project?.lng)) {
      setCoordinates({
        lat: Number(project.lat),
        lng: Number(project.lng),
      });
    }
  }, [project?.lat, project?.lng]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenUserModal = useCallback(() => {
    setOpenUsersModal(true);
    setOpen(false);
  }, []);

  const handleCloseUserModal = useCallback(() => {
    setOpenUsersModal(false);
  }, []);

  const handleOpenProjectModal = useCallback(() => {
    setOpenProjectModal(true);
    setOpen(false);
  }, []);

  const handleCloseProjectModal = useCallback(() => {
    setOpenProjectModal(false);
  }, []);

  const updateCoordinates = useCallback((currentCoords: Coords) => {
    setCoordinates(currentCoords);
  }, []);

  if (
    !project ||
    (!userPermissions?.USER_MANAGEMENT?.canUpdate &&
      !userPermissions?.SCHEDULE_TEMPLATES?.canUpdate &&
      !userPermissions?.PROJECT?.canUpdate)
  ) {
    return null;
  }

  return (
    <Grid sx={settingsButton.buttonContainer}>
      <Button
        ref={anchorRef}
        variant="outlined"
        id="companies-list-button"
        aria-controls={open ? 'companies-list-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={<SettingsIcon />}
        sx={settingsButton.button}
      />

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        sx={settingsButton.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={settingsButton.popper}>
            <Paper sx={settingsButton.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="companies-list"
                  aria-labelledby="companies-list"
                  sx={settingsButton.settingsListContainer}
                >
                  <Grid container sx={settingsButton.paperContentContainer}>
                    {userPermissions?.PROJECT?.canCreate && userPermissions?.PROJECT?.canUpdate && (
                      <Grid sx={settingsButton.itemContainer} onClick={handleOpenProjectModal}>
                        <EditIcon style={settingsButton.popperItemIcon} />
                        <Typography sx={settingsButton.popperItemTitle}>Edit Project</Typography>
                      </Grid>
                    )}

                    {userPermissions?.USER_MANAGEMENT?.canCreate &&
                      userPermissions?.USER_MANAGEMENT?.canUpdate && (
                        <Grid sx={settingsButton.itemContainer} onClick={handleOpenUserModal}>
                          <UserPlusIcon style={settingsButton.popperItemIcon} />
                          <Typography sx={settingsButton.popperItemTitle}>Manage Users</Typography>
                        </Grid>
                      )}
                  </Grid>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {userPermissions?.PROJECT?.canCreate && userPermissions?.PROJECT?.canUpdate && (
        <EditProjectModal
          project={project}
          open={openProjectModal}
          coordinates={coordinates}
          updateCoordinates={updateCoordinates}
          closeProjectModal={handleCloseProjectModal}
        />
      )}

      {openUsersModal &&
        userPermissions?.USER_MANAGEMENT?.canCreate &&
        userPermissions?.USER_MANAGEMENT?.canUpdate && (
          <ManageUsersModal
            open={openUsersModal}
            project={project}
            onCloseUserModal={handleCloseUserModal}
          />
        )}
    </Grid>
  );
};

export default SettingsButton;
