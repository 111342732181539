import { combineReducers, configureStore } from '@reduxjs/toolkit';
// api
import { api } from './api';
// reducers
import accountStepperReducer from './slices/account-stepper-slice';
import billingReducer from './slices/billing-slice';
import authReducer from './slices/auth-slice';
import queryArgsReducer from './slices/query-args-slice';
import newCommentsReducer from './slices/new-comments-slice';
import postAudienceReducer from './slices/post-audience-slice';
import replyToPostReducer from './slices/reply-to-post-slice';
import editorHeightReducer from './slices/editor-height-slice';
import sidePanelReducer from './slices/side-panel-slice';
import manageGanttReducer from './slices/manage-gantt-slice';
import fromMobileReducer from './slices/from-mobile-slice';
import fileCabinetReducer from './slices/file-cabinet-slice';
import selectedSelectionsReducer from './slices/selected-selections-slice';
import costCodeTemplateReducer from './slices/cost-code-template-slice';
import checklistReducer from './slices/checklists-slice';
// middleware
import { rtkErrorHandler } from './middleware/rtk-error-handler';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  accountStepper: accountStepperReducer,
  billing: billingReducer,
  queryArgs: queryArgsReducer,
  newComments: newCommentsReducer,
  postAudience: postAudienceReducer,
  replyToPost: replyToPostReducer,
  editorHeight: editorHeightReducer,
  sidePanel: sidePanelReducer,
  manageGantt: manageGanttReducer,
  fromMobile: fromMobileReducer,
  fileCabinet: fileCabinetReducer,
  selectedSelections: selectedSelectionsReducer,
  costCodeTemplate: costCodeTemplateReducer,
  checklist: checklistReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware, rtkErrorHandler]),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
