// Popper component cause a type error in production. This is the MUI bug.
// @ts-nocheck
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { differenceInCalendarDays } from 'date-fns';
// mui components
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// api
import { useCreateOverdueItemsViewsMutation } from 'redux/api/activity-feed';
import {
  IOverdueItem,
  OverdueItemTypeEnum,
  useGetOverdueItemsQuery,
  useGetProjectByIdQuery,
} from 'redux/api/projects';
// icons
import { ReactComponent as AttentionIcon } from 'assets/icons/Alert_icon_new.svg';
import { ReactComponent as AttentionIconWithDot } from 'assets/icons/alert-with-dot-icon.svg';
// styles
import { notificationButtonStyles } from './styles/styles';
import { useSidePanel } from 'hooks/useSidePanel';

interface IAlertButtonProps {
  projectId?: number;
  itemsCount?: number;
  unviewedItemsCount?: number;
}

const AlertButton: FC<IAlertButtonProps> = ({
  projectId,
  itemsCount = 0,
  unviewedItemsCount = 0,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { setProjectId: setProjectIdCtx } = useSidePanel();

  const [bulkUpdate] = useCreateOverdueItemsViewsMutation();

  useGetProjectByIdQuery({ id: Number(projectId) }, { skip: !open || !Number(projectId) });

  const { data: overdueItems, isLoading } = useGetOverdueItemsQuery(
    { projectId: Number(projectId) },
    { skip: !open }
  );

  const markItemsAsViewed = async () => {
    if (unviewedItemsCount && overdueItems?.overdue?.length) {
      const itemIds = overdueItems.overdue
        .filter(({ type, isViewed }) => !isViewed && type === OverdueItemTypeEnum.PHASE)
        .map(({ id }) => id);

      const selectionItemIds = overdueItems.overdue
        .filter(({ type, isViewed }) => !isViewed && type === OverdueItemTypeEnum.SELECTION)
        .map(({ id }) => id);

      if (itemIds.length || selectionItemIds.length) {
        bulkUpdate({
          projectId: Number(projectId),
          itemIds,
          selectionItemIds,
        }).finally(() => {
          setOpen(false);
        });
      } else {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const handleToggle = async () => {
    if (open) {
      await markItemsAsViewed();
      return;
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async () => {
    if (open) {
      await markItemsAsViewed();
      return;
    }
    setOpen(false);
  };

  const onSeeMore = async () => {
    if (open) {
      await markItemsAsViewed();
    }
    setOpen(false);
    navigate(`/project-dashboard/${projectId}?status=OVERDUE`);
  };

  const onItemClick = (overdueItem: IOverdueItem) => async () => {
    if (open) {
      await markItemsAsViewed();
    }

    const location = window.location.pathname.split('/')?.[1];

    if (
      !(
        (location === 'project-dashboard' && overdueItem.type === OverdueItemTypeEnum.PHASE) ||
        (location === 'selections' && overdueItem.type === OverdueItemTypeEnum.SELECTION)
      )
    ) {
      const path =
        overdueItem.type === OverdueItemTypeEnum.PHASE ? 'project-dashboard' : 'selections';
      if (overdueItem.type === OverdueItemTypeEnum.PHASE) {
        navigate(`/${path}/${projectId}?#${overdueItem.id}`);
      } else {
        navigate(`/${path}/${projectId}`);
      }
    } else {
      const element = document.getElementById(overdueItem.id.toString());
      if (element) {
        if (overdueItem.type === OverdueItemTypeEnum.PHASE) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setProjectIdCtx(projectId);
    }
  }, [projectId, open]);

  return (
    <Grid
      sx={
        !open
          ? notificationButtonStyles.buttonContainer
          : notificationButtonStyles.buttonContainerOpened
      }
    >
      <Button
        ref={anchorRef}
        variant="outlined"
        id="companies-list-button"
        aria-controls={open ? 'companies-list-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={unviewedItemsCount ? <AttentionIconWithDot /> : <AttentionIcon />}
        sx={notificationButtonStyles.container}
      >
        <Typography sx={notificationButtonStyles.buttonContent}>{itemsCount ?? 0}</Typography>
      </Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        sx={notificationButtonStyles.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={notificationButtonStyles.popper}>
            <Paper sx={notificationButtonStyles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid sx={notificationButtonStyles.paperContentContainer}>
                  <Grid sx={notificationButtonStyles.paperTitleContainer}>
                    <Grid sx={notificationButtonStyles.paperTitleWrapper}>
                      <Typography sx={notificationButtonStyles.paperTitle}>
                        Recent Overdue Items
                      </Typography>
                    </Grid>
                    <Grid sx={notificationButtonStyles.seeMoreContainer} onClick={onSeeMore}>
                      <Typography sx={notificationButtonStyles.seeMore}>See all</Typography>
                      <ChevronRightIcon sx={notificationButtonStyles.chevronIcon} />
                    </Grid>
                  </Grid>
                  <Grid
                    sx={
                      overdueItems?.overdue?.length
                        ? notificationButtonStyles.paperItemsContainerFixed
                        : notificationButtonStyles.paperItemsContainer
                    }
                  >
                    {!overdueItems?.overdue?.length && open && !isLoading ? (
                      <Grid sx={notificationButtonStyles.emptyStateContainer}>
                        <Grid sx={notificationButtonStyles.emptyStateWrapper}>
                          <Typography sx={notificationButtonStyles.emptyStateTitle}>
                            No Overdue Items
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : isLoading ? (
                      <Grid sx={notificationButtonStyles.loaderContainer}>
                        <CircularProgress />
                      </Grid>
                    ) : (
                      overdueItems?.overdue?.map((overdueItem) => {
                        const { title, id, endDate, isViewed } = overdueItem;
                        const daysDiff = differenceInCalendarDays(new Date(), new Date(endDate));
                        const overdueDays = daysDiff === 0 ? 1 : daysDiff;

                        return (
                          <Grid key={id} sx={notificationButtonStyles.paperItem}>
                            <Grid sx={notificationButtonStyles.redDotContainer}>
                              {!isViewed && <Grid sx={notificationButtonStyles.redDot}></Grid>}
                            </Grid>
                            <Grid sx={notificationButtonStyles.itemContentContainer}>
                              <Typography sx={notificationButtonStyles.itemContent}>
                                <span onClick={onItemClick(overdueItem)}>{title}</span>{' '}
                                {`is now ${overdueDays} ${
                                  overdueDays === 1 ? 'day' : 'days'
                                } overdue`}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default AlertButton;
