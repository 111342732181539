export const companiesButtonStyles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #F3F4F9',
    paddingRight: '12px',
  },

  container: {
    width: '186px',
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'black',
    border: 'unset',
    padding: '6px 8px',
    minWidth: 'auto',

    '&:hover': {
      border: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '8px',
      marginLeft: '0px',

      '& span': {
        padding: '5px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        width: '40px',
        height: '40px',
      },

      '& svg, img': {
        width: '40px',
        height: '40px',
        borderRadius: '8px',
      },
    },
  },

  grow: { transformOrigin: 'left top' },

  buttonContent: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '146%',
    alignItems: 'start',
    textAlign: 'start',
    color: '#23252F',
    maxWidth: '117px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },

  popper: {
    width: '340px',
    zIndex: 10,
  },

  paper: {
    width: '340px',
    marginTop: '20px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },

  paperContentContainer: {
    widht: '100%',
    padding: '14px 8px 0 8px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  companiesListContainer: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  companiesListContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  popperTitle: {
    widht: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#6E707A',
    marginBottom: '10px',
    paddingLeft: '12px',
  },

  popperItem: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    borderRadius: '8px',
    margin: '6px 0 4px 0',
    padding: '6px 12px',

    '&:hover': {
      background: '#F2F5FF',
    },
  },

  popperItemTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },

  popperItemSubtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#6E707A',
  },
};

export const headerStyles = {
  header: {
    height: '73px',
    padding: '0 24px',
    backgroundColor: '#fff',
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',

    '@media(max-width: 1240px)': {
      gap: '6px',
    },
  },

  logoContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '6px 20px 6px 8px',
    borderRight: '1px solid #F3F4F9',

    '& a': {
      display: 'flex',
    },
    '& svg': {
      width: '128px',
      height: '36px',
    },
    '& img': {
      height: '50px',
      objectFit: 'contain',
    },

    '@media (max-width: 1240px)': {
      padding: '6px 6px 6px 8px',
    },
  },

  logoContainerHome: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '6px 20px 6px 8px',
    borderRight: '1px solid #F3F4F9',

    '& a': {
      display: 'flex',
    },
    '& svg': {
      height: '36px',
    },
    '& img': {
      height: '50px',
      objectFit: 'contain',
    },
  },

  popperButtonContainer: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      cursor: 'pointer',
    },
  },

  actionButtons: {
    display: 'flex',
    gap: '12px',

    '@media(max-width: 1240px)': {
      gap: '6px',
    },
  },

  buttonContainer: {
    borderRight: '1px solid #F3F4F9',
    paddingRight: '12px',

    '@media (max-width: 1240px)': {
      paddingRight: '6px',
    },
  },

  fileCabinetButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'black',
    padding: '8px 12px 8px 8px',
    width: '20px',
    minWidth: '40px',
    maxWidth: '40px',
    maxHeight: '36px',
    border: 'solid 1px #F3F4F9',

    '&:hover': {
      border: 'none',
    },

    '&:active': {
      boxShadow: '0px 0px 2px #4D6EF5',
    },

    '& .MuiButton-startIcon': {
      marginRight: '0px',
      marginLeft: '0px',

      '& svg, img': {
        width: '20px',
        height: '20px',
        borderRadius: '8px',
      },
    },
  },
};

export const projectsButtonStyles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #F3F4F9',
    paddingRight: '12px',

    '@media (max-width: 1240px)': {
      paddingRight: '24px',
    },
  },

  buttonContentContainer: {
    width: '100%',
    maxWidth: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  container: {
    width: '235px',
    maxWidth: '235px',
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'black',
    border: 'unset',
    padding: '6px 8px',
    minWidth: 'auto',

    '&:hover': {
      border: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '8px',
      marginLeft: '0px',

      '& span': {
        padding: '5px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        width: '20px',
        height: '20px',
      },

      '& svg, img': {
        width: '20px',
        height: '20px',
        borderRadius: '8px',
      },
    },

    '@media(max-width: 1240px)': {
      width: '135px',
      maxWidth: '135px',
    },
  },

  containerIcon: {
    width: '20px',
    height: '20px',
  },

  grow: { transformOrigin: 'left top' },

  buttonTitle: {
    width: '100%',
    maxWidth: '228px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '146%',
    alignItems: 'start',
    textAlign: 'start',
    color: '#23252F',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    WebkitLineClamp: '1',
    'line-clamp': '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },

  buttonSubtitle: {
    width: '100%',
    maxWidth: '228px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '146%',
    alignItems: 'start',
    textAlign: 'start',
    color: '#6E707A',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    WebkitLineClamp: '1',
    'line-clamp': '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },

  popper: {
    width: '560px',
    zIndex: 10,
  },

  paper: {
    width: '560px',
    marginTop: '20px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },

  paperContentContainer: {
    widht: '100%',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  companiesListContainer: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  companiesListContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  popperTitle: {
    widht: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#6E707A',
    marginBottom: '10px',
    paddingLeft: '12px',
  },

  popperItem: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    borderRadius: '8px',
    margin: '6px 0 4px 0',
    padding: '6px 12px',

    '&:hover': {
      background: '#F2F5FF',
    },
  },

  popperItemTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },

  popperItemSubtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#6E707A',
  },

  projectCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '5px 10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F5FF',
      borderRadius: '12px',
    },
  },

  projectCardInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  projectNameContent: {
    width: '100%',
    maxWidth: '230px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '146%',
    alignItems: 'start',
    textAlign: 'start',
    color: '#23252F',
    overflow: 'hidden',
    display: '-webkit-box',
    textDecoration: 'unset',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
    transition: '300ms',
    '&:hover': {
      opacity: '0.7',
    },
  },

  projectAddressContent: {
    width: '100%',
    maxWidth: '230px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '150%',
    alignItems: 'start',
    textAlign: 'start',
    color: '#6E707A',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },

  searchInputContainer: {
    width: '100%',
    display: 'flex',
    justifyCOntent: 'center',
    alignItems: 'center',
    padding: '20px 20px 0 20px',
  },

  searchInput: {
    height: '38px !important',
    marginBottom: '24px',
    border: '1px solid #F3F4F9',
    borderRadius: '10px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
  },

  buttonGroupContainer: {
    minWidth: '190px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
    boxShadow: 'unset',
  },

  projectItemsContainer: {
    margin: '0 10px 0 10px',
    marginRight: '10px',
    maxHeight: '240px',
    overflowY: 'scroll',
    paddingRight: '5px',
    scrollbarColor: '#0A4C95 #C2D2E4',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EDEFF7',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },

  newProjectContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 20px 16px 20px',
    borderTop: '1px solid #F3F4F9',
  },

  newProjectButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0',
    transition: '300ms',

    '&:hover': {
      baclgroundColor: 'white',
      opacity: '0.7',
    },
  },

  newProjectPlusIcon: {
    marginRight: '12px',
  },

  noProject: {
    margin: '0 0 24px 0',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
  },

  projectCard: {
    minHeight: '54px',
    padding: '0 0 16px',
  },

  inputAdornment: {
    margin: 0,
    '& div': {
      display: 'flex',
    },
  },

  styledMessagesButton: {
    width: '100%',
    padding: '4px 8px',
    fontSize: '12px',
    lineHeight: '150%',
    fontWeight: 400,
    color: '#344054',
    backgroundColor: '#fff',
    borderColor: '#F3F4F9',
    '& svg': {
      width: '16px',
      height: '16px',
      marginRight: '8px',
    },

    '&:hover': {
      background: 'white',
      border: '1px solid #F3F4F9',
      opacity: '0.7',
    },
  },

  styledTextAddress: {
    width: '100%',
    maxWidth: '230px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '150%',
    alignItems: 'start',
    textAlign: 'start',
    color: '#6E707A',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },
};

export const accountsButtonStyles = {
  accounts: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '@media (max-width: 940px)': {
      display: 'none',
    },
  },

  accountsContainer: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'center',
    alignItems: 'center',

    '& div:nth-of-type(1)': {
      marginLeft: '0 !important',
    },

    '& div': {
      marginLeft: '-12px !important',
    },
  },

  avatar: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    cursor: 'pointer',
  },

  avatarAbbreviation: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#EDEFF7',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6E707A',
  },

  otherAvatarsCount: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#EDEFF7',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6E707A',
    marginLeft: '-12px',
    border: '2px solid #fff',
  },
};

export const settingsButton = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    border: '1px solid #EDEFF7',
    borderRadius: '8px',
  },

  button: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    color: 'black',
    border: 'unset',
    padding: '6px 8px',
    minWidth: 'auto',

    '&:hover': {
      border: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '0',
      marginLeft: '0',

      '& span': {
        padding: '5px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        width: '36px',
        height: '36px',
      },

      '& svg, img': {
        width: '20px',
        height: '20px',
      },
    },
  },

  popper: {
    width: '180px',
    zIndex: 10,
  },

  paper: {
    width: '180px',
    marginTop: '20px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },

  settingsListContainer: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  paperContentContainer: {
    widht: '100%',
    padding: '6px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 12px',
    borderRadius: '8px',
    transition: '300ms',
    cursor: 'pointer',

    '&:hover': {
      background: '#F2F5FF',
    },
  },

  popperItemTitle: {
    widht: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
    paddingLeft: '12px',
  },

  popperItemIcon: {
    marginRight: '0',
    marginLeft: '0',

    '& span': {
      padding: '5px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      width: '36px',
      height: '36px',
    },

    '& svg, img': {
      width: '20px',
      height: '20px',
    },
  },
};

export const helpMenuStyles = {
  settingsListContainer: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  popperItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 17px',
    borderRadius: '8px',
    transition: '300ms',
    cursor: 'pointer',

    '&:hover': {
      background: '#F2F5FF',
    },
  },
  paper: {
    marginTop: '8px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },

  popper: {
    width: '200px',
    zIndex: 10,
    padding: '0 2px',
  },

  menuItem: {
    display: 'flex',
    justifyContent: 'start',
    gap: '13px',
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
    padding: '14px 17px',
    borderRadius: '5px',
    marginLeft: '3px',
    '&:hover': {
      backgroundColor: '#F2F5FF', // set a background color on hover
      cursor: 'pointer', // change the cursor to a pointer on hover
    },
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    borderRadius: '8px',
    margin: '0 20px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgb(243, 244, 249)',
      background: 'rgba(25, 118, 210, 0.04)',
      boxShadow: 'rgb(77, 110, 245) 0px 0px 2px',
    },

    '@media (max-width: 1240px)': {
      margin: '0 6px',
    },
  },

  divider: {
    background: '#F3F4F9',
    opacity: '0.3',
    height: '48px',
  },
};
