import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReplyToPost {
  isOpen: boolean;
}

const initialState: IReplyToPost = {
  isOpen: true,
};

export const replyToPostSlice = createSlice({
  name: 'replyToPost',
  initialState,
  reducers: {
    handlePostReplyOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    handlePostReplyDefault: (state) => {
      state.isOpen = initialState.isOpen;
    },
  },
});

export const { handlePostReplyOpen, handlePostReplyDefault } = replyToPostSlice.actions;

export default replyToPostSlice.reducer;
