import { api } from 'redux/api';
import { projectsApi } from '../projects';
import queryString from 'query-string';
import isEqual from 'lodash.isequal';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
// types
import {
  IActivityFeedItemsArgs,
  TActivityFeedPosts,
  IActivityFeedPost,
  IGetPostByIdArgs,
  ICreatePostBody,
  IDeletePostsBody,
  IUpdatePostBody,
  TActivityFeedComments,
  IGetCommentsArgs,
  IActivityFeedComment,
  ICreateCommentBody,
  IUpdateCommentBody,
  IDeleteCommentsBody,
  TActivityFeedItemLikes,
  IActivityFeedItemLikesArgs,
  ILikeActivityFeedItemArgs,
  IActivityFeedUnviewedItemsArgs,
  IActivityFeedUnviewedItemsCount,
  ICreateItemsViewsBody,
  ICreateItemsViewsBulkBody,
  ICreateItemsViewsResponse,
  IGetPostAudienceArgs,
  IGetProjectAudienceArgs,
  IActivityFeedAudience,
  TGetRecentActivityResponse,
  IGetRecentActivityArgs,
  ISpecificUsers,
  ISpecificUsersArgs,
  IGetNotificationsArgs,
  TGetNotificationsResponse,
  ICreatePostAudienceBody,
  IGetUnviewedNotificationsArgs,
  IActivityFeedAppliableFilters,
  IGetActivityFeedAppliableFiltersArgs,
  TActivityFeedMediaMapped,
  IGetActivityFeedMediaArgs,
  TGetActivityFeedMediaResponse,
  IDeleteCustomAudienceBody,
} from './';
import { deleteComments, editComment, likeUnlikeComment } from 'redux/slices/new-comments-slice';
import {
  BaseQueryFn,
  BaseQueryMeta,
  BaseQueryResult,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { activityFeedMediaMapping } from '../../mappings/activity-feed-media';
import { string } from 'yup';
import { mergeActivityFeedMedia } from './helpers';

enum Endpoints {
  ACTIVITY_FEED = 'activity-feed/',
  ACTIVITY_FEED_POSTS = 'activity-feed/post',
  ACTIVITY_FEED_COMMENTS = 'activity-feed-post/comments',
  ACTIVITY_FEED_LIKES = 'activity-feed/like',
  ACTIVITY_FEED_VIEWS = 'activity-feed/items/views',
  ACTIVITY_FEED_MESSAGES = 'activity-feed/items/messages',
  ACTIVITY_FEED_AUDIENCE = '/activity-feed-post/audience',
  ACTIVITY_FEED_NOTIFICATIONS = 'notification/',
  ACTIVITY_FEED_MEDIA = 'activity-feed-media/',
}

export const activityFeedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPosts: build.query<TActivityFeedPosts, IActivityFeedItemsArgs>({
      query: ({ projectId, posterProfileIds, ...queryArgs }) => ({
        url: `${Endpoints.ACTIVITY_FEED}${projectId}/posts${
          posterProfileIds
            ? '?' + queryString.stringify({ posterProfileIds }, { arrayFormat: 'none' })
            : ''
        }`,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache, { arg: { cursor } }) => {
        if (!cursor) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
    }),

    getPostById: build.query<IActivityFeedPost, IGetPostByIdArgs>({
      query: ({ postId, projectId }) => ({
        url: `${Endpoints.ACTIVITY_FEED}${projectId}/post/${postId}`,
      }),
      keepUnusedDataFor: 0,
    }),

    createPost: build.mutation<IActivityFeedPost, ICreatePostBody>({
      query: (data) => ({ url: Endpoints.ACTIVITY_FEED_POSTS, body: data, method: 'POST' }),
    }),

    updatePost: build.mutation<IActivityFeedPost, IUpdatePostBody>({
      query: (data) => ({
        url: Endpoints.ACTIVITY_FEED_POSTS,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Post Audience'],
      onQueryStarted: async ({ projectId, id }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            activityFeedApi.util.updateQueryData('getPosts', { projectId }, (draft) => {
              const index = draft.items.findIndex(
                ({ id: updatedPostId }) => updatedPostId === response.id
              );
              if (index >= 0) {
                Object.assign(draft.items[index], response);
              }
            })
          );
          dispatch(
            activityFeedApi.util.updateQueryData(
              'getPostById',
              { postId: id, projectId },
              (draft) => {
                if (draft?.id === response.id) {
                  Object.assign(draft, response);
                }
              }
            )
          );
        } catch {}
      },
    }),

    deletePost: build.mutation<null, IDeletePostsBody>({
      query: ({ ids, projectId }) => ({
        url: `${Endpoints.ACTIVITY_FEED_POSTS}?projectId=${projectId}&${queryString.stringify(
          { ids: ids },
          { arrayFormat: 'bracket' }
        )}`,
        method: 'DELETE',
      }),
      onQueryStarted: async ({ ids, projectId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            activityFeedApi.util.updateQueryData('getPosts', { projectId }, (draft) => {
              if (draft?.items) {
                draft.items = draft.items.filter(({ id }) => id !== ids[0]);
              }
            })
          );
        } catch {}
      },
    }),

    getComments: build.query<TActivityFeedComments, IGetCommentsArgs>({
      query: ({ postId, ...queryArgs }) => ({
        url: `${Endpoints.ACTIVITY_FEED_COMMENTS}/${postId}`,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { postId } }) => {
        return { postId };
      },
      merge: (currentCache, newCache) => {
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
    }),

    createComment: build.mutation<IActivityFeedComment, ICreateCommentBody>({
      query: ({ projectId: _, addLocal, ...data }) => ({
        url: Endpoints.ACTIVITY_FEED_COMMENTS,
        body: data,
        method: 'POST',
      }),
    }),

    updateComment: build.mutation<IActivityFeedComment, IUpdateCommentBody>({
      query: ({ postId, ...data }) => ({
        url: Endpoints.ACTIVITY_FEED_COMMENTS,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: async ({ postId, id: commentId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
              if (draft?.items) {
                if (response?.rootCommentId) {
                  const rootIndex = draft.items.findIndex(
                    ({ id }) => id === response.rootCommentId
                  );
                  const index = draft.items[rootIndex].replies.findIndex(
                    ({ id }) => id === response.id
                  );
                  draft.items[rootIndex].replies[index] = response;
                } else {
                  const index = draft.items.findIndex(({ id }) => id === commentId);
                  if (index >= 0) {
                    const { replies: _, ...restResponse } = response;
                    Object.assign(draft.items[index], restResponse);
                  }
                }
              }
            })
          );
          dispatch(editComment(response));
        } catch {}
      },
    }),

    deleteComment: build.mutation<undefined, IDeleteCommentsBody>({
      query: (data) => ({
        url: `${Endpoints.ACTIVITY_FEED_COMMENTS}?projectId=${
          data.projectId
        }&${queryString.stringify({ ids: data.ids }, { arrayFormat: 'bracket' })}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (
        { postId, projectId, ids, rootCommentId },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;
          dispatch(
            activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
              if (draft?.items) {
                if (rootCommentId) {
                  const rootCommentIndex = draft.items.findIndex(({ id }) => id === rootCommentId);
                  draft.items[rootCommentIndex].replies = draft.items[
                    rootCommentIndex
                  ].replies.filter(({ id }) => id !== ids[0]);
                } else {
                  draft.items = draft.items.filter(({ id }) => id !== ids[0]);
                }
              }
            })
          );
          dispatch(
            activityFeedApi.util.updateQueryData('getPosts', { projectId }, (draft) => {
              if (draft?.items) {
                const index = draft.items.findIndex(({ id }) => id === postId);
                if (index >= 0) {
                  draft.items[index].commentsCount -= 1;
                }
              }
            })
          );
          dispatch(
            activityFeedApi.util.updateQueryData('getPostById', { postId, projectId }, (draft) => {
              if (draft) {
                draft.commentsCount -= 1;
              }
            })
          );
          dispatch(
            deleteComments({
              postId,
              rootCommentId,
              id: ids[0],
            })
          );
        } catch {}
      },
    }),

    getRecentActivity: build.query<TGetRecentActivityResponse, IGetRecentActivityArgs>({
      query: (queryArgs) => ({
        url: Endpoints.ACTIVITY_FEED_MESSAGES,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache) => {
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
    }),

    getNotifications: build.query<TGetNotificationsResponse, IGetNotificationsArgs>({
      query: (queryArgs) => ({
        url: `${Endpoints.ACTIVITY_FEED_NOTIFICATIONS}${queryArgs.projectId}`,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache) => {
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
    }),

    getUnviewedNotifications: build.query<TGetNotificationsResponse, IGetUnviewedNotificationsArgs>(
      {
        query: (queryArgs) => ({
          url: `${Endpoints.ACTIVITY_FEED_NOTIFICATIONS}${queryArgs.projectId}`,
          params: queryArgs,
        }),
        serializeQueryArgs: ({ queryArgs: { projectId } }) => {
          return { projectId };
        },
        merge: (currentCache, newCache) => {
          return {
            meta: newCache.meta,
            items: [...currentCache.items, ...newCache.items],
          };
        },
        forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
        keepUnusedDataFor: 0,
      }
    ),

    getActivityFeedItemLikes: build.query<TActivityFeedItemLikes, IActivityFeedItemLikesArgs>({
      query: ({ activityFeedId, ...queryArgs }) => ({
        url: `${Endpoints.ACTIVITY_FEED_LIKES}/${activityFeedId}`,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { activityFeedId } }) => {
        return { activityFeedId };
      },
      merge: (currentCache, newCache, { arg: { cursor } }) => {
        if (!cursor) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
    }),

    likeActivityFeedItem: build.mutation<undefined, ILikeActivityFeedItemArgs>({
      query: ({ postId, commentId, rootCommentId, ...data }) => ({
        url: `${Endpoints.ACTIVITY_FEED_LIKES}/like`,
        body: {
          ...data,
          postId,
        },
        method: 'POST',
      }),
      onQueryStarted: async (
        { commentId, postId, projectId, rootCommentId, activityFeedItemId },
        { dispatch, queryFulfilled }
      ) => {
        const patchList = dispatch(
          commentId
            ? activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
                if (draft?.items) {
                  if (rootCommentId) {
                    const index = draft.items.findIndex(({ id }) => id === rootCommentId);
                    const replyIndex = draft.items[index]?.replies?.findIndex(
                      ({ id }) => id === commentId
                    );
                    if (draft.items[index]?.replies?.[replyIndex]) {
                      draft.items[index].replies[replyIndex].isLiked = true;
                      draft.items[index].replies[replyIndex].likesCount += 1;
                    }
                  } else {
                    const index = draft.items.findIndex(({ id }) => id === commentId);
                    if (index >= 0) {
                      draft.items[index].isLiked = true;
                      draft.items[index].likesCount += 1;
                    }
                  }
                }
              })
            : activityFeedApi.util.updateQueryData('getPosts', { projectId }, (draft) => {
                if (draft?.items) {
                  const index = draft.items.findIndex(({ id }) => id === postId);
                  if (index >= 0) {
                    draft.items[index].isLiked = true;
                    draft.items[index].likesCount += 1;
                  }
                }
              })
        );
        let patchPost: PatchCollection;

        if (!commentId) {
          patchPost = dispatch(
            activityFeedApi.util.updateQueryData('getPostById', { postId, projectId }, (draft) => {
              if (draft?.id === postId) {
                draft.isLiked = true;
                draft.likesCount += 1;
              }
            })
          );
        }

        try {
          await queryFulfilled;
          if (commentId) {
            dispatch(
              likeUnlikeComment({
                activityFeedItemId,
                postId,
                projectId,
                commentId,
                rootCommentId,
                increase: true,
              })
            );
          }
        } catch {
          patchList?.undo();
          patchPost?.undo();
        }
      },
    }),

    unlikeActivityFeedItem: build.mutation<undefined, ILikeActivityFeedItemArgs>({
      query: ({ commentId, postId, rootCommentId, ...data }) => ({
        url: `${Endpoints.ACTIVITY_FEED_LIKES}/unlike`,
        body: {
          ...data,
          postId,
        },
        method: 'POST',
      }),
      onQueryStarted: async (
        { commentId, postId, projectId, rootCommentId, activityFeedItemId },
        { dispatch, queryFulfilled }
      ) => {
        const patchList = dispatch(
          commentId
            ? activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
                if (draft?.items) {
                  if (rootCommentId) {
                    const index = draft.items.findIndex(({ id }) => id === rootCommentId);
                    const replyIndex = draft.items[index]?.replies?.findIndex(
                      ({ id }) => id === commentId
                    );
                    if (draft.items[index]?.replies?.[replyIndex]) {
                      draft.items[index].replies[replyIndex].isLiked = false;
                      draft.items[index].replies[replyIndex].likesCount -= 1;
                    }
                  } else {
                    const index = draft.items.findIndex(({ id }) => id === commentId);
                    if (index >= 0) {
                      draft.items[index].isLiked = false;
                      draft.items[index].likesCount -= 1;
                    }
                  }
                }
              })
            : activityFeedApi.util.updateQueryData('getPosts', { projectId }, (draft) => {
                if (draft?.items) {
                  const index = draft.items.findIndex(({ id }) => id === postId);
                  if (index >= 0) {
                    draft.items[index].isLiked = false;
                    draft.items[index].likesCount -= 1;
                  }
                }
              })
        );
        let patchPost: PatchCollection;
        if (!commentId) {
          patchPost = dispatch(
            activityFeedApi.util.updateQueryData('getPostById', { postId, projectId }, (draft) => {
              if (draft?.id === postId) {
                draft.isLiked = false;
                draft.likesCount -= 1;
              }
            })
          );
        }
        try {
          await queryFulfilled;
          if (commentId) {
            dispatch(
              likeUnlikeComment({
                activityFeedItemId,
                postId,
                projectId,
                commentId,
                rootCommentId,
                increase: false,
              })
            );
          }
        } catch {
          patchList?.undo();
          patchPost?.undo();
        }
      },
    }),

    getActivityFeedUnviewedItemsCount: build.query<
      IActivityFeedUnviewedItemsCount,
      IActivityFeedUnviewedItemsArgs
    >({
      query: (queryArg) => ({
        url: `${Endpoints.ACTIVITY_FEED_VIEWS}/unviewed-count`,
        params: queryArg,
      }),
    }),

    createItemsViews: build.mutation<ICreateItemsViewsResponse, ICreateItemsViewsBody>({
      query: (data) => ({
        url: Endpoints.ACTIVITY_FEED_VIEWS,
        body: data,
        method: 'POST',
      }),
    }),

    createOverdueItemsViews: build.mutation<ICreateItemsViewsResponse, ICreateItemsViewsBody>({
      query: (data) => ({
        url: `${Endpoints.ACTIVITY_FEED_VIEWS}/overdue-item`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: (_, err, { projectId }) =>
        !err ? [{ type: 'Projects', id: projectId }] : [],
    }),

    createNotificationViewsBulk: build.mutation<undefined, ICreateItemsViewsBulkBody>({
      query: ({ unviewedCountArgs, projectId }) => ({
        url: `${Endpoints.ACTIVITY_FEED_NOTIFICATIONS}view-bulk/${projectId}`,
        method: 'POST',
      }),
      onQueryStarted: async ({ projectId, unviewedCountArgs }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            projectsApi.util.updateQueryData(
              'getUnviewedCounts',
              { projectIds: unviewedCountArgs ?? [projectId] },
              (draft) => {
                if (draft?.items && draft?.items?.[projectId]?.notifications) {
                  draft.items[projectId].notifications = 0;
                }
              }
            )
          );
        } catch {}
      },
    }),

    createMessagesViewsBulk: build.mutation<undefined, ICreateItemsViewsBulkBody>({
      query: ({ unviewedCountArgs, ...data }) => ({
        url: `${Endpoints.ACTIVITY_FEED_VIEWS}/messages/bulk`,
        body: data,
        method: 'POST',
      }),
      onQueryStarted: async (
        { projectId, unviewedCountArgs, withoutUpdateUnviewedCount },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;
          if (!withoutUpdateUnviewedCount) {
            dispatch(
              projectsApi.util.updateQueryData(
                'getUnviewedCounts',
                { projectIds: unviewedCountArgs ?? [projectId] },
                (draft) => {
                  if (draft?.items && draft?.items?.[projectId]?.messages) {
                    draft.items[projectId].messages = 0;
                  }
                }
              )
            );
          }
        } catch {}
      },
    }),

    getProjectAudience: build.query<IActivityFeedAudience[], IGetProjectAudienceArgs>({
      query: (queryArgs) => ({
        url: `${Endpoints.ACTIVITY_FEED}audience/${queryArgs.projectId}`,
      }),
    }),

    createCustomAudience: build.mutation<null, ICreatePostAudienceBody>({
      query: (body) => ({
        url: `${Endpoints.ACTIVITY_FEED_AUDIENCE}`,
        method: 'POST',
        body,
      }),
    }),
    deleteCustomAudience: build.mutation<null, IDeleteCustomAudienceBody>({
      query: ({ projectId, groupId, groupIdToAssign, roleIdToAssign }) => ({
        url: `${Endpoints.ACTIVITY_FEED_AUDIENCE}/${projectId}/${groupId}`,
        method: 'DELETE',
        body: { groupIdToAssign, roleIdToAssign },
      }),
      invalidatesTags: ['Specific Users'],
    }),
    getPostAudience: build.query<ISpecificUsers, IGetPostAudienceArgs>({
      query: (queryArgs) => ({
        url: `${Endpoints.ACTIVITY_FEED_AUDIENCE}/${queryArgs.projectId}/${queryArgs.postId}`,
      }),
      providesTags: ['Post Audience'],
    }),

    getSpecificAudience: build.query<Omit<ISpecificUsers, 'customUsers'>, ISpecificUsersArgs>({
      query: (queryArgs) => ({
        url: `${Endpoints.ACTIVITY_FEED_AUDIENCE}/${queryArgs.projectId}`,
      }),
      providesTags: ['Specific Users'],
    }),

    getAppliableFilters: build.query<
      IActivityFeedAppliableFilters,
      IGetActivityFeedAppliableFiltersArgs
    >({
      query: ({ projectId }) => ({
        url: `${Endpoints.ACTIVITY_FEED}${projectId}/posts/filter`,
      }),
    }),

    getActivityFeedMedia: build.query<TActivityFeedMediaMapped, IGetActivityFeedMediaArgs>({
      query: ({ projectId, ...params }) => ({
        url: `${Endpoints.ACTIVITY_FEED_MEDIA}${projectId}`,
        params,
      }),
      keepUnusedDataFor: 0,
      transformResponse: activityFeedMediaMapping,
      merge: mergeActivityFeedMedia,
      serializeQueryArgs: ({ queryArgs: { projectId } }) => ({ projectId }),
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
    }),
  }),
});

export const {
  useGetPostsQuery,
  useLazyGetPostsQuery,
  useGetPostByIdQuery,
  useLazyGetPostByIdQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
  useGetCommentsQuery,
  useGetSpecificAudienceQuery,
  useLazyGetCommentsQuery,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetActivityFeedItemLikesQuery,
  useLazyGetActivityFeedItemLikesQuery,
  useLikeActivityFeedItemMutation,
  useUnlikeActivityFeedItemMutation,
  useGetActivityFeedUnviewedItemsCountQuery,
  useLazyGetActivityFeedUnviewedItemsCountQuery,
  useCreateItemsViewsMutation,
  useCreateCustomAudienceMutation,
  useCreateMessagesViewsBulkMutation,
  useCreateNotificationViewsBulkMutation,
  useCreateOverdueItemsViewsMutation,
  useGetPostAudienceQuery,
  useGetProjectAudienceQuery,
  useLazyGetPostAudienceQuery,
  useLazyGetProjectAudienceQuery,
  useGetRecentActivityQuery,
  useLazyGetRecentActivityQuery,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useLazyGetSpecificAudienceQuery,
  useDeleteCustomAudienceMutation,
  useGetUnviewedNotificationsQuery,
  useLazyGetUnviewedNotificationsQuery,
  useGetAppliableFiltersQuery,
  useLazyGetAppliableFiltersQuery,
  useGetActivityFeedMediaQuery,
  useLazyGetActivityFeedMediaQuery,
} = activityFeedApi;
