import React, { FC } from 'react';
// mui components
import { Grid, Typography, Stepper, Step, StepLabel, StepIconProps } from '@mui/material';

type StepButtonsProps = {
  isProjectStepper?: boolean;
  StyledConnector: any;
  steps?: [];
  customStepIcon: (props: StepIconProps) => JSX.Element;
  activeStep: number;
};

const StepButtons: FC<StepButtonsProps> = ({
  isProjectStepper,
  StyledConnector,
  steps,
  activeStep,
  customStepIcon,
}) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    className={isProjectStepper ? '' : 'border-top'}
    sx={{
      width: '100%',
      maxWidth: isProjectStepper ? 'unset' : '560px',
      position: isProjectStepper ? '' : 'fixed',
      bottom: isProjectStepper ? '' : '0',
      padding: isProjectStepper ? '40px 10px 20px 10px' : '0',
      borderBottom: isProjectStepper ? '1px solid #F3F4F9' : 'none',
      height: isProjectStepper ? '' : '96px',
      '@media (max-width: 768px)': {
        maxWidth: '100%',
        position: 'static',
        bottom: 'auto',
        padding: '15px 0 0 0',
        height: 'auto',
      },
    }}
  >
    <Stepper
      activeStep={activeStep}
      orientation="horizontal"
      connector={<StyledConnector />}
      sx={{
        maxWidth: '600px',
        marginInline: 'auto',
      }}
    >
      {steps?.map(({ label, description }) => (
        <Step key={label}>
          <StepLabel StepIconComponent={customStepIcon} sx={{ padding: 0 }}>
            <Typography sx={{ fontSize: '16px', lineHeight: '21px', color: '#23252F' }}>
              {label}
            </Typography>

            <Typography sx={{ fontSize: '9px', lineHeight: '24px', color: '#C6C6E1' }}>
              {description}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Grid>
);

export default StepButtons;
