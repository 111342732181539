import { useState } from 'react';
// components
import Spinner from 'components/spinner';
// mui components
import { ClickAwayListener, Grow, Paper, Popper, Button, Typography, Grid } from '@mui/material';
import QRCode from 'react-qr-code';
// api
import {
  useGetProfilePreferenceQuery,
  useUpdateProfilePreferenceMutation,
  DonwloadMobileAppEnum,
} from 'redux/api/profile-preference';
// hooks
import useToast from 'hooks/useToast';
// icons
import { ReactComponent as DownloadAppButton } from 'assets/icons/download-app-button.svg';
import { ReactComponent as Cross } from 'assets/icons/cross-thin.svg';
import { ReactComponent as IOS } from 'assets/icons/IOS.svg';
import { ReactComponent as Android } from 'assets/icons/android.svg';
import { appPromtStyles } from './styles';

const AppPrompt = ({ anchorRef, isProgressTrackOpen }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const showToast = useToast();

  const { data: preference } = useGetProfilePreferenceQuery();
  const [updateProfilePreference, { isLoading }] = useUpdateProfilePreferenceMutation();

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
    setValue('');
  };

  const takeAction = async (action) => {
    setValue('');
    updateProfilePreference({ isProgressTrackOpen, downloadMobileApp: action })
      .unwrap()
      .then(() => {
        setOpen(false);
        showToast({
          message: 'Updated successfully',
          type: 'success',
        });
      })
      .catch(() => {
        showToast({
          message: 'Something went wrong.  Please try again or contact support for help.',
          type: 'error',
        });
      });
  };

  if (preference?.downloadMobileApp !== DonwloadMobileAppEnum.OFFER_DOWNLOAD) {
    return null;
  }

  return (
    <>
      <Button
        ref={anchorRef}
        startIcon={open ? <Cross /> : <DownloadAppButton />}
        id="composition-button-app-button"
        aria-controls={open ? 'composition-menu-app-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          position: 'fixed',
          left: '25px',
          bottom: '90px',
          margin: '16px 2px',
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          borderRadius: '50%',
          zIndex: '9999',
          padding: '0 !important',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          backgroundColor: open ? '#4D6EF5' : '',
          background: open ? '#4D6EF5' : '',
          maxWidth: '56px',
          height: '56px',
          width: '56px',
          minWidth: '56px',
          border: '1px solid #e8edff',
          '&:hover': {
            backgroundColor: open ? '#4D6EF5' : '',
            background: open ? '#4D6EF5' : '',
          },
        }}
      ></Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-end"
        anchorEl={anchorRef?.current}
        sx={{
          zIndex: 10,
          inset: open ? 'auto auto 10px 0px !important' : 'auto auto 40px 0px !important',
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}
          >
            <Paper
              sx={{
                width: '285px',
                '& .MuiList-root': {
                  padding: 0,
                },
                borderRadius: '12px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Grid sx={appPromtStyles.container}>
                  {!value ? (
                    <>
                      <Typography sx={appPromtStyles.text}>
                        Which mobile device are you using?
                      </Typography>
                      <Grid
                        component="div"
                        sx={appPromtStyles.iconContainer}
                        mr="12px"
                        onClick={() => setValue(process.env.REACT_APP_IOS_URL)}
                      >
                        <Grid sx={appPromtStyles.iconBox}>
                          <IOS />
                        </Grid>
                        <Typography sx={appPromtStyles.textStyle}>IOS</Typography>
                      </Grid>
                      <Grid
                        component="div"
                        sx={appPromtStyles.iconContainer}
                        ml="12px"
                        onClick={() => setValue(process.env.REACT_APP_ANDROID_URL)}
                      >
                        <Grid sx={appPromtStyles.iconBox}>
                          <Android />
                        </Grid>
                        <Typography sx={appPromtStyles.textStyle}>Android</Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Typography sx={appPromtStyles.text}>Scan QR code to download</Typography>
                      <div
                        style={{
                          height: 'auto',
                          margin: '0 auto',
                          maxWidth: 120,
                          width: '100%',
                          padding: '10px 10px 7px 10px',
                          background: '#ffffff',
                          borderRadius: '12px',
                          marginTop: '12px',
                          border: '1px solid #E7ECFF',
                        }}
                      >
                        <QRCode
                          size={256}
                          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                          value={value}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                      <Grid container sx={appPromtStyles.footer}>
                        <Typography
                          sx={appPromtStyles.smallText}
                          onClick={() => takeAction(DonwloadMobileAppEnum.REMIND_LATER)}
                        >
                          Remind me later
                        </Typography>
                        <Typography
                          sx={appPromtStyles.smallText}
                          onClick={() => takeAction(DonwloadMobileAppEnum.DISMISS)}
                        >
                          Dismiss
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {isLoading && (
        <Spinner
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, .6)',
          }}
        />
      )}
    </>
  );
};

export default AppPrompt;
