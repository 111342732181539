import React, { FC, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui components
import { Box, Grid } from '@mui/material';
// components
import ActionButton from 'components/buttons/index';
import Role from '../../../components/role';
// utils
import useToast from 'hooks/useToast';
// types
import { IFolder } from 'redux/api/file-cabinet';
import { useUpdateCompanyRoleFolderPermissionsMutation } from 'redux/api/company-roles';
// styles
import { styles } from './styles';

type Props = {
  folder?: IFolder;
  handleClose: () => void;
};

const ManagePermissionsRolesContent: FC<Props> = ({ folder, handleClose }) => {
  const showToast = useToast();

  const [updateFolderPermissions, { isLoading }] = useUpdateCompanyRoleFolderPermissionsMutation();

  const rolesForm = useForm({
    defaultValues: {
      roles: folder?.roles ? folder?.roles : [],
    },
    mode: 'onChange',
  });

  const fields = rolesForm.watch('roles');

  const onRolesSubmit = useCallback(async (formValues) => {
    updateFolderPermissions({
      itemId: folder?.id,
      roles: formValues?.roles,
      withOptimisticUpdate: false,
    })
      .unwrap()
      .then(() => {
        showToast({
          message: 'Updated successfully!',
          type: 'success',
        });
      })
      .catch(() => {
        showToast({
          message: 'Something went wrong!',
          type: 'error',
        });
      });
  }, []);

  return (
    <Box>
      <FormProvider {...rolesForm}>
        <form onSubmit={rolesForm.handleSubmit(onRolesSubmit)}>
          <Box sx={styles.rolesContentContainer}>
            <Grid sx={styles.rolesTableHead}>
              <Grid sx={styles.roleTableTitle}>Role</Grid>
              <Grid sx={styles.roleTableItem}>View</Grid>
              <Grid sx={styles.roleTableItem}>Add/Edit</Grid>
              <Grid sx={styles.roleTableItem}>Delete</Grid>
            </Grid>

            {fields?.map((role, index) => (
              <Role
                key={`${role.permissions.canRead}+${role.permissions.canAddUpdate}+${role.permissions.canDelete}+${index}`}
                role={role}
                index={index}
              />
            ))}
          </Box>

          <Grid sx={styles.actions}>
            <ActionButton variant="secondary" onClick={handleClose} label="Cancel" />
            <ActionButton loading={isLoading} type="submit" label="Save" />
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ManagePermissionsRolesContent;
