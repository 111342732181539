import { typoM } from '../styles';

export const styles = {
  mainButton: {
    ...typoM,
    color: '#fff',
    background: '#4D6EF5',
    padding: '8px 12px',
    maxHeight: '36px',
    minWidth: '88px',
    borderRadius: '8px',
    boxShadow: 'none !important',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#4D6EF5',
      opacity: '0.8',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      marginTop: '8px',
      padding: '4px 2px',
      borderRadius: '12px !important',
    },
    '& .MuiButtonBase-root': {
      ...typoM,
      color: '#23252f',
      textTransform: 'capitalize',
      display: 'block',
      padding: '14px 16px',
      minWidth: '174px',
      textAlign: 'left',
    },
  },
  popperBtnOutlined: {
    ...typoM,
    textTransform: 'capitalize',
    background: '#fff',
    color: '#23252F',
    padding: '5px 14px',
    maxHeight: '36px',
    minWidth: '105px',
    border: '1px solid #edeff7',
    borderRadius: '8px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    '&:hover, &:active': {
      backgroundColor: '#f3f4f9',
      boxShadow: 'none',
    },
  },
  customPopper: {
    marginTop: '4px !important',
    boxShadow: 'none',
  },

  actionBtn: {
    width: '100%',
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    padding: '14px 16px',
    marginBottom: '2px',
    ...typoM,
    textTransform: 'capitalize',
    textDecoration: 'none',

    '& .MuiButton-startIcon': {
      margin: 0,
    },

    '&:hover': {
      backgroundColor: '#F2F5FF',
    },

    '&.Mui-disabled': {
      '& svg': {
        opacity: '0.4',
      },
    },

    '&.small': {
      padding: '5px 8px',
      width: '100%',

      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },

  iconBtn: {
    padding: 0,
    '&.with-border': {
      border: '1px solid #EDEFF7',
      borderRadius: '8px',
      width: '36px',
      height: '36px',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
};
