// mui components
import { Grid, Typography } from '@mui/material';
// components
import GoogleMap from 'components/google-map';
// controllers
import PlacesAutocompleteInputController from 'controllers/places-autocomplete-input-controller';
import OutlinedInputController from 'controllers/outlined-input-controller';
import SelectController from 'controllers/select-controller';
// constants
import { countriesSelectValues } from 'utils/constants';
//styles
import { createProjectFormStyles } from '../styles/styles';

const CreateProjectFormNewUI = ({
  isFirstProject,
  coordinates,
  updateCoordinates,
  country,
  states,
  handleParseAddress,
  updateAddress,
  handleCountryChange,
}) => {
  return (
    <Grid container flexDirection="column" flex={1}>
      {isFirstProject && (
        <>
          <Typography sx={createProjectFormStyles.isFirstProjectTitle}>
            Create your first project
          </Typography>
          <Typography sx={createProjectFormStyles.isFirstProjectSubtitle}>
            Adding a project will allow you to start managing your schedule and activating the
            activity log.
          </Typography>
        </>
      )}
      <Grid container sx={{ marginTop: isFirstProject ? '20px' : 0 }}>
        <Grid sx={createProjectFormStyles.formInputsContainer}>
          <Grid sx={createProjectFormStyles.inputsContainer}>
            <Grid item sx={createProjectFormStyles.inputContainer}>
              <OutlinedInputController
                fullWidth
                withError
                name="name"
                label="Project Name"
                placeholder="E.g. Smith"
                sx={{ marginBottom: '0' }}
              />
            </Grid>

            <Grid item sx={createProjectFormStyles.inputContainer}>
              <SelectController
                isSelect
                withError
                name="country"
                label="Country"
                placeholder="Select Country"
                options={countriesSelectValues}
                defaultValue={country}
                key={country}
                onChange={handleCountryChange}
              />
            </Grid>
          </Grid>

          <Grid sx={createProjectFormStyles.inputsContainer}>
            <PlacesAutocompleteInputController
              disabled={!country}
              withError
              name="address"
              country={country}
              label="Address 1"
              placeholder="E.g. 101 Front Street"
              additionalOnChange={handleParseAddress}
            />

            <OutlinedInputController
              optional={'true'}
              disabled={!country}
              fullWidth
              name="secondAddress"
              label="Address 2"
              placeholder="E.g. Unit 10"
            />
          </Grid>

          <Grid sx={createProjectFormStyles.inputsContainer}>
            <Grid item sx={createProjectFormStyles.inputContainer}>
              <OutlinedInputController
                disabled={!country}
                withError
                fullWidth
                name="city"
                label="City"
                placeholder="E.g. Raleigh"
              />
            </Grid>

            <Grid sx={createProjectFormStyles.inputContainer}>
              <Grid item xs={8}>
                <SelectController
                  isSelect
                  withError
                  fullWidth
                  name="state"
                  label="State"
                  options={states}
                  placeholder="Select State"
                  disabled={states.length === 0 || !country}
                />
              </Grid>

              <Grid item xs={4}>
                <OutlinedInputController
                  disabled={!country}
                  withError
                  fullWidth
                  name="zip"
                  label="Zip Code"
                  placeholder="E.g. 27616"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={createProjectFormStyles.googleMapContainer}>
          <GoogleMap
            height="150px"
            coordinates={coordinates}
            updateCoordinates={updateCoordinates}
            updateAddress={updateAddress}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateProjectFormNewUI;
