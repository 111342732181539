export const fontStyles = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
};

export const typoSM = {
  ...fontStyles,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '146%',
};

export const typoM = {
  ...fontStyles,
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '136%',
  color: '#23252F',
};

const actionBtn = {
  ...typoSM,
  textTransform: 'capitalize',
  border: '1px solid #4D6EF5',
  borderRadius: '8px',
  height: 'unset',
  padding: '12px 16px',
  '&.Mui-disabled': {
    opacity: '0.7',
  },
};

export const styles = {
  actions: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px',
    '& .btn': {
      ...actionBtn,
      color: '#4D6EF5',
      backgroundColor: '#fff',
      '&:hover': {
        opacity: '0.8',
      },
    },
    '& .filled-btn': {
      color: '#fff',
      backgroundColor: '#4D6EF5',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4D6EF5',
        opacity: '0.8',
      },
    },
  },

  rolesContentContainer: {
    width: '100%',
    border: '1px solid #F3F4F9',
    borderRadius: '9.5px',

    '& .role-row:nth-last-of-type(1)': {
      borderBottom: 'none',
    },
  },

  rolesTableHead: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '2.5fr 1fr 1fr 1fr 0.1fr',
    gap: '8px',
    borderBottom: '1px solid #F3F4F9',
    padding: '20px 18px 20px 28px',
  },

  roleTableItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
  },

  roleTableEmptyItem: {
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
  },

  roleTableTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
  },
};
