const typo = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '146%',
  color: '#23252F',
};

export const styles = {
  inputContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    marginBottom: '20px',
    gap: '16px',
  },

  input: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    border: '1px solid #EDEFF7',
    borderRadius: '12px',
  },

  accessContainer: {
    width: '150px',
  },

  accessButton: {
    width: '130px',
  },

  usersContainer: {
    height: '100%',
    maxHeight: '308px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    border: '1px solid #F3F4F9',
    borderRadius: '9.5px',
    position: 'relative',

    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EDEFF7',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },

    '& .role-row:nth-last-of-type(1)': {
      borderBottom: 'none',
    },
  },

  usersForm: { gap: '0' },

  user: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '20px 0',
    borderBottom: '1px solid #F3F4F9',
  },

  avatar: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    background: '#EDEFF7',
    ...typo,
    color: '#8C8F9D',
    fontWeight: 500,
    marginRight: '8px',
  },
  name: {
    ...typo,
  },
  role: {
    ...typo,
    padding: '2px 4px',
    background: '#E7ECFF',
    borderRadius: '8px',
  },
  makePublicWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '40px 0 20px 0',
    gap: '6px',
  },

  makePublicTitle: { ...typo },

  makePublicSubTitle: {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '146%',
    color: '#6E707A',
  },

  switch: {
    margin: 0,
    gap: '8px',
    '& .MuiButtonBase-root': {
      padding: 0,
      borderRadius: '40px',
      '&.MuiSwitch-switchBase': {
        margin: '2px',
        padding: '0',
      },
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(14px)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#4D6EF5',
          opacity: 1,
          border: 0,
        },
      },
    },
    '&.MuiSwitch-root': {
      width: '38px',
      height: '24px',
      padding: 0,
      borderRadius: '40px',
    },
    '& .MuiSwitch-track': {
      background: '#eaeae9',
      opacity: 1,
    },
    '& .MuiSwitch-thumb': {
      width: '20px',
      height: '20px',
      boxShadow: 'none',
    },
  },
  copyBtnWrapper: {
    width: '100%',
    border: '1px solid #EDEFF7',
    borderRadius: '12px',
    padding: '13px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyUrl: {
    ...typo,
    color: '#8C8F9D',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100% - 86px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  copyBtn: {
    ...typo,
    color: '#4D6EF5',
    textTransform: 'unset',
    padding: 0,

    '& .MuiButton-startIcon': {
      margin: 0,

      '& svg': {
        width: '20px',
        height: '20px',
        marginRight: '8px',
        '& path': {
          stroke: '#4D6EF5',
        },
      },
    },

    '&.Mui-disabled': {
      svg: {
        opacity: '0.5',
      },
    },

    '&:hover': {
      background: 'transparent',
    },
  },

  actions: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px',
    '& .btn': {
      color: '#4D6EF5',
      backgroundColor: '#fff',
      '&:hover': {
        opacity: '0.8',
      },
    },
    '& .filled-btn': {
      color: '#fff',
      backgroundColor: '#4D6EF5',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4D6EF5',
        opacity: '0.8',
      },
    },
  },

  rolesTableHead: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr 1fr 0.8fr',
    gap: '8px',
    borderBottom: '1px solid #F3F4F9',
    padding: '0 0 0 26px',
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: '2',
  },

  roleTableItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
  },

  roleTableEmptyItem: {
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid #F3F4F9',
  },

  roleTableTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
    padding: '20px 0',
  },
};
