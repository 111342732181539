import { api } from 'redux/api';
import {
  ICompany,
  ICompanyAll,
  ICompanyBuildingStatistic,
  ICompanyUser,
  ICreateCompanyBody,
  ICreateCompanyResponse,
  IGetCompanyUsersArgs,
} from './';
// slices
import { handleSetCompanyId, handleDeleteCompanyId } from 'redux/slices/auth-slice';

enum Endpoints {
  COMPANY = 'company/',
  COMPANY_USERS = 'company/users/',
}

export const companyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompany: build.query<ICompany, void>({
      query: () => ({
        url: Endpoints.COMPANY,
      }),
      providesTags: ['Company'],
    }),
    getCompanyAll: build.query<ICompanyAll[], void>({
      query: () => ({
        url: `${Endpoints.COMPANY}all`,
      }),
      providesTags: ['Company'],
    }),
    getCompanyBuildingStatistic: build.query<ICompanyBuildingStatistic[], void>({
      query: () => ({
        url: `${Endpoints.COMPANY}building-statistic`,
      }),
    }),
    createCompany: build.mutation<ICreateCompanyResponse, ICreateCompanyBody>({
      query: ({ data }) => ({
        url: Endpoints.COMPANY,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Company', 'User'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          localStorage.setItem('company-id', response.id.toString());
          dispatch(handleSetCompanyId(response.id.toString()));
        } catch {
          dispatch(handleDeleteCompanyId());
        }
      },
    }),
    updateCompany: build.mutation<ICompany, any>({
      query: (data) => ({
        url: Endpoints.COMPANY,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Company'],
    }),
    getCompanyUsers: build.query<ICompanyUser[], IGetCompanyUsersArgs>({
      query: ({ roleId }) => ({
        url: `${Endpoints.COMPANY_USERS}${roleId}`,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetCompanyAllQuery,
  useLazyGetCompanyAllQuery,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useGetCompanyBuildingStatisticQuery,
  useLazyGetCompanyBuildingStatisticQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useGetCompanyUsersQuery,
  useLazyGetCompanyUsersQuery,
} = companyApi;
