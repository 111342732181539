import React, { useCallback } from 'react';
// mui components
import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
/// api
import { FileCabinetUser } from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type Props = {
  users: FileCabinetUser[];
  maxCount?: number;
  className?: string;
};

const CustomAvatarGroup: React.FC<Props> = ({ users, maxCount = 3, className = '' }) => {
  const getInitials = (name: string) => {
    const inits = name?.split(' ');
    return `${inits?.[0]?.[0] || ''}${inits?.[1]?.[0] || ''}`;
  };

  const usersWithAccessCount = useCallback(() => {
    const rolesWithAccess = users?.filter(({ permissions }) => {
      if (
        permissions?.canAddUpdate === false &&
        permissions?.canDelete === false &&
        permissions?.canRead === false
      ) {
        return false;
      }

      return true;
    });

    return rolesWithAccess;
  }, [users]);

  const usersWithAccess = usersWithAccessCount();

  return (
    <AvatarGroup max={maxCount} sx={styles.avatarGroup} className={className}>
      {usersWithAccess?.map((user) => (
        <Tooltip
          key={user.id}
          title={`${user?.firstName} ${user?.lastName}`}
          placement="top"
          componentsProps={{ tooltip: { sx: styles.tooltip } }}
        >
          <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={user.avatar}>
            {getInitials(`${user?.firstName} ${user?.lastName}`)}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};
export default CustomAvatarGroup;
