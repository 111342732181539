import React, { useCallback, useContext } from 'react';
// mui components
import { TableRow, TableCell, Typography } from '@mui/material';
// components
import ActionsPopper from '../../../poppers/actions-popper';
import CustomRolesGroup from 'components/custom-roles-group';
import CustomAvatarGroup from 'components/custom-avatar-group';
import LinkContainer from '../../link-container';
import ActionsModals from '../../actions-modals';
// utils
import { ActionsContext } from '..';
import { getRowIcon } from 'utils/helpers/fileCabinetHelpers';
// api
import { FileCabinetTypeEnum, IFolder } from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type Props = {
  folder?: IFolder;
  fromModal: boolean;
  projectFolderId?: string | number;
  type: string;
  projectId?: string | number;
  publicFolders?: boolean;
};

const Row: React.FC<Props> = ({
  folder,
  fromModal,
  type,
  projectFolderId,
  projectId,
  publicFolders,
}) => {
  const { handleShowImage } = useContext(ActionsContext);
  const Icon = getRowIcon(type, folder?.mimeType);

  const handleOpenFile = useCallback(() => {
    if (type === FileCabinetTypeEnum.File) {
      handleShowImage(folder?.id);
    }
  }, [type, folder]);

  return (
    <>
      <TableRow sx={styles.tableRow}>
        <TableCell sx={styles.tableCell} className="first">
          <LinkContainer
            folder={folder}
            style={styles.nameWrapper}
            handleOpenFile={handleOpenFile}
            publicFolders={publicFolders}
            type={type}
          >
            <Icon />
            <Typography>{folder?.name}</Typography>
          </LinkContainer>
        </TableCell>

        {!publicFolders && (
          <TableCell sx={styles.tableCell}>
            {!!fromModal ? (
              <CustomAvatarGroup users={folder?.users || []} maxCount={5} />
            ) : (
              <CustomRolesGroup
                roles={!!folder?.roles?.length ? folder?.roles : []}
                folder={folder}
              />
            )}
          </TableCell>
        )}

        {!publicFolders && (
          <TableCell sx={styles.tableCell} className="actions-wrapper">
            <ActionsPopper
              folder={folder}
              type={type}
              fromModal={fromModal}
              projectId={projectId}
            />
          </TableCell>
        )}
      </TableRow>

      <ActionsModals
        type={type}
        folder={folder}
        fromModal={fromModal}
        projectFolderId={projectFolderId}
        projectId={projectId}
        publicFolders={publicFolders}
      />
    </>
  );
};

export default Row;
