import React, { memo, useCallback, useMemo } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
// mui components
import {
  Grid,
  Autocomplete,
  Box,
  CircularProgress,
  AutocompleteRenderInputParams,
} from '@mui/material';
// components
import OutlinedInput from './outlined-input';
// icons
import { ReactComponent as PinIcon } from 'assets/icons/buttons/pin.svg';
// styles
import { placesAutocompleteInput } from './styles';

interface OwnProps {
  name: string;
  label: string;
  error: boolean;
  currentCountry: string | null;
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  loading?: boolean;
  value?: string;
  onChange: (...event: any[]) => void;
  additionalOnChange?: (value: string) => void;
}

const PlacesAutocompleteInput: React.FC<OwnProps> = ({
  name,
  label,
  error,
  helperText,
  placeholder,
  currentCountry,
  onChange,
  additionalOnChange,
  required,
  ...props
}) => {
  const { suggestions, setValue, clearCache, clearSuggestions } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: currentCountry },
    },
    debounce: 300,
  });

  const options = useMemo(
    () =>
      suggestions.data.map(({ description }) => ({
        label: description,
        value: description,
      })),
    [suggestions]
  );

  const handleChange = useCallback(
    (inputValue: string) => {
      clearCache();
      setValue(inputValue);
    },
    [setValue, clearCache]
  );

  const currentOnChange = useCallback(
    (value: string) => {
      if (additionalOnChange) {
        const currentSuggestion = suggestions.data.find(({ description }) => description === value);
        if (currentSuggestion) {
          additionalOnChange(currentSuggestion.place_id);
          clearSuggestions();
        }
      } else {
        onChange(value);
      }
    },
    [suggestions, additionalOnChange, onChange, clearSuggestions]
  );

  const renderInput = ({
    InputProps,
    InputLabelProps,
    ...inputProps
  }: AutocompleteRenderInputParams) => (
    <Grid item ref={InputProps.ref}>
      <OutlinedInput
        {...inputProps}
        name={name}
        label={label}
        error={error}
        required={required}
        helperText={helperText}
        placeholder={placeholder}
        endAdornment={
          <>
            {props?.loading ? (
              <Box sx={placesAutocompleteInput.loadingContainer}>
                <CircularProgress size={20} />
              </Box>
            ) : null}
            {InputProps?.endAdornment}
          </>
        }
        onChange={(event) => {
          onChange(event);
          handleChange && handleChange(event.target.value);
        }}
      />
    </Grid>
  );

  return (
    <Grid container sx={placesAutocompleteInput.container}>
      <Autocomplete
        {...props}
        multiple={false}
        freeSolo
        fullWidth
        disablePortal
        options={options}
        onChange={(_e, data) => {
          if (typeof data !== 'string' && data?.value) {
            currentOnChange(data.value);
          }
        }}
        renderInput={renderInput}
        renderOption={(optionProps, option) => {
          return (
            <Box
              component="li"
              {...optionProps}
              onClick={() => {
                currentOnChange(option.value);
              }}
              sx={placesAutocompleteInput.optionContainer}
            >
              <Grid container alignItems="center" sx={placesAutocompleteInput.option}>
                <PinIcon />
                <Grid sx={placesAutocompleteInput.label}>{option.label || option}</Grid>
              </Grid>
            </Box>
          );
        }}
      />
    </Grid>
  );
};

export default memo(PlacesAutocompleteInput);
