import dayjs from 'dayjs';
import {
  IGetActivityFeedMediaArgs,
  TActivityFeedMediaMapped,
  TGetActivityFeedMediaResponse,
  TActivityFeedMediaWithIndex,
} from '../api/activity-feed';
import { BaseQueryFn, BaseQueryMeta } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const activityFeedMediaMapping = (
  response: TGetActivityFeedMediaResponse,
  _: BaseQueryMeta<BaseQueryFn>,
  { offset }: IGetActivityFeedMediaArgs
): TActivityFeedMediaMapped => {
  const groupedItems: Record<string, TActivityFeedMediaWithIndex[]> = {};

  response.items.forEach((item, itemIndex) => {
    const createdAt = dayjs(item.createdAt).format('ll');

    const mappedItem: TActivityFeedMediaWithIndex = {
      ...item,
      index: offset ? offset + itemIndex : itemIndex,
    };

    if (groupedItems[createdAt]) {
      groupedItems[createdAt].push(mappedItem);
    } else {
      groupedItems[createdAt] = [mappedItem];
    }
  });

  return {
    items: Object.entries(groupedItems),
    meta: response.meta,
  };
};
