import React, { useMemo, FC } from 'react';
// mui components
import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
// components
import { RoundProgress } from 'components/round-progress';
import { FileWrapper } from '../modal/file-viewer-modal/file-wrapper';
// icons
import { ReactComponent as DocLogoIcon } from 'assets/icons/files/small-doc-icon.svg';
import { ReactComponent as PdfLogoIcon } from 'assets/icons/files/small-pdf-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UploadErrorIcon } from 'assets/icons/files/upload-error-icon.svg';
// types
import { ICommentUploads, IPostUploads, IUploadingFiles } from 'redux/api/activity-feed';
// constants
import { DOCUMENT_FORMATS } from 'utils/constants';
// helpers
import { convertBytes } from 'utils/helpers';
// styles
import { uploadedFilesStyles } from './styles/styles';

interface Styles {
  [key: string]: string | Styles;
}

interface UploadedFilesProps {
  type: 'recent_activity' | 'activity_feed' | 'new_files';
  uploads?: IPostUploads[] | ICommentUploads[];
  uploadingFiles?: IUploadingFiles[];
  handleDeleteUploadedData?: (fileId: string) => void;
  containerStyles?: Styles;
  containerItemStyles?: Styles;
  fileNameStyles?: Styles;
}

const StyledLink = styled('a')({
  display: 'flex',
  width: '100%',
  textDecoration: 'none',
});

const UploadedFiles: FC<UploadedFilesProps> = ({
  uploads,
  uploadingFiles,
  containerItemStyles,
  containerStyles,
  fileNameStyles,
  type,
  handleDeleteUploadedData,
}) => {
  const documents = useMemo(
    () =>
      uploads
        ? uploads.filter(({ name }) =>
            DOCUMENT_FORMATS.includes(name.substring(name.lastIndexOf('.')))
          )
        : [],
    [uploads]
  );

  const uploadingDocuments = uploadingFiles
    ? uploadingFiles.filter(({ name }) =>
        DOCUMENT_FORMATS.includes(name.substring(name.lastIndexOf('.')))
      )
    : [];

  const hiddenFilesCount = useMemo(
    () => (documents.length > 1 ? documents.length - 1 : 0),
    [documents]
  );

  const renderIcon = (url: string) => {
    if (url === '.pdf') {
      return (
        <PdfLogoIcon
          height={type === 'recent_activity' ? '20px' : '24px'}
          width={type === 'recent_activity' ? '20px' : '24px'}
        />
      );
    }
    return (
      <DocLogoIcon
        height={type === 'recent_activity' ? '20px' : '24px'}
        width={type === 'recent_activity' ? '20px' : '24px'}
      />
    );
  };

  if (!documents.length && !uploadingDocuments.length) {
    return null;
  }

  if (type === 'recent_activity') {
    const file = documents[0];

    const fileName =
      file.name?.length < 30
        ? file.name
        : file?.name.slice(0, 25) + '..' + file?.name.slice(file?.name.lastIndexOf('.'));

    return (
      <FileWrapper mimeType={file.mimeType} url={file.url}>
        <Grid sx={containerStyles ?? uploadedFilesStyles.container}>
          <Grid sx={containerItemStyles ?? uploadedFilesStyles.itemContainer}>
            <Grid sx={{ display: 'flex' }}>
              {renderIcon(file.name.substring(file.name.lastIndexOf('.')))}
            </Grid>
            <Grid sx={{ display: 'flex' }}>
              <Typography sx={fileNameStyles ?? uploadedFilesStyles.fileName}>
                {fileName}
              </Typography>
            </Grid>
          </Grid>
          {hiddenFilesCount ? (
            <Grid sx={uploadedFilesStyles.hiddenFilesContainer}>
              <Typography sx={uploadedFilesStyles.hiddenFiles}>{`+${hiddenFilesCount}`}</Typography>
            </Grid>
          ) : null}
        </Grid>
      </FileWrapper>
    );
  }

  if (type === 'activity_feed') {
    return (
      <Grid sx={containerStyles ?? uploadedFilesStyles.postContainer}>
        {documents.map(({ name, url, id, mimeType }) => (
          <FileWrapper key={id} url={url} mimeType={mimeType}>
            <Grid sx={uploadedFilesStyles.postFileItem}>
              <Grid sx={uploadedFilesStyles.postFileInfoContainer}>
                <Grid sx={uploadedFilesStyles.postIconContainer}>
                  {renderIcon(name.substring(name.lastIndexOf('.')))}
                </Grid>
                <Grid sx={uploadedFilesStyles.postFileInfo}>
                  <Typography sx={uploadedFilesStyles.postFileName}>{name}</Typography>
                  <Typography sx={uploadedFilesStyles.postFileSize}></Typography>
                </Grid>
              </Grid>
              <Grid sx={uploadedFilesStyles.postSettingsContainer}></Grid>
            </Grid>
          </FileWrapper>
        ))}
      </Grid>
    );
  }

  if (type === 'new_files') {
    return (
      <Grid sx={containerStyles ?? uploadedFilesStyles.newFilesContainer}>
        {uploadingDocuments.map(({ name, url, tempId, id, status, percent, size }) => (
          <Grid
            key={tempId}
            sx={{
              ...uploadedFilesStyles.newFilesItem,
              border:
                status === 'error_file_size' || status === 'error_upload'
                  ? '1px solid #D12D36'
                  : '1px solid #EDEFF7',
            }}
          >
            <Grid sx={uploadedFilesStyles.newFileInfoContainer}>
              <Grid sx={uploadedFilesStyles.newFileIconContainer}>
                <StyledLink href={url} download>
                  {status === 'error_file_size' || status === 'error_upload' ? (
                    <UploadErrorIcon height={24} width={24} />
                  ) : (
                    renderIcon(name.substring(name.lastIndexOf('.')))
                  )}
                </StyledLink>
              </Grid>
              <Grid sx={uploadedFilesStyles.newFileInfo}>
                <Typography sx={uploadedFilesStyles.postFileName}>{name}</Typography>
                <Typography
                  sx={{
                    ...uploadedFilesStyles.postFileSize,
                    color: status === 'error_file_size' ? '#D12D36' : '#6E707A',
                  }}
                >
                  {status === 'error_file_size'
                    ? 'The file size is too large and cannot be uploaded.'
                    : size
                    ? convertBytes(size)
                    : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={uploadedFilesStyles.postSettingsContainer}>
              {status === 'done' || status === 'error_file_size' || status === 'error_upload' ? (
                <IconButton
                  sx={uploadedFilesStyles.deleteBtn}
                  onClick={() => {
                    handleDeleteUploadedData(id);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <RoundProgress value={percent} size={40} />
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  return null;
};

export default UploadedFiles;
