import { useEffect, useState } from 'react';

export enum DevicesEnum {
  DESKTOP = 'desktop',
  LAPTOP = 'laptop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export const useGetAdaptiveProps = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCheckProps = () => {
    if (windowDimensions?.width >= 1024) {
      return { device: DevicesEnum.DESKTOP, usersCount: 5 };
    }

    if (windowDimensions?.width < 1024 && windowDimensions?.width >= 767) {
      return { device: DevicesEnum.LAPTOP, usersCount: 3 };
    }

    if (windowDimensions?.width < 766 && windowDimensions?.width >= 480) {
      return { device: DevicesEnum.TABLET, usersCount: 2 };
    }

    if (windowDimensions?.width <= 479) {
      return { device: DevicesEnum.MOBILE, usersCount: 1 };
    }
  };

  const adaptiveScreenProps = handleCheckProps();

  return {
    width: windowDimensions?.width,
    height: windowDimensions?.height,
    innerWidth: window.innerWidth,
    device: adaptiveScreenProps?.device || DevicesEnum.DESKTOP,
    usersCount: adaptiveScreenProps?.usersCount || 5,
  };
};
