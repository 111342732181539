export const isErrorWithData = (
  error: unknown
): error is { status: number; data: { message: string } } =>
  typeof error === 'object' &&
  error !== null &&
  'status' in error &&
  typeof error?.status === 'number' &&
  'data' in error &&
  typeof error?.data === 'object' &&
  error?.data !== null &&
  'message' in error?.data &&
  typeof error?.data?.message === 'string';
