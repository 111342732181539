import React, { FC } from 'react';
// mui components
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  styled,
  DialogProps,
  Theme,
} from '@mui/material';
// types
import { SxProps } from '@mui/system';
import { stopPropagation } from '../../utils/helpers';
interface IStyledDialog extends DialogProps {
  width?: string;
  withoutPadding?: boolean;
}
const StyledDialog = styled(({ withoutPadding, ...props }: IStyledDialog) => <Dialog {...props} />)(
  ({ width, withoutPadding }) => ({
    '& .MuiDialogContent-root': {
      margin: withoutPadding ? 0 : '10px 0 0',
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      height: '48px',
      padding: 0,
      '& button': {
        margin: '0 12px',
      },
    },
    '& .MuiDialog-paper': {
      width: width || '480px',
      maxWidth: width || '480px',
      maxHeight: '86vh',
      padding: withoutPadding ? 0 : '30px 40px 15px 40px',
      borderRadius: '12px',
      overflow: 'visible',
      backgroundColor: '#fff',
    },
  })
);

const StyledButton = styled(Button)({
  height: '48px',
  width: '126px',
  textTransform: 'unset',
  boxShadow: 'none',
});
interface IProps {
  open: boolean;
  withCloseButton?: boolean;
  withoutPadding?: boolean;
  withActions?: boolean;
  maxWidth?: string;
  handleClose: () => void;
  contentSx?: SxProps<Theme>;
}
const DialogModal: FC<IProps> = ({
  open,
  withoutPadding,
  withActions,
  maxWidth,
  handleClose,
  children,
  contentSx,
}) => {
  return (
    <StyledDialog
      open={open}
      width={maxWidth}
      withoutPadding={withoutPadding}
      onClose={handleClose}
      onClick={stopPropagation}
    >
      <DialogContent sx={contentSx}>{children}</DialogContent>
      {withActions && (
        <DialogActions>
          <StyledButton variant="contained" onClick={handleClose}>
            close
          </StyledButton>
        </DialogActions>
      )}
    </StyledDialog>
  );
};
export default DialogModal;
