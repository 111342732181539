import React, { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// mui components
import { Grid, InputAdornment, IconButton, Divider, Typography } from '@mui/material';
// components
import OutlinedInput from 'components/inputs/outlined-input';
import AddNewPopper from '../../poppers/add-new-popper';
import CustomTooltip from 'components/custom-tooltip';
// types
import {
  FileCabinetLocation,
  FileCabinetUserPermission,
  TBreadCrumbs,
} from 'redux/api/file-cabinet';
// api
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { handleSetBreadCrumbs, handleSetSearchValue } from 'redux/slices/file-cabinet-slice';
import { fileCabinetSelector } from 'redux/selector';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
// helpers
import { checkUserAddAccess } from 'utils/helpers/fileCabinetHelpers';
// icons
import { ReactComponent as SearchIcon } from 'assets/icons/buttons/search.svg';
import { ReactComponent as ListIcon } from 'assets/icons/List_view_icon.svg';
import { ReactComponent as GridIcon } from 'assets/icons/Grid_view_icon.svg';
// styles
import { styles } from './styles';

type Props = {
  title?: string;
  defaultBreadCrumbs?: TBreadCrumbs;
  changeView: (value: boolean) => void;
  className?: string;
  isGrid: boolean;
  fromModal?: boolean;
  projectFolderId?: string | number;
  projectId: string | number;
  publicFolders: boolean;
  myPermissions: FileCabinetUserPermission;
};

const MainActions: FC<Props> = ({
  title = '',
  changeView,
  isGrid,
  defaultBreadCrumbs = [],
  className = '',
  fromModal,
  projectFolderId,
  projectId,
  publicFolders,
  myPermissions,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { searchValue, cabinetLocation } = useAppSelector(fileCabinetSelector);
  const { pathname } = useLocation();

  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const lastIndex = defaultBreadCrumbs?.length - 1;

  const addAccess = checkUserAddAccess(
    publicFolders,
    cabinetLocation,
    userPermissions?.CREATE_FOLDERS?.canRead,
    myPermissions
  );

  const handleChangePath = useCallback(
    async (id: string | number) => {
      dispatch(handleSetSearchValue(null));

      if (id) {
        const index = defaultBreadCrumbs?.findIndex((obj) => obj?.id === id);
        const changedBreadCrumbs = defaultBreadCrumbs?.slice(0, index + 1);

        dispatch(handleSetBreadCrumbs(changedBreadCrumbs));
        localStorage.setItem('fileCabinetBreadCrumbs', JSON.stringify(changedBreadCrumbs));
      }

      if (
        pathname.includes('selections') ||
        pathname.includes('account-dashboard') ||
        pathname.includes('project-dashboard')
      ) {
        navigate(`${pathname}#${id}`);
      } else if (publicFolders) {
        navigate(`/public-file-cabinet/${id}`);
      } else {
        navigate(`/file-cabinet/${id}`);
      }
    },
    [defaultBreadCrumbs, publicFolders, pathname]
  );

  const handleClearBreadCrumbs = useCallback(async () => {
    dispatch(handleSetBreadCrumbs([]));
    dispatch(handleSetSearchValue(null));

    if (
      pathname.includes('selections') ||
      pathname.includes('account-dashboard') ||
      pathname.includes('project-dashboard')
    ) {
      navigate(`${pathname}`);
    } else if (publicFolders) {
      const accessKey = sessionStorage.getItem('accessKey');

      if (!!accessKey) {
        navigate(`/public-file-cabinet?accessKey=${sessionStorage.getItem('accessKey')}`);
      }

      if (!accessKey) {
        navigate(`/account-dashboard`);
      }
    } else {
      navigate(`/file-cabinet`);
    }

    dispatch(handleSetBreadCrumbs([]));
    localStorage.removeItem('fileCabinetBreadCrumbs');
  }, [pathname, publicFolders]);

  const handleSearchChange = useCallback((event) => {
    if (!!event?.target?.value) {
      dispatch(handleSetSearchValue(event?.target?.value));
    } else {
      dispatch(handleSetSearchValue(null));
    }
  }, []);

  return (
    <Grid sx={styles.container} className={className}>
      {!!defaultBreadCrumbs?.length ? (
        <Grid sx={styles.breadCrumbs}>
          <CustomTooltip title="File Cabinet">
            <Typography onClick={handleClearBreadCrumbs} sx={styles.breadCrumb} className="first">
              {pathname.includes('selections') ||
              pathname.includes('account-dashboard') ||
              pathname.includes('project-dashboard')
                ? 'Project Storage'
                : 'File Cabinet'}
            </Typography>
          </CustomTooltip>

          {defaultBreadCrumbs?.slice(0, lastIndex)?.map((breadCrumb) => (
            <CustomTooltip title={breadCrumb?.name} key={breadCrumb?.id}>
              <Grid
                sx={{
                  ...styles.breadCrumb,
                  maxWidth: `calc(100% / ${defaultBreadCrumbs?.length + 1}`,
                }}
                onClick={() => {
                  handleChangePath(breadCrumb?.id);
                }}
              >
                / {breadCrumb?.name}
              </Grid>
            </CustomTooltip>
          ))}

          <CustomTooltip title={defaultBreadCrumbs?.[lastIndex]?.name}>
            <Typography sx={styles.breadCrumb} className="last">
              / {defaultBreadCrumbs?.[lastIndex]?.name}
            </Typography>
          </CustomTooltip>
        </Grid>
      ) : (
        <Typography sx={styles.title}>{title}</Typography>
      )}

      <Grid sx={styles.actionsContainer}>
        <Grid sx={styles.searchInputContainer}>
          <OutlinedInput
            fullWidth
            withoutHelperText
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchValue}
            startAdornment={
              <InputAdornment position="start" sx={styles.inputAdornment}>
                <SearchIcon />
              </InputAdornment>
            }
            sx={styles.searchInput}
          />
        </Grid>

        <Grid sx={styles.btnsContainer}>
          <Grid sx={styles.viewBtns}>
            <IconButton
              sx={styles.iconBtn}
              className={`left-rounded ${isGrid ? 'active' : ''}`}
              disableRipple
              onClick={() => changeView(true)}
            >
              <GridIcon />
            </IconButton>
            <Divider sx={styles.divider} />
            <IconButton
              sx={styles.iconBtn}
              className={!isGrid ? 'active' : ''}
              disableRipple
              onClick={() => changeView(false)}
            >
              <ListIcon />
            </IconButton>
          </Grid>

          {!publicFolders && userPermissions?.CREATE_FOLDERS?.canRead && (
            <Divider sx={styles.divider} />
          )}

          {addAccess && (
            <AddNewPopper
              fromModal={fromModal}
              projectFolderId={projectFolderId}
              projectId={projectId}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainActions;
