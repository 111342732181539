import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
// mui components
import { FormHelperText, SelectChangeEvent } from '@mui/material';
// components
import Select, { IProps as ISelectProps } from 'components/select';
import JobTitleModal from './job-title-modal';
// constants
import { JOB_TITLES } from 'utils/constants';

interface IJobTitleSelectProps extends Omit<ISelectProps, 'options'> {}

const JobTitleSelect: FC<IJobTitleSelectProps> = ({ name, withError, ...props }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const form = useFormContext();

  const [jobTitle] = form.watch([`${name}`]);
  const errorState = form.getFieldState(`${name}`).error;

  const [selectOptions, setSelectOptions] =
    useState<{ label: string; value: string }[]>(JOB_TITLES);

  const handleCustomTitleSubmit = (specificTitle: string) => {
    const isExist = selectOptions.some(({ value }) => value === specificTitle);
    if (!isExist) {
      setSelectOptions((prev) => [
        ...prev.slice(0, prev.length - 1),
        { label: specificTitle, value: specificTitle },
        ...prev.slice(prev.length - 1),
      ]);
    }
    form.setValue(name, specificTitle);
    setOpenModal(false);
  };

  const onChange = useCallback(
    ({ target: { value } }: SelectChangeEvent<unknown>) => {
      if (value === 'Other') {
        setOpenModal(true);
        form.setValue(name, '');
        form.trigger(name);
        return;
      }
      form.setValue(name, value);
      form.trigger(name);
    },
    [form, name]
  );

  useEffect(() => {
    if (jobTitle && !selectOptions.some(({ value }) => value === jobTitle)) {
      setSelectOptions((prev) => [
        ...prev.slice(0, prev.length - 1),
        { label: jobTitle, value: jobTitle },
        ...prev.slice(prev.length - 1),
      ]);
    }
  }, [jobTitle, selectOptions]);

  return (
    <>
      <Select
        {...props}
        name={name}
        options={selectOptions}
        value={jobTitle ? jobTitle : ''}
        onChange={onChange}
      />
      {withError && !!errorState?.message && (
        <FormHelperText sx={{ margin: '3px 0', color: 'red' }}>
          {errorState?.message || ' '}
        </FormHelperText>
      )}
      {openModal ? (
        <JobTitleModal
          open={openModal}
          closeModal={closeModal}
          onSubmit={handleCustomTitleSubmit}
        />
      ) : null}
    </>
  );
};

export default memo(JobTitleSelect);
