export const styles = {
  link: {
    width: '100%',
    display: 'block',
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#424550',
    textTransform: 'capitalize',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: '14px',

    '&.active': {
      color: '#4D6EF5',
    },
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: '8px',
  },

  container: {
    borderRight: '1px solid #F3F4F9',
    paddingRight: '12px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  button: {
    width: '115px',
    maxWidth: '115px',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: 'black',
    border: 'unset',
    padding: '6px 8px',
    minWidth: 'auto',

    '&:hover': {
      border: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '8px',
      marginLeft: '0px',

      '& span': {
        padding: '5px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        width: '20px',
        height: '20px',
      },

      '& svg, img': {
        width: '20px',
        height: '20px',
        borderRadius: '8px',
      },
    },
  },

  popper: {
    width: '185px',
    zIndex: 10,
  },

  paper: {
    width: '171px',
    marginTop: '8px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },

  paperContentContainer: {
    widht: '100%',
    padding: '2px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '8px',
    transition: '300ms',
    cursor: 'pointer',
    gap: '12px',

    '&:hover': {
      background: '#F2F5FF',
    },

    '& svg': {
      width: '20px',
      height: '20px',
    },
  },

  itemContainerDisabled: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px',
    borderRadius: '8px',
    transition: '300ms',
    cursor: 'pointer',
    pointerEvents: 'none',
    background: '#EDEFF7',
    opacity: '0.4',
  },

  popperItemIcon: {
    marginRight: '0',
    marginLeft: '0',

    '& span': {
      padding: '5px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      width: '36px',
      height: '36px',
    },

    '& svg, img': {
      width: '20px',
      height: '20px',
    },
  },

  popperItemTitle: {
    widht: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },
};
