export const styles = {
  tableRow: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '2.5fr 1fr 1fr 1fr',
    gap: '8px',
    borderBottom: '1px solid #F3F4F9',
    padding: '11px 12px',
  },

  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tableRoleName: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#8C8F9D',
  },
};
