import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectionItem, SelectionStatusEnum } from 'redux/api/selections';

export type TSelectedSelections = Record<string, ISelectionItem[]>;

interface IInitialState {
  selectedSelections: TSelectedSelections;
}

interface ISelectSelectionPayload {
  costCodeId: string;
  selection: ISelectionItem;
}

interface IUnselectSelectSelectionPayload {
  costCodeId: string;
  selectionId: number;
}

interface ISelectAllSelectionsPayload {
  costCodeId: string;
  selections: ISelectionItem[];
}

interface IUpdateSelectedSelectionPayload {
  costCodeId: string;
  selection: ISelectionItem;
}

interface IUnselectCostCode {
  costCodeId: string;
}

const initialState: IInitialState = {
  selectedSelections: {},
};

const isSelectionUnapproved = (selection: ISelectionItem): boolean =>
  selection.status !== SelectionStatusEnum.APPROVED;

const getUnapprovedSelections = (selections: ISelectionItem[]): ISelectionItem[] =>
  selections.filter(({ status }) => status !== SelectionStatusEnum.APPROVED);

const selectedSelectionsSlice = createSlice({
  name: 'selectedSelections',
  initialState,
  reducers: {
    selectSelection: (state, action: PayloadAction<ISelectSelectionPayload>) => {
      const { costCodeId, selection } = action.payload;

      if (isSelectionUnapproved(selection)) {
        if (Array.isArray(state.selectedSelections?.[costCodeId])) {
          state.selectedSelections[costCodeId].push(selection);
        } else {
          state.selectedSelections[costCodeId] = [selection];
        }
      }
    },

    unselectSelection: (state, action: PayloadAction<IUnselectSelectSelectionPayload>) => {
      const { costCodeId, selectionId } = action.payload;

      if (selectionId && !!state.selectedSelections?.[costCodeId].length) {
        if (state.selectedSelections?.[costCodeId].length === 1) {
          delete state.selectedSelections[costCodeId];
        } else if (state.selectedSelections[costCodeId].find(({ id }) => id === selectionId)) {
          state.selectedSelections[costCodeId] = state.selectedSelections[costCodeId].filter(
            ({ id }) => id !== selectionId
          );
        }
      }
    },

    unselectCostCode: (state, action: PayloadAction<IUnselectCostCode>) => {
      const { costCodeId } = action.payload;

      if (state.selectedSelections?.[costCodeId]) {
        delete state.selectedSelections[costCodeId];
      }
    },

    selectAllSelections: (state, action: PayloadAction<ISelectAllSelectionsPayload>) => {
      const { costCodeId, selections: allSelections } = action.payload;

      const selections = getUnapprovedSelections(allSelections);

      if (selections.length) {
        if (
          Array.isArray(state.selectedSelections?.[costCodeId]) &&
          state.selectedSelections[costCodeId].length === selections.length
        ) {
          delete state.selectedSelections[costCodeId];
        } else {
          state.selectedSelections[costCodeId] = selections;
        }
      }
    },

    updateSelectedSelection: (state, action: PayloadAction<IUpdateSelectedSelectionPayload>) => {
      const { costCodeId, selection } = action.payload;

      if (state.selectedSelections?.[costCodeId]) {
        if (selection.status === SelectionStatusEnum.APPROVED) {
          if (state.selectedSelections[costCodeId].length === 1) {
            delete state.selectedSelections[costCodeId];
          } else {
            state.selectedSelections[costCodeId] = state.selectedSelections[costCodeId].filter(
              ({ id }) => id !== selection.id
            );
          }
        } else {
          const selectionIndex = state.selectedSelections[costCodeId].findIndex(
            ({ id }) => id === selection.id
          );

          if (selectionIndex >= 0) {
            state.selectedSelections[costCodeId][selectionIndex] = selection;
          }
        }
      }
    },

    resetSelectedSelections: (state) => {
      state.selectedSelections = {};
    },
  },
});

export const {
  resetSelectedSelections,
  selectAllSelections,
  selectSelection,
  unselectSelection,
  updateSelectedSelection,
  unselectCostCode,
} = selectedSelectionsSlice.actions;

export default selectedSelectionsSlice.reducer;
