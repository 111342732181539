export const styles = {
  title: {
    fontSize: '24px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
    whiteSpace: 'nowrap',
  },

  text: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#6E707A',
  },
};
