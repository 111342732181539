import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProjectProfiles } from 'redux/api/projects';

export type TSidePanelContentType =
  | 'notifications_feed'
  | 'activity_feed'
  | 'selections_file_cabinet';

export enum ActivityFeedContentEnum {
  FEED = 'Feed',
  MEDIA = 'Media',
}

export interface ISidePanel {
  isOpen: boolean;
  isEditorExpanded: boolean;
  isBeforeOpened: boolean;
  isFullScreen: boolean;
  sidePanelContent: TSidePanelContentType;
  projectId: number;
  mentionedProfile: IProjectProfiles | null;
  editorKey: number;
  costCodeCabinetId: string | number | null;
  activityFeedContent: ActivityFeedContentEnum;
}

const initialState: ISidePanel = {
  isOpen: false,
  isBeforeOpened: false,
  isEditorExpanded: false,
  isFullScreen: false,
  sidePanelContent: 'activity_feed',
  projectId: 0,
  mentionedProfile: null,
  editorKey: Math.floor(Math.random() * 100),
  costCodeCabinetId: null,
  activityFeedContent: ActivityFeedContentEnum.FEED,
};

export const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState,
  reducers: {
    handleOpenSidePanel: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
      if (!action.payload) {
        state.mentionedProfile = null;
      }
    },

    setFullScreen: (state) => {
      state.isFullScreen = true;
    },

    setCollapseFullScreen: (state) => {
      state.isFullScreen = false;
    },

    handleSetSidePanelContent: (state, action: PayloadAction<TSidePanelContentType>) => {
      state.sidePanelContent = action.payload;
    },

    handleSetProjectId: (state, action: PayloadAction<number>) => {
      state.projectId = action.payload;
    },

    handleSetMentionedProfile: (state, action: PayloadAction<IProjectProfiles>) => {
      state.mentionedProfile = action.payload;
      state.editorKey = Math.floor(Math.random() * 100);
    },

    handleResetMentionedProfile: (state) => {
      state.mentionedProfile = null;
    },

    handleSetEditorExpanded: (state, action: PayloadAction<boolean>) => {
      state.isEditorExpanded = action.payload;
      if (!action.payload) {
        state.mentionedProfile = null;
      }
    },

    handleSetIsBeforeOpened: (state, action: PayloadAction<boolean>) => {
      state.isBeforeOpened = action.payload;
    },

    handleSetCabinetId: (state, action: PayloadAction<string | number | null>) => {
      state.costCodeCabinetId = action.payload;
    },

    handleSetActivityFeedContent: (state, action: PayloadAction<ActivityFeedContentEnum>) => {
      state.activityFeedContent = action.payload;
    },
  },
});

export const {
  setFullScreen,
  handleOpenSidePanel,
  handleSetProjectId,
  setCollapseFullScreen,
  handleSetSidePanelContent,
  handleResetMentionedProfile,
  handleSetMentionedProfile,
  handleSetEditorExpanded,
  handleSetIsBeforeOpened,
  handleSetCabinetId,
  handleSetActivityFeedContent,
} = sidePanelSlice.actions;

export default sidePanelSlice.reducer;
