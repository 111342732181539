import makeAxiosRequest from './axios.instance';

const api = {
  auth: {
    signUp: makeAxiosRequest('/auth/sign-up', 'post'),
    invitesBulk: makeAxiosRequest('invites/bulk', 'post'),
    sendPasswordReset: makeAxiosRequest('/auth/send-password-reset', 'post'),
    resendVerificationEmail: makeAxiosRequest('/auth/resend-verification-email', 'post'),
    resetPassword: makeAxiosRequest('/auth/password-reset', 'patch'),
    updatePassword: makeAxiosRequest((id) => `/users/${id}/password`, 'patch'),
  },
  templates: {
    getTemplatesList: makeAxiosRequest('/schedule-templates', 'get'),
    getTemplate: makeAxiosRequest((id) => `/schedule-templates/${id}`, 'get'),
    createTemplate: makeAxiosRequest('/schedule-templates', 'post'),
    updateTemplate: makeAxiosRequest((id) => `/schedule-templates/${id}`, 'patch'),
    deleteTemplate: makeAxiosRequest((id) => `/schedule-templates/${id}`, 'delete'),
    createTemplatePhase: makeAxiosRequest('/schedule-templates/phases', 'post'),
    updateTemplatePhase: makeAxiosRequest((id) => `/schedule-templates/phases/${id}`, 'patch'),
    deleteTemplatePhase: makeAxiosRequest((id) => `/schedule-templates/phases/${id}`, 'delete'),
    createTemplatePhaseItem: makeAxiosRequest('/schedule-templates/phases-items', 'post'),
    updateTemplatePhaseItem: makeAxiosRequest(
      (id) => `/schedule-templates/phases-items/${id}`,
      'patch'
    ),
    deleteTemplatePhaseItem: makeAxiosRequest(
      (id) => `/schedule-templates/phases-items/${id}`,
      'delete'
    ),
  },
};

export default api;
