export const editProjectModalStyles = {
  form: {
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '86vh',

    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EDEFF7',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },

  formHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '40px 40px 20px 40px',
    borderBottom: '1px solid #F3F4F9',
  },

  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '24px',
    lineHeight: '136%',
    fontWeight: 400,
    color: '#23252F',
  },

  headerSubtitle: {
    fontSize: '14px',
    lineHeight: '146%',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#6E707A',
    fontFamily: 'Inter, sans-serif !important',
  },

  formContainer: {
    padding: '20px 40px 0px 40px',
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 40px 20px 40px',
  },

  archive: {
    display: 'flex',
    gap: '8px',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: '400',
    color: '#4D6EF5',
    fontFamily: 'Inter, sans-serif !important',
    cursor: 'pointer',
  },

  saveButton: {
    width: '70px',
    minWidth: '70px',
    fonFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
  },

  cancelButton: {
    width: '70px',
    minWidth: '70px',
    fonFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#4D6EF5',
  },
};
