import { useEffect } from 'react';
import useToast from './useToast';

export const useValidationNotification = (
  isSubmitSuccessfull: boolean,
  submitCount: number,
  message?: string
) => {
  const showToast = useToast();

  useEffect(() => {
    if (!isSubmitSuccessfull && submitCount > 0) {
      showToast({
        type: 'error',
        message: message ?? 'Could not apply changes. Please fix issues and try again.',
      });
    }
  }, [isSubmitSuccessfull, submitCount]);

  return null;
};
