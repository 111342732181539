import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IEditorHeight {
  editorHeight: number;
}

const initialState: IEditorHeight = {
  editorHeight: 0,
};

export const editorHeightSlice = createSlice({
  name: 'editorHeight',
  initialState,
  reducers: {
    setEditorHeight: (state, action: PayloadAction<number>) => {
      state.editorHeight = action.payload;
    },
    setDefaultEditorHeight: (state) => {
      state.editorHeight = initialState.editorHeight;
    },
  },
});

export const { setDefaultEditorHeight, setEditorHeight } = editorHeightSlice.actions;

export default editorHeightSlice.reducer;
