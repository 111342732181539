export const createProjectFormStyles = {
  isFirstProjectTitle: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
  },

  isFirstProjectSubtitle: {
    padding: '4px 0 20px',
    borderBottom: '1px solid rgba(235, 238, 246, 0.42)',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    color: '#6E707A',
    fontFamily: 'Inter, sans-serif !important',
  },

  formInputsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  inputsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '20px',
    marginBottom: '24px',
  },

  inputContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '20px',
  },

  googleMapContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '16px 0',
    overflow: 'hidden',
    borderRadius: '12px',
  },
};
