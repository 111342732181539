import React, { useCallback, FC } from 'react';
// mui components
import { Grid, StepIconProps, StepConnector, stepConnectorClasses } from '@mui/material';
import { GridProps, styled } from '@mui/system';

// icons
import { ReactComponent as ProjectSetupIcon } from 'assets/icons/Project_setup_new_icon.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule_new_icon.svg';
import { ReactComponent as ScheduleActiveIcon } from 'assets/icons/Schedule_new_active_icon.svg';
import { ReactComponent as UserInviteIcon } from 'assets/icons/Users_invite_new_icon.svg';
import { ReactComponent as UserInviteActiveIcon } from 'assets/icons/Users_invite_new_active_icon.svg';
import { ReactComponent as ProfileActiveIcon } from 'assets/icons/User_profile_new_active_icon.svg';
import { ReactComponent as CompanyProfileIcon } from 'assets/icons/Company_profile_new_icon.svg';
import { ReactComponent as CompanyProfileActiveIcon } from 'assets/icons/Company_profile_new_active_icon.svg';
import { ReactComponent as CheckStepperNewIcon } from 'assets/icons/Check_stepper_new_icon.svg';

import AuthStepperNewUI from './new-ui';

const StyledConnectorNewUI = styled(StepConnector)(() => ({
  width: '24px',
  background: '#AEB0BC',
  [`& .${stepConnectorClasses.line}`]: {
    border: '1px solid #CED4DA',
  },
}));

const StyledIconNewUI = styled((props: GridProps) => (
  <Grid container alignItems="center" justifyContent="center" item {...props} />
))(() => ({
  width: '32px',
  height: '32px',
  textAlign: 'center',
  color: '#4D6EF5',
  backgroundColor: '#fff',
  border: '1px solid #4D6EF5',
  borderRadius: '50%',
  fontSize: '22px',
}));

interface OwnProps {
  activeStep: number;
  currentStep: JSX.Element;
  steps: {
    label: string;
    description: string;
  }[];
  isProjectStepper?: boolean;
  isFirstProject?: boolean;
}

const AuthStepper: FC<OwnProps> = ({ isProjectStepper, activeStep, currentStep, steps }) => {
  const CustomStepIconNewUI = useCallback(
    (props: StepIconProps) => {
      const { active, completed } = props;

      const icons: { [index: string]: React.ReactElement } = {
        1: (
          <StyledIconNewUI>
            {isProjectStepper ? <ProjectSetupIcon /> : <ProfileActiveIcon />}
          </StyledIconNewUI>
        ),
        2: (
          <StyledIconNewUI>
            {isProjectStepper ? (
              active === true ? (
                <ScheduleActiveIcon />
              ) : (
                <ScheduleIcon />
              )
            ) : active === true ? (
              <CompanyProfileActiveIcon />
            ) : (
              <CompanyProfileIcon />
            )}
          </StyledIconNewUI>
        ),
        3: (
          <StyledIconNewUI>
            {isProjectStepper ? (
              active === true ? (
                <UserInviteActiveIcon />
              ) : (
                <UserInviteIcon />
              )
            ) : (
              '03'
            )}
          </StyledIconNewUI>
        ),
      };

      return (
        <Grid
          item
          sx={{
            fontWeight: active ? 'bold' : 'normal',
            lineHeight: active ? '26px' : '21px',
          }}
        >
          {completed && !active ? (
            <StyledIconNewUI sx={{ background: '#4D6EF5', color: '#fff' }}>
              <CheckStepperNewIcon />
            </StyledIconNewUI>
          ) : active ? (
            icons[String(props.icon)]
          ) : (
            <div
              className={`${
                !completed && !active
                  ? isProjectStepper
                    ? 'inactive-bordered-step'
                    : 'inactive-step'
                  : ''
              }`}
            >
              {icons[String(props.icon)]}
            </div>
          )}
        </Grid>
      );
    },
    [isProjectStepper]
  );

  const props = {
    isProjectStepper,
    activeStep,
    currentStep,
    steps,
  };

  return (
    <AuthStepperNewUI
      {...props}
      CustomStepIcon={CustomStepIconNewUI}
      StyledConnector={StyledConnectorNewUI}
    />
  );
};

export default AuthStepper;
