import React, { FC, ReactNode } from 'react';
// mui components
import {
  Select,
  SelectChangeEvent,
  SelectProps,
  MenuItem,
  Box,
  FormControl,
  Grid,
} from '@mui/material';
// components
import OutlinedInput from 'components/inputs/outlined-input';
// icons
import { ReactComponent as AngleDownIcon } from '../assets/icons/angle-down.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check_icon.svg';
// types
import { FieldError } from 'react-hook-form';
import { ICommonSelectValues } from '../utils/types';
//styles
import { selecNativeStyles } from './styles';

export interface IProps extends Omit<SelectProps, 'error'> {
  addNewItem?: boolean;
  options: ICommonSelectValues[];
  optionStyles?: { [key: string]: string };
  required?: boolean;
  withError?: boolean;
  withoutHelperText?: boolean;
  maxWidth?: string;
  additionalOnChange?: (event: SelectChangeEvent<any>) => void;
  error?: FieldError;
  label?: string;
  isCapitalize?: boolean;
  renderOption?: (item: ICommonSelectValues) => ReactNode | null;
  onAddNewItem?: () => void;
}

const SelectNative: FC<IProps> = ({
  addNewItem,
  options,
  optionStyles,
  placeholder,
  maxWidth,
  name,
  value,
  label,
  error,
  required,
  withError,
  onChange,
  additionalOnChange,
  sx,
  isCapitalize,
  renderOption,
  onAddNewItem,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <Select
          {...props}
          name={name}
          displayEmpty
          defaultValue={value}
          value={value}
          sx={
            label === 'Country'
              ? selecNativeStyles.selecNativeCountry
              : selecNativeStyles.selecNative
          }
          IconComponent={AngleDownIcon}
          input={
            <OutlinedInput
              name={name}
              label={label}
              error={!!error}
              required={!!required}
              helperText={withError ? error?.message || ' ' : ' '}
              withoutHelperText={!withError}
              sx={{ maxWidth: maxWidth || '100%' }}
            />
          }
          onChange={(event, child: React.ReactNode) => {
            onChange?.(event, child);
            additionalOnChange && additionalOnChange(event);
          }}
        >
          {!!placeholder && (
            <option value="" hidden>
              {placeholder}
            </option>
          )}

          {options?.map((item) => (
            <MenuItem
              key={item?.value}
              value={item?.value}
              sx={{
                ...optionStyles,
                ...selecNativeStyles?.option,
              }}
            >
              <Grid sx={selecNativeStyles?.selectValue}>
                {item.icon ? (
                  <img
                    src={item?.icon}
                    alt={item?.label}
                    style={selecNativeStyles?.selecNativeOptionIcon}
                  />
                ) : null}

                {item?.color ? (
                  <div
                    style={{
                      ...selecNativeStyles?.selecNativeOptionColor,
                      background: item?.color,
                    }}
                  />
                ) : null}

                {item?.label}
              </Grid>

              <span className="checkIcon">
                <CheckIcon />
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectNative;
