export const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  link: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '146%',
    textTransform: 'capitalize',
    textDecoration: 'none',
    background: '#fff',
    color: '#4D6EF5',
    border: '1px solid #4D6EF5',
    borderRadius: '8px',
    padding: '7px 12px',
    '&.filled': {
      color: '#fff',
      background: '#4D6EF5',
    },
    '&:hover': {
      opacity: '0.9',
    },
  },
};
