export const typoSm = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '150%',
};

export const typoM = {
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '146%',
  color: '#23252F',
};

export const cabinetStyles = {
  tooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#23252F',
      borderRadius: '4px',
      padding: '4px 8px',
      fontFamily: 'Inter',
      fontSize: '10px',
      textTransform: 'capitalize',
      '&.MuiTooltip-tooltipPlacementTop': {
        marginBottom: '4px',
      },
    },
  },
};
