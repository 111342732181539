export const styles = {
  container: { display: 'flex', width: '700px', flexDirection: 'column', alignItems: 'center' },
  header: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    gap: '4px',
    borderBottom: '1px solid #F3F4F9',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '136%',
    color: '#23252F',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '136%',
    color: '#6E707A',
  },
  crossBtn: { display: 'flex', position: 'absolute', top: '16px', right: '16px', padding: '0' },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    maxHeight: '518px',
    padding: '20px',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    gap: '12px',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '478px',
    padding: '0 20px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },
};
