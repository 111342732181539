import { RootState } from 'redux/store';

export const accountStepperSelector = (state: RootState) => state.accountStepper;
export const authSelector = (state: RootState) => state.auth;
export const queryArgsSelector = (state: RootState) => state.queryArgs;
export const newCommentsSelector = (state: RootState) => state.newComments;
export const audienceSelector = (state: RootState) => state.postAudience;
export const billingSelector = (state: RootState) => state.billing;
export const replyToPostSelector = (state: RootState) => state.replyToPost;
export const editorHeightSelector = (state: RootState) => state.editorHeight;
export const sidePanelSelector = (state: RootState) => state.sidePanel;
export const manageGanttSelector = (state: RootState) => state.manageGantt;
export const fromMobileSelector = (state: RootState) => state.fromMobile;
export const fileCabinetSelector = (state: RootState) => state.fileCabinet;
export const selectedSelectionsSelector = (state: RootState) => state.selectedSelections;
export const costCodeTemplateSelector = (state: RootState) => state.costCodeTemplate;
export const checklistSelector = (state: RootState) => state.checklist;
