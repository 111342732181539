import React, { useEffect, useState, useCallback, useMemo, FC } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Geocode from 'react-geocode';

const defaultValues = {
  lat: 40.70767372919335,
  lng: -74.01176164593505,
};

export interface Coords {
  lat: number;
  lng: number;
}

export interface Address {
  country: string;
  city: string;
  state: string;
  postCode: string;
  formattedAddress: string;
  lat: number;
  lng: number;
}

interface OwnProps {
  height: string;
  coordinates?: Coords;
  updateCoordinates: (currentCoords: Coords) => void;
  updateAddress: (currentAddress: Address) => void;
}

const Map: FC<OwnProps> = ({ height, coordinates, updateCoordinates, updateAddress }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);

  const [currentMarker, setMarker] = useState<any>();

  const currentCoordinates = useMemo(() => coordinates || defaultValues, [coordinates]);

  useEffect(() => {
    currentMarker?.setPosition(currentCoordinates);
  }, [currentMarker, currentCoordinates]);

  const onLoad = useCallback(
    (map: any) => {
      const marker = new window.google.maps.Marker({
        position: currentCoordinates,
        map,
        draggable: true,
      });

      marker.addListener('dragend', (values: any) => {
        updateCoordinates({ lat: values.latLng.lat(), lng: values.latLng.lng() });

        Geocode.fromLatLng(values.latLng.lat(), values.latLng.lng()).then(
          (response) => {
            const address = response?.results?.[0]?.address_components;
            const formattedAddress = response?.results?.[0]?.formatted_address;

            const country = address?.find(({ types }) => {
              return types?.includes('country');
            });

            const state = address?.find(({ types }) => {
              return types?.includes('administrative_area_level_1');
            });

            const city = address?.find(({ types }) => {
              return types?.includes('locality');
            });

            const postCode = address?.find(({ types }) => {
              return types?.includes('postal_code');
            });

            updateAddress({
              country: country?.short_name,
              state: state?.long_name,
              city: city?.long_name,
              postCode: postCode?.long_name,
              formattedAddress: formattedAddress,
              lat: values.latLng.lat(),
              lng: values.latLng.lng(),
            });
          },
          (error) => {
            console.error(error);
          }
        );
      });

      setMarker(marker as any);
    },
    [currentCoordinates, updateCoordinates]
  );

  if (!isLoaded) {
    return <></>;
  }

  return (
    <GoogleMap
      zoom={18}
      onLoad={onLoad}
      center={currentCoordinates}
      mapContainerStyle={{
        height,
        width: '100%',
      }}
    />
  );
};

export default Map;
