export const styles = {
  inputContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    marginBottom: '20px',
    gap: '16px',
  },

  input: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    border: '1px solid #EDEFF7',
    borderRadius: '12px',
  },

  accessContainer: {
    width: '150px',
  },

  accessButton: {
    width: '130px',
  },

  rolesContentContainer: {
    width: '100%',
    border: '1px solid #F3F4F9',
    borderRadius: '9.5px',

    '& .role-row:nth-last-of-type(1)': {
      borderBottom: 'none',
    },
  },

  rolesTableHead: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr 1fr',
    gap: '8px',
    borderBottom: '1px solid #F3F4F9',
    padding: '20px 18px 20px 28px',
  },

  roleTableItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
  },

  roleTableEmptyItem: {
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
  },

  roleTableTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
  },

  actions: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px',
    '& .btn': {
      color: '#4D6EF5',
      backgroundColor: '#fff',
      '&:hover': {
        opacity: '0.8',
      },
    },
    '& .filled-btn': {
      color: '#fff',
      backgroundColor: '#4D6EF5',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4D6EF5',
        opacity: '0.8',
      },
    },
  },
};
