import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IActivityFeedAudience } from 'redux/api/activity-feed';
import { IPostAudienceMember, IPostEveryoneMember } from 'utils/types';
import { number } from 'yup';

interface IInitialState {
  audienceName: string;
  isSavedAudience: boolean;
  selectedItems: { fullName: string; id: number }[];
  groupToAssign: { groupId: number; groupName: string };
  audiences: {
    everyone: boolean;
    justMe: boolean;
    openSpecific: boolean;
  };
  openAudienceModal: boolean;
  openConfirmDeletionModal: boolean;
  openCustomAudienceModal: boolean;
  audienceMembers: { isOpenModal: boolean; members: IPostAudienceMember };
  everyoneMembers: { isOpenModal: boolean; members: IPostEveryoneMember[] };
  customGroupIds: number[];
  byRoleIds: number[];
  selectedAudience: {
    audienceGroups: number[];
    audienceRoles: number[];
  };
  byRolePreference: {
    id: number;
    groupName: string;
    profiles: IActivityFeedAudience[];
  }[];
  postVisibility: string;
  selectedSpecificUsers: number[];
  postAudienceRoles: string[];
  isAudienceEditable: boolean;
}

const initialState: IInitialState = {
  audienceName: '',
  isSavedAudience: false,
  selectedItems: [],
  groupToAssign: { groupId: null, groupName: null },
  audiences: {
    justMe: false,
    everyone: true,
    openSpecific: false,
  },
  openAudienceModal: false,
  openConfirmDeletionModal: false,
  openCustomAudienceModal: false,
  audienceMembers: { isOpenModal: false, members: null },
  everyoneMembers: { isOpenModal: false, members: null },
  customGroupIds: [],
  byRoleIds: [],
  selectedAudience: {
    audienceGroups: [],
    audienceRoles: [],
  },
  byRolePreference: [],
  postVisibility: 'EVERYONE',
  selectedSpecificUsers: [],
  postAudienceRoles: [],
  isAudienceEditable: false,
};

const postAudienceSlice = createSlice({
  name: 'post-audience-slice',
  initialState,
  reducers: {
    setOpenAudienceModal: (state, action) => {
      state.openAudienceModal = action.payload;
    },
    setPostAudienceRoles: (state, action) => {
      state.postAudienceRoles = action.payload;
    },
    setOpenConfirmDeletionModal: (state, action) => {
      state.openConfirmDeletionModal = action.payload;
    },
    setOpenCustomAudienceModal: (state, action) => {
      state.openCustomAudienceModal = action.payload;
    },
    setGroupToAssignAudience: (state, action) => {
      state.groupToAssign = action.payload;
    },
    setSpecificMembersOpen: (state, action) => {
      state.audiences.openSpecific = action.payload;
      state.audiences.everyone = false;
      state.audiences.justMe = false;
      state.selectedSpecificUsers = [];
    },
    setAudienceEditable: (state, action) => {
      state.isAudienceEditable = action.payload;
    },
    setJustMeOpen: (state, action) => {
      state.audiences.justMe = action.payload;
      state.audiences.everyone = false;
      state.audiences.openSpecific = false;
      state.selectedSpecificUsers = [];
    },

    setEveryoneOpen: (state, action) => {
      state.audiences.everyone = action.payload;
      state.audiences.justMe = false;
      state.audiences.openSpecific = false;
      state.selectedSpecificUsers = [];
    },

    onChooseJustMe: (state) => {
      state.audiences.justMe = !state.audiences.justMe;
      state.audiences.openSpecific = false;
      state.audiences.everyone = false;
      state.selectedSpecificUsers = [];
      if (state.audiences.justMe) {
        state.postVisibility = 'JUST_ME';
      } else {
        state.postVisibility = 'EVERYONE';
      }
    },
    onChooseEveryone: (state) => {
      state.audiences.everyone = !state.audiences.everyone;
      state.audiences.openSpecific = false;
      state.audiences.justMe = false;
      state.selectedSpecificUsers = [];
      if (state.audiences.everyone) {
        state.postVisibility = 'EVERYONE';
      } else {
        state.postVisibility = 'EVERYONE';
      }
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    handleDleteSelected: (state, action) => {
      state.selectedItems = state.selectedItems.filter((item) => {
        return item.id !== action.payload;
      });
    },
    setIsSavedAudience: (state, action) => {
      state.isSavedAudience = action.payload;
    },
    setAudienceName: (state, action) => {
      state.audienceName = action.payload;
    },
    setAudienceMembers: (state, action) => {
      state.audienceMembers = action.payload;
    },
    setEveryoneMembers: (state, action) => {
      state.everyoneMembers = action.payload;
    },
    setCustomGroupIds: (state, action) => {
      if (action.payload.choosed) {
        state.customGroupIds.push(action.payload.audienceId);
        state.postVisibility = 'SPECIFIC_MEMBERS';
      } else {
        state.customGroupIds = state.customGroupIds.filter(
          (item) => item !== action.payload.audienceId
        );
        state.postVisibility = 'EVERYONE';
      }
    },
    setByRoleGroupIds: (state, action) => {
      if (action.payload.choosed) {
        state.postVisibility = 'SPECIFIC_MEMBERS';
        state.byRoleIds.push(action.payload.member.id);
        state.byRolePreference.push(action.payload.member);
      } else {
        state.postVisibility = 'EVERYONE';
        state.byRoleIds = state.byRoleIds.filter((item) => item !== action.payload.member.id);
        state.byRolePreference = state.byRolePreference.filter(
          (item) => item.id !== action.payload.member.id
        );
      }
    },
    setSelectedAudiences: (state, action) => {
      state.selectedAudience = action.payload;
    },
    setSpecificUsers: (state, action: PayloadAction<number[]>) => {
      if (
        state.audiences.everyone ||
        state.audiences.justMe ||
        state.audiences.openSpecific ||
        state.byRoleIds.length ||
        state.customGroupIds.length
      ) {
        state.audiences = {
          justMe: false,
          everyone: false,
          openSpecific: false,
        };
        state.customGroupIds = [];
        state.byRoleIds = [];
        state.selectedAudience = {
          audienceGroups: [],
          audienceRoles: [],
        };
      }
      if (state.postVisibility !== 'SPECIFIC_MEMBERS') {
        state.postVisibility = 'SPECIFIC_MEMBERS';
      }
      state.selectedSpecificUsers = action.payload;
    },
    addSpecificUser: (state, action: PayloadAction<number>) => {
      if (
        state.audiences.everyone ||
        state.audiences.justMe ||
        state.audiences.openSpecific ||
        state.byRoleIds.length ||
        state.customGroupIds.length
      ) {
        state.audiences = {
          justMe: false,
          everyone: false,
          openSpecific: false,
        };
        state.customGroupIds = [];
        state.byRoleIds = [];
        state.selectedAudience = {
          audienceGroups: [],
          audienceRoles: [],
        };
      }
      if (state.postVisibility !== 'SPECIFIC_MEMBERS') {
        state.postVisibility = 'SPECIFIC_MEMBERS';
      }
      state.selectedSpecificUsers.push(action.payload);
    },
    deleteSpecificUser: (state, action: PayloadAction<number>) => {
      state.selectedSpecificUsers = state.selectedSpecificUsers.filter(
        (item) => item !== action.payload
      );
    },
    resetAudienceReducerData: (state) => {
      state.postVisibility = 'EVERYONE';
      state.audienceName = '';
      state.byRolePreference = [];
      state.isSavedAudience = false;
      state.selectedItems = [];
      state.audiences = {
        justMe: false,
        everyone: true,
        openSpecific: false,
      };
      state.openAudienceModal = false;
      state.openCustomAudienceModal = false;
      state.audienceMembers = { isOpenModal: false, members: null };
      state.everyoneMembers = { isOpenModal: false, members: null };
      state.customGroupIds = [];
      state.byRoleIds = [];
      state.selectedAudience = {
        audienceGroups: [],
        audienceRoles: [],
      };
      state.selectedSpecificUsers = [];
    },
  },
});

export const {
  setOpenAudienceModal,
  setOpenCustomAudienceModal,
  setOpenConfirmDeletionModal,
  setGroupToAssignAudience,
  onChooseJustMe,
  onChooseEveryone,
  setSpecificMembersOpen,
  setJustMeOpen,
  setEveryoneOpen,
  setSelectedItems,
  handleDleteSelected,
  setIsSavedAudience,
  setAudienceName,
  setAudienceMembers,
  setEveryoneMembers,
  setPostAudienceRoles,
  setCustomGroupIds,
  setAudienceEditable,
  setByRoleGroupIds,
  setSelectedAudiences,
  resetAudienceReducerData,
  addSpecificUser,
  deleteSpecificUser,
  setSpecificUsers,
} = postAudienceSlice.actions;

export default postAudienceSlice.reducer;
