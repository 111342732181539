import React, { FC } from 'react';
// mui components
import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';

interface IRoundProgressProps extends CircularProgressProps {
  value: number;
}

export const RoundProgress: FC<IRoundProgressProps> = (props) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '10px',
            lineHeight: '150%',
            display: 'flex',
            color: '#23252F',
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
