// Popper component cause a type error in production. This is the MUI bug.
// @ts-nocheck
import React, { useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// mui components
import {
  ClickAwayListener,
  Grow,
  Paper,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
  ButtonGroup,
  InputAdornment,
  debounce,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/system';
// custom hooks
import { useProjectStepper } from 'hooks/useProjectStepperState';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
// api
import {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  IProject,
  useGetUnviewedCountsQuery,
} from 'redux/api/projects';
import { PhaseStatusesEnum } from 'redux/types';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
// selectors
import { queryArgsSelector } from 'redux/selector';
// slices
import { setDefaultArgs, setProjectsArgs } from 'redux/slices/query-args-slice';
import { handleAllowGanttRefetch } from 'redux/slices/manage-gantt-slice';
// components
import OutlinedInput from 'components/inputs/outlined-input';
import CreateProjectModal from 'components/modal/create-project-modal';
// icons
import { ReactComponent as ChevronsIcon } from 'assets/icons/buttons/chevrons.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/buttons/search.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/buttons/comment.svg';
import { ReactComponent as BellIcon } from 'assets/icons/buttons/bell.svg';
import { ReactComponent as AllertCircleIcon } from 'assets/icons/buttons/allert-circle.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/buttons/plus-circle.svg';
//styles
import { projectsButtonStyles } from '../styles/styles';
import { useScrollToBottom } from 'hooks/useScrollToBottom';
import { firstAddressHelper } from 'utils/helpers/firstAddressHelper';

export const StyledMessagesButton = styled(Button)(projectsButtonStyles.styledMessagesButton);

const ProjectsButton = () => {
  const dispatch = useAppDispatch();
  const { projects: projectsArgs } = useAppSelector(queryArgsSelector);
  const [open, setOpen] = useState(false);
  const projectId = window.location.pathname.split('/')?.[2];
  const place = window.location.pathname.split('/')?.[1];

  const { data: projectsList, isLoading, isFetching } = useGetProjectsQuery(projectsArgs);
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { data: selectedProject } = useGetProjectByIdQuery(
    { id: Number(projectId) },
    { skip: !Number(projectId) }
  );

  const filteredProjectsList: IProject<Array> = projectsList?.items?.filter(
    (project) => project.id !== selectedProject?.id
  );

  const { data: unviewedItemCounts, isLoading: isUnviewedItemsLoading } = useGetUnviewedCountsQuery(
    {
      projectIds: projectsList?.items?.map(({ id }) => id) ?? [],
    },
    { skip: isLoading || !projectsList?.items?.length }
  );

  const { openModal, setOpenModal, setRedirect } = useProjectStepper();
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(setProjectsArgs({ page: 1, search: undefined }));
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
    setRedirect(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const searchProject = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(
        setProjectsArgs({ page: 1, search: event.target.value ? event.target.value : undefined })
      );
    },
    []
  );

  const handleSearchProject = debounce(searchProject, 300);

  const calcOverdueItemsCount = useCallback((project?: IProject) => {
    const itemsList = project?.phases?.flatMap(({ items }) => items);

    return itemsList?.filter((item) => item.status === PhaseStatusesEnum.OVERDUE).length;
  }, []);

  const moveToAnchor = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, project?: IProject) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(`/project-dashboard/${project?.id}#activity_feed`);
    },
    [navigate]
  );

  const handleNavigateToOverdue = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, project?: IProject) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(`/schedule/${project?.id}?status=OVERDUE`);
    },
    [navigate]
  );

  const getMoreProjects = () => {
    dispatch(setProjectsArgs({ page: projectsArgs.page + 1 }));
  };

  const { handleScroll, scrollAreaRef } = useScrollToBottom(
    getMoreProjects,
    projectsList?.meta?.totalPages &&
      projectsList?.meta?.currentPage &&
      projectsList?.meta?.totalPages > projectsList?.meta?.currentPage &&
      !isFetching,
    true
  );

  const handleChangeProject = (id) => {
    dispatch(handleAllowGanttRefetch(true));
    dispatch(setDefaultArgs());
    navigate(`/${place ?? 'project-dashboard'}/${id}`);
    handleClose();
  };

  if (!selectedProject) {
    return null;
  }

  return (
    <Grid sx={projectsButtonStyles.buttonContainer}>
      <Button
        ref={anchorRef}
        variant="outlined"
        id="projects-list-button"
        aria-controls={open ? 'projects-list-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={
          projectsList?.items?.length > 1 && (
            <ChevronsIcon style={projectsButtonStyles.containerIcon} />
          )
        }
        sx={projectsButtonStyles.container}
      >
        <Grid sx={projectsButtonStyles.buttonContentContainer}>
          <Typography sx={projectsButtonStyles.buttonTitle}>{selectedProject?.name}</Typography>
          <Typography sx={projectsButtonStyles.buttonSubtitle}>
            {`${firstAddressHelper(selectedProject?.address) + ','} ${
              selectedProject?.secondAddress ? `${selectedProject?.secondAddress},` : ''
            } ${selectedProject?.city}, ${selectedProject?.stateAbbr || selectedProject?.state} ${
              selectedProject?.zip
            } ${
              selectedProject?.address?.split(',')?.[3]
                ? selectedProject?.address?.split(',')?.[3]
                : ''
            }`}
          </Typography>
        </Grid>
      </Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        sx={projectsButtonStyles.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={projectsButtonStyles.popper}>
            <Paper sx={projectsButtonStyles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid container sx={projectsButtonStyles.paperContentContainer}>
                  {isLoading || isUnviewedItemsLoading ? (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ minHeight: '100px' }}
                    >
                      <CircularProgress size={40} />
                    </Grid>
                  ) : (
                    <>
                      <Grid sx={projectsButtonStyles.searchInputContainer}>
                        <OutlinedInput
                          fullWidth
                          withoutHelperText
                          placeholder="Search"
                          startAdornment={
                            <InputAdornment
                              position="start"
                              sx={projectsButtonStyles.inputAdornment}
                            >
                              <Grid item sx={{ marginRight: '20px' }}>
                                <SearchIcon />
                              </Grid>
                            </InputAdornment>
                          }
                          sx={projectsButtonStyles.searchInput}
                          onChange={handleSearchProject}
                        />
                      </Grid>

                      <Grid
                        container
                        sx={projectsButtonStyles.projectItemsContainer}
                        ref={scrollAreaRef}
                        onScroll={handleScroll}
                      >
                        {!!projectsList && projectsList?.items?.length > 0 ? (
                          filteredProjectsList?.map((project, index, array) => {
                            if (index === array?.length - 1 && isFetching) {
                              return (
                                <Grid
                                  key={project.id}
                                  sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    marginTop: '12px',
                                  }}
                                >
                                  <CircularProgress />
                                </Grid>
                              );
                            }
                            const unviewedItems = unviewedItemCounts?.items?.[project.id];

                            return (
                              <Grid
                                key={project.id}
                                container
                                sx={projectsButtonStyles.projectCard}
                              >
                                <Grid
                                  container
                                  sx={projectsButtonStyles.projectCardContainer}
                                  onClick={() => {
                                    handleChangeProject(project?.id);
                                  }}
                                >
                                  <Grid sx={projectsButtonStyles.projectCardInfo}>
                                    <Tooltip title={project.name}>
                                      <Typography sx={projectsButtonStyles.projectNameContent}>
                                        {project.name}
                                      </Typography>
                                    </Tooltip>
                                    <Typography sx={projectsButtonStyles.styledTextAddress}>
                                      {`${firstAddressHelper(project?.address)}, ${
                                        project?.secondAddress ? `${project?.secondAddress},` : ''
                                      } ${project?.city}, ${project?.stateAbbr || project?.state} ${
                                        project?.zip
                                      } ${
                                        project?.address?.split(',')?.[3]
                                          ? project?.address?.split(',')?.[3]
                                          : ''
                                      }`}
                                    </Typography>
                                  </Grid>
                                  <ButtonGroup sx={projectsButtonStyles.buttonGroupContainer}>
                                    <StyledMessagesButton
                                      onClick={(event) => moveToAnchor(event, project)}
                                    >
                                      <CommentIcon />
                                      {unviewedItems?.posts ?? 0}
                                    </StyledMessagesButton>
                                    <StyledMessagesButton
                                      onClick={(event) => moveToAnchor(event, project)}
                                    >
                                      <BellIcon />
                                      {unviewedItems?.notifications ?? 0}
                                    </StyledMessagesButton>
                                    <StyledMessagesButton
                                      onClick={(event) => handleNavigateToOverdue(event, project)}
                                    >
                                      <AllertCircleIcon />
                                      {calcOverdueItemsCount(project)}
                                    </StyledMessagesButton>
                                  </ButtonGroup>
                                </Grid>
                              </Grid>
                            );
                          })
                        ) : (
                          <Typography sx={projectsButtonStyles.noProject}>
                            No projects found
                          </Typography>
                        )}
                      </Grid>

                      {userPermissions?.PROJECT?.canCreate && (
                        <Grid sx={projectsButtonStyles.newProjectContainer}>
                          <Button
                            sx={projectsButtonStyles.newProjectButton}
                            onClick={handleOpenModal}
                          >
                            <PlusIcon style={projectsButtonStyles.newProjectPlusIcon} />
                            Start new project
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <CreateProjectModal
        open={openModal}
        handleClose={handleCloseModal}
        isFirstProject={!isLoading && !projectsList?.meta?.totalItems}
      />
    </Grid>
  );
};

export default ProjectsButton;
