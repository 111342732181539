export const uploadedImagesStyles = {
  container: {
    maxHeight: '38px',
    gap: '8px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: '8px',
  },

  recentActivityItem: {
    maxWidth: '36px',
    maxHeight: '36px',
    objectFit: 'contain',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid #EDEFF7',
  },

  mediaItem: {
    maxWidth: '64px',
    maxHeight: '64px',
    objectFit: 'contain',
    borderRadius: '8px',
    cursor: 'pointer',
    border: '1px solid #F3F4F9',
  },

  bigImage: {
    objectFit: 'contain',
    borderRadius: '12px',
    cursor: 'pointer',
    border: '1px solid #EDEFF7',
  },

  bigImageContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
  },

  moreImagesContainer: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F4F9',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid #EDEFF7',
  },

  moreImages: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '150%',
    color: '#8C8F9D',
  },

  recentActivityImagesContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  bigImageBackground: {
    display: 'flex',
    width: 'calc((100% - 8px) / 2)',
    height: '100%',
    borderRadius: '12px',
    cursor: 'pointer',
    border: '1px solid #F3F4F9',
  },

  smallImageBackground: {
    display: 'flex',
    width: 'calc((100% - 8px) / 2)',
    height: '100%',
    borderRadius: '12px',
    cursor: 'pointer',
    border: '1px solid #F3F4F9',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },

  activityFeedImagesContainer: { display: 'flex', width: '100%', height: '100%', gap: '8px' },

  activityFeedImagesSmallContainer: {
    display: 'flex',
    width: 'calc((100% - 8px) / 2)',
    flexWrap: 'wrap',
    gap: '8px',
  },

  activityFeedLastImage: {
    display: 'flex',
    position: 'relative',
    width: 'calc((100% - 8px) / 2)',
    height: 'calc((100% - 8px) / 2)',
    borderRadius: '12px',
    cursor: 'pointer',
    border: '1px solid #F3F4F9',
    alignItems: 'center',
    justifyContent: 'center',
  },

  activityFeedLastImageTypo: {
    display: 'flex',
    position: 'absolute',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#F3F4F9',
  },

  activityFeedImageBackground: {
    display: 'flex',
    width: 'calc((100% - 8px) / 2)',
    height: 'calc((100% - 8px) / 2)',
    borderRadius: '12px',
    cursor: 'pointer',
    border: '1px solid #F3F4F9',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },

  newImagesContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    height: '64px',
  },

  newImageProgressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    border: '1px solid #F3F4F9',
    backgroundColor: '#EDEFF7',
    width: '100%',
    height: '100%',
  },

  newImageProgressTypo: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#23252F',
  },

  newImageDeleteButton: {
    position: 'absolute',
    top: '2px',
    left: '42px',
    padding: '0',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },

  audioContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#897979',
    borderRadius: '8px',
  },

  audioSmallContainer: {
    display: 'flex',
    position: 'relative',
    width: '36px',
    height: '36px',
    backgroundColor: '#F2F5FF',
    borderRadius: '4px',
  },

  videoAudioIconContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  duration: {
    position: 'absolute',
    bottom: '4px',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '146%',
    fontSize: '14px',
    color: '#fff',
  },

  durationSmall: {
    position: 'absolute',
    bottom: '2px',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '146%',
    fontSize: '8px',
    color: '#fff',
  },

  playIconBackgroundContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  playIconContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-40%, -40%)',
  },
};
