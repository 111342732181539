export const notificationButtonStyles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px #F3F4F9',
    borderRadius: '8px',
    maxHeight: '36px',
    '&:hover': {
      boxShadow: '0px 0px 2px #4D6EF5',
    },
  },
  buttonContainerOpened: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px #F3F4F9',
    borderRadius: '8px',
    maxHeight: '36px',
    boxShadow: '0px 0px 2px #4D6EF5',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'black',
    border: 'unset',
    padding: '8px 12px 8px 8px',
    minWidth: 'auto',
    maxHeight: '36px',

    '&:hover': {
      border: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '12px',
      marginLeft: '0px',

      '& svg, img': {
        width: '20px',
        height: '32px',
        borderRadius: '8px',
      },
    },
  },
  grow: { transformOrigin: 'left top' },
  buttonContent: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20.44px',
    alignItems: 'start',
    textAlign: 'right',
    color: '#23252F',
    maxWidth: '117px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'keep-all',
  },
  popper: {
    width: '450px',
    zIndex: 10,
  },
  paper: {
    width: '450px',
    marginTop: '8px',
    marginBottom: '8px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },
  paperContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  paperTitleContainer: {
    display: 'flex',
    padding: '20px',
    borderBottom: '1px solid #F3F4F9',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paperTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '148%',
  },
  paperItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '20px 0 24px',
    rowGap: '20px',
    width: '100%',
  },
  paperItemsContainerFixed: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '20px',
    marginBottom: '24px',
    rowGap: '20px',
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },
  paperItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    padding: '0 20px',
    columnGap: '6px',
  },

  itemContentContainer: { display: 'flex' },

  itemContent: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#424550',
    '& span': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px !important',
      lineHeight: '146%',
      color: '#23252F',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  redDotContainer: {
    minWidth: '15px',
    height: '20px',
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  redDot: {
    width: '6px',
    height: '6px',
    display: 'flex',
    backgroundColor: '#D12D36',
    borderRadius: '50%',
  },
  paperTitleWrapper: {
    display: 'flex',
  },
  seeMoreContainer: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    columnGap: '4px',
    alignItems: 'center',
  },
  seeMore: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#4D6EF5',
  },
  chevronIcon: {
    width: '20px',
    height: '20px',
    color: '#4D6EF5',
  },
  emptyStateContainer: {
    display: 'flex',
    width: '100%',
    padding: '150px 20px',
  },
  emptyStateWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '60px',
    alignItems: 'center',
  },
  emptyStateTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },
  emptyStateSubtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#424550',
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
};
