import React, { FC } from 'react';
import { FixedSizeList as List, ListChildComponentProps as ListProps } from 'react-window';
// mui components
import { Grid, Typography } from '@mui/material';
// styles
import { styles } from './styles';
import './styles.css';

interface IProps {
  data: string[][];
}

const CONTAINER_WIDTH = 870;

const getColumnWidth = (columnsNumber: number): number => {
  if (columnsNumber < 5) {
    return CONTAINER_WIDTH / columnsNumber;
  }

  return 200;
};

const getListWidth = (columnWidth: number, columnsNumber: number): number =>
  columnWidth * columnsNumber - 4;

const CSVTable: FC<IProps> = ({ data }) => {
  const columnWidth = getColumnWidth(data[0].length);
  const listWidth: number = getListWidth(columnWidth, data[0].length);

  const renderRow = ({ index, style }: ListProps) => {
    const item = data[index];

    return (
      <Grid
        sx={{ ...styles.row, borderBottom: index === data.length - 1 ? 'none' : '2px solid black' }}
        style={style}
      >
        {item.map((curItem, curItemIdx, arr) => {
          const width =
            curItemIdx === arr.length - 1 ? `${columnWidth - 10}px` : `${columnWidth}px`;
          const borderRight = curItemIdx === arr.length - 1 ? 'none' : '2px solid black';

          return (
            <Grid
              key={curItemIdx}
              sx={{
                ...styles.rowItem,
                borderRight,
                width,
                minWidth: width,
                maxWidth: width,
              }}
            >
              <Typography sx={styles.rowItemTypo}>{curItem}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <List
      height={550}
      width={listWidth}
      itemCount={data.length}
      itemSize={40}
      className="csv-list-container"
    >
      {renderRow}
    </List>
  );
};

export default CSVTable;
