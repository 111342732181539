export const styles = {
  container: {
    width: '100%',

    '& iframe': {
      maxWidth: '100%',
      width: '100%',
      height: 'calc(100vh - 200px)',
      outline: 'none',
      border: 'none',

      '& hover': {
        border: 'none',
        outline: 'none',
      },
    },
  },
};
