export const fontStyles = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
};

export const typoSM = {
  ...fontStyles,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '146%',
};

export const actionBtn = {
  ...typoSM,
  textTransform: 'capitalize',
  border: '1px solid #4D6EF5',
  borderRadius: '8px',
  height: 'unset',
  padding: '12px 16px',
  '&.Mui-disabled': {
    opacity: '0.7',
  },
};

export const styles = {
  actions: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px',
    '& .btn': {
      ...actionBtn,
      color: '#4D6EF5',
      backgroundColor: '#fff',
      '&:hover': {
        opacity: '0.8',
      },
    },
    '& .filled-btn': {
      color: '#fff',
      backgroundColor: '#4D6EF5',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#4D6EF5',
        opacity: '0.8',
      },
    },
  },
};
