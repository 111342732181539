import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// custom hooks
import { useRequest } from 'hooks/useRequest';
import { useProjectStepper } from 'hooks/useProjectStepperState';
// api
import api from 'utils/api';
import { useUpdateProjectTemplateMutation } from 'redux/api/projects';
// validations
import { SetScheduleSchema } from 'layouts/set-schedule-form/validation';
// types
import { ITemplate } from 'utils/types';

import SetScheduleNewUI from './new-ui';

const SetSchedule: React.FC = () => {
  const [updateProjectTemplate, { isLoading: loading }] = useUpdateProjectTemplateMutation();
  const { handleNext, projectId, handleMutate } = useProjectStepper();

  const form = useForm({
    resolver: yupResolver(SetScheduleSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: templates } = useRequest<{ items: ITemplate[] }>(
    '/templates',
    api.templates.getTemplatesList,
    {
      onSuccess: (data) => {
        form.setValue('templateId', data.items[0].id);
      },
    }
  );

  const onSubmit = useCallback(
    async (formValues) => {
      if (projectId) {
        const data = formValues.templateId === -1 ? { canBeEmpty: true } : formValues;

        updateProjectTemplate({
          projectId: projectId,
          ...data,
        })
          .unwrap()
          .then((projectData) => {
            handleMutate(projectData);
            if (projectData) window.dispatchEvent(new CustomEvent('project_updated'));

            handleNext();
          })
          .catch(() => {});
      }
    },
    [handleNext, projectId, updateProjectTemplate]
  );

  const props = {
    loading,
    templates,
    form,
    onSubmit,
  };

  return <SetScheduleNewUI {...props} />;
};

export default SetSchedule;
