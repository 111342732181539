import React, { FC, memo } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
// mui components
import { Grid } from '@mui/material';

interface IProps extends ReactPlayerProps {}

const DefaultWrapper: FC = ({ children }) => {
  return (
    <Grid
      sx={{
        width: '100%',
        height: 'auto',
        '& video': {
          borderRadius: '12px',
        },
      }}
    >
      {children}
    </Grid>
  );
};

const Player: FC<IProps> = ({ wrapper, ...props }) => {
  return <ReactPlayer wrapper={wrapper ?? DefaultWrapper} {...props} />;
};

export default memo(Player);
