import React, { useCallback, useEffect, useState, FC } from 'react';
import { useLocation } from 'react-router-dom';
// mui components
import { Grid } from '@mui/material';
// components
import CustomModal from '../components/custom-modal';
import FolderView from 'components/file-cabinet-folders/components/folder-view';
import { TModalProps } from 'components/file-cabinet-folders/types';
// api
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { fileCabinetSelector } from 'redux/selector';
import { handleSetBreadCrumbs } from 'redux/slices/file-cabinet-slice';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useGetMeQuery } from 'redux/api/auth';
import {
  useGetFileCabinetFolderDetailsQuery,
  useGetFileCabinetFolderItemsQuery,
  useGetFolderBreadCrumbsQuery,
  useGetProjectFileCabinetByIdQuery,
} from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type Props = TModalProps & {
  title: string;
  projectId?: string | number;
  projectFolderId?: string | number;
  fromSelectionAttachements?: boolean;
};

const ProjectFileCabinetModal: FC<Props> = ({
  open,
  title,
  projectId,
  projectFolderId,
  fromSelectionAttachements,
  handleClose,
}) => {
  const [isInit, setIsInit] = useState(false);
  const { isGridDefaultFolders, searchValue } = useAppSelector(fileCabinetSelector);
  const { hash } = useLocation();
  const dispatch = useAppDispatch();

  const { data: userMe } = useGetMeQuery();
  const { data: defaultFolders, isLoading } = useGetProjectFileCabinetByIdQuery({
    projectId: projectId,
  });

  const { data: foldersChildrenById, isLoading: foldersChildrenByIdLoading } =
    useGetFileCabinetFolderItemsQuery(
      {
        folderId: hash?.substring(1),
        projectId: projectId,
      },
      { skip: !hash }
    );

  const { data: folderById } = useGetFileCabinetFolderDetailsQuery(
    {
      folderId: hash?.substring(1),
      projectId: projectId,
    },
    { skip: !hash }
  );

  const { data: folderBreadCrumbs } = useGetFolderBreadCrumbsQuery(
    {
      itemId: hash?.substring(1),
      projectId: projectId,
    },
    { skip: !hash && !fromSelectionAttachements }
  );

  useEffect(() => {
    if (!!folderBreadCrumbs?.items?.length && fromSelectionAttachements && !isInit) {
      dispatch(handleSetBreadCrumbs(folderBreadCrumbs?.items));
      setIsInit(true);
    }
  }, [folderBreadCrumbs, fromSelectionAttachements, isInit]);

  const handleGetMyPermissions = useCallback(() => {
    if (!hash) {
      return null;
    }

    const foldersMyPermissions = folderById?.users?.find(({ id }) => {
      return String(id) === String(userMe?.id);
    });

    if (!foldersMyPermissions) {
      return null;
    }

    return foldersMyPermissions?.permissions;
  }, [folderById, hash]);

  const handleChechFolders = useCallback(() => {
    if (hash) {
      return foldersChildrenById?.items;
    }

    return defaultFolders?.items;
  }, [defaultFolders, foldersChildrenById, hash]);

  const foldersList = useCallback(() => {
    let folders = handleChechFolders();

    if (!!searchValue) {
      const searchResults = handleChechFolders()?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );

      folders = searchResults;
    }

    return folders;
  }, [searchValue, defaultFolders, foldersChildrenById, hash]);

  useEffect(() => {
    const clearBreadCrumbs = () => {
      localStorage.removeItem('fileCabinetBreadCrumbs');
    };

    window.addEventListener('beforeunload', clearBreadCrumbs);

    return () => {
      window.removeEventListener('beforeunload', clearBreadCrumbs);
    };
  }, []);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="File Cabinet"
      subtitle={title}
      withCloseIconBtn
      fullWidth
    >
      <Grid sx={styles.contentContainer}>
        <FolderView
          folders={foldersList()}
          isGrid={isGridDefaultFolders}
          loading={isLoading || foldersChildrenByIdLoading}
          className="in-modal"
          fromModal={true}
          backUrl={folderById?.parentId}
          projectFolderId={projectFolderId}
          projectId={projectId}
          containerStyles={styles.containerStyles}
          myPermissions={handleGetMyPermissions()}
        />
      </Grid>
    </CustomModal>
  );
};

export default ProjectFileCabinetModal;
