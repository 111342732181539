import React, { ReactNode } from 'react';
import { Dialog, Grid, IconButton, Typography } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/cabinet/delete-icon.svg';

import { styles } from '../../styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;
  children: ReactNode;
  dialogClassName?: string;
  withCloseIconBtn?: boolean;
  fullWidth?: boolean;
};

const CustomModal: React.FC<Props> = ({
  open,
  handleClose,
  title,
  subtitle,
  children,
  dialogClassName = '',
  withCloseIconBtn = false,
  fullWidth = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={styles.dialog}
      className={`${dialogClassName} ${fullWidth ? 'fullwidth' : ''}`}
    >
      <Grid sx={styles.dialogTitleWrapper} className={dialogClassName}>
        <Typography sx={styles.dialogTitle}>{title}</Typography>
        {subtitle && <Typography sx={styles.dialogSubtitle}>{subtitle}</Typography>}
        {withCloseIconBtn && (
          <IconButton onClick={handleClose} disableRipple sx={styles.iconBtn}>
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
      <Grid sx={styles.dialogContent} className={dialogClassName}>
        {children}
      </Grid>
    </Dialog>
  );
};

export default CustomModal;
