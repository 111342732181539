import { getDetails } from 'use-places-autocomplete';
// types
import { ICommonSelectValues } from 'utils/types';

interface IAddressComponent {
  types: string[];
}

export const useParseAddress = () => {
  const parseAddress = async (placeId: string, states: ICommonSelectValues[]) => {
    const details = await getDetails({
      placeId,
    });

    let latLng: { lat: number; lng: number } | undefined = undefined;
    let city = '';
    let zip = '';
    let state = '';
    let address = '';
    let stateAbbr = '';

    if (!!details && typeof details !== 'string') {
      if (details.geometry?.location) {
        latLng = { lat: details.geometry.location.lat(), lng: details.geometry.location.lng() };
      }

      const addressComponents = details.address_components;

      if (addressComponents) {
        let currentCity = addressComponents.find(({ types }: IAddressComponent) =>
          types.includes('locality')
        );
        if (!currentCity) {
          currentCity = addressComponents.find(({ types }: IAddressComponent) =>
            types.includes('sublocality')
          );
        }

        city = currentCity?.long_name || '';

        const zipCode = addressComponents.find(({ types }: IAddressComponent) =>
          types.includes('postal_code')
        );

        zip = zipCode?.short_name || '';

        const foundState = addressComponents.find(({ types }: IAddressComponent) =>
          types.includes('administrative_area_level_1')
        );

        if (foundState) {
          const currentState = states.find(
            ({ label }) => label === foundState.long_name || label === foundState.short_name
          );

          state = currentState?.value.toString() || '';

          stateAbbr = foundState.short_name;
        }

        address = details?.name ?? '';
      }
    }

    return { latLng, city, zip, state, address, stateAbbr };
  };

  return { parseAddress };
};
