import { IPostUploads, IUploadingFiles } from 'redux/api/activity-feed';

export const isAudio = (mimeType?: string) => {
  if (mimeType?.includes('audio')) {
    return true;
  }

  return false;
};

export const isVideo = (mimeType?: string) => {
  if (mimeType?.includes('video')) {
    return true;
  }

  return false;
};

export const isVideoOrAudio = (mimeType?: string) => {
  if (isVideo(mimeType) || isAudio(mimeType)) {
    return true;
  }

  return false;
};

export const getImageUrl = (item: IUploadingFiles) => {
  if (isVideoOrAudio(item?.mimeType)) {
    return item?.previewUrl;
  }

  return item?.url;
};

export const isUploadsFromPost = (data: any[]): data is IPostUploads[] => {
  return data?.some((item) => item.mimeType);
};

export const hasMimeType = (data: any): data is IPostUploads => {
  return data?.mimeType;
};
