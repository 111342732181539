import React, { createContext, useContext, useCallback, useMemo, useState, useEffect } from 'react';
// sections
import CreateProject from 'pages/project-setup/sections/create-project';
import SetSchedule from 'pages/project-setup/sections/set-schedule';
import AddUsers from 'pages/project-setup/sections/add-users';
// api
import { IProject } from 'redux/api/projects';

interface IProjectDetails {
  address: string;
  state: string;
  zip: string;
  city: string;
  name: string;
  country: string;
  secondAddress: string;
}

interface IAccountSetupStepperContextProps {
  activeStep: number;
  openModal: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  projectId: number | null;
  currentStep: JSX.Element;
  handleNext: (projId?: number) => void;
  resetForm: () => void;
  handleBack: () => void;
  updateProjectDetails: (props?: IProjectDetails) => void;
  handleCancel: (setOpenModal: React.Dispatch<React.SetStateAction<boolean>>) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  projectDetails?: IProjectDetails;
  handleMutate?: (props: IProject) => void;
  projectAdded?: IProject;
  resetProjectDetails: () => void;
  setRedirect: React.Dispatch<React.SetStateAction<boolean>>;
  redirect: boolean;
  firstStepSubmited: boolean;
}

const ProjectSetupStepperContext = createContext({} as IAccountSetupStepperContextProps);

export const useProjectStepper = () => useContext(ProjectSetupStepperContext);

export enum StepEnum {
  createProject = 0,
  setSchedule = 1,
  addUsers = 2,
}

export const ProjectStepperContextProvider: React.FC = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [firstStepSubmited, setFirstStepSubmited] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<IProjectDetails>();
  const [projectAdded, setProjectAdded] = useState<IProject>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const [completed, setCompleted] = useState<{
    [x: number]: boolean;
  }>({});

  const [projectId, setProjectId] = useState<number | null>(null);

  const handleCancel = () => {
    setOpenModal(false);
  };
  const currentStep = useMemo(() => {
    switch (activeStep) {
      case StepEnum.createProject:
        return <CreateProject />;
      case StepEnum.setSchedule:
        return <SetSchedule />;
      case StepEnum.addUsers:
        return (
          <AddUsers
            handleCancel={() => setOpenModal(false)}
            handleMutate={(proj) => setProjectAdded(proj)}
            redirect={redirect}
          />
        );
      default:
        return <CreateProject />;
    }
  }, [activeStep]);

  const isFirstStep = useMemo(() => activeStep === StepEnum.createProject, [activeStep]);

  const isLastStep = useMemo(() => activeStep === StepEnum.addUsers, [activeStep]);

  const handleNext = useCallback(
    (projId?: number) => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);

      setActiveStep((prevActiveStep) => {
        if (projId) {
          setProjectId(projId);
          setFirstStepSubmited(true);
        }

        if (prevActiveStep < StepEnum.addUsers) {
          window.scrollTo(0, 0);
          return prevActiveStep + 1;
        }

        return prevActiveStep;
      });
    },
    [activeStep, completed]
  );

  const resetForm = useCallback(() => {
    setActiveStep(0);
    setCompleted({});
    setFirstStepSubmited(false);
  }, []);

  const updateProjectDetails = (props?: IProjectDetails) => {
    setProjectDetails(props);
  };

  const handleMutate = (props: IProject) => {
    setProjectAdded(props);
  };

  const resetProjectDetails = () => {
    setProjectDetails({} as IProjectDetails);
  };

  return (
    <ProjectSetupStepperContext.Provider
      value={{
        projectId,
        activeStep,
        currentStep,
        isFirstStep,
        isLastStep,
        openModal,
        handleNext,
        setOpenModal,
        resetForm,
        handleBack,
        updateProjectDetails,
        projectDetails,
        handleMutate,
        handleCancel,
        projectAdded,
        resetProjectDetails,
        setRedirect,
        redirect,
        firstStepSubmited,
      }}
    >
      {children}
    </ProjectSetupStepperContext.Provider>
  );
};
