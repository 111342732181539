import React, { useCallback, FC } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui compoents
import { Grid } from '@mui/material';
// components
import ActionButton from 'components/buttons/index';
import CustomModal from '../components/custom-modal';
import OutlinedInput from 'components/inputs/outlined-input';
import SelectController from 'controllers/select-controller';
// hooks
import useToast from 'hooks/useToast';
// helpers
import { destinationFoldersOptions } from 'utils/helpers/fileCabinetHelpers';
// api
import {
  IFolder,
  FileCabinetTypeEnum,
  useGetDestinationFoldersQuery,
  useGetDestinationDefaultFoldersQuery,
  useMoveFolderOrFileMutation,
} from 'redux/api/file-cabinet';

// styles
import { styles } from './styles';

type Props = {
  folder?: IFolder;
  open: boolean;
  itemId: string | number;
  projectId: string | number;
  fromModal?: boolean;
  handleClose: () => void;
};

const ChangeFolderModal: FC<Props> = ({
  folder,
  open,
  itemId,
  projectId,
  fromModal,
  handleClose,
}) => {
  const showToast = useToast();

  const [handleMoveFolder, { isLoading }] = useMoveFolderOrFileMutation();
  const { data: destinationFolders, isLoading: isLoadingDestinationFolders } =
    useGetDestinationFoldersQuery(
      {
        projectId: projectId,
        excludeIds: [folder?.id],
      },
      { skip: !fromModal }
    );

  const { data: destinationDefaultFolders, isLoading: isLoadingDestinationDefaultFolders } =
    useGetDestinationDefaultFoldersQuery(
      {
        excludeIds: [folder?.parentId],
      },
      { skip: fromModal }
    );

  const form = useForm({
    resolver: yupResolver(
      yup.object({ parentId: yup.number().required('This is a required field') })
    ),
  });

  const checkDestinationFolders = useCallback(() => {
    if (fromModal) {
      return destinationFolders?.items;
    }

    return destinationDefaultFolders?.items;
  }, [destinationDefaultFolders, destinationFolders, fromModal]);

  const onSumbit = useCallback(
    (data) => {
      if (fromModal) {
        handleMoveFolder({ ...data, itemId: itemId, projectId: projectId })
          .unwrap()
          .then(() => {
            handleClose();
          })
          .catch(() => {
            showToast({
              message: 'Something went wrong!',
              type: 'error',
            });
          });
      }
    },
    [itemId]
  );

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Change Folder"
      subtitle="Select destination folder for the item"
    >
      <form onSubmit={form.handleSubmit(onSumbit)}>
        <FormProvider {...form}>
          <OutlinedInput
            label={folder?.type === FileCabinetTypeEnum.File ? 'File Name' : 'Folder Name'}
            value={folder?.name}
            disabled
          />

          <SelectController
            name="parentId"
            label="Destination Folder"
            placeholder="Destination Folder"
            disabled={isLoadingDestinationFolders || isLoadingDestinationDefaultFolders}
            options={destinationFoldersOptions(checkDestinationFolders())}
            paperStyles={{}}
            isSelect
            withError
          />

          <Grid sx={styles.actions}>
            <ActionButton
              loading={isLoading}
              variant="secondary"
              onClick={handleClose}
              label="Cancel"
            />

            <ActionButton loading={isLoading} variant="primary" type="submit" label="Change" />
          </Grid>
        </FormProvider>
      </form>
    </CustomModal>
  );
};

export default ChangeFolderModal;
