import React, { memo } from 'react';
// mui components
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as CheckBoxChekedDisabledIcon } from 'assets/icons/new/profile/checkbox-checked-disabled.svg';
import { ReactComponent as CheckBoxChekedIcon } from 'assets/icons/new/profile/checkbox-checked.svg';
import { ReactComponent as InterMediateCheckBoxIcon } from 'assets/icons/new/profile/intermediate-checkbox.svg';
import { ReactComponent as CheckBoxIcon } from 'assets/icons/new/profile/checkbox.svg';
import { ReactComponent as CheckBoxUnchekedDisabledIcon } from 'assets/icons/new/profile/checkbox-unchecked-disabled.svg';

const MaterialUISwitch = styled(MuiCheckbox)({
  width: 'unset',
  height: 'unset',
  padding: 0,
});

interface OwnProps extends CheckboxProps {}

const Ckeckbox: React.FC<OwnProps> = (props) => (
  <MaterialUISwitch
    {...props}
    indeterminateIcon={<InterMediateCheckBoxIcon style={{ width: '16px' }} />}
    icon={
      props.disabled ? (
        <CheckBoxUnchekedDisabledIcon style={{ width: '16px' }} />
      ) : (
        <CheckBoxIcon style={{ width: '16px' }} />
      )
    }
    checkedIcon={props.disabled ? <CheckBoxChekedDisabledIcon /> : <CheckBoxChekedIcon />}
  />
);

export default memo(Ckeckbox);
