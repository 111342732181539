import { typoM } from '../../styles';

const mainTypo = {
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '148%',
};

export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: '0 24px',

    '&.in-modal': {
      padding: 0,
    },

    '@media (max-width: 895px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  title: {
    ...mainTypo,
    color: '#23252f',
    textTransform: 'capitalize',

    '@media (max-width: 350px)': {
      width: '100%',
      maxWidth: '246px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      WebkitLineClamp: '1',
      'line-clamp': '1',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'keep-all',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    minWidth: '506px',

    '@media (max-width: 895px)': {
      width: '100%',
      justifyContent: 'space-between',
    },

    '@media (max-width: 600px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '100%',
    },
  },
  searchInputContainer: {
    width: '100%',
    maxWidth: '200px',

    '@media (max-width: 600px)': {
      maxWidth: 'calc(100vw - 88px)',
    },
  },

  inputAdornment: {
    margin: 0,
    marginRight: '8px',
    '& path': {
      stroke: '#8C8F9D',
    },
  },

  searchInput: {
    ...typoM,
    textAlign: 'start',
    '&.MuiOutlinedInput-root': {
      height: '36px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
    },
    '& fieldset': {
      border: 'none',
    },
  },

  btnsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',

    '@media (max-width: 600px)': {
      justifyContent: 'flex-start',
    },
  },

  viewBtns: {
    display: 'flex',
  },

  iconBtn: {
    boxSizing: 'border-box',
    padding: '7px 13px',
    border: '1px solid #f3f4f9',
    borderRadius: '0 8px 8px 0',
    borderLeft: 0,
    '&.left-rounded': {
      borderRadius: '8px 0 0 8px',
      borderLeft: '1px solid #f3f4f9',
      borderRight: 0,
    },
    '&.active': {
      background: '#E7ECFF',
      '&.left-rounded': {
        '& path': {
          stroke: '#4D6EF5',
          fill: 'none',
        },
      },
      '& path': {
        fill: '#4D6EF5',
      },
    },
    '&:hover': {
      background: '#F3F4F9',
    },
  },
  divider: {
    border: 0,
    borderLeft: '1px solid #f3f4f9',
    minHeight: '100%',
  },
  breadCrumbs: {
    display: 'flex',
    gap: '3px',
    maxWidth: 'calc(100% - 520px)',

    '@media (max-width: 895px)': {
      maxWidth: '100%',
    },
  },

  breadCrumb: {
    ...mainTypo,
    color: '#8C8F9D',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',

    '&.first': {
      color: '#4D6EF5',
      textDecoration: 'underline',
    },

    '&.last': {
      color: 'black',
    },
  },
};
