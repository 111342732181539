import React, { memo } from 'react';
// custom hooks
import { useProjectStepper } from 'hooks/useProjectStepperState';
// components
import StepperButtonsNewUI from './new-ui';

interface OwnProps {
  loading: boolean;
  formIsEmpty?: boolean;
  onSubmit?: () => void;
  handleClose: () => void;
}

const StepperButtons: React.FC<OwnProps> = ({
  loading,
  formIsEmpty = false,
  onSubmit,
  handleClose,
}) => {
  const { isFirstStep, isLastStep, handleBack } = useProjectStepper();

  const props = {
    loading,
    formIsEmpty,
    isFirstStep,
    isLastStep,
    onSubmit,
    handleBack,
    handleClose,
  };

  return <StepperButtonsNewUI {...props} />;
};

export default memo(StepperButtons);
