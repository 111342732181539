export const styles = {
  row: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
    height: '40px',
  },

  rowItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },

  rowItemTypo: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '146%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
