import React, { FC, memo } from 'react';
// components
import ViewImages from './components/view-images';
import ViewDocx from './components/view-docx';
import ViewCSV from './components/view-csv';
import ViewPDF from './components/view-pdf';
import NotSupportedFiles from './components/not-supported-files';
// types
import { IProps as ModalProps } from './';
import { FileExtensionEnum } from 'utils/types';

type TProps = Pick<ModalProps, 'url' | 'mimeType'>;

export const Content: FC<TProps> = memo(({ mimeType, url }) => {
  switch (mimeType) {
    case FileExtensionEnum.XLS:
    case FileExtensionEnum.XLSX:
    case FileExtensionEnum.PPT:
    case FileExtensionEnum.PPTX:
    case FileExtensionEnum.DOC:
    case FileExtensionEnum.DOCX:
      return <ViewDocx name="pdf" src={url} />;

    case FileExtensionEnum.JPG:
    case FileExtensionEnum.JPEG:
    case FileExtensionEnum.PNG:
    case FileExtensionEnum.GIF:
    case FileExtensionEnum.WEBP:
    case FileExtensionEnum.TIFF:
    case FileExtensionEnum.AVIF:
    case FileExtensionEnum.HEIC:
    case FileExtensionEnum.HEIF:
      return <ViewImages name="image" src={url} />;

    case FileExtensionEnum.PDF:
      return <ViewPDF name="pdf" src={url} />;

    case FileExtensionEnum.CSV:
      return <ViewCSV name="csv" src={url} />;

    default:
      return <NotSupportedFiles src={url} />;
  }
});
