import { AccountStepEnum } from 'redux/slices/account-stepper-slice';

export const authStepperStyles = (
  isProjectStepper?: boolean,
  activeStep?: number,
  isMobile?: boolean
) => {
  return {
    backgroundColor: isProjectStepper ? 'white' : '#F2F5FF',

    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      width: isProjectStepper ? '100%' : 'auto',
    },

    auth: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      padding: isProjectStepper ? '0px 0px 20px 0px' : isMobile ? '0' : '20px 0px',
      borderRadius: '12px',
      alignItems: isProjectStepper ? 'center' : 'unset',
    },

    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      padding: '16px 0',
    },

    logOutContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px 40px',
      background: 'transparent',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      '@media (max-width: 470px)': {
        display: 'none',
      },
    },

    icon: {
      '& svg': {
        width: '127px',
        height: '28px',
      },
    },

    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding:
        isMobile && activeStep === AccountStepEnum.companyProfile
          ? '40px 20px 80px 20px'
          : isMobile
          ? '40px 20px 136px 20px'
          : isProjectStepper
          ? '40px 40px 0px 40px'
          : '32px 40px',
      borderRadius: isMobile ? '0px' : '12px',
      backgroundColor: '#fff',
      maxWidth: isProjectStepper ? '' : '560px !important',
      marginInline: 'auto',
      height: isProjectStepper
        ? 'auto'
        : activeStep === AccountStepEnum.companyProfile && isMobile
        ? '100vh'
        : activeStep === AccountStepEnum.companyProfile
        ? 'calc(100vh - 220px)'
        : activeStep === AccountStepEnum.myProfile && isMobile
        ? '100vh'
        : 'calc(100vh - 250px)',
      minWidth: isProjectStepper ? '100%' : '',
      maxHeight: activeStep === AccountStepEnum.companyProfile ? '837px' : 'unset',
    },
  };
};
