import React, { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import { Link, Grid, Typography, IconButton } from '@mui/material';

import ConfirmModal from 'components/confirm-modal';
import { getRowIcon } from 'utils/helpers/fileCabinetHelpers';

import { ReactComponent as PDFIcon } from 'assets/icons/cabinet/pdf-icon.svg';
import { ReactComponent as CSVIcon } from 'assets/icons/cabinet/csv-icon.svg';
import { ReactComponent as DOCIcon } from 'assets/icons/cabinet/doc-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/cabinet/delete-icon.svg';
import { ReactComponent as FileErrorIcon } from 'assets/icons/cabinet/filer-error-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/cabinet/download-icon.svg';
import { ReactComponent as BinIcon } from 'assets/icons/cabinet/bin-icon.svg';

import { styles } from './styles';

type F = {
  id: number;
  name: string;
  size: string;
  type: string;
  path: string;
  uploadedBy: { id: number; name: string };
  uploadedAt: string;
}; // to be deleted

type Props = {
  file?: F;
  uploadedFile?: File;
  rejectedFile?: FileRejection;
  handleDeleteFile?: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
  handleDeleteUploadedFile?: (name: string) => void;
  handleDeleteRejectedFile?: (name: string) => void;
};

const getFileIcon = (type: string) => {
  return type === 'application/pdf' ? <PDFIcon /> : type === 'text/csv' ? <CSVIcon /> : <DOCIcon />;
};

const FileCard: React.FC<Props> = ({
  uploadedFile,
  file,
  rejectedFile,
  handleDeleteFile,
  handleDeleteRejectedFile,
  handleDeleteUploadedFile,
}) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { pathname } = useLocation();

  const Icon = getRowIcon('file', uploadedFile?.type);

  const handleOpenDelete = (val: boolean) => setIsOpenDelete(val);

  return (
    <>
      {uploadedFile && (
        <Grid sx={styles.fileContainer}>
          <Icon />
          <Grid sx={styles.fileNameWrapper}>
            <Typography sx={styles.fileName}>{uploadedFile.name}</Typography>
            <Typography sx={styles.fileSize}>{uploadedFile.size}</Typography>
          </Grid>
          <IconButton
            sx={styles.iconBtn}
            disableRipple
            onClick={() => handleDeleteUploadedFile(uploadedFile.name)}
          >
            {pathname.includes('selections') ? <BinIcon /> : <DeleteIcon />}
          </IconButton>
        </Grid>
      )}
      {rejectedFile && (
        <Grid sx={styles.fileContainer} className="isWarning">
          <FileErrorIcon />
          <Grid sx={styles.fileNameWrapper}>
            <Typography sx={styles.fileName}>{rejectedFile.file.name}</Typography>
            <Typography sx={styles.fileSize} className="warning-text">
              {rejectedFile.errors[0].message}
            </Typography>
          </Grid>
          <IconButton
            sx={styles.iconBtn}
            disableRipple
            onClick={() => handleDeleteRejectedFile(rejectedFile.file.name)}
          >
            {pathname.includes('selections') ? <BinIcon /> : <DeleteIcon />}
          </IconButton>
        </Grid>
      )}
      {file && (
        <Grid key={file.id} sx={styles.fileContainer}>
          {getFileIcon(file.type)}
          <Grid sx={styles.fileNameWrapper}>
            <Typography sx={styles.fileName}>{file.name}</Typography>
            <Typography sx={styles.fileSize}>{file.size}</Typography>
          </Grid>
          <Grid sx={styles.iconButtons}>
            <Link href={file.path} sx={styles.iconBtn} download>
              <DownloadIcon />
            </Link>
            <IconButton sx={styles.iconBtn} disableRipple onClick={() => handleOpenDelete(true)}>
              <BinIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <ConfirmModal
        open={isOpenDelete}
        onConfirm={() => handleOpenDelete(false)}
        onClose={() => handleOpenDelete(false)}
        title="Delete Item"
        text="Are you sure you want to delete this file?"
        confirmText="Delete"
      />
    </>
  );
};

export default FileCard;
