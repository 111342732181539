// mui components
import { Grid } from '@mui/material';
// components
import ActionButton from 'components/buttons';
// custom hooks
import { useProjectStepper } from 'hooks/useProjectStepperState';
// icons
import { ReactComponent as LeftIcon } from 'assets/icons/chevron-left_new.svg';

const StepperButtonsNewUI = ({
  loading,
  formIsEmpty,
  isFirstStep,
  isLastStep,
  onSubmit,
  handleBack,
}) => {
  const { setOpenModal, openModal } = useProjectStepper();

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" mt={3}>
      <Grid item>
        {!isFirstStep && (
          <ActionButton
            variant="tertiary"
            size="large"
            label="Back"
            onClick={handleBack}
            startIcon={<LeftIcon />}
            className="back-btn min-w-auto"
          />
        )}
      </Grid>
      <Grid item sx={{ display: 'flex', gap: '12px' }}>
        <>
          {/* {isLastStep && (
            <>
              <ActionButton variant="tertiary" size="large" label="Skip" onClick={handleCancel} />
            </>
          )} */}
          {isFirstStep && (
            <ActionButton variant="secondary" label="Cancel" type="button" onClick={handleCancel} />
          )}
          <ActionButton
            loading={loading}
            type={!!onSubmit ? 'button' : 'submit'}
            variant="primary"
            size="large"
            onClick={onSubmit}
            label={isFirstStep ? 'Next' : isLastStep ? 'Save' : 'Next'}
          />
        </>
      </Grid>
    </Grid>
  );
};

export default StepperButtonsNewUI;
