import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuth {
  isAuth: boolean;
  companyId: string | null;
  isDeploying: boolean;
  isInactive: null | boolean;
}

const initialState: IAuth = {
  isAuth: !!localStorage.getItem('Authorization'),
  companyId: localStorage.getItem('company-id') ?? null,
  isDeploying: false,
  isInactive: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    handleSetCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload;
    },
    handleDeleteCompanyId: (state) => {
      state.companyId = null;
    },
    handlSetIsDeployig: (state) => {
      state.isDeploying = true;
    },
    handleSetInactive: (state) => {
      state.isInactive = true;
    },
    handleSetInactiveInitial: (state) => {
      state.isInactive = null;
    },
  },
});

export const {
  handleAuth,
  handleSetInactive,
  handleSetInactiveInitial,
  handleSetCompanyId,
  handleDeleteCompanyId,
  handlSetIsDeployig,
} = authSlice.actions;

export default authSlice.reducer;
