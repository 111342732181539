import { typoM, typoSm } from '../../styles';

export const styles = {
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    border: '1px solid #EDEFF7',
    borderRadius: '8px',
    textDecoration: 'none',
    '&.isWarning': {
      borderColor: '#D12D36',
    },
    '& svg': {
      minWidth: '20px',
      height: '20px',
    },
  },
  fileNameWrapper: {
    width: '100%',
  },
  fileName: {
    width: '100%',
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...typoM,
    fontWeight: 500,
  },
  fileSize: {
    ...typoSm,
    color: '#6E707A',
    '&.warning-text': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  iconButtons: {
    display: 'flex',
    gap: '16px',
  },
  iconBtn: {
    padding: 0,
  },
};
