// @ts-nocheck

import hmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';

export const dashlyAuth = async (userId: string, email: string, name: string, phone: string) => {
  const dashlyAuthorized = sessionStorage.getItem('dashly-auth');

  if (!dashlyAuthorized) {
    const hmacDigest = Hex.stringify(hmacSHA256(userId, process.env.REACT_APP_DASHLY_AUTH_KEY));
    try {
      window?.dashly?.auth(userId, hmacDigest);
      window?.dashly?.identify([
        { op: 'update_or_create', key: '$email', value: `${email}` },
        { op: 'update_or_create', key: '$name', value: `${name}` },
        { op: 'update_or_create', key: '$phone', value: `${phone}` },
      ]);
      sessionStorage.setItem('dashly-auth', 'true');
    } catch {}
  }
};
