import React, { FC } from 'react';
// mui components
import { Grid } from '@mui/material';
// styles
import { styles } from './styles';

type TViewImagesProps = {
  src: string;
  name: string;
};

const ViewImages: FC<TViewImagesProps> = ({ src, name }) => {
  return (
    <Grid sx={styles.container}>
      <img src={src} alt={name} />
    </Grid>
  );
};

export default ViewImages;
