export const styles = {
  avatarGroup: {
    '& .MuiAvatar-root': {
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      background: '#EDEFF7',
      color: '#8C8F9D',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '146%',
    },
    '&.small': {
      width: 'fit-content',
      '& .MuiAvatar-root': {
        width: '24px',
        height: '24px',
        fontSize: '12px',
      },
    },
  },
  tooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: 'white',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '4px 8px',
      borderRadius: '8px',
      boxShadow: '1px 2px 2px 0px rgba(35, 37, 47, 0.04), 0px 4px 10px 0px rgba(35, 37, 47, 0.10)',
      gap: '6px',

      '&.MuiTooltip-tooltipPlacementTop': {
        marginBottom: '4px',
      },
    },

    '& .tooltip-content': {
      width: 'auto',
      maxWidth: '230px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '6px',
      flexWrap: 'wrap',
    },
  },

  rolesCount: {
    maxWidth: '64px',
    padding: '4px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    background: '#EDEFF7',
    color: '#8C8F9D',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    cursor: 'pointer',

    '&:hover > .roles-tooltip': {
      display: 'flex',
    },
  },

  rolesCountCard: {
    padding: '4px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    background: '#EDEFF7',
    color: '#8C8F9D',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    cursor: 'pointer',
    position: 'absolute',
    right: '24px',
    bottom: '38px',

    '&:hover > .roles-tooltip': {
      display: 'flex',
    },
  },

  rolesTooltip: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '4px 8px',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '1px 2px 2px 0px rgba(35, 37, 47, 0.04), 0px 4px 10px 0px rgba(35, 37, 47, 0.10)',
    position: 'absolute',
    left: '60px',
    gap: '6px',
    transition: '300ms',
  },

  role: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#E7ECFF',
    padding: '4px 6px',
    color: '#4D6EF5',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
  },

  popper: {
    width: '340px',
    zIndex: 10,
  },

  paper: {
    width: '340px',
    marginTop: '20px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },

  paperContentContainer: {
    widht: '100%',
    padding: '14px 8px 0 8px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  companiesListContainer: {
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  popover: {
    '& .MuiPaper-root': {
      boxShadow: '1px 2px 2px 0px rgba(35, 37, 47, 0.04), 0px 4px 10px 0px rgba(35, 37, 47, 0.10)',
      borderRadius: '8px',
      width: '298px',
    },

    '&.MuiPopover-root': {
      '& .MuiPopover-paper': {
        padding: '8px !important',
      },
    },
  },

  popperContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid #F3F4F9',
    background: 'white',
    height: '100%',
    overflow: 'hidden',
  },

  tableRow: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '2.5fr 1fr 1fr 1fr',
    gap: '8px',
    borderBottom: '1px solid #F3F4F9',
    padding: '11px 12px',
  },

  tableRoleHeader: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
  },

  tableRoleContent: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#505159',
    display: 'flex',
    justifyContent: 'center',
    alignItes: 'center',
  },

  tableRoleName: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#8C8F9D',
  },

  rolesEditContainer: {},
};
