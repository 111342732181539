import { FC, useState } from 'react';
// mui components
import { Grid } from '@mui/material';
// components
import FileViewerModal, {
  IProps as FileViewerProps,
} from 'components/modal/file-viewer-modal/index';
// custom hooks
import { useModalState } from 'hooks/useModalState';
// styles
import { uploadedFilesStyles as styles } from '../../uploaded-files/styles/styles';

export const FileWrapper: FC<Pick<FileViewerProps, 'url' | 'mimeType'>> = ({
  children,
  ...fileViewerProps
}) => {
  const [isOpen, handleOpen, handleClose] = useModalState();

  return (
    <Grid onClick={handleOpen} sx={styles.wrapperContainer}>
      {children}

      <FileViewerModal open={isOpen} handleClose={handleClose} {...fileViewerProps} />
    </Grid>
  );
};
