import { FormProvider } from 'react-hook-form';
// layouts
import SetScheduleForm from 'layouts/set-schedule-form';
// components
import StepperButtons from '../../../components/stepper-buttons';
import StyledForm from 'components/styled-form';

const SetScheduleNewUI = ({ loading, templates, form, onSubmit }) => {
  return (
    <FormProvider {...form}>
      <StyledForm sx={{ width: '100%' }} onSubmit={form.handleSubmit(onSubmit)}>
        <SetScheduleForm isFirstProject templates={templates?.items} />
        <StepperButtons loading={loading} />
      </StyledForm>
    </FormProvider>
  );
};

export default SetScheduleNewUI;
