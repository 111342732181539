import React from 'react';
// components
import CustomModal from '../components/custom-modal';
import ManagePermissionsUsersContent from './components/users';
import ManagePermissionsRolesContent from './components/roles';
// types
import { TModalProps } from 'components/file-cabinet-folders/types';
import { IFolder } from 'redux/api/file-cabinet';

type Props = TModalProps & {
  folder?: IFolder;
  fromModal: boolean;
  projectId: string | number;
};

const ManagePermissionsModal: React.FC<Props> = ({
  folder,
  open,
  fromModal,
  projectId,
  handleClose,
}) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Permissions"
      subtitle="Manage the list of users with access to the Inspections folder."
    >
      {fromModal ? (
        <ManagePermissionsUsersContent
          folder={folder}
          projectId={projectId}
          handleClose={handleClose}
        />
      ) : (
        <ManagePermissionsRolesContent folder={folder} handleClose={handleClose} />
      )}
    </CustomModal>
  );
};

export default ManagePermissionsModal;
