export const capitalizeFirstLetter = (str: string, withoutDash?: boolean) => {
  const transformetString = str?.toLowerCase();

  return transformetString
    ?.split(/[ -]+/)
    .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    .join('-');
};

export const capitalizeWordsFirstLetter = (str: string) => {
  let newWord = str;

  if (str === 'Owner') {
    newWord = 'Account Owner';
  }

  const transformetString = newWord?.toLowerCase();

  return transformetString
    ?.split(/[ -_]+/)
    .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    .join(' ');
};
