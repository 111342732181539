import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// components
import Header from 'components/header';
// custom hooks;
import { useAppSelector } from 'redux/hooks/useAppSelector';
// api
import { IUserMe } from 'redux/types';
// selectors
import { authSelector } from 'redux/selector';

interface IProps {
  userMe?: IUserMe;
}

const PAGES_WITH_HEADER = [
  'feature-requests',
  'current-roadmap',
  'help-center',
  'updates',
  '/account-dashboard',
  '/project-dashboard',
  '/schedule',
  '/profile',
  '/default-settings',
  '/users',
  '/billing',
  '/company-details',
  '/without-plan',
  '/selections',
  '/selection-templates',
  '/selection-categories',
  '/feedbacks',
  '/gallery',
  '/file-cabinet',
  '/project-settings',
  '/schedule-template',
];

const AppHeader: FC<IProps> = ({ userMe }) => {
  const location = useLocation();

  const { isDeploying } = useAppSelector(authSelector);

  const hasSubscription = userMe?.roles?.find(
    (company) => company.isOwner || company.isSubscription
  );

  const showHeader = useMemo(
    () =>
      (PAGES_WITH_HEADER.some((page) => location.pathname.includes(page)) &&
        userMe &&
        hasSubscription &&
        !isDeploying) ||
      location.pathname.includes('/guest-gallery'),
    [location.pathname, userMe, hasSubscription, isDeploying]
  );

  if (showHeader) {
    return <Header />;
  }

  return null;
};

export default AppHeader;
