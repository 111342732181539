import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// components
import { ActionsContext } from '../folder-view';
import FileViewerModal from 'components/modal/file-viewer-modal';
// modals
import CreateOrEditItemModal from 'components/file-cabinet-folders/modals/create-or-edit-item';
import ManagePermissionsModal from 'components/file-cabinet-folders/modals/manage-permissions-modal';
import ChangeFolderModal from 'components/file-cabinet-folders/modals/change-folder-modal';
import DeleteFileItemModal from 'components/file-cabinet-folders/modals/delete-file-item-modal';
// api
import {
  FileCabinetTypeEnum,
  IFolder,
  useGetShowCabinetFileURLQuery,
  useGetShowPublicCabinetFileURLQuery,
} from 'redux/api/file-cabinet';

type Props = {
  folder?: IFolder;
  fromModal: boolean;
  projectFolderId?: string | number;
  projectId?: string | number;
  type: string;
  publicFolders: boolean;
};

const ActionsModals: React.FC<Props> = ({
  type,
  folder,
  fromModal,
  projectFolderId,
  projectId,
  publicFolders,
}) => {
  const {
    editingItemId,
    managingPermissionsItemId,
    changingFolderItemId,
    deletingItemId,
    imageId,
    handleChangeEditingItemId,
    handleChangeManagingPermissionsItemId,
    handleChangeChangingFolderItemId,
    handleChangeDeletigItemId,
    handleShowImage,
  } = useContext(ActionsContext);

  const id = folder?.id || null;

  const location = useLocation();

  const { data: privateData } = useGetShowCabinetFileURLQuery(
    { fileId: id, projectId: projectId },
    { skip: location.pathname.includes('public-file-cabinet') || imageId !== id }
  );
  const { data: publicFileData } = useGetShowPublicCabinetFileURLQuery(
    { accessKey: folder?.accessKey },
    { skip: !location.pathname.includes('public-file-cabinet') || imageId !== id }
  );

  const fileUrl = useMemo(() => {
    if (publicFolders) {
      return publicFileData?.url;
    }

    return privateData?.url;
  }, [publicFolders, publicFileData?.url, privateData?.url]);

  return (
    <>
      {id === editingItemId && (
        <CreateOrEditItemModal
          open={!!editingItemId}
          handleClose={() => handleChangeEditingItemId(null)}
          title={type === FileCabinetTypeEnum.File ? 'Edit File' : 'Edit Folder'}
          subtitle={`Enter new name for the ${
            type === FileCabinetTypeEnum.File ? 'file' : 'folder'
          }`}
          isEdit={true}
          defValue={folder.name}
          itemId={editingItemId}
          projectFolderId={projectFolderId}
          fromModal={fromModal}
          projectId={projectId}
        />
      )}

      {id === managingPermissionsItemId && (
        <ManagePermissionsModal
          open={!!managingPermissionsItemId}
          handleClose={() => handleChangeManagingPermissionsItemId(null)}
          folder={folder}
          fromModal={fromModal}
          projectId={projectId}
        />
      )}

      {id === deletingItemId && (
        <DeleteFileItemModal
          open={!!deletingItemId}
          onClose={() => handleChangeDeletigItemId(null)}
          title={`Delete ${folder?.type === FileCabinetTypeEnum.File ? 'File' : 'Folder'}`}
          isFolder={folder?.type === FileCabinetTypeEnum.File ? false : true}
          itemId={deletingItemId}
          fromModal={fromModal}
          projectId={projectId}
        />
      )}

      {id === changingFolderItemId && (
        <ChangeFolderModal
          open={!!changingFolderItemId}
          handleClose={() => handleChangeChangingFolderItemId(null)}
          folder={folder}
          itemId={changingFolderItemId}
          projectId={projectId}
          fromModal={fromModal}
        />
      )}

      {imageId === id ? (
        <FileViewerModal
          open={!!imageId}
          handleClose={() => handleShowImage(null)}
          mimeType={folder?.mimeType}
          url={fileUrl}
        />
      ) : null}
    </>
  );
};

export default ActionsModals;
