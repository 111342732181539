import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import ActionButton from 'components/buttons';
import DialogModal from './modal/dialog-modal';
interface IProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  text: unknown;
  textNode?: JSX.Element;
  confirmText?: string;
  confirmBtnColor?: LoadingButtonProps['color'];
  cancelBtnColor?: LoadingButtonProps['color'];
  confirmBtnWidth?: string;
  isLoading?: boolean;
}

const ConfirmModal: FC<IProps> = ({
  open,
  onClose,
  title,
  text,
  onConfirm,
  isLoading,
  textNode,
  confirmText = 'Delete',
  confirmBtnColor = 'error',
  cancelBtnColor = 'primary',
  confirmBtnWidth,
}) => {
  return (
    <DialogModal open={open} maxWidth="466px" withCloseButton={false} handleClose={onClose}>
      <Grid container sx={{ mb: '10px' }} alignItems="center">
        <Typography
          component="span"
          sx={{
            fontSize: '24px',
            lineHeight: '20px',
            fontWeight: 400,
            color: '#23252F',
            fontFamily: 'Inter, sans-serif !important',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Typography>
      </Grid>
      {textNode ? (
        textNode
      ) : (
        <Typography
          sx={{
            //margin: '30px 0',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
            color: '#6E707A',
          }}
        >
          {text}
        </Typography>
      )}
      <Grid container justifyContent="flex-end" sx={{ mt: '20px' }} gap={2}>
        <ActionButton variant="secondary" onClick={onClose} label="Cancel"></ActionButton>
        <ActionButton onClick={onConfirm} loading={isLoading} label={confirmText}></ActionButton>
      </Grid>
    </DialogModal>
  );
};
export default ConfirmModal;
