import { api } from 'redux/api';

import { IGeolocation } from './';

export const geolocationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGeolocation: build.query<IGeolocation, void>({
      query: () => ({
        url: 'https://ipapi.co/json/',
        params: {
          key: process.env.REACT_APP_IPAPI_KEY,
        },
        headers: {},
      }),
    }),
  }),
});

export const { useGetGeolocationQuery, useLazyGetGeolocationQuery } = geolocationApi;
