// mui components
import { Grid } from '@mui/material';
// components
import StepButtons from '../components/step-buttons/step-buttons';
import ActionButton from 'components/buttons';
// layouts
import AuthLayout from 'layouts/auth-layout';
// api
import { useGetMeQuery } from 'redux/api/auth';
import { useLogOut } from 'hooks/useLogOut';
import { fromMobileSelector } from 'redux/selector';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// icons
import { ReactComponent as LogoDark } from 'assets/icons/logo_dark.svg';
// styles
import { authStepperStyles } from '../styles';

const AuthStepperNewUI = ({
  isProjectStepper,
  activeStep,
  currentStep,
  steps,
  CustomStepIcon,
  StyledConnector,
}) => {
  const { data: userMe } = useGetMeQuery();
  const { onLogOut: logOut } = useLogOut();
  const { isMobile } = useAppSelector(fromMobileSelector);

  const signOutHandler = () => {
    logOut();
  };

  return (
    <AuthLayout
      backgroundColor={authStepperStyles(isProjectStepper)?.backgroundColor}
      isProjectStepper={isProjectStepper}
    >
      <Grid sx={authStepperStyles(isProjectStepper)?.container}>
        <Grid sx={authStepperStyles(isProjectStepper, false, isMobile)?.auth}>
          {isProjectStepper && (
            <StepButtons
              customStepIcon={CustomStepIcon}
              steps={steps}
              StyledConnector={StyledConnector}
              activeStep={activeStep}
              isProjectStepper={isProjectStepper}
            />
          )}

          {!isProjectStepper && !isMobile && (
            <Grid sx={authStepperStyles(isProjectStepper)?.iconContainer}>
              <Grid sx={authStepperStyles(isProjectStepper)?.icon}>
                <LogoDark />
              </Grid>
            </Grid>
          )}

          {!isProjectStepper && !userMe?.isActive && !isMobile && (
            <Grid sx={authStepperStyles(isProjectStepper)?.logOutContainer}>
              <Grid sx={authStepperStyles(isProjectStepper)?.icon}>
                <ActionButton type="button" label="Sign Out" onClick={signOutHandler} />
              </Grid>
            </Grid>
          )}

          <Grid
            className={isProjectStepper ? '' : 'on-boarding-flow'}
            sx={authStepperStyles(isProjectStepper, activeStep, isMobile)?.stepContainer}
          >
            {currentStep}
          </Grid>

          {!isProjectStepper && !isMobile && (
            <StepButtons
              customStepIcon={CustomStepIcon}
              steps={steps}
              StyledConnector={StyledConnector}
              activeStep={activeStep}
              isProjectStepper={isProjectStepper}
            />
          )}
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default AuthStepperNewUI;
