import stringToColor from 'string-to-color';
import { differenceInCalendarDays } from 'date-fns';
// mui components
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Button,
  Typography,
  Grid,
} from '@mui/material';
// components
import GradientButton from './gradient';
import StyledMenuItem from '../../components/menu-item';
// icons
import { ReactComponent as ChevronDownLightIcon } from 'assets/icons/chevron-down-light.svg';
import { ReactComponent as BillingIcon } from 'assets/icons/Billing_new_icon.svg';
import { ReactComponent as CompanyDetailsIcon } from 'assets/icons/new/profile/company-details.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/Log_out_icon.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/buttons/arrow-left-rec.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/Users_new_icon.svg';
import { ReactComponent as ProfileNewIcon } from 'assets/icons/Account_new_icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/new/profile/default-settings.svg';
// hooks
import { useUserInfo } from 'hooks/useUserInfo';
// types
import { PaymentPlanType } from 'utils/types';
// styles
import { selectPlanBoxStyles } from './styles';

const PopperButtonNewUI = ({
  isShowBillingInfo,
  userPermissions,
  companyProfile,
  currentPaymentPlan,
  anchorRef,
  open,
  handleToggle,
  handleClose,
  getImageBySize,
  handleListKeyDown,
  navigate,
  handleLogOut,
  openSelectPaymentPlanModal,
  handleOpenSuperAdminPage,
  isOwner,
}) => {
  const { currentRole } = useUserInfo();
  const trialDays = differenceInCalendarDays(
    new Date(currentPaymentPlan?.trialEndDate),
    new Date(companyProfile?.createdAt)
  );
  const passedDays = differenceInCalendarDays(new Date(), new Date(companyProfile?.createdAt));
  const daysLeft = trialDays - passedDays;

  const showPlan =
    (isOwner && currentPaymentPlan?.type === PaymentPlanType.billingPlanTrial) ||
    (isOwner &&
      currentPaymentPlan?.type !== PaymentPlanType.billingPlanTrial &&
      differenceInCalendarDays(new Date(currentPaymentPlan?.trialEndDate), new Date()) > 0);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        id="composition-button-avatar-button"
        aria-controls={open ? 'composition-menu-avatar-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ChevronDownLightIcon />}
        startIcon={
          companyProfile && companyProfile.avatar ? (
            <img src={getImageBySize(companyProfile.avatar, 40, 40)} alt="avatar" />
          ) : (
            <Grid
              component="span"
              sx={{
                backgroundColor: stringToColor(
                  `${companyProfile?.firstName[0]}${companyProfile?.lastName[0]}`
                ),
              }}
            >
              {`${companyProfile?.firstName[0]}${companyProfile?.lastName[0]}`}
            </Grid>
          )
        }
        sx={{
          justifyContent: 'space-between',
          marginLeft: '24px',
          textTransform: 'unset',
          color: '#fff',
          border: 'unset',
          padding: '0px',
          minWidth: 'auto',
          '&:hover': {
            border: 'unset',
          },
          '& .MuiButton-endIcon': {
            // marginLeft: '14px',
            '& svg': {
              width: '14px',
              display: 'none',
            },
          },

          '@media(max-width: 1240px)': {
            marginLeft: '6px',
          },

          '& .MuiButton-startIcon': {
            marginRight: '0px',
            marginLeft: '0px',

            '& span': {
              padding: '5px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              width: '40px',
              height: '40px',
            },

            '& svg, img': {
              width: '40px',
              height: '40px',
              borderRadius: '8px',
            },
          },
        }}
      >
        {/* {`${companyProfile?.firstName} ${companyProfile?.lastName}`}
        {!!isSuperAdminLogged && (
          <>
            <br />
            (logged as super admin)
          </>
        )} */}
      </Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        sx={{
          zIndex: 10,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}
          >
            <Paper
              sx={{
                width: '260px',
                '& .MuiList-root': {
                  padding: 0,
                },
                borderRadius: '12px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu-avatar-button"
                  aria-labelledby="composition-button-avatar-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ borderRadius: '12px', padding: '2px !important' }}
                >
                  {showPlan && (
                    <StyledMenuItem disableRipple sx={selectPlanBoxStyles.planBox}>
                      <Grid container sx={selectPlanBoxStyles.innerBox}>
                        <Grid>
                          <Typography sx={selectPlanBoxStyles.planName}>
                            {currentPaymentPlan?.type === 'BILLING_PLAN'
                              ? currentPaymentPlan?.name
                              : currentPaymentPlan?.promoCodeType
                              ? 'First Project Free'
                              : 'Free Trial'}
                          </Typography>
                          {currentPaymentPlan?.type === 'BILLING_PLAN' &&
                          differenceInCalendarDays(
                            new Date(currentPaymentPlan?.trialEndDate),
                            new Date()
                          ) > 0 ? (
                            <Typography sx={selectPlanBoxStyles.daysLeftText}>
                              {daysLeft} {daysLeft === 1 ? 'day' : 'days'} left
                            </Typography>
                          ) : null}
                          {currentPaymentPlan?.type !== 'BILLING_PLAN' ? (
                            differenceInCalendarDays(
                              new Date(currentPaymentPlan?.trialEndDate),
                              new Date()
                            ) > 0 || currentPaymentPlan?.promoCodeType ? (
                              currentPaymentPlan?.promoCodeType ? (
                                <Typography sx={selectPlanBoxStyles.daysLeftText}>
                                  {currentPaymentPlan?.activeProjectsAmount === 0 ? '1' : '0'}
                                  {currentPaymentPlan?.activeProjectsAmount === 0
                                    ? ' project '
                                    : ' projects '}
                                  left
                                </Typography>
                              ) : (
                                <Typography sx={selectPlanBoxStyles.daysLeftText}>
                                  {daysLeft} {daysLeft === 1 ? 'day' : 'days'} left
                                </Typography>
                              )
                            ) : (
                              <Typography sx={selectPlanBoxStyles.expiredText}>
                                {currentPaymentPlan?.type === PaymentPlanType.billingPlanTrial &&
                                differenceInCalendarDays(
                                  new Date(currentPaymentPlan?.trialEndDate),
                                  new Date()
                                ) < 0
                                  ? 'Trial expired.'
                                  : ''}
                              </Typography>
                            )
                          ) : null}
                        </Grid>
                        {currentPaymentPlan?.type === PaymentPlanType.billingPlanTrial && (
                          <Grid>
                            <GradientButton onClick={openSelectPaymentPlanModal}>
                              <Typography sx={selectPlanBoxStyles.selectPlanButton}>
                                Select Plan
                              </Typography>
                            </GradientButton>
                          </Grid>
                        )}
                      </Grid>
                    </StyledMenuItem>
                  )}
                  <StyledMenuItem
                    onClick={(event) => {
                      handleClose(event);
                      navigate('/profile');
                    }}
                  >
                    <ProfileNewIcon />
                    Account Profile
                  </StyledMenuItem>
                  {userPermissions?.COMPANY_MANAGEMENT?.canRead && currentRole?.isSubscription && (
                    <StyledMenuItem
                      onClick={(event) => {
                        handleClose(event);
                        navigate('/company-details');
                      }}
                    >
                      <CompanyDetailsIcon />
                      Company Details
                    </StyledMenuItem>
                  )}
                  {(userPermissions?.FILE_CABINET_DEFAULT_FOLDERS?.canRead ||
                    userPermissions?.SCHEDULE_TEMPLATES?.canRead ||
                    userPermissions?.WORK_DAYS?.canRead ||
                    userPermissions?.COST_CODE?.canRead ||
                    userPermissions?.SELECTION_MANAGE?.canRead) &&
                    !currentRole?.isSubscription === false && (
                      <StyledMenuItem
                        onClick={(event) => {
                          handleClose(event);
                          navigate('/default-settings');
                        }}
                      >
                        <SettingsIcon />
                        Default Settings
                      </StyledMenuItem>
                    )}
                  {userPermissions?.USER_MANAGEMENT?.canUpdate && currentRole?.isSubscription && (
                    <StyledMenuItem
                      onClick={(event) => {
                        handleClose(event);
                        navigate('/users');
                      }}
                    >
                      <UsersIcon />
                      Users & Roles
                    </StyledMenuItem>
                  )}
                  {(isShowBillingInfo || isOwner) && (
                    <StyledMenuItem
                      onClick={(event) => {
                        if (currentPaymentPlan?.type === PaymentPlanType.billingPlanTrial) {
                          openSelectPaymentPlanModal();
                          handleClose(event);
                        } else {
                          handleClose(event);
                          navigate('/billing');
                        }
                      }}
                    >
                      <BillingIcon />
                      Billing
                    </StyledMenuItem>
                  )}

                  {!!companyProfile?.isSuperAdmin && (
                    <StyledMenuItem onClick={handleOpenSuperAdminPage}>
                      <ArrowLeftIcon />
                      Super Admin Area
                    </StyledMenuItem>
                  )}

                  <StyledMenuItem onClick={handleLogOut} sx={{ borderRadius: '12px' }}>
                    <LogOutIcon />
                    Sign Out
                  </StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default PopperButtonNewUI;
