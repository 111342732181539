import React, { MouseEvent } from 'react';
import { formatISO, addDays } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import upperFirst from 'lodash.upperfirst';
// types
import { IPhase, IPhaseItem, PhaseStatusesEnum } from 'redux/types';
import { RawDraftContentState } from 'draft-js';
import { postDateHelper } from './postDateHelper';
import { IActivityFeedProfile, IMention } from 'redux/api/activity-feed';
import { IProjectProfiles } from 'redux/api/projects';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getCurrentDate = (date, projectTz?: string) => {
  if (!date || !projectTz) {
    return null;
  }

  return dayjs.tz(date, 'UTC').format('MMM DD, YYYY');
};

export const getDateNumericValue = (date: string) => {
  const startOfDay = dayjs.tz(date, 'UTC').format('YYYY-MM-DD') + 'T00:00:00.000Z';

  return dayjs(startOfDay).valueOf();
};

export const weekDayNumbers: number[] = [1, 2, 3, 4, 5, 6, 0];

export const calculateBusinessDays = (
  startDate: string,
  endDate: string,
  workingDays: number[]
) => {
  if (!startDate || !endDate) {
    return -1;
  }

  const numericStartDate = getDateNumericValue(startDate);
  const numericEndDate = getDateNumericValue(endDate);

  var fromDay = new Date(numericStartDate);
  var toDay = new Date(numericEndDate);
  var numOfWorkingDays = 1;

  fromDay.setHours(0, 0, 0, 0);
  toDay.setHours(0, 0, 0, 0);

  const workDaysFn = (day: number) => {
    if (workingDays?.some((item) => day === item)) {
      numOfWorkingDays = numOfWorkingDays + 1;
    }
  };

  while (fromDay < toDay) {
    fromDay.setDate(fromDay.getDate() + 1);
    var day = fromDay.getDay();

    workDaysFn(day);
  }

  return numOfWorkingDays;
};

export const calculatePhaseItemProgress = (item: IPhaseItem | IPhase, workingDays?: number[]) => {
  const workDays = calculateBusinessDays(item?.startDate, item?.endDate, workingDays);

  const pastDays = item?.startDate
    ? calculateBusinessDays(item?.startDate, postDateHelper(), workingDays) - 1
    : workDays;

  const overdue = item?.startDate
    ? calculateBusinessDays(item?.endDate, postDateHelper(), workingDays) - 1
    : workDays;

  if (item?.status === PhaseStatusesEnum.COMPLETED) {
    return 100;
  } else if (overdue > 0) {
    return 101;
  } else if (pastDays <= 0 || workDays <= 0) {
    return 0;
  } else {
    return Math.round((pastDays / workDays) * 100);
  }
};

export const getInitials = (text: string) =>
  text
    .split(' ')
    .slice(0, 2)
    .map((i) => i.charAt(0).toUpperCase())
    .join('');

export const makeStringUpperFirst = (text: string) => {
  let formattedText = text.toLowerCase();

  const firstLevelCheck = formattedText.split(' ');

  for (let i = 0; i < firstLevelCheck.length; i++) {
    firstLevelCheck[i] = firstLevelCheck[i][0].toUpperCase() + firstLevelCheck[i].substring(1);
  }

  formattedText = firstLevelCheck.join(' ');

  const secondLevelCheck = formattedText.split('-');

  for (let i = 0; i < secondLevelCheck.length; i++) {
    secondLevelCheck[i] = secondLevelCheck[i][0].toUpperCase() + secondLevelCheck[i].substring(1);
  }

  formattedText = secondLevelCheck.join('-');

  const thirdLevelCheck = formattedText.split('/');

  for (let i = 0; i < thirdLevelCheck.length; i++) {
    thirdLevelCheck[i] = thirdLevelCheck[i][0].toUpperCase() + thirdLevelCheck[i].substring(1);
  }

  formattedText = thirdLevelCheck.join('/');

  const fourthLevelCheck = formattedText.split('(');

  for (let i = 0; i < fourthLevelCheck.length; i++) {
    fourthLevelCheck[i] = fourthLevelCheck[i][0].toUpperCase() + fourthLevelCheck[i].substring(1);
  }

  formattedText = fourthLevelCheck.join('(');

  return formattedText;
};

export const transformZonedTimeToUtc = (date: Date | string, tz?: string) =>
  zonedTimeToUtc(
    formatISO(new Date(date)),
    tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  ).toISOString();

export const transformUtcToZonedTime = (date: string, tz?: string) =>
  utcToZonedTime(date, tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone);

export const getImageBySize = (
  url: string | object,
  w: number,
  h: number,
  fitIn = true,
  temp = false
) => {
  if (typeof url === 'string') {
    const urlPathnameSplitted = new URL(url).pathname.split('/');
    const sliceIndex = urlPathnameSplitted.findIndex((str) =>
      str.includes('builderpad-backend-infra')
    );
    const lastPart = urlPathnameSplitted.slice(sliceIndex + 1).join('/');

    return `${process.env.REACT_APP_CLOUD_FRONT}${fitIn ? '/fit-in' : ''}/${Math.ceil(
      w
    )}x${Math.ceil(h)}/${lastPart}`;
  }
};

export const addDay = (date: string, days: number) => {
  return addDays(new Date(date), days);
};

export const weekDays = [0, 1, 2, 3, 4, 5, 6];

export const toggleClassByTagName = (tagName: string, className: string) => {
  const element = document.getElementsByTagName(tagName)[0];
  if (element) {
    if (element.classList.contains(className)) element.classList.remove(className);
    else element.classList.add(className);
  }
};

export const getTimeSpend = (date: string) =>
  date
    .split(' ')
    .map((item, index) => {
      if (index === 1) {
        return item[0];
      }
      return item;
    })
    .join('')
    .concat(' ago');

export const convertBytes = (bytes: number, decimals = 1) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(decimals)} ${sizes[i]}`;
};

export const convertHtml = (htmlString: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  return Array.from(tempDiv.children).map((item) => item.textContent);
};

export const createContent = (content: string, mentions: IMention[]) => {
  return convertHtml(content)
    .map((item, index) => {
      const currentBlockMentions = mentions.filter(({ block }) => block === index);
      if (!currentBlockMentions.length) {
        return `<p>${item}</p>`;
      }
      const itemsArr = item.match(/./gu);
      let indexChange = 0;
      currentBlockMentions.forEach(({ offset, length }) => {
        const newOffset = offset - indexChange;

        const value = `<span>${itemsArr.slice(newOffset + 1, newOffset + length).join('')}</span>`;

        itemsArr.splice(newOffset, length, value);

        indexChange += length - 1;
      });

      return `<p>${itemsArr.join('')}</p>`;
    })
    .join('');
};

export const createDraftContent = (
  mentions: IMention[],
  profiles: IProjectProfiles[],
  blocksContent: string[]
) => {
  const entityMap = Object.fromEntries(
    mentions.map(({ profileId }, index) => {
      const user = profiles.find(({ id }) => id === profileId);
      if (!user) {
        return [index.toString(), {}];
      }
      const { avatar, email, id, firstName, lastName } = user;
      return [
        index.toString(),
        {
          type: 'mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              id,
              avatar,
              email,
              firstName,
              lastName,
              name: `${firstName} ${lastName}`,
            },
          },
        },
      ];
    })
  ) as RawDraftContentState['entityMap'];

  const blocks = blocksContent.map((text, index) => ({
    key: uuidv4(),
    text,
    type: 'unstyled',
    data: {},
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: mentions
      .filter((item) => item.block === index)
      .map(({ offset, length, key }) => ({ offset, length, key })),
  }));

  return { blocks, entityMap };
};

export const createMentionsArray = (contentState: RawDraftContentState) =>
  contentState.blocks
    .map(({ entityRanges }, block) =>
      entityRanges.map(({ offset, length, key }) => {
        const profileId = contentState.entityMap[key.toString()].data?.mention?.id;
        if (typeof profileId !== 'number') {
          return;
        }
        return {
          offset,
          length,
          key,
          profileId,
          block,
        };
      })
    )
    .flat()
    .filter((item) => item)
    .map((mention, idx) => ({
      ...mention,
      key: idx,
    }));

export const createMentionToContentHelper = (
  profile: IActivityFeedProfile
): { textContent: string; mentionEntity: IMention } => {
  const textContent = `@${profile.firstName} ${profile.lastName} `;
  const mentionEntity = {
    key: 0,
    block: 0,
    offset: 0,
    length: textContent.length - 1,
    profileId: profile.id,
  };
  return { textContent, mentionEntity };
};

export const isContentEmpty = (content: string) => content === '<p></p>';

export const convertHour = (convertTo: 'utc' | 'locale', hour: number, tz: string | number) => {
  const offset =
    typeof tz === 'string'
      ? getTimezoneOffset(tz, new Date()) / (60 * 60 * 1000)
      : Math.round(tz / 60);

  const convertedHour = convertTo === 'locale' ? hour + offset : hour - offset;

  return convertedHour >= 24
    ? convertedHour - 24
    : convertedHour < 0
    ? convertedHour + 24
    : convertedHour;
};

export const getUpperFirstWithoutUnderline = (str: string) =>
  upperFirst(str.split('_').join(' ').toLowerCase());

export const stopPropagation = (e: MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

export const callHandlerWithoutPropagation =
  <T extends any[] = any[], R = any>(cb: (...args: T) => R, args?: T) =>
  (event: MouseEvent<HTMLElement>) => {
    stopPropagation(event);

    cb(...(args ?? ([] as T)));
  };

export const convertCamelCaseToTitleCase = (input: string) =>
  input.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

export const handleCheckProjectEndDate = (phases: IPhase[]) => {
  if (phases?.length === 0) {
    return null;
  }

  let maxEndDateObject = phases?.[0];

  for (let i = 1; i < phases?.length; i++) {
    const currentObject = phases?.[i];
    if (currentObject?.endDate > maxEndDateObject.endDate) {
      maxEndDateObject = currentObject;
    }
  }

  return maxEndDateObject?.endDate;
};

export const getCompanyId = (): number | null => {
  const companyId = localStorage.getItem('company-id');
  return companyId ? Number(companyId) : null;
};

export const getCapitalizedString = (str: string) => upperFirst(str.toLowerCase());
