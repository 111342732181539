import React from 'react';
import CustomModal from '../components/custom-modal';
import { Divider, Grid, Typography } from '@mui/material';

import { TModalProps } from 'components/file-cabinet-folders/types';
import ModalActions from '../components/actions';

import { styles } from './styles';

type Props = TModalProps & {
  currentCapacity: number;
  maxCapacity: number;
};

const StorageFullAlertModal: React.FC<Props> = ({
  open,
  handleClose,
  currentCapacity,
  maxCapacity,
}) => {
  const capacity = (currentCapacity / maxCapacity) * 100;

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Storage is full"
      subtitle="To upload more files, you need to increase the storage capacity. Contact the project administrator to increase the storage capacity."
      dialogClassName="storage-modal"
    >
      <Grid>
        <Grid sx={styles.contentWrapper}>
          <Typography
            sx={styles.text}
          >{`${currentCapacity} GB of ${maxCapacity} GB is used`}</Typography>
          <Grid sx={styles.progressBar}>
            <Grid
              sx={{ ...styles.progress, width: `${capacity}%` }}
              className={capacity < 71 ? 'isBlue' : capacity < 91 ? 'isYellow' : 'isRed'}
            />
          </Grid>
        </Grid>
        <Divider sx={styles.divider} />
        <Grid sx={styles.contentWrapper}>
          <ModalActions
            label="Got it"
            handleClose={handleClose}
            saveBtnType="button"
            handleSave={handleClose}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default StorageFullAlertModal;
