import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
// api
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
// custom hooks
import { useUserInfo } from 'hooks/useUserInfo';

const Redirect: FC = () => {
  const { currentRole } = useUserInfo();
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();

  return (
    <Navigate replace to={currentRole && userPermissions ? '/account-dashboard' : '/sign-in'} />
  );
};

export default Redirect;
