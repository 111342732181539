import { Grid, Link } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { styles } from './styles';

const AuthButtons = () => {
  const { pathname } = useLocation();

  return (
    <Grid sx={styles.container}>
      <Link sx={styles.link} component={RouterLink} to={`/sign-in?redirectPath=${pathname}`}>
        log in
      </Link>
      <Link
        sx={styles.link}
        className="filled"
        component={RouterLink}
        to={`/sign-up?redirectPath=${pathname}`}
      >
        sign up
      </Link>
    </Grid>
  );
};

export default AuthButtons;
