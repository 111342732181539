const typo = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '146%',
  color: '#23252F',
};

export const styles = {
  tableRow: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr 1fr 0.8fr',
    gap: '8px',
    borderBottom: '1px solid #F3F4F9',
    padding: '0 0 0 26px',
  },

  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      cursor: 'pointer',
    },
  },

  checkboxContainerBulk: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: '1px solid #F3F4F9',

    '& svg': {
      cursor: 'pointer',
    },
  },

  checkboxContainerOwner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    opacity: '0.5',

    '& svg': {
      cursor: 'pointer',
    },
  },

  tableUserInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    color: '#8C8F9D',
    padding: '20px 0',
  },

  avatar: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    background: '#EDEFF7',
    ...typo,
    color: '#8C8F9D',
    fontWeight: 500,
    marginRight: '8px',
  },

  nameContainer: {
    displaay: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '6px',
  },

  name: {
    ...typo,
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '4px',
  },

  tooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#23252F',
      borderRadius: '8px',
      padding: '4px 8px',
      marginBottom: '4px !important',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: '10px',
      textTransform: 'capitalize',
    },
  },

  role: {
    maxWidth: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    background: '#E7ECFF',
    padding: '2px 4px',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '126%',
    color: '#23252F',
    textAlign: 'start',
    display: 'inline-flex',
  },
};
