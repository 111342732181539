import React, { FC } from 'react';
// mui components
import { Grid, TableContainer, Table } from '@mui/material';
import { TableHead, TableRow, TableBody, TableCell, Tooltip } from '@mui/material';
// components
import Row from './Row';
import EmptyList from '../../empty-list';
// api
import { FileCabinetUserPermission, IFolder } from 'redux/api/file-cabinet';
// utils
import { fileCabinetTablColumns } from 'utils/fileCabinet.constants';
// icons
import { ReactComponent as AlertCircleIcon } from 'assets/icons/cabinet/alert-circle.svg';
// styles
import { styles } from './styles';

type Props = {
  folders?: IFolder[];
  className?: string;
  backUrl?: string | number;
  fromModal: boolean;
  projectFolderId?: string | number;
  projectId?: string | number;
  publicFolders?: boolean;
  myPermissions: FileCabinetUserPermission;
};

const FolderListView: FC<Props> = ({
  folders,
  className = '',
  fromModal,
  projectFolderId,
  projectId,
  publicFolders,
  myPermissions,
}) => {
  return (
    <Grid sx={styles.container} className={className}>
      {!folders?.length ? (
        !publicFolders && (
          <EmptyList
            fromModal={fromModal}
            projectFolderId={projectFolderId}
            projectId={projectId}
            myPermissions={myPermissions}
          />
        )
      ) : (
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {fileCabinetTablColumns(publicFolders)?.map((col) => (
                  <TableCell key={col.id} sx={styles.tableHeadItem}>
                    {!!col?.tooltip ? (
                      <Grid sx={styles.tableCellWithTooltip}>
                        {col.name}
                        {!!col?.tooltip && (
                          <Tooltip
                            componentsProps={{ tooltip: { sx: styles.tooltip } }}
                            placement="right"
                            title={<Grid>Applied to users added to each project</Grid>}
                          >
                            <AlertCircleIcon />
                          </Tooltip>
                        )}
                      </Grid>
                    ) : (
                      col?.name
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {folders?.map((fold) => {
                return (
                  <Row
                    key={fold?.id}
                    folder={fold}
                    fromModal={fromModal}
                    projectFolderId={projectFolderId}
                    type={fold?.type}
                    projectId={projectId}
                    publicFolders={publicFolders}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default FolderListView;
