export const styles = {
  container: {
    width: '100%',

    '& img': {
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
};
