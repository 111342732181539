import React, { useState, useCallback } from 'react';
import { FileRejection } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
// mui components
import { Grid, Button } from '@mui/material';
// components
import FileUpload from 'components/file-cabinet-folders/components/file-upload';
import CustomModal from '../components/custom-modal';
import StorageFullAlertModal from '../storage-full-alert-modal';
import SelectController from 'controllers/select-controller';
import FileCard from 'components/file-cabinet-folders/components/file-card';
import { TModalProps } from 'components/file-cabinet-folders/types';
// hooks
import useToast from 'hooks/useToast';
import { destinationFoldersOptions } from 'utils/helpers/fileCabinetHelpers';
// api
import {
  useGetDestinationFoldersQuery,
  useLazyGetFileCabinetUploadURlQuery,
  usePostFileToAWSMutation,
  useUploadFileCabinetFileMutation,
} from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type UploadFileProps = TModalProps & {
  fromModal?: boolean;
  projectFolderId?: string | number;
  projectId: string | number;
};

const UploadFileModal: React.FC<UploadFileProps> = ({
  open,
  fromModal,
  projectFolderId,
  projectId,
  handleClose,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [isOpenStorageFull, setIsOpenStorageFull] = useState(false);

  const { folderId } = useParams();
  const { hash } = useLocation();
  const showToast = useToast();

  const [postFileToCabinet] = useUploadFileCabinetFileMutation();
  const [getUploadUrl] = useLazyGetFileCabinetUploadURlQuery();
  const [postFileToAWS] = usePostFileToAWSMutation();
  const { data: folders, isLoading: isLoading } = useGetDestinationFoldersQuery({
    projectId: projectId,
  });

  const form = useForm({});
  const { handleSubmit, clearErrors, reset } = form;

  const handleOpenStorageModal = (val: boolean) => setIsOpenStorageFull(val);

  const handleFileChange = (newFiles: File[]) => {
    setFiles((prev) => [...newFiles, ...prev]);
  };

  const handleRejectedFilesChange = (newFiles: FileRejection[]) =>
    setRejectedFiles((prev) => [...newFiles, ...prev]);

  const handleDeleteFile = (name: string) => {
    setFiles((prev) => prev.filter((item) => item.name !== name));
  };

  const closeModalHandler = useCallback(() => {
    handleClose();
    setFiles([]);
  }, []);

  const checkParentFolderId = useCallback(() => {
    if (!!folderId) {
      return folderId;
    }

    if (fromModal && !!hash) {
      return hash?.substring(1);
    }

    if (fromModal && !!projectFolderId) {
      return projectFolderId;
    }

    return 0;
  }, [folderId, hash, projectFolderId]);

  const handleCustomSubmit = useCallback(
    async (data) => {
      if (!!files?.length) {
        await Promise.all(
          files?.map(async (file) => {
            getUploadUrl({
              folderId: !!data?.folderId ? data?.folderId : checkParentFolderId(),
              key: file.name,
              mimeType: file?.type,
            })
              .unwrap()
              .then(async (AWSRes) => {
                const formData = new FormData();
                formData.append('file', file);
                await postFileToAWS({ URL: AWSRes?.url, file: file, fileType: file?.type })
                  .unwrap()
                  .then(() => {
                    postFileToCabinet({
                      folderId: !!data?.folderId ? data?.folderId : checkParentFolderId(),
                      fileId: AWSRes?.id,
                      projectId: projectId,
                    })
                      .unwrap()
                      .catch(() => {
                        showToast({ type: 'error', message: 'Something went wrong!' });
                      });
                  })
                  .catch(() => {
                    showToast({ type: 'error', message: 'Something went wrong!' });
                  });
              })
              .catch(() => {
                showToast({ type: 'error', message: 'Something went wrong!' });
              });
          })
        ).then(() => {
          handleClose();
          clearErrors();
          setFiles([]);
          reset();
        });
      }
    },
    [files]
  );

  return (
    <>
      <CustomModal
        open={open}
        handleClose={closeModalHandler}
        title="Upload File"
        subtitle="Here you can upload files to the folder"
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleCustomSubmit)}>
            <SelectController
              name="folderId"
              label="Folder"
              options={destinationFoldersOptions(folders?.items)}
              defaultValue={hash?.substring(1)}
              disabled={isLoading}
              isSelect
              paperStyles={{}}
              withError
            />
            <FileUpload
              files={files}
              rejectedFiles={rejectedFiles}
              handleFileChange={handleFileChange}
              handleRejectedFilesChange={handleRejectedFilesChange}
            />
            {files?.map((file) => (
              <FileCard
                key={file?.name}
                uploadedFile={file}
                handleDeleteUploadedFile={handleDeleteFile}
              />
            ))}
            {rejectedFiles?.map((item) => (
              <FileCard
                key={item?.file?.name}
                rejectedFile={item}
                handleDeleteUploadedFile={handleDeleteFile}
              />
            ))}

            <Grid sx={styles.actions}>
              <Button className="btn" onClick={closeModalHandler}>
                Cancel
              </Button>
              <Button className="btn filled-btn" type="submit" disabled={!files?.length}>
                Save
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </CustomModal>

      <StorageFullAlertModal
        open={isOpenStorageFull}
        handleClose={() => handleOpenStorageModal(false)}
        currentCapacity={4.8}
        maxCapacity={5}
      />
    </>
  );
};

export default UploadFileModal;
