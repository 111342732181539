import React, { FC } from 'react';
// mui components
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
// api
import { IPostUploads } from 'redux/api/activity-feed';
import { IStyles, IUploadedFilesData } from 'redux/types';
// helpers
import { getImageBySize } from 'utils/helpers';
import { hasMimeType, isAudio, isVideo, isVideoOrAudio } from '../helpers';
// icons
import { ReactComponent as AudioIcon } from 'assets/icons/audio-icon.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon-small.svg';
import { ReactComponent as PlayIconBackground } from 'assets/icons/play-icon-background-small.svg';
// styles
import { uploadedImagesStyles as styles } from '../styles/styles';

interface IProps {
  item: IPostUploads | IUploadedFilesData;
  index: number;
  visibleImagesCount: number;
  hiddenImagesCount: number;
  handleOpenModal: (idx: number) => void;
}

const StyledImage = styled('img')(() => styles.recentActivityItem as IStyles);

interface IVideoIconProps {
  onClick: () => void;
}

const VideoIcon: FC<IVideoIconProps> = ({ onClick }) => {
  return (
    <Grid onClick={onClick} sx={{ ...styles.playIconBackgroundContainer, cursor: 'pointer' }}>
      <PlayIconBackground />
      <Grid sx={{ ...styles.playIconContainer, transform: 'translate(-45%, -45%)' }}>
        <PlayIcon />
      </Grid>
    </Grid>
  );
};

const RecentActivityItem: FC<IProps> = ({
  item,
  hiddenImagesCount,
  visibleImagesCount,
  handleOpenModal,
  index,
}) => {
  const showCondition =
    index !== visibleImagesCount || (index === visibleImagesCount && hiddenImagesCount === 0);

  const onOpenModal = () => {
    handleOpenModal(index);
  };

  const renderVideoAudioIcon =
    hasMimeType(item) && isVideoOrAudio(item?.mimeType) ? (
      <Grid sx={styles.videoAudioIconContainer}>
        {isVideo(item?.mimeType) ? (
          <VideoIcon onClick={onOpenModal} />
        ) : (
          <AudioIcon height={24} width={24} />
        )}
      </Grid>
    ) : null;

  const renderBackground =
    hasMimeType(item) && isAudio(item?.mimeType) ? (
      <Grid sx={styles.audioSmallContainer}></Grid>
    ) : hasMimeType(item) && isVideo(item?.mimeType) ? (
      <StyledImage
        src={getImageBySize(item.options?.previewUrl, 36, 36, false)}
        alt={item.name}
        onClick={onOpenModal}
      />
    ) : (
      <StyledImage
        src={getImageBySize(item.url, 36, 36, false)}
        alt={item.name}
        onClick={onOpenModal}
      />
    );

  return (
    <Grid key={item.id} sx={styles.recentActivityImagesContainer}>
      {showCondition && renderBackground}
      {showCondition && renderVideoAudioIcon}
      {index === visibleImagesCount && hiddenImagesCount > 0 && (
        <Grid onClick={() => handleOpenModal(index)} sx={styles.moreImagesContainer}>
          <Typography sx={styles.moreImages}>{`+${hiddenImagesCount}`}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default RecentActivityItem;
