import React, { FC, memo } from 'react';
// mui components
import { CircularProgress, Grid } from '@mui/material';
// types
import { GridProps } from '@mui/material/Grid/Grid';

interface IProps {
  size?: number;
  width?: string | number;
  minWidth?: string;
  minHeight?: string;
}

const Spinner: FC<GridProps & IProps> = ({
  size = 70,
  width,
  minWidth,
  minHeight,
  ...gridProps
}) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    sx={{
      minHeight: minHeight ?? '100vh',
      maxWidth: `${width}px` ? width : '',
      width: '100%',
      minWidth: minWidth ?? 'unset',
    }}
    {...gridProps}
  >
    <CircularProgress size={size} />
  </Grid>
);

export default memo(Spinner);
