import React, { FC, useCallback } from 'react';
import useToast from 'hooks/useToast';
// mui components
import { Grid } from '@mui/material';
// components
import Checkbox from 'components/checkbox';
// types
import { TFileCabinetRole } from 'redux/api/file-cabinet';
// api
import {
  AccessOptionsEnum,
  useGetCompanyRoleFolderPermissionsQuery,
  useUpdateCompanyRoleFolderPermissionsMutation,
} from 'redux/api/company-roles';
// helpers
import { capitalizeFirstLetter } from 'utils/helpers/capitalizeFirstLetterHelper';
// styles
import { styles } from './styles';

type RolesRowProps = {
  role: TFileCabinetRole;
  index: number;
  folderId: number;
  roles: TFileCabinetRole[];
  permissionSubject: string;
};

const RolesRow: FC<RolesRowProps> = ({ index, role, roles, folderId, permissionSubject }) => {
  const showToast = useToast();

  const [updateFolderPermissions, { isLoading }] = useUpdateCompanyRoleFolderPermissionsMutation();
  const { data: userPermissions } = useGetCompanyRoleFolderPermissionsQuery();

  const accessChangeHandler = useCallback(
    (event, access: AccessOptionsEnum) => {
      const rolesGroup: TFileCabinetRole[] = roles.filter(({ id }) => id !== role?.id);
      const updatedRolesGroup: TFileCabinetRole[] = [
        ...rolesGroup,
        {
          ...role,
          permissions: {
            canAddUpdate:
              access === AccessOptionsEnum.CanAddUpdate
                ? event.target.checked
                : role.permissions.canAddUpdate,
            canDelete:
              access === AccessOptionsEnum.CanDelete
                ? event.target.checked
                : role.permissions.canDelete,
            canRead:
              access === AccessOptionsEnum.CanRead
                ? event.target.checked
                : role.permissions.canRead,
          },
        },
      ];

      if (userPermissions?.[permissionSubject]?.canUpdate) {
        updateFolderPermissions({
          itemId: folderId,
          roles: updatedRolesGroup,
          withOptimisticUpdate: true,
          roleId: role.id,
          access: access,
          value: event.target.checked,
        })
          .unwrap()
          .then(() => {
            showToast({
              message: 'Updated successfully!',
              type: 'success',
            });
          })
          .catch(() => {
            showToast({
              message: 'Something went wrong!',
              type: 'error',
            });
          });
      } else {
        showToast({
          message: 'Something went wrong!',
          type: 'error',
        });
      }
    },
    [role]
  );

  return (
    <Grid key={index} sx={styles.tableRow}>
      <Grid sx={styles.tableRoleName}>{capitalizeFirstLetter(role?.name) || '-'}</Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          name="canRead"
          disabled={!userPermissions?.[permissionSubject]?.canUpdate || isLoading}
          defaultChecked={role?.permissions?.canRead}
          value={role?.permissions?.canRead}
          onChange={(e) => {
            accessChangeHandler(e, AccessOptionsEnum.CanRead);
          }}
        />
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          name="canAddUpdate"
          disabled={!userPermissions?.[permissionSubject]?.canUpdate || isLoading}
          defaultChecked={role?.permissions?.canAddUpdate}
          value={role?.permissions?.canAddUpdate}
          onChange={(e) => {
            accessChangeHandler(e, AccessOptionsEnum.CanAddUpdate);
          }}
        />
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          name="canDelete"
          disabled={!userPermissions?.[permissionSubject]?.canUpdate || isLoading}
          defaultChecked={role?.permissions?.canDelete}
          value={role?.permissions?.canDelete}
          onChange={(e) => {
            accessChangeHandler(e, AccessOptionsEnum.CanDelete);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RolesRow;
