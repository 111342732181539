import { api } from 'redux/api';
import { IGetCountryRegionsArgs, IGetCountryResponse } from './';

export const rapidApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCountryRegions: build.query<IGetCountryResponse, IGetCountryRegionsArgs>({
      query: ({ country, ...queryArgs }) => ({
        url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries/${country}/regions`,
        params: queryArgs,
      }),
    }),
  }),
});

export const { useGetCountryRegionsQuery, useLazyGetCountryRegionsQuery } = rapidApi;
