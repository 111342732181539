import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBillingPlan } from 'redux/api/billing';

export interface IBilling {
  promoButton: 'unchecked' | 'checked';
  couponValidation: string;
  openWarningModal: boolean;
  openCancelDeactivationModal: boolean;
  deletedNotification: {
    isDeleted: boolean;
    ccNumNew: string;
    ccNumOld: string;
    cardTypeNew: string;
    cardTypeOld: string;
  };
  paymentPlan: {
    paymentPlanId: number | null;
    couponCode: string | null;
  };
  openUpgradeNotificationModal: boolean;
  subscribedPlanInfo: { plan: IBillingPlan; isSubscribed: boolean };
  promoCode: string | null;
  defaultPlanTab: 'monthly' | 'annually' | 'one_time';
}

const initialState: IBilling = {
  promoButton: 'unchecked',
  couponValidation: '',
  openWarningModal: false,
  openCancelDeactivationModal: false,
  subscribedPlanInfo: { plan: null, isSubscribed: false },
  openUpgradeNotificationModal: false,
  deletedNotification: {
    isDeleted: false,
    ccNumNew: '',
    ccNumOld: '',
    cardTypeNew: '',
    cardTypeOld: '',
  },
  paymentPlan: {
    paymentPlanId: null,
    couponCode: null,
  },
  promoCode: '',
  defaultPlanTab: 'monthly',
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setDeletedNotification: (state, action: PayloadAction<IBilling['deletedNotification']>) => {
      state.deletedNotification = action.payload;
    },
    setPaymentPlan: (
      state,
      action: PayloadAction<{
        paymentPlanId: number;
        couponCode: string;
      }>
    ) => {
      state.paymentPlan = action.payload;
    },
    setOpenUpgradeNotificationModal: (state, action: PayloadAction<boolean>) => {
      state.openUpgradeNotificationModal = action.payload;
    },
    setSubscribedPlanInfo: (
      state,
      action: PayloadAction<{ plan: IBillingPlan; isSubscribed: boolean }>
    ) => {
      state.subscribedPlanInfo = action.payload;
    },
    setOpenWarningModal: (state, action: PayloadAction<boolean>) => {
      state.openWarningModal = action.payload;
    },
    setOpenCancelDeactivationModal: (state, action: PayloadAction<boolean>) => {
      state.openCancelDeactivationModal = action.payload;
    },
    setPromoCode: (state, action: PayloadAction<string>) => {
      state.promoCode = action.payload;
      // state.paymentPlan.paymentPlanId = null;
      // state.paymentPlan.couponCode = null;
    },
    setPromoCodeValidation: (state, action) => {
      state.couponValidation = action.payload;
    },
    setPromoCodeButton: (state, action) => {
      state.promoButton = action.payload;
    },
    setDefaultPlanTab: (state, action: PayloadAction<IBilling['defaultPlanTab']>) => {
      state.defaultPlanTab = action.payload;
    },
  },
});

export const {
  setOpenCancelDeactivationModal,
  setOpenUpgradeNotificationModal,
  setPromoCodeValidation,
  setDeletedNotification,
  setSubscribedPlanInfo,
  setPromoCodeButton,
  setOpenWarningModal,
  setPaymentPlan,
  setPromoCode,
  setDefaultPlanTab,
} = billingSlice.actions;

export default billingSlice.reducer;
