import * as yup from 'yup';

export const CreateProjectSchema = yup.object({
  country: yup.string().required('Field is required'),
  address: yup.string().required('Field is required'),
  state: yup.string().required('Field is required'),
  type: yup.string().required('Field is required'),
  name: yup.string().required('Field is required').max(100, 'Max 100 characters'),
  city: yup.string().required('Field is required'),
  zip: yup.string().required('Field is required'),
});

export const CreateProjectSchemaNewUI = yup.object({
  country: yup.string().required('Field is required'),
  address: yup.string().required('Field is required'),
  state: yup.string().required('Field is required'),
  // type: yup.string().required('Field is required'),
  name: yup.string().required('Field is required').max(100, 'Max 100 characters'),
  city: yup.string().required('Field is required'),
  zip: yup.string().required('Field is required'),
});
