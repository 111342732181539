import React, { useMemo, useState, useCallback, FC } from 'react';
// mui components
import { Avatar, AvatarGroup, Grid } from '@mui/material';
// modals
import UsersModal from './components/users-modal';
// custom hooks
import { useSidePanel } from 'hooks/useSidePanel';
import { useGetAdaptiveProps } from 'hooks/useGetAdaptiveProps';
// api
import { IProjectProfiles, IProject } from 'redux/api/projects';
// utils
import { getImageBySize } from 'utils/helpers';
//styles
import { accountsButtonStyles } from '../styles/styles';

interface IAccountsButton {
  project: IProject;
}

const AccountsButton: FC<IAccountsButton> = ({ project }) => {
  const { setProjectId } = useSidePanel();
  const { usersCount } = useGetAdaptiveProps();

  const [selectedUser, setSelectedUser] = useState<IProjectProfiles[] | null>(null);

  const sortedProfiles = useMemo(() => {
    const profiles: IProjectProfiles[] = [];

    if (project) {
      project?.profiles?.forEach((profile) => {
        if (profile.role === 'OWNER') {
          profiles.unshift(profile);
        } else {
          profiles.push(profile);
        }
      });
    }

    return profiles;
  }, [project]);

  const handleCloseAvatarModal = useCallback(() => {
    setSelectedUser(null);
  }, []);

  if (!project) {
    return null;
  }

  return (
    <Grid
      item
      sx={{
        ...accountsButtonStyles.accounts,
        marginRight: window.location.pathname.includes('account-dashboard') ? '8px' : '0',
      }}
    >
      <AvatarGroup max={6} spacing={10} sx={accountsButtonStyles.accountsContainer}>
        {sortedProfiles.slice(0, usersCount).map((user) => {
          if (user.avatar) {
            return (
              <Avatar
                key={user.userId}
                alt="avatar"
                src={getImageBySize(user.avatar, 100, 100)}
                sx={accountsButtonStyles.avatar}
                onClick={() => {
                  setProjectId(project.id);
                  setSelectedUser([user]);
                }}
              />
            );
          } else {
            const abbreviation = `${user.firstName[0]}${user.lastName[0]}`;

            return (
              <Avatar
                key={user.userId}
                alt="avatar abbreviation"
                sx={accountsButtonStyles.avatarAbbreviation}
                onClick={() => {
                  setProjectId(project.id);
                  setSelectedUser([user]);
                }}
              >
                {abbreviation}
              </Avatar>
            );
          }
        })}
      </AvatarGroup>
      {project?.profiles?.length > usersCount && (
        <Avatar
          sx={accountsButtonStyles.otherAvatarsCount}
          onClick={() => {
            setProjectId(project.id);
            setSelectedUser(project.profiles);
          }}
        >
          {`+${project.profiles.length - usersCount}`}
        </Avatar>
      )}

      {!!selectedUser ? (
        <UsersModal
          profiles={selectedUser}
          open={!!selectedUser}
          closeModal={handleCloseAvatarModal}
          type={selectedUser?.length && selectedUser.length > 1 ? 'multiple users' : 'single user'}
        />
      ) : null}
    </Grid>
  );
};

export default AccountsButton;
