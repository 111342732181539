import React, { useCallback, useState, useRef } from 'react';
// mui components
import { Grid, Tooltip, Popover } from '@mui/material';
// components
import RolesRow from './RolesRow';
// helpers
import { capitalizeFirstLetter } from 'utils/helpers/capitalizeFirstLetterHelper';
// types
import { IFolder, TFileCabinetRole } from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type Props = {
  roles: TFileCabinetRole[];
  fromCard?: boolean;
  folder: IFolder;
};

const CustomRolesGroup: React.FC<Props> = ({ fromCard, folder }) => {
  const [hover, setHover] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);

  const handleOnMouseOver = useCallback(() => {
    if (!open) {
      setHover(true);
    }
  }, [hover, open]);

  const handleOnMouseOut = useCallback(() => {
    setHover(false);
  }, [hover]);

  const handleOpenPopper = useCallback(() => {
    setOpen(true);
    setHover(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const rolesWithAccessCount = useCallback(() => {
    const rolesWithAccess = folder?.roles?.filter(({ permissions }) => {
      if (
        permissions?.canAddUpdate === false &&
        permissions?.canDelete === false &&
        permissions?.canRead === false
      ) {
        return false;
      }

      return true;
    });

    return rolesWithAccess;
  }, [folder]);

  const rolesWithAccess = rolesWithAccessCount();

  if (!folder?.roles?.length) {
    return null;
  }

  return (
    <Grid>
      <Tooltip
        componentsProps={{ tooltip: { sx: styles.tooltip } }}
        placement="right"
        open={hover}
        title={
          <Grid className="tooltip-content">
            {rolesWithAccess?.map((role, index) => (
              <Grid key={index} sx={styles.role}>
                {capitalizeFirstLetter(role?.name)}
              </Grid>
            ))}
          </Grid>
        }
      >
        <Grid
          ref={anchorRef}
          sx={fromCard ? styles.rolesCountCard : styles.rolesCount}
          onClick={handleOpenPopper}
          onMouseOver={handleOnMouseOver}
          onMouseOut={handleOnMouseOut}
        >
          {rolesWithAccess?.length === 1 ? '1 Role' : `+${rolesWithAccess?.length} Roles`}
        </Grid>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        sx={styles.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid sx={styles.popperContent}>
          <Grid sx={styles.tableRow}>
            <Grid sx={styles.tableRoleHeader}>Role</Grid>
            <Grid sx={styles.tableRoleContent}>View</Grid>
            <Grid sx={styles.tableRoleContent}>Add/Edit</Grid>
            <Grid sx={styles.tableRoleContent}>Delete</Grid>
          </Grid>

          {rolesWithAccess?.map((role, index) => {
            return (
              <RolesRow
                role={role}
                roles={folder?.roles}
                index={index}
                key={`${role?.permissions?.canRead}+${role?.permissions?.canAddUpdate}+${role?.permissions?.canDelete}+${index}`}
                folderId={folder?.id}
                permissionSubject={folder?.permissionSubject}
              />
            );
          })}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default CustomRolesGroup;
