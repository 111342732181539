import * as yup from 'yup';

export const SetScheduleSchema = yup.object({
  templateId: yup.number().when('canBeEmpty', {
    is: true,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required(),
  }),
  lastCompletedPhaseId: yup.number().notRequired(),
});
