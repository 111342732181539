import isEqual from 'lodash.isequal';
import { api } from 'redux/api';
import {
  IDeleteSelectionItemBody,
  IUpdateBulkSelectionItemsBody,
  IApproveSelectionItemBody,
  IProjectSelectionsSummary,
  IGetProjectSelectionsSummaryArgs,
  ISelectionItem,
  SelectionStatusEnum,
  IDeleteManySelectionItemsBody,
  IApproveManySelectionItemsBody,
  IUpdateRemindersBulkBody,
  IDeleteSelectionItemAttachmentBody,
  IUnapproveSelectionBody,
  SelectionPhaseStatusesEnum,
  TGetCostCodeCategoriesResponse,
  IGetCostCategoriesArgs,
  TGetCostCodesWithSelectionsResponse,
  IGetCostCodesWithSelectionsArgs,
  TGetCostCodesResponse,
  IGetCostCodesArgs,
  ICostCodeCategory,
  ICreateCostCodeCategoryBody,
  IUpdateCostCodeCategoryBody,
  IDeleteCostCodeCategoryBody,
  ICostCode,
  ICreateCostCodeBody,
  IUpdateCostCodeBody,
  IDeleteCostCodeBody,
  TGetPreLoadedCostCodeTemplatesResponse,
  TGetPreLoadedCostCodeTemplateByIdResponse,
  IGetPreLoadedCostCodeTemplateByIdArgs,
  ICreateCostCodesByTemplateIdBody,
  TGetDefaultCostCodeCategoriesResponse,
  IGetDefaultCostCodeCategoriesArgs,
  IDefaultCostCodeCategory,
  ICreateDefaultCategoryBody,
  IUpdateDefaultCategoryBody,
  IArchiveDefaultCategory,
  IDefaultCostCode,
  ICreateDefaultCostCodeBody,
  IUpdateDefaultCostCodeBody,
  IArchiveDefaultCostCodeBody,
  ICreateCostCodesByCustomTemplateBody,
  ICreateCostCodesByCustomTemplateResponse,
  CreateByTemplateResponseTypeEnum,
  IImportCostCodesBody,
  ICreateSelectionItemBody,
  IUpdateSelectionItemBody,
  TGetLibrarySelectionsResponse,
  IGetLibrarySelectionsArgs,
  ILibrarySelection,
  ICreateLibrarySelectionBody,
  IUpdateLibrarySelectionBody,
  IDeleteLibrarySelectionBody,
  IBulkCreateSelectionsBody,
} from './';
// helpers
import { getTotalSelectionsAmount } from './helpers';
// slices
import { setDefaultArgs } from 'redux/slices/query-args-slice';
import {
  selectSelection,
  unselectCostCode,
  unselectSelection,
  updateSelectedSelection,
} from 'redux/slices/selected-selections-slice';
import { RootState } from 'redux/store';
import { preLoadedTemplateMapping } from 'redux/mappings/pre-loaded-template';
import { ITemplateData } from 'redux/slices/cost-code-template-slice';

enum Endpoints {
  SELECTION_ITEMS = 'project-selection/items/',
  PROJECT_SELECTION = 'project-selection/',
  COST_CODE_CATEGORIES = 'project-selection/cost-code/categories/',
  COST_CODE = 'project-selection/cost-code/',
  COST_CODE_TEMPLATE = 'cost-code-template/',
  DEFAULT_CATEGORIES = 'cost-category/',
  DEFAULT_COST_CODES = 'cost-code/',
  LIBRARY_SELECTION = 'library-selection/',
}

const DEFAULT_COST_CODES_SERIALIZATION_KEY = 5;
const LIBRARY_SELECTIONS_SERIALIZATION_KEY = 6;

export const selectionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCostCodeCategories: build.query<TGetCostCodeCategoriesResponse, IGetCostCategoriesArgs>({
      query: (queryArgs) => ({
        url: Endpoints.COST_CODE_CATEGORIES,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache, { arg: { cursor } }) => {
        if (!cursor) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
    }),

    getCostCodeCategoriesForSelect: build.query<
      TGetCostCodeCategoriesResponse,
      IGetCostCategoriesArgs
    >({
      query: (queryArgs) => ({
        url: Endpoints.COST_CODE_CATEGORIES,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache, { arg: { cursor } }) => {
        if (!cursor) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
    }),

    getCostCodesWithSelections: build.query<
      TGetCostCodesWithSelectionsResponse,
      IGetCostCodesWithSelectionsArgs
    >({
      query: (queryArgs) => ({
        url: `${Endpoints.COST_CODE}selections`,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache, { arg: { cursor } }) => {
        if (!cursor) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
          canRead: newCache.canRead,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
      providesTags: (res, _, { projectId }) =>
        res ? [{ type: 'Project Cost Codes', id: projectId }] : [],
    }),

    getCostCodes: build.query<TGetCostCodesResponse, IGetCostCodesArgs>({
      query: (queryArgs) => ({
        url: Endpoints.COST_CODE,
        params: queryArgs,
      }),
      serializeQueryArgs: ({ queryArgs: { projectId } }) => {
        return { projectId };
      },
      merge: (currentCache, newCache, { arg: { cursor } }) => {
        if (!cursor) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
    }),

    createCostCodeCategory: build.mutation<
      Omit<ICostCodeCategory, 'costCodes'>,
      ICreateCostCodeCategoryBody
    >({
      query: (data) => ({
        url: Endpoints.COST_CODE_CATEGORIES,
        body: data,
        method: 'POST',
      }),
      onQueryStarted: async ({ projectId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData('getCostCodeCategories', { projectId }, (draft) => {
              if (draft?.items) {
                draft.items.unshift(response);
              }
            })
          );
        } catch {}
      },
    }),

    updateCostCodeCategory: build.mutation<ICostCodeCategory, IUpdateCostCodeCategoryBody>({
      query: (data) => ({
        url: Endpoints.COST_CODE_CATEGORIES,
        body: data,
        method: 'PATCH',
      }),
    }),

    deleteCostCodeCategory: build.mutation<ICostCodeCategory, IDeleteCostCodeCategoryBody>({
      query: (data) => ({
        url: Endpoints.COST_CODE_CATEGORIES,
        body: data,
        method: 'DELETE',
      }),
    }),

    createCostCode: build.mutation<ICostCode, ICreateCostCodeBody>({
      query: (data) => ({
        url: Endpoints.COST_CODE,
        body: data,
        method: 'POST',
      }),
      onQueryStarted: async ({ projectId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft?.items) {
                  draft.items.unshift({
                    ...response,
                    selections: [],
                    approvedSelectionAmountDecimalForm: '0',
                  });
                }
              }
            )
          );
        } catch {}
      },
      invalidatesTags: (res) => (res ? ['Selections Summary'] : []),
    }),

    updateCostCode: build.mutation<ICostCode, IUpdateCostCodeBody>({
      query: (data) => ({
        url: Endpoints.COST_CODE,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: async ({ projectId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft?.items) {
                  const index = draft.items.findIndex(({ id }) => id === response.id);
                  if (index >= 0) {
                    Object.assign(draft.items[index], response);
                  }
                }
              }
            )
          );
        } catch {}
      },
      invalidatesTags: (res) => (res ? ['Selections Summary'] : []),
    }),

    deleteCostCode: build.mutation<undefined, IDeleteCostCodeBody>({
      query: (data) => ({
        url: Endpoints.COST_CODE,
        params: data,
        method: 'DELETE',
      }),
      onQueryStarted: async ({ projectId, costCodeId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft?.items) {
                  const index = draft.items.findIndex(({ id }) => id === costCodeId);
                  if (index >= 0) {
                    draft.items = draft.items.filter(({ id }) => id !== costCodeId);
                  }
                  dispatch(unselectCostCode({ costCodeId: String(costCodeId) }));
                }
              }
            )
          );
        } catch {}
      },
      invalidatesTags: (err) => (!err ? ['Selections Summary'] : []),
    }),

    createSelectionItem: build.mutation<ISelectionItem, ICreateSelectionItemBody>({
      query: (data) => ({
        url: Endpoints.SELECTION_ITEMS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (res) => (res ? ['Selections Summary'] : []),
      onQueryStarted: async ({ costCodeId, projectId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              {
                projectId,
              },
              (draft) => {
                if (draft?.items) {
                  const categoryIndex = draft.items.findIndex(({ id }) => id === costCodeId);
                  if (categoryIndex >= 0) {
                    // update approved amount
                    if (response?.status === SelectionStatusEnum.APPROVED) {
                      const approvedOption = response?.options?.find(
                        (responseOption) => responseOption?.isSelected
                      );

                      if (approvedOption) {
                        draft.items[categoryIndex].approvedSelectionAmountDecimalForm =
                          getTotalSelectionsAmount(
                            draft.items[categoryIndex].approvedSelectionAmountDecimalForm,
                            Number(approvedOption.amount) * approvedOption.quantity,
                            'sum'
                          );
                      }
                    }
                    // add created selection
                    draft.items[categoryIndex].selections.unshift(response);
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    updateSelectionItem: build.mutation<ISelectionItem, IUpdateSelectionItemBody>({
      query: ({ updatedData, selectionId }) => ({
        url: `${Endpoints.SELECTION_ITEMS}${selectionId}`,
        method: 'PATCH',
        body: updatedData,
      }),
      invalidatesTags: (res) => (res ? ['Selections Summary'] : []),
      onQueryStarted: async (
        { updatedData, oldCategoryId, selectionId, oldTotalAmount, oldStatus, newStatus },
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              {
                projectId: updatedData.projectId,
              },
              (draft) => {
                if (draft?.items) {
                  if (oldCategoryId !== response?.costCode?.id) {
                    const deletableSelectionCostCodeIndex = draft.items.findIndex(
                      ({ id }) => id === oldCategoryId
                    );
                    if (deletableSelectionCostCodeIndex >= 0) {
                      dispatch(
                        unselectSelection({
                          costCodeId: String(oldCategoryId),
                          selectionId,
                        })
                      );
                      // delete item from cost code
                      draft.items[deletableSelectionCostCodeIndex].selections = draft.items[
                        deletableSelectionCostCodeIndex
                      ].selections.filter(({ id: sId }) => sId !== selectionId);
                      // reduce total amount for cost code
                      if (oldStatus === SelectionStatusEnum.APPROVED && oldTotalAmount) {
                        draft.items[
                          deletableSelectionCostCodeIndex
                        ].approvedSelectionAmountDecimalForm = getTotalSelectionsAmount(
                          draft.items[deletableSelectionCostCodeIndex]
                            .approvedSelectionAmountDecimalForm,
                          oldTotalAmount,
                          'subtract'
                        );
                      }
                    }

                    const addableSelectionCostCodeIndex = draft.items.findIndex(
                      ({ id }) => id === response?.costCode?.id
                    );
                    if (addableSelectionCostCodeIndex >= 0 && response?.costCode?.id) {
                      // add to cost code
                      draft.items[addableSelectionCostCodeIndex].selections.unshift(response);
                      dispatch(
                        selectSelection({
                          costCodeId: String(response.costCode.id),
                          selection: response,
                        })
                      );
                      // increase category total amount
                      if (newStatus === SelectionStatusEnum.APPROVED) {
                        const responseSelectedOption = response?.options?.find(
                          ({ isSelected }) => isSelected
                        );

                        if (responseSelectedOption) {
                          draft.items[
                            addableSelectionCostCodeIndex
                          ].approvedSelectionAmountDecimalForm = getTotalSelectionsAmount(
                            draft.items[addableSelectionCostCodeIndex]
                              .approvedSelectionAmountDecimalForm,
                            Number(responseSelectedOption.amount) * responseSelectedOption.quantity,
                            'sum'
                          );
                        }
                      }
                    }
                  } else {
                    const costCodeIndex = draft.items.findIndex(({ id }) => id === oldCategoryId);
                    if (costCodeIndex >= 0) {
                      // update cost code total allowance
                      if (
                        oldStatus === SelectionStatusEnum.PENDING &&
                        newStatus === SelectionStatusEnum.APPROVED
                      ) {
                        // increase (from response)
                        const responseSelectedOption = response?.options?.find(
                          ({ isSelected }) => isSelected
                        );

                        if (responseSelectedOption) {
                          draft.items[costCodeIndex].approvedSelectionAmountDecimalForm =
                            getTotalSelectionsAmount(
                              draft.items[costCodeIndex].approvedSelectionAmountDecimalForm,
                              Number(responseSelectedOption.amount) *
                                responseSelectedOption.quantity,
                              'sum'
                            );
                        }
                      } else if (
                        oldStatus === SelectionStatusEnum.APPROVED &&
                        newStatus === SelectionStatusEnum.APPROVED
                      ) {
                        const responseSelectedOption = response?.options?.find(
                          ({ isSelected }) => isSelected
                        );

                        if (responseSelectedOption && oldTotalAmount) {
                          draft.items[costCodeIndex].approvedSelectionAmountDecimalForm =
                            getTotalSelectionsAmount(
                              draft.items[costCodeIndex].approvedSelectionAmountDecimalForm,
                              oldTotalAmount -
                                Number(responseSelectedOption.amount) *
                                  responseSelectedOption.quantity,
                              'subtract'
                            );
                        }
                      } else if (
                        oldStatus === SelectionStatusEnum.APPROVED &&
                        newStatus === SelectionStatusEnum.PENDING &&
                        oldTotalAmount
                      ) {
                        // reduce (from old total)
                        draft.items[costCodeIndex].approvedSelectionAmountDecimalForm =
                          getTotalSelectionsAmount(
                            draft.items[costCodeIndex].approvedSelectionAmountDecimalForm,
                            oldTotalAmount,
                            'subtract'
                          );
                      }

                      const selectionIndex = draft.items[costCodeIndex].selections.findIndex(
                        ({ id }) => id === selectionId
                      );

                      if (selectionIndex >= 0) {
                        // update selection item
                        draft.items[costCodeIndex].selections[selectionIndex] = response;
                      }

                      dispatch(
                        updateSelectedSelection({
                          costCodeId: String(response.costCode.id),
                          selection: response,
                        })
                      );
                    }
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    deleteSelectionItemAttachment: build.mutation<
      ISelectionItem,
      IDeleteSelectionItemAttachmentBody
    >({
      query: ({ selectionId, ...data }) => ({
        url: `${Endpoints.SELECTION_ITEMS}${selectionId}`,
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted: async ({ projectId, selectionId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft.items && response?.costCode?.id) {
                  const categoryIndex = draft.items.findIndex(
                    ({ id }) => id === response.costCode.id
                  );
                  if (categoryIndex >= 0) {
                    const selectionIndex = draft.items[categoryIndex].selections?.findIndex(
                      ({ id }) => id === selectionId
                    );
                    if (selectionIndex >= 0) {
                      draft.items[categoryIndex].selections[selectionIndex] = Object.assign(
                        draft.items[categoryIndex].selections[selectionIndex],
                        response
                      );
                    }
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    updateBulkSelectionItems: build.mutation<undefined, IUpdateBulkSelectionItemsBody>({
      query: (data) => ({
        url: `${Endpoints.SELECTION_ITEMS}bulk`,
        method: 'PATCH',
        body: data,
      }),
    }),

    approveSelectionItem: build.mutation<undefined, IApproveSelectionItemBody>({
      query: ({ id, projectId, optionIds }) => ({
        url: `${Endpoints.SELECTION_ITEMS}approve`,
        method: 'PATCH',
        body: {
          items: [{ id, optionIds }],
          projectId,
        },
      }),
      invalidatesTags: (_, err) => (!err ? ['Selections Summary'] : []),
      onQueryStarted: async (
        { projectId, categoryId, id, approvedAt, approverProfile, totalAmount, optionIds },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft?.items) {
                  const categoryIndex = draft.items.findIndex(({ id: cId }) => cId === categoryId);
                  if (categoryIndex >= 0) {
                    // update selection item
                    const selectionIndex = draft.items[categoryIndex].selections.findIndex(
                      ({ id: selectionId }) => selectionId === id
                    );
                    if (selectionIndex >= 0) {
                      const selectedOption = optionIds[0];
                      const item = draft.items[categoryIndex].selections[selectionIndex];
                      const newSelectionBody = {
                        ...item,
                        status: SelectionStatusEnum.APPROVED,
                        approvedAt: approvedAt,
                        approvedByProfile: approverProfile,
                        phaseStatus: SelectionPhaseStatusesEnum.APPROVED_STATUS,
                        options: item.options.map((option) => {
                          if (option.id === selectedOption) {
                            return {
                              ...option,
                              isSelected: true,
                            };
                          }

                          return {
                            ...option,
                            isSelected: false,
                          };
                        }),
                      };
                      draft.items[categoryIndex].selections[selectionIndex] = newSelectionBody;
                      dispatch(
                        updateSelectedSelection({
                          costCodeId: String(newSelectionBody.costCode.id),
                          selection: newSelectionBody,
                        })
                      );
                    }
                    // update category total amount
                    draft.items[categoryIndex].approvedSelectionAmountDecimalForm =
                      getTotalSelectionsAmount(
                        draft.items[categoryIndex].approvedSelectionAmountDecimalForm,
                        totalAmount,
                        'sum'
                      );
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    unapproveSelectionItem: build.mutation<ISelectionItem, IUnapproveSelectionBody>({
      query: ({ categoryId, ...data }) => ({
        url: `${Endpoints.SELECTION_ITEMS}disapprove`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (res) => (res ? ['Selections Summary'] : []),
      onQueryStarted: async (
        { categoryId, projectId, selectionId },
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft?.items) {
                  const categoryIndex = draft.items.findIndex(({ id }) => id === categoryId);
                  if (categoryIndex >= 0) {
                    // update category total allowance
                    const responseSelectedOption = response?.options?.find(
                      ({ isSelected }) => isSelected
                    );
                    if (responseSelectedOption) {
                      draft.items[categoryIndex].approvedSelectionAmountDecimalForm =
                        getTotalSelectionsAmount(
                          draft.items[categoryIndex].approvedSelectionAmountDecimalForm,
                          Number(responseSelectedOption.amount) * responseSelectedOption.quantity,
                          'subtract'
                        );
                    }
                    // update selection item
                    const selectionIndex = draft.items[categoryIndex].selections?.findIndex(
                      ({ id: sId }) => sId === selectionId
                    );
                    if (selectionIndex >= 0) {
                      draft.items[categoryIndex].selections[selectionIndex] = Object.assign(
                        draft.items[categoryIndex].selections[selectionIndex],
                        response
                      );
                    }
                    dispatch(
                      updateSelectedSelection({
                        costCodeId: String(response.costCode.id),
                        selection: response,
                      })
                    );
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    approveManySelectionItems: build.mutation<undefined, IApproveManySelectionItemsBody>({
      query: (data) => ({
        url: `${Endpoints.SELECTION_ITEMS}approve`,
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted: async ({ projectId }, { queryFulfilled, dispatch, getState }) => {
        try {
          await queryFulfilled;
          const cursor = (getState() as RootState).queryArgs.selectionCategories?.cursor;
          if (cursor) {
            dispatch(setDefaultArgs());
          } else {
            dispatch(
              selectionsApi.util.invalidateTags([
                'Selections Summary',
                { type: 'Project Cost Codes', id: projectId },
              ])
            );
          }
        } catch {}
      },
    }),

    deleteSelectionItem: build.mutation<void, IDeleteSelectionItemBody>({
      query: ({ id, projectId }) => ({
        url: Endpoints.SELECTION_ITEMS,
        method: 'DELETE',
        body: {
          projectId,
          ids: [id],
        },
      }),
      invalidatesTags: (_, err) => (!err ? ['Selections Summary'] : []),
      onQueryStarted: async (
        { id, categoryId, projectId, status, totalAmount },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          dispatch(
            selectionsApi.util.updateQueryData(
              'getCostCodesWithSelections',
              { projectId },
              (draft) => {
                if (draft?.items) {
                  const costCodeIndex = draft.items.findIndex(({ id: cId }) => cId === categoryId);
                  if (costCodeIndex >= 0) {
                    draft.items[costCodeIndex] = {
                      ...draft.items[costCodeIndex],
                      selections: draft.items[costCodeIndex].selections.filter(
                        ({ id: selectionId }) => id !== selectionId
                      ),
                    };
                  }
                  if (
                    totalAmount &&
                    status === SelectionStatusEnum.APPROVED &&
                    costCodeIndex >= 0
                  ) {
                    draft.items[costCodeIndex].approvedSelectionAmountDecimalForm =
                      getTotalSelectionsAmount(
                        draft.items[costCodeIndex].approvedSelectionAmountDecimalForm,
                        totalAmount,
                        'subtract'
                      );
                  }
                }
              }
            )
          );

          dispatch(
            unselectSelection({
              costCodeId: String(categoryId),
              selectionId: id,
            })
          );
        } catch {}
      },
    }),

    deleteManySelectionItems: build.mutation<undefined, IDeleteManySelectionItemsBody>({
      query: (data) => ({
        url: Endpoints.SELECTION_ITEMS,
        method: 'DELETE',
        body: data,
      }),
      onQueryStarted: async ({ projectId }, { queryFulfilled, dispatch, getState }) => {
        try {
          await queryFulfilled;
          const cursor = (getState() as RootState).queryArgs.selectionCategories?.cursor;
          if (cursor) {
            dispatch(setDefaultArgs());
          } else {
            dispatch(
              selectionsApi.util.invalidateTags([
                'Selections Summary',
                { type: 'Project Cost Codes', id: projectId },
              ])
            );
          }
        } catch {}
      },
    }),

    updateRemindersBulk: build.mutation<undefined, IUpdateRemindersBulkBody>({
      query: (data) => ({
        url: `${Endpoints.SELECTION_ITEMS}bulk/set-reminder`,
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted: async ({ projectId }, { queryFulfilled, dispatch, getState }) => {
        try {
          await queryFulfilled;
          const cursor = (getState() as RootState).queryArgs.selectionCategories?.cursor;
          if (cursor) {
            dispatch(setDefaultArgs());
          } else {
            dispatch(
              selectionsApi.util.invalidateTags([
                'Selections Summary',
                { type: 'Project Cost Codes', id: projectId },
              ])
            );
          }
        } catch {}
      },
    }),

    getProjectSelectionsSummary: build.query<
      IProjectSelectionsSummary,
      IGetProjectSelectionsSummaryArgs
    >({
      query: ({ projectId }) => ({
        url: `${Endpoints.PROJECT_SELECTION}${projectId}/summary`,
      }),
      providesTags: ['Selections Summary'],
    }),

    getPreLoadedCostCodeTemplates: build.query<TGetPreLoadedCostCodeTemplatesResponse, void>({
      query: () => ({
        url: Endpoints.COST_CODE_TEMPLATE,
      }),
      keepUnusedDataFor: 120,
    }),

    getPreLoadedCostCodeTemplateById: build.query<
      ITemplateData[],
      IGetPreLoadedCostCodeTemplateByIdArgs
    >({
      query: ({ templateId }) => ({
        url: `${Endpoints.COST_CODE_TEMPLATE}${templateId}/cost-categories`,
      }),
      keepUnusedDataFor: 120,
      transformResponse: (response: TGetPreLoadedCostCodeTemplateByIdResponse) =>
        preLoadedTemplateMapping(response),
    }),

    createCostCodesByTemplateId: build.mutation<undefined, ICreateCostCodesByTemplateIdBody>({
      query: ({ templateId }) => ({
        url: `${Endpoints.COST_CODE_TEMPLATE}${templateId}`,
        method: 'POST',
      }),
      invalidatesTags: (_, err) => (!err ? ['Default Cost Codes'] : []),
    }),

    createCostCodeByCustomTemplate: build.mutation<
      ICreateCostCodesByCustomTemplateResponse,
      ICreateCostCodesByCustomTemplateBody
    >({
      query: (data) => ({
        url: `${Endpoints.COST_CODE_TEMPLATE}custom`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (res) =>
        res.type === CreateByTemplateResponseTypeEnum.SUCCESS ? ['Default Cost Codes'] : [],
    }),

    getDefaultCostCodeCategories: build.query<
      TGetDefaultCostCodeCategoriesResponse,
      IGetDefaultCostCodeCategoriesArgs
    >({
      query: (queryArgs) => ({
        url: Endpoints.DEFAULT_CATEGORIES,
        params: queryArgs,
      }),
      serializeQueryArgs: () => {
        return { DEFAULT_COST_CODES_SERIALIZATION_KEY };
      },
      merge: (_, newCache) => {
        return newCache;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
      providesTags: (res) => (res ? ['Default Cost Codes'] : []),
    }),

    getDefaultCategoriesForSelect: build.query<
      TGetDefaultCostCodeCategoriesResponse,
      IGetDefaultCostCodeCategoriesArgs
    >({
      query: (queryArgs) => ({
        url: Endpoints.DEFAULT_CATEGORIES,
        params: queryArgs,
      }),
      providesTags: (res) => (res ? ['Default Cost Categories'] : []),
    }),

    createDefaultCostCodeCategory: build.mutation<
      IDefaultCostCodeCategory,
      ICreateDefaultCategoryBody
    >({
      query: (data) => ({
        url: Endpoints.DEFAULT_CATEGORIES,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getDefaultCostCodeCategories',
              {
                DEFAULT_COST_CODES_SERIALIZATION_KEY,
              } as IGetDefaultCostCodeCategoriesArgs,
              (draft) => {
                if (draft?.items) {
                  draft.items.unshift(response);
                }
              }
            )
          );
        } catch (e) {}
      },
      invalidatesTags: (_, err) => (!err ? ['Default Cost Categories'] : []),
    }),

    updateDefaultCostCodeCategory: build.mutation<
      IDefaultCostCodeCategory,
      IUpdateDefaultCategoryBody
    >({
      query: (data) => ({
        url: Endpoints.DEFAULT_CATEGORIES,
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getDefaultCostCodeCategories',
              {
                DEFAULT_COST_CODES_SERIALIZATION_KEY,
              } as IGetDefaultCostCodeCategoriesArgs,
              (draft) => {
                if (draft?.items) {
                  const categoryIndex = draft.items.findIndex(({ id }) => id === response.id);
                  if (categoryIndex >= 0) {
                    Object.assign(draft.items[categoryIndex], response);
                  }
                }
              }
            )
          );
        } catch (e) {}
      },
      invalidatesTags: (_, err) => (!err ? ['Default Cost Categories'] : []),
    }),

    archiveDefaultCostCodeCategory: build.mutation<undefined, IArchiveDefaultCategory>({
      query: ({ costCategoryId }) => ({
        url: `${Endpoints.DEFAULT_CATEGORIES}${costCategoryId}/toggle-archive-status`,
        method: 'PATCH',
      }),
      onQueryStarted: async ({ costCategoryId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getDefaultCostCodeCategories',
              {
                DEFAULT_COST_CODES_SERIALIZATION_KEY,
              } as IGetDefaultCostCodeCategoriesArgs,
              (draft) => {
                if (draft?.items) {
                  draft.items = draft.items.filter(({ id }) => id !== costCategoryId);
                }
              }
            )
          );
        } catch (e) {}
      },
      invalidatesTags: (_, err) => (!err ? ['Default Cost Categories'] : []),
    }),

    createDefaultCostCode: build.mutation<IDefaultCostCode, ICreateDefaultCostCodeBody>({
      query: (data) => ({
        url: Endpoints.DEFAULT_COST_CODES,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: async ({ costCategoryId }, { queryFulfilled, dispatch }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getDefaultCostCodeCategories',
              {
                DEFAULT_COST_CODES_SERIALIZATION_KEY,
              } as IGetDefaultCostCodeCategoriesArgs,
              (draft) => {
                if (draft.items) {
                  const categoryIndex = draft.items.findIndex(({ id }) => id === costCategoryId);
                  if (categoryIndex >= 0 && draft.items[categoryIndex]?.companyCostCodes) {
                    draft.items[categoryIndex].companyCostCodes.unshift(response);
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    updateDefaultCostCode: build.mutation<IDefaultCostCode, IUpdateDefaultCostCodeBody>({
      query: (data) => ({
        url: Endpoints.DEFAULT_COST_CODES,
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getDefaultCostCodeCategories',
              {
                DEFAULT_COST_CODES_SERIALIZATION_KEY,
              } as IGetDefaultCostCodeCategoriesArgs,
              (draft) => {
                if (draft?.items) {
                  const categoryIndex = draft.items.findIndex(
                    ({ id }) => id === response.costCategoryId
                  );
                  if (categoryIndex >= 0) {
                    const costCodeIndex = draft.items[categoryIndex]?.companyCostCodes?.findIndex(
                      ({ id }) => id === response.id
                    );
                    if (costCodeIndex >= 0) {
                      Object.assign(
                        draft.items[categoryIndex].companyCostCodes[costCodeIndex],
                        response
                      );
                    }
                  }
                }
              }
            )
          );
        } catch (e) {}
      },
    }),

    archiveDefaultCostCode: build.mutation<undefined, IArchiveDefaultCostCodeBody>({
      query: ({ costCodeId }) => ({
        url: `${Endpoints.DEFAULT_COST_CODES}${costCodeId}/toggle-archive-status`,
        method: 'PATCH',
      }),
      onQueryStarted: async ({ costCodeId, categoryId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getDefaultCostCodeCategories',
              {
                DEFAULT_COST_CODES_SERIALIZATION_KEY,
              } as IGetDefaultCostCodeCategoriesArgs,
              (draft) => {
                if (draft?.items) {
                  const categoryIndex = draft.items.findIndex(({ id }) => id === categoryId);
                  if (categoryIndex >= 0 && draft.items[categoryIndex]?.companyCostCodes) {
                    draft.items[categoryIndex].companyCostCodes = draft.items[
                      categoryIndex
                    ].companyCostCodes.filter(({ id }) => id !== costCodeId);
                  }
                }
              }
            )
          );
        } catch (e) {}
      },
    }),

    importCostCodes: build.mutation<void, IImportCostCodesBody>({
      query: ({ projectId, ...data }) => ({
        url: `${Endpoints.COST_CODE}import/${projectId}`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: async ({ projectId }, { queryFulfilled, dispatch, getState }) => {
        try {
          await queryFulfilled;
          const cursor = (getState() as RootState).queryArgs.selectionCategories?.cursor;
          if (cursor) {
            dispatch(setDefaultArgs());
          } else {
            dispatch(
              selectionsApi.util.invalidateTags([
                'Selections Summary',
                { type: 'Project Cost Codes', id: projectId },
              ])
            );
          }
        } catch {}
      },
    }),

    getLibrarySelections: build.query<TGetLibrarySelectionsResponse, IGetLibrarySelectionsArgs>({
      query: (params) => ({
        url: Endpoints.LIBRARY_SELECTION,
        params,
      }),
      serializeQueryArgs: () => {
        return { LIBRARY_SELECTIONS_SERIALIZATION_KEY };
      },
      merge: (currentCache, newCache, { arg: { offset } }) => {
        if (!offset) {
          return newCache;
        }

        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
    }),

    createLibrarySelection: build.mutation<ILibrarySelection, ICreateLibrarySelectionBody>({
      query: (body) => ({
        url: Endpoints.LIBRARY_SELECTION,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getLibrarySelections',
              { LIBRARY_SELECTIONS_SERIALIZATION_KEY } as IGetLibrarySelectionsArgs,
              (draft) => {
                if (draft.items) {
                  draft.items.unshift(response);
                }
              }
            )
          );
        } catch {}
      },
    }),

    updateLibrarySelection: build.mutation<ILibrarySelection, IUpdateLibrarySelectionBody>({
      query: ({ id, ...body }) => ({
        url: `${Endpoints.LIBRARY_SELECTION}${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getLibrarySelections',
              { LIBRARY_SELECTIONS_SERIALIZATION_KEY } as IGetLibrarySelectionsArgs,
              (draft) => {
                if (draft.items) {
                  const selectionIndex = draft.items.findIndex((item) => id === item.id);
                  if (selectionIndex >= 0) {
                    draft.items[selectionIndex] = response;
                  }
                }
              }
            )
          );
        } catch {}
      },
    }),

    deleteLibrarySelection: build.mutation<void, IDeleteLibrarySelectionBody>({
      query: ({ id }) => ({
        url: Endpoints.LIBRARY_SELECTION,
        method: 'DELETE',
        body: { ids: [id] },
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            selectionsApi.util.updateQueryData(
              'getLibrarySelections',
              { LIBRARY_SELECTIONS_SERIALIZATION_KEY } as IGetLibrarySelectionsArgs,
              (draft) => {
                if (draft.items) {
                  draft.items = draft.items.filter((item) => item.id !== id);
                }
              }
            )
          );
        } catch {}
      },
    }),

    bulkCreateSelections: build.mutation<void, IBulkCreateSelectionsBody>({
      query: (body) => ({
        url: `${Endpoints.SELECTION_ITEMS}bulk`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async ({ projectId }, { queryFulfilled, dispatch, getState }) => {
        try {
          await queryFulfilled;
          const cursor = (getState() as RootState).queryArgs.selectionCategories?.cursor;
          if (cursor) {
            dispatch(setDefaultArgs());
          } else {
            dispatch(
              selectionsApi.util.invalidateTags([
                'Selections Summary',
                { type: 'Project Cost Codes', id: projectId },
              ])
            );
          }
        } catch {}
      },
    }),
  }),
});

export const {
  useGetCostCodeCategoriesQuery,
  useGetCostCodesQuery,
  useGetCostCodesWithSelectionsQuery,
  useLazyGetCostCodeCategoriesQuery,
  useLazyGetCostCodesQuery,
  useLazyGetCostCodesWithSelectionsQuery,
  useCreateCostCodeCategoryMutation,
  useUpdateCostCodeCategoryMutation,
  useDeleteCostCodeCategoryMutation,
  useCreateCostCodeMutation,
  useUpdateCostCodeMutation,
  useDeleteCostCodeMutation,
  useCreateSelectionItemMutation,
  useUpdateSelectionItemMutation,
  useDeleteSelectionItemMutation,
  useUpdateBulkSelectionItemsMutation,
  useApproveSelectionItemMutation,
  useGetProjectSelectionsSummaryQuery,
  useLazyGetProjectSelectionsSummaryQuery,
  useDeleteManySelectionItemsMutation,
  useApproveManySelectionItemsMutation,
  useUpdateRemindersBulkMutation,
  useDeleteSelectionItemAttachmentMutation,
  useUnapproveSelectionItemMutation,
  useGetPreLoadedCostCodeTemplateByIdQuery,
  useGetPreLoadedCostCodeTemplatesQuery,
  useLazyGetPreLoadedCostCodeTemplateByIdQuery,
  useLazyGetPreLoadedCostCodeTemplatesQuery,
  useCreateCostCodesByTemplateIdMutation,
  useCreateCostCodeByCustomTemplateMutation,
  useGetDefaultCostCodeCategoriesQuery,
  useLazyGetDefaultCostCodeCategoriesQuery,
  useCreateDefaultCostCodeCategoryMutation,
  useUpdateDefaultCostCodeCategoryMutation,
  useArchiveDefaultCostCodeCategoryMutation,
  useCreateDefaultCostCodeMutation,
  useUpdateDefaultCostCodeMutation,
  useArchiveDefaultCostCodeMutation,
  useGetDefaultCategoriesForSelectQuery,
  useLazyGetDefaultCategoriesForSelectQuery,
  useGetCostCodeCategoriesForSelectQuery,
  useLazyGetCostCodeCategoriesForSelectQuery,
  useImportCostCodesMutation,
  useGetLibrarySelectionsQuery,
  useLazyGetLibrarySelectionsQuery,
  useCreateLibrarySelectionMutation,
  useUpdateLibrarySelectionMutation,
  useDeleteLibrarySelectionMutation,
  useBulkCreateSelectionsMutation,
} = selectionsApi;
