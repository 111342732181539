import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum CSVTemplateColumnsEnum {
  COST_CATEGORY_NUMBER = 'Cost Category Number',
  COST_CATEGORY_NAME = 'Cost Category Name',
  COST_CODE_NUMBER = 'Cost Code Number',
  COST_CODE_NAME = 'Cost Code Name',
  COST_CODE_DESCRIPTION = 'Cost Code Description',
}

export type TParsedRowFromCSV = {
  [key in CSVTemplateColumnsEnum]: string;
};

export interface ITemplateData extends TParsedRowFromCSV {
  position: string;
}

export enum CostCodeTemplateTypeEnum {
  PRE_LOADED = 'PRE_LOADED',
  CUSTOM = 'CUSTOM',
}

export enum CostCodeTemplateStepsEnum {
  SELECT_TEMPLATE,
  PREVIEW_TEMPLATE,
  MAP_TEMPLATE_FIELDS,
  REVIEW_TEMPLATE,
}

interface ISelectTemplatePayload
  extends Pick<IInitialState, 'selectedTemplateId' | 'selectedTemplateName' | 'templateType'> {
  withoutUnselect?: boolean;
}

export interface IInitialState {
  templateType: CostCodeTemplateTypeEnum | null;
  currentStep: CostCodeTemplateStepsEnum;
  openModal: boolean;
  selectedTemplateId: number | null;
  selectedTemplateName: string | null;
  templateData: ITemplateData[] | null;
  deletedColumns: CSVTemplateColumnsEnum[];
}

const initialState: IInitialState = {
  currentStep: CostCodeTemplateStepsEnum.SELECT_TEMPLATE,
  templateType: null,
  openModal: false,
  selectedTemplateId: null,
  selectedTemplateName: null,
  templateData: null,
  deletedColumns: [],
};

const costCodeTemplateSlice = createSlice({
  initialState,
  name: 'costCodeTemplate',
  reducers: {
    nextStep: (state) => {
      state.currentStep += 1;
    },

    previousStep: (state) => {
      state.currentStep -= 1;
    },

    handleOpenModal: (state) => {
      state.openModal = true;
    },

    handleCloseModal: (state) => {
      state.openModal = false;
      state.currentStep = initialState.currentStep;
      state.templateType = initialState.templateType;
      state.selectedTemplateId = initialState.selectedTemplateId;
      state.selectedTemplateName = initialState.selectedTemplateName;
      state.templateData = initialState.templateData;
      state.deletedColumns = initialState.deletedColumns;
    },

    selectTemplate: (state, action: PayloadAction<ISelectTemplatePayload>) => {
      if (
        !action.payload?.withoutUnselect &&
        state.selectedTemplateId === action.payload.selectedTemplateId
      ) {
        state.templateType = initialState.templateType;
        state.selectedTemplateId = initialState.selectedTemplateId;
        state.selectedTemplateName = initialState.selectedTemplateName;
      } else {
        state.templateType = action.payload.templateType;
        state.selectedTemplateId = action.payload.selectedTemplateId;
        state.selectedTemplateName = action.payload.selectedTemplateName;
      }
    },

    setCustomTemplateData: (state, action: PayloadAction<ITemplateData[]>) => {
      state.templateData = action.payload;
    },

    deleteTemplateColumns: (state, action: PayloadAction<CSVTemplateColumnsEnum[]>) => {
      state.deletedColumns = action.payload;
    },
  },
});

export const {
  nextStep,
  previousStep,
  selectTemplate,
  handleCloseModal,
  handleOpenModal,
  setCustomTemplateData,
  deleteTemplateColumns,
} = costCodeTemplateSlice.actions;

export default costCodeTemplateSlice.reducer;
