import React, { FC } from 'react';
// mui components
import { Grid, Box } from '@mui/material';
// components
import DialogModal from 'components/modal/dialog-modal';
import { Content } from './content';
// icons
import CloseIcon from '@mui/icons-material/Close';
// styles
import { styles } from './styles';

export interface IProps {
  open: boolean;
  handleClose: () => void;
  url: string;
  mimeType: string;
}

const FileViewerModal: FC<IProps> = ({ open, handleClose, ...contentProps }) => {
  return (
    <DialogModal open={open} handleClose={handleClose} maxWidth="900px" withoutPadding={true}>
      <Grid sx={styles.modalHeader}>
        <CloseIcon sx={styles.icon} onClick={handleClose} />
      </Grid>

      <Box sx={styles.wrapper}>
        <Content {...contentProps} />
      </Box>
    </DialogModal>
  );
};

export default FileViewerModal;
