import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
// mui components
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
// components
import Checkbox from 'components/checkbox';
// types
import { FileCabinetUser } from 'redux/api/file-cabinet';
// helpers
import { capitalizeWordsFirstLetter } from 'utils/helpers/capitalizeFirstLetterHelper';
// styles
import { styles } from './styles';

type UserProps = {
  index: number;
  user: FileCabinetUser;
  getInitials: (name: string) => string;
};

const User: FC<UserProps> = ({ index, user, getInitials }) => {
  const form = useFormContext();

  const viewChangeHandler = useCallback(
    (event) => {
      const { id, firstName, lastName, avatar, email, role, jobTitle, isOwner } = user;

      if (event.target.checked === true) {
        form.setValue(`users.${index}`, {
          id,
          firstName,
          lastName,
          avatar,
          email,
          role,
          jobTitle,
          isOwner,
          permissions: { ...user.permissions, canRead: event.target.checked },
        });
      }

      if (event.target.checked === false) {
        form.setValue(`users.${index}`, {
          id,
          firstName,
          lastName,
          avatar,
          email,
          role,
          jobTitle,
          isOwner,
          permissions: {
            canRead: event.target.checked,
            canAddUpdate: event.target.checked,
            canDelete: event.target.checked,
          },
        });
      }
    },
    [user]
  );

  const addEditChangeHandler = useCallback(
    (event) => {
      const { id, firstName, lastName, avatar, email, role, jobTitle, isOwner } = user;

      form.setValue(`users.${index}`, {
        id,
        firstName,
        lastName,
        avatar,
        email,
        role,
        jobTitle,
        isOwner,
        permissions: { ...user.permissions, canAddUpdate: event.target.checked },
      });
    },
    [user]
  );

  const deleteChangeHandler = useCallback(
    (event) => {
      const { id, firstName, lastName, avatar, email, role, jobTitle, isOwner } = user;

      form.setValue(`users.${index}`, {
        id,
        firstName,
        lastName,
        avatar,
        email,
        role,
        jobTitle,
        isOwner,
        permissions: { ...user.permissions, canDelete: event.target.checked },
      });
    },
    [user]
  );

  const bulkChangeHandler = useCallback(
    (e) => {
      const { id, firstName, lastName, avatar, email, role, jobTitle, isOwner } = user;

      form.setValue(`users.${index}`, {
        id,
        firstName,
        lastName,
        avatar,
        email,
        role,
        jobTitle,
        isOwner,
        permissions: {
          canRead: e.target.checked,
          canAddUpdate: e.target.checked,
          canDelete: e.target.checked,
        },
      });
    },
    [index, form]
  );

  return (
    <Grid sx={styles.tableRow} className="user-row">
      <Grid sx={styles.tableUserInfo}>
        <Avatar src={user?.avatar} sx={styles.avatar}>
          {getInitials(`${user?.firstName} ${user?.lastName}`)}
        </Avatar>

        <Grid sx={styles.nameContainer}>
          <Typography sx={styles.name}>
            {user?.firstName ? `${user?.firstName} ${user?.lastName}` : '-'}
          </Typography>

          {user?.role?.name && (
            <Tooltip
              title={capitalizeWordsFirstLetter(user?.jobTitle)}
              placement="bottom"
              componentsProps={{ tooltip: { sx: styles.tooltip } }}
              className="avatarEmail"
            >
              <Typography sx={styles.role}>{capitalizeWordsFirstLetter(user?.jobTitle)}</Typography>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          onChange={viewChangeHandler}
          defaultChecked={user?.isOwner || user?.permissions?.canRead}
          value={user.permissions.canRead}
          disabled={user?.isOwner}
        />
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          onChange={addEditChangeHandler}
          defaultChecked={user?.isOwner || user?.permissions?.canAddUpdate}
          value={user.permissions.canAddUpdate}
          disabled={!user.permissions.canRead || user?.isOwner}
        />
      </Grid>

      <Grid sx={styles.checkboxContainer}>
        <Checkbox
          onChange={deleteChangeHandler}
          defaultChecked={user?.isOwner || user?.permissions?.canDelete}
          value={user.permissions.canDelete}
          disabled={!user.permissions.canRead || user?.isOwner}
        />
      </Grid>

      <Grid sx={styles.checkboxContainerBulk}>
        <Checkbox
          onChange={bulkChangeHandler}
          defaultChecked={
            user?.isOwner ||
            (user?.permissions?.canDelete &&
              user?.permissions?.canAddUpdate &&
              user?.permissions?.canRead)
          }
          disabled={user?.isOwner}
        />
      </Grid>
    </Grid>
  );
};

export default User;
