import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const GradientButton = styled(Button)(({ theme }) => ({
  borderRadius: 10,
  padding: 0,
  border: 'solid 1px transparent',
  backgroundImage: 'linear-gradient(93.74deg, #FF7051 1.23%, #D3AC1E 100%)',
  backgroundOrigin: 'border-box',
  boxShadow: '2px 1000px 1px #fff inset',
}));

export default GradientButton;
