import { TGetPreLoadedCostCodeTemplateByIdResponse } from 'redux/api/selections';
import { ITemplateData, CSVTemplateColumnsEnum } from 'redux/slices/cost-code-template-slice';

export const preLoadedTemplateMapping = (
  response: TGetPreLoadedCostCodeTemplateByIdResponse
): ITemplateData[] => {
  if (!response || !response?.items?.length) {
    return [];
  }

  return response.items
    .map(({ name: categoryName, number: categoryNumber, costCodes }, index) => {
      if (costCodes.length) {
        const mappedCostCodes: ITemplateData[] = costCodes.map(({ name, number }, idx) => ({
          [CSVTemplateColumnsEnum.COST_CATEGORY_NAME]: categoryName,
          [CSVTemplateColumnsEnum.COST_CATEGORY_NUMBER]: categoryNumber,
          [CSVTemplateColumnsEnum.COST_CODE_NAME]: name,
          [CSVTemplateColumnsEnum.COST_CODE_NUMBER]: number,
          [CSVTemplateColumnsEnum.COST_CODE_DESCRIPTION]: '',
          position: `${idx}`,
        }));

        return mappedCostCodes;
      }

      return [
        {
          [CSVTemplateColumnsEnum.COST_CATEGORY_NAME]: categoryName,
          [CSVTemplateColumnsEnum.COST_CATEGORY_NUMBER]: categoryNumber,
          [CSVTemplateColumnsEnum.COST_CODE_NAME]: '',
          [CSVTemplateColumnsEnum.COST_CODE_NUMBER]: '',
          [CSVTemplateColumnsEnum.COST_CODE_DESCRIPTION]: '',
          position: `${index}`,
        },
      ];
    })
    .flat()
    .map((item, itemIndex) => ({
      ...item,
      position: `${itemIndex + 1}`,
    }));
};
