import React from 'react';
import { Grid, Button } from '@mui/material';

import { styles } from '../../styles';

type Props = {
  handleClose: () => void;
  label: string;
  saveBtnType?: 'submit' | 'button';
  handleSave?: () => void;
  isDisabled?: boolean;
};

const ModalActions = ({
  handleClose,
  label,
  saveBtnType = 'submit',
  handleSave,
  isDisabled = false,
}: Props) => {
  const handleCustomClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    handleClose();
  };
  return (
    <Grid sx={styles.actions}>
      <Button className="btn" onClick={handleCustomClose}>
        cancel
      </Button>
      <Button
        className="btn filled-btn"
        type={saveBtnType}
        onClick={saveBtnType === 'button' ? handleSave : handleCustomClose}
        disabled={isDisabled}
      >
        {label}
      </Button>
    </Grid>
  );
};

export default ModalActions;
