export enum FileCabinetTypeEnum {
  File = 'file',
  Folder = 'folder',
}

export enum FileCabinetLocation {
  INSIDE_DEFAULT_FOLDERS = 'INSIDE_DEFAULT_FOLDERS',
  INSIDE_PROJECT_FOLDERS = 'INSIDE_PROJECT_FOLDERS',
  INSIDE_PUBLIC_FOLDERS = 'INSIDE_PUBLIC_FOLDERS',
  INSIDE_FOLDER = 'INSIDE_FOLDER',
}

export type TBreadCrumbs = {
  id: string | number;
  name: string;
}[];

export type FileCabinetUserPermission = {
  canRead: boolean;
  canAddUpdate: boolean;
  canDelete: boolean;
};

export type TUserRole = {
  id: number;
  name: string;
};

export type TOwner = {
  id: string;
  firstName: string;
  lastName: string;
};

export interface IFileCabinetFile {
  id: number;
  name: string;
  size: string;
  type: File;
  mimeType: string;
  path: string;
  canDelete?: boolean;
}

export type FileCabinetUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string | null;
  role: TUserRole;
  jobTitle: string;
  isOwner?: boolean;
  permissions: FileCabinetUserPermission;
};

export interface TFileCabinetRole {
  id: string | number;
  name?: string;
  permissions: FileCabinetUserPermission;
}

export interface IFolder {
  id: number;
  name: string;
  size: string;
  type: string;
  mimeType: string;
  path: string;
  canDelete?: boolean;
  users?: FileCabinetUser[];
  roles?: TFileCabinetRole[];
  children?: [];
  fileCabinetPath: TBreadCrumbs | [];
  parentId: string | number;
  itemsCount?: string | number;
  awsPath?: string;
  url?: string;
  permissionSubject?: string;
  isPublic: boolean;
  accessKey: string;
  owner: TOwner;
  createdAt: string;
}

export type IGetFolderDetailsResponse = IFolder;

export type IGetFileCabinetByIdResponse = { items: IFolder[] };

export interface IGetFileCabinetByIdArgs {
  projectId: number | string;
}

export type IGetDefaultFoldersResponse = { items: IFolder[] };

export interface IGetDefaultFoldersArgs {
  companyId: number | string;
}

export interface IGetDestinationFoldersArgs {
  projectId?: number | string;
  excludeIds?: Array<number | string>;
}

export interface IGetFolderByIdFilesArgs {
  folderId: number | string;
  projectId: number | string;
  onlyFiles?: boolean;
}

export interface IGetFolderDetailsByIdArgs {
  folderId: number | string;
  projectId: string | number;
}

export interface IGetFolderByIdFilesResponse {
  items: IFolder[];
}

export interface IFileCabinetUploadURLArgs {
  key: string;
  folderId: string | number;
  mimeType: string;
}

export interface IFileCabinetSelectionUploadURLArgs {
  key: string;
  projectId?: string | number;
  mimeType: string;
  isLogo?: boolean;
}

export interface IPostFileBody {
  file: File;
  URL: string;
  fileType: string;
}

export interface IFileCabinetUploadURLResponse {
  url: string;
  id: number | string;
}

export interface IFileCabinetSelectionUploadURLResponse {
  id: number;
  url: string;
  previewUrl: string;
}

export interface ICreateFolderResposnse {
  id: number;
  name: string;
  size: string;
  type: File;
  mimeType: string;
  path: string;
}

export interface ICreateFolderBody {
  name: any;
  parentFolderId: number | string;
  projectId: string | number;
}

export interface ICreateDefaultFolderBody {
  name: any;
  roles: TFileCabinetRole[];
}

export interface IUploadFileCabinetFileResposnse {
  id: number;
  name: string;
  size: string;
  type: File;
  mimeType: string;
  path: string;
}

export interface ICreateFileBody {
  fileId: number | string;
  folderId: number | string;
  projectId: number | string;
  withOptimisticUpdate?: boolean;
}

export interface IEditFileCabinetItemResposne {}

export interface IEditFileCabinetItem {
  name: string;
}

export interface IEditFileCabinetItemBody extends Partial<IEditFileCabinetItem> {
  name: string;
  itemId: string | number;
  projectId: string | number;
}

export type IChangeDefaultFoldersPermissionsResposne = IFolder;

export type IChangeDefaultFoldersPermissionsBody = {
  itemId: string | number;
  roles: TFileCabinetRole[];
};

export type TAddCabinetUserBody = {
  profileId: string | number;
  itemId: string | number;
  permissions: {
    canRead: boolean;
    canAddUpdate: boolean;
    canDelete: boolean;
  };
};

export type TEditCabinetUserBody = TAddCabinetUserBody[];

export type TGetShowCabinetFileBody = {
  fileId: string | number;
  projectId?: string | number;
  isTemp?: boolean;
};

export type TGetShowCabinetFileResponse = { url: string };

export type TGetPublicFoldersBody = { accessKey: string };

export type TGetPublicFoldersResponse = {
  items: IFolder[];
  projectName?: string;
  folderName?: string;
};

export type TUpdateFoldersVisibilityBody = {
  itemId: string | number;
  isPublic: boolean;
  projectId: string | number;
};

export type TUpdateFoldersVisibilityResponse = {
  url: string;
};

export type TMoveFolderOrFileBody = {
  parentId: string | number;
  itemId: string | number;
  projectId: string | number;
};

export type TGetShowPublicCabinetFileBody = { accessKey: string | number };

export type TGetShowPublicCabinetFileResponse = { url: string };

export type TGetCostCodeFilesBody = { id: number | string };

export type TGetCostCodeFilesResponse = { items: IFolder[] };

export type TGetFolderBreadCrumbsBody = { itemId: string | number; projectId: string | number };

export type TGetFolderBreadCrumbsResponse = {
  items: [
    {
      id: number;
      name: string;
      fileCabinetPath: string;
    }
  ];
};

export type TGetNewFolderPublicKeyResponse = {
  accessKey: string;
};
