import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActivityFeedComment, ILikeActivityFeedItemArgs } from 'redux/api/activity-feed';

export type TActionBy = keyof Pick<IActivityFeedComment, 'id' | 'activityFeedItemId'>;

interface ILikeUnlikePayload extends ILikeActivityFeedItemArgs {
  increase: boolean;
  fromSocket?: boolean;
}

export interface INewComments {
  comments: IActivityFeedComment[];
}

const initialState: INewComments = {
  comments: [],
};

export const newCommentsSlice = createSlice({
  name: 'newComments',
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<IActivityFeedComment>) => {
      if (action.payload?.rootCommentId) {
        const commentIndex = state.comments.findIndex(
          (item) => item.id === action.payload.rootCommentId
        );
        if (commentIndex >= 0) {
          state.comments[commentIndex]?.replies?.push(action.payload);
        }
      } else {
        state.comments = [{ ...action.payload, replies: [] }, ...state.comments];
      }
    },

    editComment: (state, action: PayloadAction<IActivityFeedComment>) => {
      if (action.payload?.rootCommentId) {
        const rootCommentIndex = state.comments.findIndex(
          (item) => item.id === action.payload.rootCommentId
        );

        if (rootCommentIndex >= 0) {
          const commentIndex = state.comments[rootCommentIndex]?.replies?.findIndex(
            (comItem) => comItem?.id === action.payload.id
          );
          if (commentIndex >= 0) {
            state.comments[rootCommentIndex].replies[commentIndex] = Object.assign(
              state.comments[rootCommentIndex].replies[commentIndex],
              action.payload
            );
          }
        }
      } else {
        const index = state.comments.findIndex(({ id }) => id === action.payload.id);
        if (index >= 0) {
          state.comments[index] = Object.assign(state.comments[index], action.payload);
        }
      }
    },

    deleteComments: (
      state,
      action: PayloadAction<{
        id: number;
        postId: number;
        rootCommentId?: number;
        deleteBy?: TActionBy;
      }>
    ) => {
      const deleteByKey = action.payload?.deleteBy ?? 'id';

      if (action.payload?.rootCommentId) {
        const rootCommentIndex = state.comments.findIndex(
          (item) => item.id === action.payload.rootCommentId
        );

        if (rootCommentIndex >= 0) {
          state.comments[rootCommentIndex].replies = state.comments[
            rootCommentIndex
          ].replies.filter((item) => item[deleteByKey] !== action.payload.id);
        }
      } else {
        state.comments = state.comments.filter((item) => item[deleteByKey] !== action.payload.id);
      }
    },

    deleteAllComments: (state) => {
      state.comments = [];
    },

    likeUnlikeComment: (state, action: PayloadAction<ILikeUnlikePayload>) => {
      const { activityFeedItemId, commentId, rootCommentId, increase, fromSocket } = action.payload;
      if (rootCommentId) {
        const rootCommentIndex = state.comments.findIndex((item) => item.id === rootCommentId);

        if (rootCommentIndex >= 0) {
          const commentIndex = state.comments[rootCommentIndex]?.replies?.findIndex(
            (comItem) =>
              comItem?.id === commentId ||
              comItem?.id === activityFeedItemId ||
              comItem?.activityFeedItemId === activityFeedItemId
          );

          if (commentIndex >= 0) {
            if (!fromSocket) {
              state.comments[rootCommentIndex].replies[commentIndex].isLiked = increase;
            }
            if (increase) {
              state.comments[rootCommentIndex].replies[commentIndex].likesCount += 1;
            } else {
              state.comments[rootCommentIndex].replies[commentIndex].likesCount -= 1;
            }
          }
        }
      } else {
        const index = state.comments.findIndex(
          (item) =>
            item.id === commentId ||
            item.id === activityFeedItemId ||
            item.activityFeedItemId === activityFeedItemId
        );
        if (index >= 0) {
          if (!fromSocket) {
            state.comments[index].isLiked = increase;
          }
          if (increase) {
            state.comments[index].likesCount += 1;
          } else {
            state.comments[index].likesCount -= 1;
          }
        }
      }
    },
  },
});

export const { addComment, deleteComments, editComment, deleteAllComments, likeUnlikeComment } =
  newCommentsSlice.actions;

export default newCommentsSlice.reducer;
