import React, { useMemo, FC } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
// mui components
import { Grid } from '@mui/material';
// components
import CustomModal from '../components/custom-modal';
import ActionButton from 'components/buttons/index';
import Role from '../components/role';
// controllers
import OutlinedInputController from 'controllers/outlined-input-controller';
// hooks
import useToast from 'hooks/useToast';
import { defaultRolesHelper } from 'utils/helpers/fileCabinetHelpers';
// api
import { TModalProps } from 'components/file-cabinet-folders/types';
import { useCreateFileCabinetDefaultFolderMutation } from 'redux/api/file-cabinet';
import { ICompanyRole } from 'redux/api/company-roles';
// styles
import { styles } from './styles';

type Props = TModalProps & {
  title: string;
  subtitle: string;
  companyRoles: ICompanyRole[];
};

const CreateDefaultFolder: FC<Props> = ({ open, title, subtitle, companyRoles, handleClose }) => {
  const [handleCreateDefaultFolder, { isLoading }] = useCreateFileCabinetDefaultFolderMutation();

  const defaultValues = useMemo(() => {
    return {
      name: '',
      parentFolderId: 0,
      roles: defaultRolesHelper(companyRoles),
    };
  }, [companyRoles]);

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(yup.object({ name: yup.string().required('Please fill the field') })),
  });

  const fields = form.watch('roles');

  const showToast = useToast();
  const { handleSubmit, clearErrors, reset } = form;

  const handleCustomSubmit = (data) => {
    const formData = {
      name: data?.name,
      roles: data?.roles,
    };

    handleCreateDefaultFolder({ ...formData })
      .unwrap()
      .then(() => {
        clearErrors();
        handleClose();
        reset();
      })
      .catch((err) => {
        showToast({
          message: !!err.data.message ? err.data.message : 'Something went wrong!',
          type: 'error',
        });
      });
  };

  return (
    <CustomModal open={open} title={title} subtitle={subtitle} handleClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleCustomSubmit)}>
          <OutlinedInputController name="name" label="Name" placeholder="Enter Name" withError />

          <Grid sx={styles.rolesContentContainer}>
            <Grid sx={styles.rolesTableHead}>
              <Grid sx={styles.roleTableTitle}>Role</Grid>
              <Grid sx={styles.roleTableItem}>View</Grid>
              <Grid sx={styles.roleTableItem}>Add/Edit</Grid>
              <Grid sx={styles.roleTableItem}>Delete</Grid>
              <Grid sx={styles.roleTableEmptyItem}></Grid>
            </Grid>

            {fields?.map((role, index) => (
              <Role
                key={`${role.permissions.canRead}+${role.permissions.canAddUpdate}+${role.permissions.canDelete}+${index}`}
                role={role}
                index={index}
              />
            ))}
          </Grid>

          <Grid sx={styles.actions}>
            <ActionButton
              loading={isLoading}
              variant="secondary"
              onClick={handleClose}
              type="button"
              label="Cancel"
            />

            <ActionButton loading={isLoading} variant="primary" type="submit" label="Save" />
          </Grid>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateDefaultFolder;
