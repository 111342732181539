import { api } from 'redux/api';

import {
  IInviteArgs,
  IInviteSignUpBody,
  IInviteSignUpCheckArgs,
  IInviteSignUpCheckResponse,
  IInviteSignUpResponse,
  IInvitesBulkBody,
  TInviteResponse,
} from './invites.types';

enum Endpoints {
  INVITES = 'invites/',
}

export const invitesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvites: build.query<TInviteResponse, IInviteArgs>({
      query: (queryArgs) => ({
        url: Endpoints.INVITES,
        params: queryArgs,
      }),
      providesTags: ['Invites'],
    }),
    getInviteSignUpCheck: build.query<IInviteSignUpCheckResponse, IInviteSignUpCheckArgs>({
      query: (queryArgs) => ({
        url: `${Endpoints.INVITES}invite-sign-up-check`,
        params: queryArgs,
      }),
    }),
    invitesBulk: build.mutation<undefined, IInvitesBulkBody>({
      query: (data) => ({
        url: `${Endpoints.INVITES}bulk`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Invites'],
    }),
    inviteSignUp: build.mutation<IInviteSignUpResponse, IInviteSignUpBody>({
      query: (data) => ({
        url: `${Endpoints.INVITES}invite-sign-up`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['User', 'Company'],
    }),
  }),
});

export const {
  useGetInvitesQuery,
  useLazyGetInvitesQuery,
  useGetInviteSignUpCheckQuery,
  useLazyGetInviteSignUpCheckQuery,
  useInviteSignUpMutation,
  useInvitesBulkMutation,
} = invitesApi;
