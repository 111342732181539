import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { handlSetIsDeployig } from 'redux/slices/auth-slice';

export const rtkErrorHandler: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status === 'FETCH_ERROR') {
        dispatch(handlSetIsDeployig());
      }
    }

    return next(action);
  };
