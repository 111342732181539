export const placesAutocompleteInput = {
  loadingContainer: { display: 'flex', transform: 'translateY(-50%)' },

  container: {
    '& .MuiPaper-root': {
      transform: 'translateY(4px)',
      borderRadius: '10px',

      '& ul': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 0',

        '& li': {
          width: 'calc(100% - 4px)',
          padding: '14px 16px',
          borderRadius: '4px',
          transition: '300ms',

          '&:hover': {
            background: '#F2F5FF',
          },
        },
      },
    },
  },

  optionContainer: {
    minHeight: '52px',
    fontWeight: 'normal',
  },

  option: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'no-wrap',
    gap: '12px',
    padding: '0',
  },

  label: {
    width: 'calc(100% - 32px)',
    maxWidth: 'calc(100% - 32px)',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
  },
};
