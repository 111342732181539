import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import Select, { IProps as ISelectProps } from 'components/select';
import { CSSProperties } from '@mui/styles';
import SelectNative from 'components/select-native';
import SearchSelect, { TAdditionalOption } from 'components/select-components/search-select';
import { SelectChangeEvent } from '@mui/material';

export interface ISelectController extends ISelectProps {
  isNative?: boolean;
  isSelect?: boolean;
  withIcons?: boolean;
  addNewItem?: boolean;
  onAddNewItem?: () => void;
  withDataFetching?: boolean;
  isFetching?: boolean;
  withoutBorder?: boolean;
  hideIcon?: boolean;
  withAvatar?: boolean;
  inputStyles?: CSSProperties;
  avatarStyles?: CSSProperties;
  onIntersection?: () => void;
  onSearchChange?: (text: string) => void;
  additionalOption?: TAdditionalOption;
}

const SelectController: React.FC<ISelectController> = ({
  name = '',
  options,
  defaultValue = '',
  isNative,
  isSelect,
  addNewItem,
  onAddNewItem,
  additionalOption,
  onChange: onSelectChange,
  ...props
}) => {
  const { control } = useFormContext();

  const CurrentSelect = isNative ? SelectNative : isSelect ? SearchSelect : Select;

  return (
    <Controller
      render={({ field: { value, onChange: onFormValueChange }, fieldState: { error } }) => {
        const foundedOption = options?.find(({ value: optionValue }) => optionValue === value);

        const currentValue = value && foundedOption ? value : defaultValue;

        const onChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
          onFormValueChange?.(event, child);
          onSelectChange?.(event, child);
        };

        return (
          <CurrentSelect
            onAddNewItem={onAddNewItem}
            addNewItem={addNewItem}
            value={currentValue}
            options={options}
            error={error}
            onChange={onChange}
            {...(isSelect && { additionalOption: additionalOption })}
            {...props}
          />
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default memo(SelectController);
