export interface IPaymentMethod {
  id: number;
  companyId: number;
  ccExpireMonth: number;
  ccExpireYear: number;
  ccNum: string;
  type: string;
}

export type PaymentPlanRecurring = 'ONE_TIME' | 'MONTHLY' | 'ANNUAL';
export type PaymentPlanStatus = 'TRIALING' | 'ACTIVE' | 'INACTIVE';

export enum PromoCodeEnum {
  DISCOUNT = 'DISCOUNT',
  FIRST_PROJECT_FREE = 'FIRST_PROJECT_FREE',
  LIFETIME_DEAL = 'LIFETIME_DEAL',
}

export interface IPaymentPlanItem {
  id: number;
  name: string;
  recurring: PaymentPlanRecurring;
  externalProductId: number;
  amount: number;
  data: { maxProjectsAmount: number; lifetimeDeal?: boolean };
  couponCode: {
    code: string;
    discount: number;
  };
}

export interface IPaymentPlan {
  items: IPaymentPlanItem[];
}

export interface ICurrentPaymentPlan {
  purchaseId: number;
  productId: number;
  name: string;
  amount: number;
  recurring: PaymentPlanRecurring;
  trialEndDate: string | null;
  activeUntil: string | null;
  activeProjectsAmount: number;
  maxProjectsAmount: number | null;
  status: PaymentPlanStatus;
  type: 'BILLING_PLAN' | 'BILLING_PLAN_TRIAL';
  needUpdatePaymentMethod: boolean;
  promoCodeType?: PromoCodeEnum;
  isPendingForCancel: boolean;
}

export interface IBillingPlan {
  productId: number;
  type: 'BILLING_PLAN' | 'BILLING_PLAN_TRIAL';
  name: string;
  amount: number;
  recurring: PaymentPlanRecurring;
  trialEndDate: string;
  activeUntil: string;
  activeProjectsAmount: number;
  maxProjectsAmount: number;
  status: PaymentPlanStatus;
  purchaseId: number;
}

export interface IPaymentPlanBodyData {
  data: {
    productId: number;
    firstName: string;
    lastName: string;
    email: string;
    stripeKeys?: {
      stripeToken: string;
    };
    creditCard?: {
      ccExpireMonth: number;
      ccExpireYear: string;
      ccNum: string;
      type: string;
    };
    couponCode?: string;
  };
}

export interface IPaymentPlanUpdateBody {
  data: {
    productId: number;
    couponCode: string;
  };
}

export interface ICancelSubscriptionBody {
  data: {
    purchaseId: number;
  };
}

export interface IUpdatePaymentMethodBody {
  data: {
    ccExpireMonth: number;
    ccExpireYear: string;
    ccNum: string;
    type: string;
    stripeKeys: {
      stripeToken: string;
    };
  };
}

export interface IPromoCodeBody {
  data: { code: string | null };
}
