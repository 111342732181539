import React, { memo, FC, ButtonHTMLAttributes } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type TButtonSize = 'large' | 'small';
type TButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'text' | 'outlined' | 'error';

type TVariantClasses = Record<TButtonVariant, string>;

interface OwnProps {
  loading?: boolean;
  disabled?: boolean;
  size?: TButtonSize;
  className?: string;
  startIcon?: any;
  variant?: TButtonVariant;
  endIcon?: any;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  label: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: any;
}

const VARIANT_CLASSES: TVariantClasses = {
  primary: 'primary-btn-custom',
  secondary: 'secondary-btn-custom',
  tertiary: 'tertiary-btn-custom',
  text: 'text-btn-custom',
  outlined: 'outlined-btn-custom',
  error: 'error-btn-outlined',
};

const ActionButton: FC<OwnProps> = ({
  label,
  startIcon,
  endIcon,
  onClick,
  loading = false,
  disabled = false,
  variant = 'primary',
  className = '',
  size = 'large',
  type,
  style,
}) => {
  return (
    <button
      style={style}
      disabled={disabled || loading}
      data-size={size}
      type={type}
      onClick={onClick}
      className={`${VARIANT_CLASSES[variant]} ${loading && 'loading'} ${className}`}
    >
      {loading ? (
        <>
          <CircularProgress size={20} color="inherit" />
        </>
      ) : (
        <>
          {startIcon && <span>{startIcon}</span>}
          <span>{label}</span>
          {endIcon && <span>{endIcon}</span>}
        </>
      )}
    </button>
  );
};

export default memo(ActionButton);
