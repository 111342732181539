// mui components
import { CircularProgress, Grid, Typography } from '@mui/material';
// components
import StyledInputContainerNewUi from 'pages/auth/components/new-ui/styled-input-container-new-ui';
// controllers
import SelectController from 'controllers/select-controller';
//styles
import { setScheduleFormStyles } from '../styles/styles';

const SetScheduleFormNewUI = ({ isFirstProject, templates, templateSelectValues }) => {
  return (
    <Grid container flexDirection="column" flex={1} sx={setScheduleFormStyles.selectScheduleBody}>
      {isFirstProject && (
        <>
          <Typography sx={setScheduleFormStyles.setSchedule}>Set Schedule</Typography>

          <Typography sx={setScheduleFormStyles.selectSchedule}>
            Select Schedule template or start from scratch
          </Typography>
        </>
      )}

      <Grid container className="set-schedule" sx={setScheduleFormStyles.templatesBody}>
        {templates ? (
          <Grid container flexDirection="column" item xs={12}>
            <StyledInputContainerNewUi>
              <SelectController
                fullWidth
                isSelect
                name="templateId"
                label="Schedule Template"
                options={templateSelectValues}
                disabled={templateSelectValues.length === 0}
                placeholder={templateSelectValues.length === 0 ? 'Loading...' : 'Select a template'}
              />
            </StyledInputContainerNewUi>
          </Grid>
        ) : (
          <Grid container sx={setScheduleFormStyles.circularProgress}>
            <CircularProgress size={40} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SetScheduleFormNewUI;
