import useSWR, { SWRConfiguration, SWRResponse, Key } from 'swr';
import { GetAxiosResponse } from 'utils/axios.instance';

type TRequest = <T>(
  key: Key,
  fetcher: GetAxiosResponse,
  options?: SWRConfiguration
) => SWRResponse<T, Error>;

export const useRequest: TRequest = (key, fetcher, options) => {
  const requestOptions: SWRConfiguration = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    ...options,
  };
  return useSWR(key, fetcher, requestOptions);
};
