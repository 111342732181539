import React, { useCallback, useState, FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import _ from 'lodash';
// mui components
import { Box, Button, Switch, Grid, Typography } from '@mui/material';
// components
import User from 'components/file-cabinet-folders/modals/components/user';
import ActionButton from 'components/buttons/index';
// hooks
import useToast from 'hooks/useToast';
// api
import {
  IFolder,
  useUpdateFileCabinetUserMutation,
  useUpdateFoldersVisibilityMutation,
} from 'redux/api/file-cabinet';
// icons
import { ReactComponent as CopyIcon } from 'assets/icons/cabinet/copy.svg';
// styles
import { styles } from './styles';

type Props = {
  folder?: IFolder;
  projectId: string | number;
  handleClose: () => void;
};

const ManagePermissionsUsersContent: FC<Props> = ({ folder, projectId, handleClose }) => {
  const [isPublic, setIsPublic] = useState(false);
  const showToast = useToast();

  const [updateCabinetUsers, { isLoading }] = useUpdateFileCabinetUserMutation();
  const [updateFoldersVisibility, { isLoading: isLoadingFolderVisibility }] =
    useUpdateFoldersVisibilityMutation();

  useEffect(() => {
    if (!!folder?.isPublic) {
      setIsPublic(folder?.isPublic);
    }
  }, [folder]);

  const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  };

  const usersForm = useForm({
    defaultValues: {
      users: folder?.users ? folder?.users : [],
    },
    mode: 'onChange',
  });

  const fields = usersForm.watch('users');

  const onUsersSubmit = useCallback(
    async (formValues) => {
      const editedObjects = formValues?.users?.filter((editedItem) => {
        const originalItem = folder?.users?.find(
          (item) => Number(item?.id) === Number(editedItem?.id)
        );

        return !_.isEqual(originalItem, editedItem);
      });

      const updatingUsersData = editedObjects?.map((item) => ({
        profileId: Number(item?.id),
        itemId: Number(folder?.id),
        permissions: item?.permissions,
      }));

      updateCabinetUsers(updatingUsersData)
        .unwrap()
        .then(() => {
          showToast({
            message: 'Users updated successfully!',
            type: 'success',
          });
        })
        .catch(() => {
          showToast({
            message: 'Something went wrong!',
            type: 'error',
          });
        });
    },
    [usersForm]
  );

  const toggleMakePublic = useCallback((e) => {
    updateFoldersVisibility({ itemId: folder.id, isPublic: e.target.checked, projectId: projectId })
      .unwrap()
      .then(() => {
        showToast({ type: 'success', message: 'Updated successfully' });
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Something went wrong' });
      });

    setIsPublic((prev) => !prev);
  }, []);

  const handleCopy = async () => {
    try {
      if (!!folder?.accessKey) {
        await navigator.clipboard.writeText(
          `${process.env.REACT_APP_PUBLIC_URL}public-file-cabinet?accessKey=${folder?.accessKey}`
        );
        showToast({ type: 'success', message: 'Link copied' });
      }
    } catch (err) {
      showToast({ type: 'error', message: 'Something went wrong' });
    }
  };

  return (
    <Box>
      <FormProvider {...usersForm}>
        <form onSubmit={usersForm.handleSubmit(onUsersSubmit)}>
          <Box sx={styles.usersContainer}>
            <Grid sx={styles.rolesTableHead}>
              <Grid sx={styles.roleTableTitle}>User</Grid>
              <Grid sx={styles.roleTableItem}>View</Grid>
              <Grid sx={styles.roleTableItem}>Add/Edit</Grid>
              <Grid sx={styles.roleTableItem}>Delete</Grid>
              <Grid sx={styles.roleTableEmptyItem}></Grid>
            </Grid>

            {fields?.map((user, index) => (
              <User
                key={`${user?.permissions?.canRead}+${user?.permissions?.canAddUpdate}+${user?.permissions?.canDelete}+${index}`}
                user={user}
                index={index}
                getInitials={getInitials}
              />
            ))}
          </Box>

          <Grid sx={styles.makePublicWrapper}>
            <Grid>
              <Typography sx={styles.makePublicTitle}>Make public</Typography>
              <Typography sx={styles.makePublicSubTitle}>
                Copy link and send to your friend.
              </Typography>
            </Grid>

            <Switch
              sx={styles.switch}
              onChange={toggleMakePublic}
              disabled={isLoadingFolderVisibility}
              defaultChecked={folder?.isPublic}
            />

            {!!isPublic && (
              <Grid sx={styles.copyBtnWrapper}>
                <Typography sx={styles.copyUrl}>
                  {folder?.accessKey
                    ? `${process.env.REACT_APP_PUBLIC_URL}public-file-cabinet?accessKey=${folder?.accessKey}`
                    : ''}
                </Typography>

                <Button
                  startIcon={<CopyIcon />}
                  sx={styles.copyBtn}
                  disableRipple
                  onClick={handleCopy}
                  disabled={!folder?.accessKey}
                >
                  Copy
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid sx={styles.actions}>
            <ActionButton variant="secondary" onClick={handleClose} label="Cancel" />
            <ActionButton loading={isLoading} type="submit" label="Save" />
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ManagePermissionsUsersContent;
