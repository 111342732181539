import { api } from 'redux/api';
import {
  IOneTimeEntryBody,
  IOneTimeEntryResponse,
  IPasswordResetBody,
  IPasswordResetResponse,
  IResendVerificationEmailBody,
  IResendVerificationEmailResponse,
  ISendPasswordResetBody,
  ISignInBody,
  ISignInResponse,
  ISignUpBody,
  ISignUpResponse,
  IUpdatePasswordBody,
  IVerifyEmailBody,
  IVerifyEmailResponse,
  TGetSsoTokenResponse,
} from './';
import { IUserMe } from 'redux/types';
import { clearAuthData, getAuthData, setAuthData } from 'utils/authData';
import { postMessage, BroadcastChannelMessagesEnum } from 'hooks/useBroadcastChannel';
// slices
import {
  handleSetActiveStep,
  handleSetCompleted,
  AccountStepEnum,
} from 'redux/slices/account-stepper-slice';
import {
  handleAuth,
  handleSetCompanyId,
  handleDeleteCompanyId,
  handleSetInactive,
} from 'redux/slices/auth-slice';
import { dashlyAuth } from 'utils/dashlyAuth';
import {
  handleShowDownloadApp,
  handleSetAllowShowDownloadModal,
} from 'redux/slices/from-mobile-slice';
import { RootState } from 'redux/store';

enum Endpoints {
  AUTH = 'auth/',
  USERS = 'users/',
  REMOVE = 'remove-user/me',
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMe: build.query<IUserMe, void>({
      query: () => ({
        url: `${Endpoints.AUTH}me`,
      }),
      providesTags: ['User', 'Current Plan'],
      onQueryStarted: async (_, { queryFulfilled, dispatch, getState }) => {
        try {
          const userMeResponse = await queryFulfilled;
          sessionStorage.setItem('userEmail', userMeResponse?.data?.email);
          sessionStorage.setItem(
            'userName',
            `${userMeResponse?.data?.firstName} ${userMeResponse?.data?.lastName}`
          );
          const hasRoles = userMeResponse?.data?.roles?.length;
          if (!hasRoles) {
            if (!userMeResponse?.data?.isActive) {
              dispatch(handleSetInactive());
              dispatch(handleSetActiveStep(AccountStepEnum.companyProfile));
            } else {
              dispatch(handleSetActiveStep(AccountStepEnum.companyProfile));
            }
            dispatch(handleDeleteCompanyId());
            dispatch(handleSetCompleted(false));
          }
          if (hasRoles) {
            dispatch(handleSetCompleted(true));
          }

          if (
            (getState() as RootState)?.fromMobile?.isMobile &&
            (getState() as RootState)?.fromMobile?.allowShowDownloadModal
          ) {
            dispatch(handleShowDownloadApp(true));
          }

          const companyId = hasRoles
            ? userMeResponse.data.roles.find(
                ({ isOwner, isSubscription }) => isOwner || isSubscription
              )?.companyId
            : null;

          if (!localStorage.getItem('company-id') && companyId) {
            localStorage.setItem('company-id', companyId.toString());
            dispatch(handleSetCompanyId(companyId.toString()));
          }
          if (!getAuthData()?.['company-id'] && companyId) {
            setAuthData({
              'company-id': companyId.toString(),
            });
          }

          const companyMatch = userMeResponse.data.roles.find(
            (company) =>
              localStorage.getItem('company-id').toString() === company.companyId.toString()
          );

          if (!companyMatch || (!companyMatch.isSubscription && !companyMatch.isOwner)) {
            const compId = userMeResponse.data.roles
              .find(({ isOwner, isSubscription }) => isOwner || isSubscription)
              .companyId.toString();

            dispatch(handleSetCompanyId(compId));
            localStorage.setItem('company-id', compId);
            setAuthData({
              'company-id': compId,
            });
          }

          postMessage({ message: BroadcastChannelMessagesEnum.LOG_IN });
          dashlyAuth(
            userMeResponse?.data?.userId?.toString(),
            userMeResponse?.data?.email,
            `${userMeResponse?.data?.firstName} ${userMeResponse?.data?.lastName}`,
            userMeResponse?.data?.phone
          );
        } catch (err) {
          if (err?.error?.status === 404) {
            dispatch(handleDeleteCompanyId());
            dispatch(handleSetActiveStep(AccountStepEnum.myProfile));
            dispatch(handleSetCompleted(false));

            if (
              (getState() as RootState)?.fromMobile?.isMobile &&
              (getState() as RootState)?.fromMobile?.allowShowDownloadModal
            ) {
              dispatch(handleShowDownloadApp(true));
            }
          } else if (err?.error?.status === 401) {
            localStorage.clear();
            clearAuthData();
            dispatch(handleAuth(false));
            dispatch(handleDeleteCompanyId());
            dispatch(handleSetCompleted(null));
          }
        }
      },
    }),

    getSsoToken: build.query<TGetSsoTokenResponse, void>({
      query: () => ({
        url: `${Endpoints.AUTH}sso-token`,
      }),
    }),

    signIn: build.mutation<ISignInResponse, ISignInBody>({
      query: (data) => ({
        url: `${Endpoints.AUTH}sign-in`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          localStorage.setItem('userId', response.id.toString());
          if (response?.accessToken) {
            localStorage.setItem('Authorization', `Bearer ${response.accessToken}`);
            setAuthData({ Authorization: `Bearer ${response.accessToken}` });
            dispatch(handleAuth(true));
            dispatch(handleSetAllowShowDownloadModal(true));
          }
        } catch (err) {
          dispatch(handleAuth(false));
        }
      },
    }),

    signUp: build.mutation<ISignUpResponse, ISignUpBody>({
      query: (data) => ({
        url: `${Endpoints.AUTH}sign-up`,
        body: data,
        method: 'POST',
      }),
    }),
    oneTimeEntry: build.mutation<IOneTimeEntryResponse, IOneTimeEntryBody>({
      query: (data) => ({
        url: `${Endpoints.AUTH}one-time-entry`,
        body: data,
        method: 'POST',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          localStorage.setItem('userId', response?.data?.id?.toString());
          if (response?.data?.accessToken) {
            localStorage.setItem('Authorization', `Bearer ${response.data.accessToken}`);
            setAuthData({ Authorization: `Bearer ${response.data.accessToken}` });
            dispatch(handleAuth(true));
          }
        } catch (err) {}
      },
      invalidatesTags: ['User'],
    }),

    verifyEmail: build.mutation<IVerifyEmailResponse, IVerifyEmailBody>({
      query: (data) => ({
        url: `${Endpoints.AUTH}verify-email`,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          localStorage.setItem('userId', response.id.toString());
          localStorage.setItem('Authorization', `Bearer ${response.accessToken}`);
          setAuthData({ Authorization: `Bearer ${response.accessToken}` });
          dispatch(handleSetAllowShowDownloadModal(true));
          dispatch(handleAuth(true));
        } catch {
          dispatch(handleAuth(true));
        }
      },
    }),
    resendVerificationEmail: build.mutation<
      IResendVerificationEmailResponse,
      IResendVerificationEmailBody
    >({
      query: (data) => ({
        url: `${Endpoints.AUTH}resend-verification-email`,
        body: data,
        method: 'POST',
      }),
    }),
    sendPasswordReset: build.mutation<undefined, ISendPasswordResetBody>({
      query: (data) => ({
        url: `${Endpoints.AUTH}send-password-reset`,
        body: data,
        method: 'POST',
      }),
    }),
    passwordReset: build.mutation<IPasswordResetResponse, IPasswordResetBody>({
      query: (data) => ({
        url: `${Endpoints.AUTH}password-reset`,
        body: data,
        method: 'PATCH',
      }),
    }),
    updatePassword: build.mutation<undefined, IUpdatePasswordBody>({
      query: ({ id, ...data }) => ({
        url: `${Endpoints.USERS}${id}/password`,
        body: data,
        method: 'PATCH',
      }),
    }),
    removeUser: build.mutation<undefined, void>({
      query: () => ({
        url: Endpoints.REMOVE,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useLazyGetMeQuery,
  useGetSsoTokenQuery,
  useLazyGetSsoTokenQuery,
  useOneTimeEntryMutation,
  usePasswordResetMutation,
  useResendVerificationEmailMutation,
  useSendPasswordResetMutation,
  useSignInMutation,
  useSignUpMutation,
  useVerifyEmailMutation,
  useUpdatePasswordMutation,
  useRemoveUserMutation,
} = authApi;
