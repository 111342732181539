import { api } from 'redux/api';
// types
import {
  IPaymentMethod,
  IPaymentPlan,
  ICurrentPaymentPlan,
  IPaymentPlanBodyData,
  IBillingPlan,
  IPaymentPlanUpdateBody,
  ICancelSubscriptionBody,
  IUpdatePaymentMethodBody,
  IPromoCodeBody,
} from './billing.types';
import { setPaymentPlan } from 'redux/slices/billing-slice';

enum Endpoints {
  BILLING = 'payments/plans',
  PAYMENT_METHODS = 'payments/payment-methods/',
}

export const billingApi = api.injectEndpoints({
  endpoints: (build) => ({
    setPaymentPlan: build.mutation<IBillingPlan, IPaymentPlanBodyData>({
      query: ({ data }) => ({
        url: Endpoints.BILLING,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Company', 'Current Plan', 'User', 'Payment Methods'],
      onQueryStarted: (_, { dispatch }) => {
        dispatch(setPaymentPlan({ paymentPlanId: null, couponCode: null }));
      },
    }),
    updatePaymentPlan: build.mutation<IBillingPlan, IPaymentPlanUpdateBody>({
      query: ({ data }) => ({
        url: `${Endpoints.BILLING}`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: ['Current Plan'],
    }),
    cancelSubscription: build.mutation<void, ICancelSubscriptionBody>({
      query: ({ data }) => ({
        url: `${Endpoints.BILLING}/cancel`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Company', 'Current Plan'],
    }),
    getPlans: build.query<IPaymentPlan, void>({
      query: () => ({
        url: `${Endpoints.BILLING}`,
      }),
      providesTags: ['Payment Plans'],
    }),
    getCurrentPaymentPlan: build.query<ICurrentPaymentPlan, void>({
      query: () => ({
        url: `${Endpoints.BILLING}/current`,
      }),
      providesTags: ['Current Plan'],
    }),
    getPaymentMethods: build.query<IPaymentMethod, void>({
      query: () => ({
        url: `${Endpoints.PAYMENT_METHODS}`,
      }),
      providesTags: ['Payment Methods'],
    }),
    updatePaymentMethod: build.mutation<IPaymentMethod, IUpdatePaymentMethodBody>({
      query: ({ data }) => ({
        url: `${Endpoints.PAYMENT_METHODS}`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: ['Payment Methods'],
    }),
    deletePaymentMethod: build.mutation<IPaymentMethod, void>({
      query: () => ({
        url: `${Endpoints.PAYMENT_METHODS}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Payment Methods'],
    }),
    cancelDeactivation: build.mutation<ICurrentPaymentPlan, void>({
      query: () => ({
        url: `${Endpoints.BILLING}/current/reactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['Current Plan'],
    }),
    applyPromoCode: build.mutation<void, IPromoCodeBody>({
      query: ({ data }) => ({
        url: `promo-code`,
        body: { code: data.code.toUpperCase() },
        method: 'POST',
      }),
      invalidatesTags: ['Current Plan', 'Payment Plans'],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useGetCurrentPaymentPlanQuery,
  useGetPaymentMethodsQuery,
  useSetPaymentPlanMutation,
  useUpdatePaymentPlanMutation,
  useCancelSubscriptionMutation,
  useDeletePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useApplyPromoCodeMutation,
  useCancelDeactivationMutation,
} = billingApi;
