export const styles = {
  contentWrapper: {
    padding: '0 40px',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#23252F',
    marginBottom: '8px',
  },
  progressBar: {
    width: '100%',
    height: '8px',
    borderRadius: '8px',
    background: '#F3F4F9',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    borderRadius: '8px',
    '&.isRed': {
      background: '#D12D36',
    },
    '&.isYellow': {
      background: '#D3AC1E',
    },
    '&.isBlue': {
      background: '#4D6EF5',
    },
  },
  divider: {
    marginTop: '20px',
    borderColor: '#F3F4F9',
  },
};
