import React, { FC, memo } from 'react';
import merge from 'lodash.merge';
import upperfirst from 'lodash.upperfirst';
import { useNavigate } from 'react-router-dom';
// mui components
import { Avatar, Grid, IconButton, Typography } from '@mui/material';
// components
import DefaultAvatar from 'components/default-avatar';
// custom hooks
import { useSidePanel } from 'hooks/useSidePanel';
// api
import { IProjectProfiles } from 'redux/api/projects';
import { IStyles } from 'redux/types';
// helpers
import { getImageBySize } from 'utils/helpers';
// icons
import { ReactComponent as CommentIcon } from 'assets/icons/buttons/comment-icon.svg';
import { ReactComponent as MailboxIcon } from 'assets/icons/buttons/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/buttons/phone.svg';
// styles
import { styles } from './styles';
import { useGetMeQuery } from 'redux/api/auth';

interface IUserCardProps {
  profile: IProjectProfiles;
  customStyles?: IStyles;
  closeModal: () => void;
}

const UserCard: FC<IUserCardProps> = ({ profile, customStyles, closeModal }) => {
  const navigate = useNavigate();

  const {
    onSetMentionedProfile,
    handleExpandeEditor,
    isSidePanelOpen,
    handleOpenActivityFeed,
    projectId,
  } = useSidePanel();

  const { data: userMe } = useGetMeQuery();

  const initials = `${profile.firstName[0]}${profile.lastName[0]}`;

  const containerStyles = customStyles ? merge(styles.container, customStyles) : styles.container;

  const onMessageIconClick = () => {
    if (window.location.pathname.includes('project-dashboard')) {
      onSetMentionedProfile(profile);
      handleExpandeEditor(true);
      closeModal();
    } else {
      onSetMentionedProfile(profile);
      if (!isSidePanelOpen) {
        handleOpenActivityFeed();
      }
      handleExpandeEditor(true);
      navigate(`/project-dashboard/${projectId}`);
    }
  };

  return (
    <Grid sx={containerStyles}>
      <Grid sx={styles.firstSectionContainer}>
        <Grid sx={styles.avatarContainer}>
          {profile?.avatar ? (
            <Avatar
              alt="avatar"
              sx={styles.avatar}
              src={getImageBySize(profile.avatar, 40, 40)}
              variant="square"
            />
          ) : (
            <DefaultAvatar initials={initials} variant="square" sx={styles.avatar} />
          )}
          <Grid sx={styles.nameContainer}>
            <Typography sx={styles.name}>{`${profile.firstName} ${profile.lastName}`}</Typography>
            <Typography sx={styles.role}>
              {upperfirst(profile?.role?.toLowerCase() ?? '')}
            </Typography>
          </Grid>
        </Grid>
        {userMe?.id !== profile?.id ? (
          <IconButton onClick={onMessageIconClick} sx={styles.messageIcon}>
            <CommentIcon />
          </IconButton>
        ) : null}
      </Grid>
      <Grid sx={styles.contactsContainer}>
        <Grid sx={styles.contactSectionContainer}>
          <PhoneIcon />
          <Typography sx={styles.userContact}>{profile.phone ? profile.phone : '-'}</Typography>
        </Grid>
        <Grid sx={styles.contactSectionContainer}>
          <MailboxIcon />
          <Typography sx={styles.userContact}>{profile.email}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(UserCard);
