import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Coords, Address } from 'components/google-map';
// components
import CreateProjectFormNewUI from './new-ui';
import { SelectChangeEvent } from '@mui/material';
// custom hooks
import { useParseAddress } from 'hooks/useParseAddress';
// api
import { useGetCountryRegionsQuery } from 'redux/api/rapid';
import { firstAddressHelper } from 'utils/helpers/firstAddressHelper';

interface OwnProps {
  isFirstProject?: boolean;
  coordinates: Coords | undefined;
  updateCoordinates: (currentCoords: Coords) => void;
  updateAddress: (currentAddress: Address) => void;
  country?: string;
  handleCountryChange: (e: SelectChangeEvent<string>) => void;
}

const CreateProjectForm: React.FC<OwnProps> = ({
  isFirstProject,
  coordinates,
  updateCoordinates,
  updateAddress,
  country,
  handleCountryChange,
}) => {
  const { parseAddress } = useParseAddress();
  const form = useFormContext();

  const { data: states } = useGetCountryRegionsQuery(
    { limit: '100', country: country },
    {
      skip: !country,
      selectFromResult: (queryState) => ({
        ...queryState,
        data: queryState.data?.data?.length
          ? queryState.data.data.map(({ name }) => ({ label: name, value: name }))
          : [],
      }),
    }
  );

  const handleParseAddress = useCallback(
    async (placeId: string) => {
      const { address, city, zip, state, latLng, stateAbbr } = await parseAddress(placeId, states);

      if (latLng) {
        updateCoordinates(latLng);
      }

      if (address) {
        const newAddress = firstAddressHelper(address);

        form.setValue('address', newAddress);
      }
      form.setValue('country', country);
      form.setValue('city', city);
      form.setValue('zip', zip);
      form.setValue('state', state);
      form.setValue('stateAbbr', stateAbbr);
      form.clearErrors(['country', 'city', 'zip', 'state', 'stateAbbr']);
    },
    [parseAddress, states, form, country, updateCoordinates]
  );

  const props = {
    isFirstProject,
    coordinates,
    country,
    states,
    updateCoordinates,
    handleParseAddress,
    updateAddress,
    handleCountryChange,
  };

  return <CreateProjectFormNewUI {...props} />;
};

export default CreateProjectForm;
