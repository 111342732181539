import { api } from 'redux/api';

import { ILoginAsUser, ILoginAsUserResponse } from './';

enum Endpoints {
  SUPER_ADMIN = 'super-admin/',
}

export const superAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginAsSuperAdmin: build.mutation<ILoginAsUserResponse, ILoginAsUser>({
      query: (data) => ({
        url: `${Endpoints.SUPER_ADMIN}login-as-user`,
        body: data,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginAsSuperAdminMutation } = superAdminApi;
