export const styles = {
  avatarGroup: {
    justifyContent: 'flex-end',
    maxWidth: '92px',

    '& .MuiAvatar-root': {
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      background: '#EDEFF7',
      color: '#8C8F9D',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '146%',
    },
    '&.small': {
      width: 'fit-content',
      '& .MuiAvatar-root': {
        width: '24px',
        height: '24px',
        fontSize: '12px',
      },
    },
  },
  tooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#23252F',
      borderRadius: '4px',
      padding: '4px 8px',
      fontFamily: 'Inter',
      fontSize: '10px',
      textTransform: 'capitalize',
      '&.MuiTooltip-tooltipPlacementTop': {
        marginBottom: '4px',
      },
    },
  },
};
