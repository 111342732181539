import React, { FC, useRef, KeyboardEvent, useState, FocusEvent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// mui components
import { Avatar, CircularProgress, IconButton, SxProps } from '@mui/material';
// components
import DefaultAvatar from 'components/default-avatar';
// custom hooks
import useToast from 'hooks/useToast';
// api
import { IUpdatePostBody, useUpdatePostMutation } from 'redux/api/activity-feed';
import { useGetMeQuery } from 'redux/api/auth';
// helpers
import { getImageBySize } from 'utils/helpers';
// icons
import { ReactComponent as SendIcon } from 'assets/icons/buttons/checkbox_checked.svg';
// styles
import './styles.css';

interface IProps {
  id: string;
  description: string;
  editablePostData: IUpdatePostBody;
  isDescriptionEditable?: boolean;
  updateDescription?: (id: string, description: string) => void;
}

interface IFormValues {
  description: string;
}

const getButtonStyles = (isFocused: boolean): SxProps =>
  isFocused
    ? {
        display: 'flex',
        backgroundColor: '#4d6ef5',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#4d6ef5',
        },
      }
    : {
        display: 'none',
      };

const getInputContainerClassName = (isFocused: boolean): string =>
  `description-input-container ${isFocused ? 'description-input-container-focused' : ''}`;

const ItemDescription: FC<IProps> = ({
  description,
  id,
  editablePostData,
  isDescriptionEditable,
  updateDescription,
}) => {
  const showToast = useToast();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { data: userMe } = useGetMeQuery();

  const [updatePost, { isLoading }] = useUpdatePostMutation();

  const form = useForm<IFormValues>({ defaultValues: { description } });

  const { ref, ...inputMethods } = form.register('description');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const setInpuRef = (e: HTMLInputElement) => {
    ref(e);
    inputRef.current = e;
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const handleBlurInput = () => {
    inputRef?.current.blur();
  };

  const onSubmit = (formValues: IFormValues) => {
    if (updateDescription) {
      updateDescription(id, formValues.description);
      handleBlurInput();
    } else {
      const data: IUpdatePostBody = {
        ...editablePostData,
        content: !editablePostData?.content ? null : editablePostData?.content,
        uploads: editablePostData.uploads.map(({ id: itemId, description: itemDescription }) => ({
          id: itemId,
          description:
            id === itemId
              ? formValues.description
                ? formValues.description
                : null
              : itemDescription,
        })),
      };

      updatePost(data)
        .unwrap()
        .then(handleBlurInput)
        .catch((err) => {
          showToast({
            type: 'error',
            message:
              typeof err?.data?.message === 'string'
                ? err?.data?.message
                : 'Something went wrong. Please try again or contact support for help.',
          });
        });
    }
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>): KeyboardEvent<HTMLInputElement> => {
    e.stopPropagation();
    return e;
  };

  const renderAvatar = (
    <div
      className={
        isFocused
          ? 'description-input-container-avatar-focused'
          : 'description-input-container-avatar'
      }
    >
      {userMe?.avatar ? (
        <Avatar
          alt="avatar"
          sx={{
            width: '20px',
            height: '20px',
            borderRadius: '4px',
          }}
          src={getImageBySize(userMe.avatar, 20, 20)}
          variant="circular"
        />
      ) : (
        <DefaultAvatar
          initials={`${userMe.firstName[0]}${userMe.lastName[0]}`}
          variant="rounded"
          alt="avatar"
          sx={{ borderRadius: '4px', width: '20px', height: '20px', fontSize: '12px' }}
        />
      )}
    </div>
  );

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          form.handleSubmit(onSubmit)(event);
        }}
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <div className={getInputContainerClassName(isFocused)}>
          {renderAvatar}
          <input
            {...inputMethods}
            defaultValue={description}
            placeholder={isDescriptionEditable ? 'Click to type a description...' : ''}
            autoComplete="off"
            className="description-input"
            ref={setInpuRef}
            onKeyDown={onInputKeyDown}
            disabled={!isDescriptionEditable}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          {isLoading ? (
            <div className="description-loader-container">
              <CircularProgress size={20} />
            </div>
          ) : (
            <IconButton
              type="submit"
              onMouseDown={(e) => e.preventDefault()}
              sx={getButtonStyles(isFocused)}
            >
              <SendIcon height={13} width={13} />
            </IconButton>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ItemDescription;
