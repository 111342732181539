import React, { FC, useCallback } from 'react';
// mui components
import { Grid, Typography } from '@mui/material';
// components
import ActionButton from 'components/buttons';
import DialogModal from 'components/modal/dialog-modal';
// hooks
import useToast from 'hooks/useToast';
// api
import {
  useDeleteFileCabinetItemMutation,
  useDeleteDefaultFolderItemMutation,
} from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isFolder: boolean;
  itemId: string | number;
  fromModal: boolean;
  projectId: string | number;
}

const DeleteFileItemModal: FC<IProps> = ({
  open,
  onClose,
  title,
  projectId,
  fromModal,
  isFolder,
  itemId,
}) => {
  const showToast = useToast();

  const [handleDeleteItem, { isLoading }] = useDeleteFileCabinetItemMutation();
  const [handleDeleteDefaultItem, { isLoading: defaultFolderLoading }] =
    useDeleteDefaultFolderItemMutation();

  const subTitle = useCallback(() => {
    if (isFolder) {
      return 'Are you sure you want to delete this folder, including all of the items inside of it?  This cannot be undone.';
    }

    return 'Are you sure you want to delete this file?';
  }, [isFolder]);

  const onConfirm = useCallback(() => {
    if (fromModal) {
      handleDeleteItem({ itemId: itemId, projectId: projectId })
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(() => {
          showToast({
            message: 'Something went wrong!',
            type: 'error',
          });
        });
    }

    if (!fromModal) {
      handleDeleteDefaultItem(itemId)
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(() => {
          showToast({
            message: 'Something went wrong!',
            type: 'error',
          });
        });
    }
  }, [isFolder]);

  return (
    <DialogModal open={open} maxWidth="466px" withCloseButton={false} handleClose={onClose}>
      <Grid container sx={{ mb: '10px' }} alignItems="center">
        <Typography sx={styles.title}>{title}</Typography>
      </Grid>
      <Typography sx={styles.text}>{subTitle()}</Typography>

      <Grid container justifyContent="flex-end" sx={{ mt: '20px' }} gap={2}>
        <ActionButton variant="secondary" onClick={onClose} label="Cancel" />
        <ActionButton
          onClick={onConfirm}
          loading={isLoading || defaultFolderLoading}
          label="Delete"
        />
      </Grid>
    </DialogModal>
  );
};
export default DeleteFileItemModal;
