export const styles = {
  container: {
    width: '100%',
    height: '100%',
    maxHeight: '550px',
    overflowX: 'auto',
    overflowY: 'hidden',

    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '10px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },

  loaderContainer: {
    display: 'flex',
    height: '550px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
