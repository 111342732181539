import { FC } from 'react';
// mui components
import { Portal } from '@mui/material';
// components
import { Viewer } from './components/viewer';
// types
import { IViewer } from './entities';

const PhotoViewer: FC<IViewer> = ({ isOpen, ...props }) => {
  if (!isOpen) return null;

  return (
    <Portal>
      <Viewer {...props} />
    </Portal>
  );
};

export default PhotoViewer;
export type { IViewer as IGalleryProps } from './entities';
