import { memo } from 'react';
// mui components
import { styled } from '@mui/material/styles';

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export default memo(StyledForm);
