import { typoSm } from 'pages/selections/styles';

export const selecNativeStyles = {
  inputAdornment: {
    margin: 0,
    '& div': {
      display: 'flex',
    },
  },

  inputAdornmentContainer: {
    marginRight: '20px',
  },

  selecNativeCountry: {
    '& div': {
      display: 'flex',
      alignItems: 'center',

      '& .checkIcon': {
        display: 'none !important',
      },
    },

    '& svg': {
      left: '45px',
    },
  },

  selecNative: {
    '& div': {
      display: 'flex',
      alignItems: 'center',

      '& .MuiGrid-root': {
        maxWidth: '100%',
        overflow: 'hidden',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        wordBreak: 'keep-all',
      },

      '& .checkIcon': {
        display: 'none !important',
      },
    },

    '& .avatarLabel': {
      paddingRight: '10px',
    },

    '& .avatarIcon': {
      width: '20px !important',
      height: '20px !important',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '146%',
      color: '#23252F',
      borderRadius: '6px',
      marginRight: '8px !important',
    },

    '& .avatarlabelContainer': {
      '& .avatarRole': {
        display: 'none',
      },

      '& .avatarEmail': {
        display: 'none',
      },
    },
  },

  optionContainer: {
    position: 'relative',
  },

  option: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 16px',
    borderRadius: '8px',
    margin: '0 2px',

    '&:hover': {
      background: '#F2F5FF',
    },

    '& .checkIcon': { display: 'none' },

    '&[aria-selected="true"]': {
      '& .checkIcon': {
        display: 'inline-block !important',
      },
    },
  },

  selectValue: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
  },

  selectValueWithAvatar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
  },

  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '4px 0',
  },

  withAvatarLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'no-wrap',
    gap: '10px',
  },

  withAvatarEmail: {
    width: '100%',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  withAvatarInitials: {
    width: '52px',
    height: '52px',
    marginRight: '16px',
    borderRadius: '8px',
    background: '#EDEFF7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '146%',
    color: '#23252F',
  },

  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
  },

  role: {
    padding: '2px 4px',
    background: '#E7ECFF',
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },

  selecNativeOptionIcon: {
    height: '28px',
    marginRight: '12px',
    borderRadius: '4px',
    overflow: 'hidden',
  },

  selecNativeOptionAvatarIcon: {
    width: '52px',
    height: '52px',
    marginRight: '16px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundSize: 'cover',
  },

  selecNativeOptionColor: {
    height: '10px',
    width: '10px',
    marginRight: '10px',
    borderRadius: '50%',
    overflow: 'hidden',
  },

  tooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#23252F',
      borderRadius: '8px',
      padding: '4px 8px',
      marginBottom: '4px !important',
      ...typoSm,
      fontSize: '10px',
      textTransform: 'capitalize',
    },
  },

  tooltipContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  searchInput: {
    height: '48px',
    border: '1px solid #EDEFF7',
    borderRadius: '10px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
    marginBottom: '12px',

    '& > div': {
      height: '48px',
    },

    '& fieldset': {
      border: 'none !important',
    },
  },
};
