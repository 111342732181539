import {
  IGetActivityFeedMediaArgs,
  TActivityFeedMediaMapped,
  TActivityFeedMediaWithIndex,
} from './';
import { BaseQueryFn, BaseQueryMeta } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const mergeActivityFeedMedia = (
  currentCache: TActivityFeedMediaMapped,
  responseData: TActivityFeedMediaMapped,
  _: {
    arg: IGetActivityFeedMediaArgs;
    baseQueryMeta: BaseQueryMeta<BaseQueryFn>;
    requestId: string;
    fulfilledTimeStamp: number;
  }
): TActivityFeedMediaMapped => {
  const currentGroupedItems: Record<string, TActivityFeedMediaWithIndex[]> = Object.fromEntries(
    currentCache.items
  );

  responseData.items.forEach(([key, items]) => {
    if (Array.isArray(currentGroupedItems[key])) {
      currentGroupedItems[key] = [...currentGroupedItems[key], ...items];
    } else {
      currentGroupedItems[key] = items;
    }
  });

  return {
    items: Object.entries(currentGroupedItems),
    meta: responseData.meta,
  };
};
