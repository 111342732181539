import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export const postDateHelper = (
  date?: string | number | dayjs.Dayjs | Date | undefined,
  short?: boolean,
  datePopper?: boolean
) => {
  if (!date) {
    return dayjs().format('YYYY-MM-DD') + 'T00:00:00.000Z';
  }

  if (short) {
    return dayjs(date).format('YYYY-MM-DD');
  }

  if (datePopper) {
    return dayjs(date).format('ddd MMM DD YYYY ') + '00:00:00 GMT+0000 (Greenwich Mean Time)';
  }

  return dayjs(date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
};
