import React, { useCallback, useContext, useState, createContext, ReactNode } from 'react';
// mui
import { Alert, AlertColor, Snackbar } from '@mui/material';
// icons
import { ReactComponent as PositiveNotificationIcon } from 'assets/icons/positive-notification-icon.svg';
import { ReactComponent as NegativeNotificationIcon } from 'assets/icons/negative-notification-icon.svg';

type Toast = {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
};

const styles = {
  toastSuccess: {
    backgroundColor: '#DFF0D6',
    padding: '16px',
    border: '1px solid #379552',
    borderRadius: '12px',
    filter:
      'drop-shadow(0px 4px 10px rgba(35, 37, 47, 0.1)) drop-shadow(1px 2px 2px rgba(35, 37, 47, 0.04))',
    '& .MuiAlert-icon': {
      padding: 'unset',
      marginRight: '6px',
    },
    '& .MuiAlert-message': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '146%',
      color: '#23252F',
      padding: '0 7px 0 5px',
    },
    '& .MuiAlert-action': {
      padding: 'unset',
      marginRight: '0',
      '& button': {
        padding: 'unset !important',
        color: '#23252F',
      },
    },
  },
  toastError: {
    backgroundColor: '#FAE4E6',
    padding: '16px',
    border: '1px solid #D12D36',
    borderRadius: '12px',
    filter:
      'drop-shadow(0px 4px 10px rgba(35, 37, 47, 0.1)) drop-shadow(1px 2px 2px rgba(35, 37, 47, 0.04))',
    '& .MuiAlert-icon': {
      padding: 'unset',
      marginRight: '6px',
    },
    '& .MuiAlert-message': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '146%',
      color: '#23252F',
      padding: '0 7px 0 5px',
    },
    '& .MuiAlert-action': {
      padding: 'unset',
      marginRight: '0',
      '& button': {
        padding: 'unset !important',
        color: '#23252F',
      },
    },
  },
};

const ToastContext = createContext((_toast: Toast) => {});

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Array<Toast & { id: string }>>([]);
  const addToast = useCallback(
    (toast) =>
      setToasts((prevState) => [
        ...prevState,
        { id: `${prevState.length + 1}-${Date.now()}`, ...toast },
      ]),
    [setToasts]
  );

  const onClose = (id: string) => {
    setToasts((prevState) => prevState.filter((toast) => id !== toast.id));
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {toasts.map(({ id, message, type }, index) => (
        <Snackbar
          key={index}
          open
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={3000}
          onClose={() => onClose(id)}
          ClickAwayListenerProps={{ onClickAway: () => {} }}
        >
          <Alert
            variant="filled"
            severity={type as AlertColor}
            onClose={() => onClose(id)}
            sx={type === 'success' ? styles.toastSuccess : styles.toastError}
            iconMapping={{
              success: <PositiveNotificationIcon fontSize="small" />,
              error: <NegativeNotificationIcon fontSize="small" />,
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      ))}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export default useToast;
