export const styles = {
  loadingContainer: {
    marginTop: '12px',
    padding: '24px 0',
    border: '1px solid #EDEFF7',
    borderRadius: '12px',
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalLoadingContainer: {
    marginTop: '12px',
    padding: '24px 0',
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
