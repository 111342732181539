import upperFirst from 'lodash.upperfirst';
import { ICompanyRolePermission, PERMISSION_CATEGORIES_ENUM } from 'redux/api/company-roles';

export const companyRolePermissionsMapping = (response: ICompanyRolePermission[]) => {
  if (!response || !response?.length) {
    return [];
  }

  return Object.values(PERMISSION_CATEGORIES_ENUM).map((subject) => ({
    title: upperFirst(subject.replaceAll('_', ' ').toLowerCase()),
    items: response.filter((item) => item.group === subject).sort((a, b) => a.index - b.index),
  }));
};
