import React, { useRef, useState, useCallback } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
// mui components
import { Grow, Paper, Button, Grid, MenuList, Link } from '@mui/material';
import { Popper, ClickAwayListener } from '@mui/material';
// api
import { IPermissionsScope, PERMISSIONS_SUBJECT_ENUM } from 'redux/api/company-roles';
import { ReactComponent as ChevronsIcon } from 'assets/icons/buttons/chevrons.svg';
// modals
import ProjectFileCabinetModal from 'components/file-cabinet-folders/modals/project-file-cabinet-modal';
// styles
import { styles } from './styles';
import { useGetProjectByIdQuery } from 'redux/api/projects';
const LINKS: ILink[] = [
  { id: 1, name: 'schedule', path: '/project-dashboard' },
  {
    id: 2,
    name: 'selections',
    path: '/selections',
    permissionSubject: PERMISSIONS_SUBJECT_ENUM.SELECTION_MANAGE,
    permissionScope: 'canRead',
  },
];
interface ILink {
  id: number;
  name: string;
  path?: string;
  permissionSubject?: PERMISSIONS_SUBJECT_ENUM;
  permissionScope?: keyof IPermissionsScope;
  handleItemClick?: () => void;
}
const ProjectNav = () => {
  const [openProjectNavPopper, setOpenProjectNavPopper] = useState<boolean>(false);
  const [isOpenFileCabinetModal, setIsOpenFileCabinetModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = pathname.split('/')?.[2];
  const anchorRef = useRef(null);
  const { data: projectData } = useGetProjectByIdQuery({ id: Number(id) });
  const handleOpenFileCabinetModal = () => {
    setIsOpenFileCabinetModal(true);
  };
  const handleCloseFileCabinetModal = () => {
    setIsOpenFileCabinetModal(false);
    navigate(pathname);
  };
  const handleToggleScheduleActionsPopper = useCallback(() => {
    setOpenProjectNavPopper((prev) => !prev);
  }, []);
  const handleClosePopper = useCallback(() => {
    setOpenProjectNavPopper(false);
  }, []);
  return (
    <Grid sx={styles.container}>
      <Button
        ref={anchorRef}
        variant="outlined"
        id="schedule-actions-popper-button"
        aria-controls={openProjectNavPopper ? 'schedule-actions-popper-button' : undefined}
        aria-expanded={openProjectNavPopper ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggleScheduleActionsPopper}
        sx={styles.button}
        endIcon={<ChevronsIcon />}
      >
        {pathname?.includes('project-dashboard') ? 'Schedule' : 'Selections'}
      </Button>
      <Popper
        transition
        open={openProjectNavPopper}
        role={undefined}
        placement="bottom"
        anchorEl={anchorRef.current}
        sx={styles.popper}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={styles.popper}>
            <Paper sx={styles.paper}>
              <ClickAwayListener onClickAway={handleToggleScheduleActionsPopper}>
                <MenuList
                  autoFocusItem={openProjectNavPopper}
                  id="filter-list"
                  aria-labelledby="add-schedule-item-actions"
                  sx={styles.paperContentContainer}
                >
                  {LINKS.map((item) => (
                    <Grid sx={styles.itemContainer} key={item.id}>
                      <Link
                        component={NavLink}
                        sx={styles.link}
                        to={`${item.path}/${id}`}
                        onClick={handleClosePopper}
                      >
                        {item.name}
                      </Link>
                    </Grid>
                  ))}
                  <Grid sx={styles.itemContainer}>
                    <Grid sx={styles.link} onClick={handleOpenFileCabinetModal}>
                      File Cabinet
                    </Grid>
                  </Grid>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {isOpenFileCabinetModal && (
        <ProjectFileCabinetModal
          open={isOpenFileCabinetModal}
          handleClose={handleCloseFileCabinetModal}
          title={projectData?.name}
          projectId={id}
          projectFolderId={projectData?.folderId}
        />
      )}
    </Grid>
  );
};
export default ProjectNav;
