import React, { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// mui components
import { Typography, Grid } from '@mui/material';
// types
import {
  INotification,
  ProjectNotificationEventEnum,
  SelectionNotificationEventEnum,
} from 'redux/api/activity-feed';
//styles
import { notificationButtonStyles } from '../styles/styles';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  onItemClick: (id: number) => Promise<void>;
  post: INotification;
}

const NotificationMessage: FC<IProps> = ({ post, onItemClick }) => {
  switch (post.data.event) {
    case ProjectNotificationEventEnum.NEW_USERS_HAVE_BEEN_ADDED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemSimpleContent}>
            <span>{`${post?.profile?.firstName} ${post?.profile?.lastName}`}</span> has been added
            to the project.
          </Typography>
        </Grid>
      );
    case ProjectNotificationEventEnum.PHASE_HAS_BEEN_COMPLETED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemContent}>
            <span onClick={() => onItemClick(post?.phase?.id ?? 0)}>{`${
              post.phase?.title || ''
            }`}</span>{' '}
            phase has been completed.
          </Typography>
        </Grid>
      );
    case ProjectNotificationEventEnum.INSPECTION_HAS_PASSED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemContent}>
            Congrats! Your{' '}
            <span
              onClick={() => {
                onItemClick(post?.phaseItem?.id ?? 0);
              }}
            >{`${post.phaseItem?.title || ''}`}</span>{' '}
            has passed inspection.
          </Typography>
        </Grid>
      );
    case ProjectNotificationEventEnum.SCHEDULE_ITEM_HAS_BEEN_COMPLETED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemContent}>
            <span
              onClick={() => {
                onItemClick(post?.phaseItem?.id ?? 0);
              }}
            >{`${post.phaseItem?.title || ''}`}</span>{' '}
            has been completed.
          </Typography>
        </Grid>
      );
    case ProjectNotificationEventEnum.ITEM_OR_PHASE_HAS_BEEN_RESCHEDULED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemContent}>
            <span
              onClick={() => {
                onItemClick(post?.phaseItem?.id ?? 0);
              }}
            >{`${post.phaseItem?.title || ''}`}</span>{' '}
            {`has been moved from ${dayjs
              .tz(post.data.oldStartDate, 'UTC')
              .format('MM/DD/YY')} - ${dayjs
              .tz(post.data.oldEndDate, 'UTC')
              .format('MM/DD/YY')} to ${dayjs
              .tz(post.data.newStartDate, 'UTC')
              .format('MM/DD/YY')} - ${dayjs.tz(post.data.newEndDate, 'UTC').format('MM/DD/YY')}.`}
          </Typography>
        </Grid>
      );
    case ProjectNotificationEventEnum.SCHEDULE_ITEM_HAS_BEEN_CREATED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemContent}>
            {`${post?.profile?.firstName} ${post?.profile?.lastName}`} has added{' '}
            <span
              onClick={() => {
                onItemClick(post?.phaseItem?.id ?? 0);
              }}
            >
              {post?.phaseItem?.title}
            </span>{' '}
            to the schedule
          </Typography>
        </Grid>
      );
    case SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_ADDED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.selectionItemContent}>
            <span>{`${post?.profile?.firstName} ${post?.profile?.lastName}`}</span> has added{' '}
            <span>{post?.data?.projectSelectionItem?.title}</span> to selections
          </Typography>
        </Grid>
      );
    case SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_APPROVED:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.selectionItemContent}>
            <span>{`${post?.profile?.firstName} ${post?.profile?.lastName}`}</span> has approved{' '}
            <span>{post?.data?.projectSelectionItem?.title}</span>
          </Typography>
        </Grid>
      );
    case SelectionNotificationEventEnum.SELECTION_ITEM_NEEDS_APPROVAL:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.selectionItemContent}>
            <span>{post?.data?.projectSelectionItem?.title}</span>{' '}
            {'selection is now needing approval'}
          </Typography>
        </Grid>
      );

    default:
      return (
        <Grid sx={notificationButtonStyles.paperText}>
          <Typography sx={notificationButtonStyles.itemContent}>Unknown message</Typography>
        </Grid>
      );
  }
};

export default NotificationMessage;
