import isEqual from 'lodash.isequal';
import queryString from 'query-string';
import { api } from 'redux/api';
import {
  TGetProjectsResponse,
  IGetProjectsArgs,
  IGetPhasesArgs,
  IProject,
  IUpdateProjectBody,
  IUpdateProjectTemplateBody,
  IGetProjectProfilesManagementArgs,
  IProjectProfilesManagement,
  IGetProjectByIdArgs,
  IGetUnviewedCountsArgs,
  IGetUnviewedItemsResponse,
  IProjectShort,
  IGetAllProjectsArgs,
  GetTemplateChangesArgs,
  TemplateChanges,
  UpdateTemplateBody,
  IGetOverdueItemsResponse,
  IGetOverdueItemsArgs,
} from './';
import { IPhase } from 'redux/types';
// slices
import { handleSetSucceeded, handleShowRefetchLoader } from 'redux/slices/manage-gantt-slice';
import { isErrorWithData } from 'redux/helpers/is-error-with-data';

enum Endpoints {
  PROJECTS_V2 = 'v2/projects/',
  PROJECTS = 'projects/',
}

const PROJECTS_SERIALIZATION_KEY = 1;

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<TGetProjectsResponse, IGetProjectsArgs>({
      query: (queryArgs) => ({
        url: Endpoints.PROJECTS_V2,
        params: queryArgs,
      }),
      serializeQueryArgs: () => {
        return PROJECTS_SERIALIZATION_KEY;
      },
      merge: (currentCache, newCache, { arg: { page } }) => {
        if (page === 1) {
          return newCache;
        }
        return {
          meta: newCache.meta,
          items: [...currentCache.items, ...newCache.items],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      keepUnusedDataFor: 0,
    }),

    getProjectById: build.query<IProject, IGetProjectByIdArgs>({
      query: ({ id }) => ({
        url: `${Endpoints.PROJECTS_V2}${id}`,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(handleSetSucceeded(true));
          dispatch(handleShowRefetchLoader(false));
          dispatch(
            projectsApi.util.updateQueryData(
              'getProjects',
              PROJECTS_SERIALIZATION_KEY as IGetProjectsArgs,
              (draft) => {
                if (draft?.items) {
                  const index = draft.items.findIndex(({ id }) => response.id === id);
                  if (index >= 0) {
                    Object.assign(draft.items[index], response);
                  }
                }
              }
            )
          );
        } catch {
          dispatch(handleSetSucceeded(false));
        }
      },
      providesTags: (_, __, { id }) => [{ type: 'Projects', id: Number(id) }],
    }),

    getAllProjects: build.query<IProjectShort[], IGetAllProjectsArgs>({
      query: ({ profileId }) => ({
        url: `${Endpoints.PROJECTS_V2}${profileId}/all`,
      }),
    }),

    achiveAllProjects: build.mutation({
      query: () => ({
        url: `${Endpoints.PROJECTS_V2}archive/all`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Current Plan'],
    }),

    getPhases: build.query<IPhase[], IGetPhasesArgs>({
      query: ({ projectId }) => ({
        url: `${Endpoints.PROJECTS_V2}${projectId}/phases`,
      }),
    }),

    getUnviewedCounts: build.query<IGetUnviewedItemsResponse, IGetUnviewedCountsArgs>({
      query: ({ projectIds }) => ({
        url: `${Endpoints.PROJECTS_V2}unviewed-counts?${queryString.stringify(
          { projectIds },
          { arrayFormat: 'bracket' }
        )}`,
      }),
      providesTags: (res, err, { projectIds }) =>
        res && !err ? projectIds.map((pId) => ({ id: pId, type: 'Projects' })) : [],
    }),

    getProjectsProfilesManagement: build.query<
      IProjectProfilesManagement,
      IGetProjectProfilesManagementArgs
    >({
      query: ({ id }) => ({
        url: `${Endpoints.PROJECTS}${id}/profiles-management`,
      }),
      providesTags: (result, _, args) => (result ? [{ type: 'Projects', id: args?.id }] : []),
    }),

    createProject: build.mutation<IProject, FormData>({
      query: (data) => ({
        url: Endpoints.PROJECTS_V2,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Current Plan'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            projectsApi.util.updateQueryData(
              'getProjects',
              PROJECTS_SERIALIZATION_KEY as IGetProjectsArgs,
              (draft) => {
                if (draft?.items) {
                  draft.items.unshift(response);
                }
              }
            )
          );
        } catch {}
      },
    }),

    updateProject: build.mutation<IProject, IUpdateProjectBody>({
      query: ({ data, id }) => ({
        url: `${Endpoints.PROJECTS_V2}${id}`,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: async ({ withoutRefetching, id }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          if (!withoutRefetching) {
            dispatch(
              projectsApi.util.updateQueryData(
                'getProjects',
                PROJECTS_SERIALIZATION_KEY as IGetProjectsArgs,
                (draft) => {
                  if (draft?.items?.length) {
                    const index = draft.items.findIndex(({ id: projectId }) => id === projectId);
                    if (
                      index >= 0 &&
                      draft.items.some(({ status }) => status !== response?.status)
                    ) {
                      draft.items = draft.items.filter(({ id: pId }) => pId !== response?.id);
                    } else if (index >= 0) {
                      Object.assign(draft.items[index], response);
                    }
                  }
                }
              )
            );
            dispatch(
              projectsApi.util.updateQueryData('getProjectById', { id }, (draft) => {
                if (draft) {
                  Object.assign(draft, response);
                }
              })
            );
          }
        } catch (e) {
          const error = e?.error;
          if (isErrorWithData(error)) {
            if (error.status === 404) {
              if (!withoutRefetching) {
                dispatch(
                  projectsApi.util.updateQueryData(
                    'getProjects',
                    PROJECTS_SERIALIZATION_KEY as IGetProjectsArgs,
                    (draft) => {
                      if (draft?.items?.length) {
                        draft.items = draft.items.filter((project) => project.id !== id);
                      }
                    }
                  )
                );
              }
            }
          }
        }
      },
      invalidatesTags: (result, _, { withoutRefetching }) =>
        result && !withoutRefetching ? [{ type: 'Projects', id: result?.id }, 'Current Plan'] : [],
    }),

    updateProjectTemplate: build.mutation<IProject, IUpdateProjectTemplateBody>({
      query: ({ projectId, ...data }) => ({
        url: `${Endpoints.PROJECTS_V2}${projectId}/change-template`,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: async ({ projectId: templateProjectId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            projectsApi.util.updateQueryData(
              'getProjects',
              PROJECTS_SERIALIZATION_KEY as IGetProjectsArgs,
              (draft) => {
                if (draft?.items?.length) {
                  const index = draft.items.findIndex(
                    ({ id: projectId }) => templateProjectId === projectId
                  );
                  if (index >= 0 && draft.items.some(({ status }) => status !== response?.status)) {
                    draft.items = draft.items.filter(({ id: pId }) => pId !== response?.id);
                  } else if (index >= 0) {
                    Object.assign(draft.items[index], response);
                  }
                }
              }
            )
          );
          dispatch(
            projectsApi.util.updateQueryData(
              'getProjectById',
              { id: templateProjectId },
              (draft) => {
                if (draft) {
                  Object.assign(draft, response);
                }
              }
            )
          );
        } catch {}
      },
      invalidatesTags: (result) =>
        result ? [{ type: 'Projects', id: result?.id }, 'Current Plan'] : [],
    }),

    getTemplateChangesCount: build.query<TemplateChanges, GetTemplateChangesArgs>({
      query: ({ templateId, projectId }) => ({
        url: `${Endpoints.PROJECTS_V2}template-changes/${templateId}/${projectId}`,
      }),
      providesTags: ['Project Changes Count'],
    }),

    saveAsTemplate: build.mutation<TemplateChanges, UpdateTemplateBody>({
      query: ({ projectId, ...data }) => ({
        url: `${Endpoints.PROJECTS_V2}${projectId}/save-as-template`,
        body: data.data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Template', 'Projects'],
    }),

    getOverdueItems: build.query<IGetOverdueItemsResponse, IGetOverdueItemsArgs>({
      query: ({ projectId }) => ({
        url: `${Endpoints.PROJECTS_V2}${projectId}/overdue`,
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useAchiveAllProjectsMutation,
  useGetPhasesQuery,
  useLazyGetPhasesQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetProjectsProfilesManagementQuery,
  useLazyGetProjectsProfilesManagementQuery,
  useUpdateProjectTemplateMutation,
  useGetProjectByIdQuery,
  useLazyGetProjectByIdQuery,
  useGetUnviewedCountsQuery,
  useLazyGetUnviewedCountsQuery,
  useGetAllProjectsQuery,
  useLazyGetAllProjectsQuery,
  useGetTemplateChangesCountQuery,
  useSaveAsTemplateMutation,
  useGetOverdueItemsQuery,
  useLazyGetOverdueItemsQuery,
} = projectsApi;
