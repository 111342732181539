export const styles = {
  modal: {
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      height: '48px',
      padding: 0,
      '& button': {
        margin: '0 12px',
      },
    },

    '& .MuiDialog-paper': {
      width: '430px',
      maxWidth: '430px',
      borderRadius: '12px',
      overflow: 'auto',
      backgroundColor: '#fff',
    },
  },

  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #F3F4F9',
    padding: '40px 40px 20px',
    grid: '4px',
  },

  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '136%',
    color: '#23252F',
  },

  subTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#6E707A',
  },

  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 40px',
    gap: '8px',
  },

  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '12px',
    padding: '20px 40px',
    '& button': {
      minWidth: 'unset',
    },
  },

  inputLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
  },

  input: {
    height: '48px !important',
    borderRadius: '8px !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
  },
};
