import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// mui components
import { Button, Popover } from '@mui/material';
// modals
import CreateDefaultFolder from 'components/file-cabinet-folders/modals/create-default-folder';
import UploadFileModal from 'components/file-cabinet-folders/modals/upload-file-modal';
// api
import { useGetCompanyRolesQuery } from 'redux/api/company-roles';
// styles
import { styles } from '../styles';
import CreateProjectCabinetFolder from 'components/file-cabinet-folders/modals/create-project-folder';

type Props = { fromModal?: boolean; projectFolderId?: string | number; projectId: string | number };

const AddNewPopper: React.FC<Props> = ({ fromModal, projectFolderId, projectId }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
  const [isOpenCreateDefaultFolder, setIsOpenCreateDefaultFolder] = useState(false);
  const [isOpenUploadFile, setIsOpenUploadFile] = useState(false);

  const { data: companyRoles } = useGetCompanyRolesQuery();
  const { hash, pathname } = useLocation();
  const { folderId } = useParams();

  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (pathname?.includes('file-cabinet') || !hash) {
        handleOpenCreateModal(true);
      } else {
        setAnchorEl(e.currentTarget);
      }
    },
    [hash, folderId, pathname]
  );

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const handleOpenCreateModal = (val: boolean) => {
    if (pathname?.includes('file-cabinet')) {
      setIsOpenCreateDefaultFolder(val);
    }

    if (!pathname?.includes('file-cabinet')) {
      setIsOpenCreateFolder(val);
    }

    handleClose();
  };

  const handleOpenUploadModal = (val: boolean) => {
    setIsOpenUploadFile(val);
    handleClose();
  };

  return (
    <>
      <Button variant="contained" sx={styles.mainButton} onClick={handleOpen}>
        add new
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={styles.popover}
      >
        <Button onClick={() => handleOpenCreateModal(true)}>create folder</Button>
        {!pathname?.includes('file-cabinet') && (
          <Button onClick={() => handleOpenUploadModal(true)}>upload file</Button>
        )}
      </Popover>

      {isOpenCreateFolder && (
        <CreateProjectCabinetFolder
          open={isOpenCreateFolder}
          handleClose={() => handleOpenCreateModal(false)}
          title="Create Folder"
          subtitle="Enter name for the folder"
          projectFolderId={projectFolderId}
          fromModal={fromModal}
          projectId={projectId}
        />
      )}

      {isOpenCreateDefaultFolder && (
        <CreateDefaultFolder
          open={isOpenCreateDefaultFolder}
          handleClose={() => handleOpenCreateModal(false)}
          companyRoles={companyRoles}
          title="Create Folder"
          subtitle="Creating this new folder will add it to existing and new projects."
        />
      )}

      {isOpenUploadFile && (
        <UploadFileModal
          open={isOpenUploadFile}
          handleClose={() => handleOpenUploadModal(false)}
          projectFolderId={projectFolderId}
          fromModal={fromModal}
          projectId={projectId}
        />
      )}
    </>
  );
};

export default AddNewPopper;
