import {
  IDataList,
  IPhase,
  IPhaseItem,
  IUploadedFilesData,
  IUserMe,
  IFileUploadingUtilityProps,
} from 'redux/types';
import { ISelectionItem } from '../selections';
import { IProfile } from '../profiles';

export interface IUploadingFiles extends IUploadedFilesData, IFileUploadingUtilityProps {}

export interface IPostUploadsOptions {
  soundLevel: number[];
  duration: string;
  previewUrl?: string;
}

export interface IPostUploads extends IUploadedFilesData {
  path: string;
  mimeType: string;
  description: string | null;
  location?: string | null;
  timestamp?: string | null;
  options: IPostUploadsOptions | null;
}

export interface ICommentUploads extends IUploadedFilesData {
  mimeType: string;
  description: string | null;
  location?: string | null;
  timestamp?: string | null;
}

export interface IActivityFeedProfile {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  role: string;
  avatar: string | null;
}

export interface IMention {
  profileId: number;
  length: number;
  offset: number;
  block: number;
  key: number;
}

export interface IPostPhase {
  id: number;
  title: string;
  color: string;
}

export interface IPostPhaseItem {
  id: number;
  title: string;
}

export interface IPostUploadedFiles {
  id: string;
  description: string | null;
  location?: string | null;
  timestamp?: string | null;
}

export interface IActivityFeedPost {
  id: number;
  createdAt: string;
  isViewed: boolean;
  isLiked: boolean;
  likesCount: number;
  commentsCount: number;
  data: {
    mentions: IMention[];
    activityFeedItemId: number;
    content: string | null;
    phases: IPostPhase[];
    phaseItems: IPostPhaseItem[];
    uploads: IPostUploads[];
    profile: IActivityFeedProfile;
  };
  postVisibility: string;
}

export interface ICreatePostAudienceBody {
  projectId: number;
  group: {
    groupName: string;
    profileIds: number[];
    isVisible: boolean;
  };
}

export interface IActivityFeedComment {
  id: number;
  postId: number;
  activityFeedItemId: number;
  createdAt: string;
  content: string | null;
  uploads: ICommentUploads[];
  isLiked: boolean;
  likesCount: number;
  isView: boolean;
  profile: IActivityFeedProfile;
  replyToCommentId: number | null;
  rootCommentId: number | null;
  replyToComment: {
    id: number;
    content: string | null;
    profile: IActivityFeedProfile;
  } | null;
  replies: IActivityFeedComment[];
  mentions: IMention[];
}

export interface IActivityFeedAudience {
  profileId: number;
  firstName: string;
  lastName: string;
  roleName: string;
  avatar: string;
}

export interface IActivityFeedLike {
  id: number;
  activityFeedItemId: number;
  profile: {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

export interface IActivityFeedUnviewedItemsCount {
  notifications?: number;
  posts?: number;
  messages?: number;
}

export interface IActivityFeedItemsArgs {
  projectId: number;
  cursor?: number;
  limit?: number;
  search?: string;
  fromDate?: string;
  toData?: string;
  isFileIncluded?: boolean;
  isPhotoIncluded?: boolean;
  phasesIds?: number[];
  phaseItemsIds?: number[];
  posterProfileIds?: number[];
}

export interface ICreatePostBody {
  content: string;
  projectId: number;
  phaseIds?: number[];
  phaseItemIds?: number[];
  uploads?: IPostUploadedFiles[];
  profileIds?: number[];
  mentions: IMention[];
  visibleProfileIds?: number[];
}

export interface IUpdatePostBody {
  id: number;
  content: string;
  projectId: number;
  phaseIds?: number[];
  phaseItemIds?: number[];
  uploads?: IPostUploadedFiles[];
  mentionedProfiles?: number[];
  mentions?: IMention[];
  audienceGroups?: number[];
  audienceRoles?: number[];
  postVisibility: string;
  visibleProfileIds?: number[];
}

export interface IDeletePostsBody {
  ids: number[];
  projectId: number;
}

export interface IGetPostByIdArgs {
  postId: number;
  projectId: number;
}

export interface IGetCommentsArgs {
  postId: number;
  cursor?: number;
  limit?: number;
  isView?: boolean;
}

export interface ICreateCommentBody {
  projectId: number;
  postId: number;
  content: string | null;
  replyToCommentId?: number | null;
  rootCommentId?: number | null;
  uploads?: string[];
  mentions: IMention[];
  addLocal?: boolean;
}

export interface IUpdateCommentBody {
  postId: number;
  id: number;
  content: string | null;
  projectId: number;
  uploads?: string[];
  mentions: IMention[];
}

export interface IDeleteCommentsBody {
  ids: number[];
  postId: number;
  projectId: number;
  rootCommentId: number | null;
}

export interface IActivityFeedItemLikesArgs {
  activityFeedId: number;
  cursor?: number;
  limit?: number;
}

export interface ILikeActivityFeedItemArgs {
  activityFeedItemId: number;
  projectId: number;
  postId: number;
  commentId?: number;
  rootCommentId?: number;
}

export interface IActivityFeedUnviewedItemsArgs {
  projectId: number;
}

export interface ICreateItemsViewsBody {
  projectId: number;
  itemIds: number[];
  selectionItemIds: number[];
}

export interface ICreateItemsViewsBulkBody {
  projectId: number;
  unviewedCountArgs?: number[];
  withoutUpdateUnviewedCount?: boolean;
}

export interface IGetProjectAudienceArgs {
  projectId: number;
}

export interface IGetPostAudienceArgs {
  projectId: number;
  postId: number;
}

export interface IDeleteCustomAudienceBody {
  projectId: number;
  groupId: number;
  groupIdToAssign: number;
  roleIdToAssign: number;
}

export interface IGetRecentActivityArgs {
  projectId: number;
  isViewed?: boolean;
  cursor?: number;
  limit?: number;
}

export interface ICreateItemsViewsResponse {
  itemIds: number[];
}

export interface INotificationAuthorProfile
  extends Pick<
    IUserMe,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'role'
    | 'userId'
    | 'createdAt'
    | 'deletedAt'
    | 'avatar'
    | 'isActive'
  > {}

export enum ProjectNotificationEventEnum {
  ITEM_OR_PHASE_HAS_BEEN_RESCHEDULED = 'ITEM_OR_PHASE_HAS_BEEN_RESCHEDULED',
  INSPECTION_HAS_PASSED = 'INSPECTION_HAS_PASSED',
  SCHEDULE_ITEM_HAS_BEEN_COMPLETED = 'SCHEDULE_ITEM_HAS_BEEN_COMPLETED',
  PHASE_HAS_BEEN_CHANGED = 'PHASE_HAS_BEEN_CHANGED',
  PHASE_OR_ITEM_TITLE_HAS_CHANGED = 'PHASE_OR_ITEM_TITLE_HAS_CHANGED',
  PHASE_HAS_BEEN_COMPLETED = 'PHASE_HAS_BEEN_COMPLETED',
  NEW_USERS_HAVE_BEEN_ADDED = 'NEW_USERS_HAVE_BEEN_ADDED',
  ITEMS_BECOME_OVERDUE = 'ITEMS_BECOME_OVERDUE',
  ITEM_OVERDUE_STATUS_HAS_CHANGED = 'ITEM_OVERDUE_STATUS_HAS_CHANGED',
  SCHEDULE_ITEM_HAS_BEEN_CREATED = 'SCHEDULE_ITEM_HAS_BEEN_CREATED',
}

export enum SelectionNotificationEventEnum {
  SELECTION_ITEM_HAS_BEEN_ADDED = 'SELECTION_ITEM_HAS_BEEN_ADDED',
  SELECTION_ITEM_BECOME_OVERDUE = 'SELECTION_ITEM_BECOME_OVERDUE',
  SELECTION_ITEM_HAS_BEEN_APPROVED = 'SELECTION_ITEM_HAS_BEEN_APPROVED',
  SELECTION_ITEM_NEEDS_APPROVAL = 'SELECTION_ITEM_NEEDS_APPROVAL',
}

export interface IProjectNotificationData {
  event: ProjectNotificationEventEnum;
  reasonForChange: string | null;
  newStartDate: string | null;
  newEndDate: string | null;
  oldStartDate: string | null;
  oldEndDate: string | null;
}

export interface ISelectionNotificationData {
  event: SelectionNotificationEventEnum;
  id: number;
  createdAt: string;
  projectSelectionItemId: number;
  projectSelectionItem: {
    id: number;
    categoryId: number;
    endDate: string | null;
    title: string;
  };
}

export type TNotificationData = IProjectNotificationData | ISelectionNotificationData;

export interface INotification {
  id: number;
  projectId: number;
  phase: Pick<IPhase, 'id' | 'title' | 'color'> | null;
  phaseItem: Pick<IPhaseItem, 'id' | 'title'>;
  isViewed: boolean;
  createdAt: string;
  profile: INotificationAuthorProfile;
  data: TNotificationData;
}

export enum SocketNotificationTypeEnum {
  ACTIVITY_FEED = 'ACTIVITY_FEED',
  SELECTION = 'SELECTION',
}

export interface ISocketProjectNotificationData {
  projectId: number;
  phaseId: number | null;
  phaseItemId: number | null;
  profileId: number;
  activityFeedNotificationId: number | null;
  selectionNotificationId: number | null;
  id: number;
  createdAt: string;
  type: SocketNotificationTypeEnum;
}

export interface ISocketProjectNotification
  extends Pick<
    IProjectNotificationData,
    'newEndDate' | 'newStartDate' | 'oldEndDate' | 'oldStartDate' | 'reasonForChange'
  > {
  event: ProjectNotificationEventEnum;
  id: number;
  phaseName?: string;
  phaseItemName?: string;
  newItem?: IPhaseItem;
  notification: ISocketProjectNotificationData;
}

export interface ISocketSelectionNotification {
  id: number;
  projectSelectionItemId: number;
  createdAt: string;
  event: SelectionNotificationEventEnum;
  selectionItem: ISelectionItem;
}

export type TSocketNotification = ISocketProjectNotification | ISocketSelectionNotification;

export interface IRecentPost {
  type: 'POST';
  id: number;
  createdAt: string;
  isViewed: boolean;
  data: {
    activityFeedItemId: number;
    content: string | null;
    mentions: IMention[];
    uploads: IPostUploads[];
    profile: IActivityFeedProfile;
    profileId: number;
  };
}

export interface IRecentComment {
  type: 'COMMENT';
  id: number;
  createdAt: string;
  isViewed: boolean;
  data: {
    activityFeedItemId: number;
    content: string | null;
    mentions: IMention[];
    uploads: ICommentUploads[];
    profile: IActivityFeedProfile;
    profileId: number;
    comment: IActivityFeedComment;
  };
}

export type TRecentActivity = IRecentPost | IRecentComment;

export type TGetRecentActivityResponse = IDataList<TRecentActivity>;
export type TGetNotificationsResponse = IDataList<INotification>;
export type TActivityFeedPosts = IDataList<IActivityFeedPost>;
export type TActivityFeedComments = IDataList<IActivityFeedComment>;
export type TActivityFeedItemLikes = IDataList<IActivityFeedLike>;
export interface ISocketPost extends IActivityFeedPost {
  postUserIds: number[];
}

export interface ISocketComment extends IActivityFeedComment {
  userIds: number[];
}

export interface ISocketPostLikeData {
  activityFeedItemId: number;
  profileId: number;
}

export interface ISocketCommentLikeData {
  activityFeedItemId: number;
  profileId: number;
  postId: number;
  rootCommentId?: number;
}

export interface ISocketDeletePostData {
  activityFeedItemIds: { activityFeedItemId: number }[];
  profileId: number;
}

export interface ISocketDeleteCommentData {
  activityFeedItemIds: {
    activityFeedItemId: number;
    postId: number;
    rootCommentId?: number;
  }[];
  profileId: number;
}

export type TActivityFeedEvent =
  | { type: 'NEW_POST'; data: ISocketPost; projectId: number }
  | { type: 'NEW_COMMENT'; data: ISocketComment; projectId: number }
  | { type: 'NEW_NOTIFICATION'; data: TSocketNotification; projectId: number }
  | {
      type: 'LIKED_POST';
      data: ISocketPostLikeData;
      projectId: number;
    }
  | {
      type: 'UNLIKED_POST';
      data: ISocketPostLikeData;
      projectId: number;
    }
  | {
      type: 'LIKED_COMMENT';
      data: ISocketCommentLikeData;
      projectId: number;
    }
  | {
      type: 'UNLIKED_COMMENT';
      data: ISocketCommentLikeData;
      projectId: number;
    }
  | { type: 'CHANGED_POST'; data: ISocketPost; projectId: number; profileId: number }
  | { type: 'CHANGED_COMMENT'; data: ISocketComment; projectId: number; profileId: number }
  | {
      type: 'DELETED_POST';
      data: ISocketDeletePostData;
      projectId: number;
    }
  | {
      type: 'DELETED_COMMENT';
      data: ISocketDeleteCommentData;
      projectId: number;
    }
  | {
      type: 'projectCreatorJoinToRoom';
      profileId: number;
      companyId: number;
      projectId: number;
    };

export interface ISpecificUsers {
  byRole: {
    id: number;
    groupName: string;
    profiles: IActivityFeedAudience[];
  }[];
  customGroups: {
    id: number;
    groupName: string;
    profiles: IActivityFeedAudience[];
  }[];
  customUsers: IActivityFeedAudience[];
}

export interface ISpecificUsersArgs {
  projectId: number;
}

export interface IGetNotificationsArgs {
  projectId: number;
  limit: number;
  cursor?: number;
}

export interface IGetUnviewedNotificationsArgs {
  projectId: number;
  isViewed?: boolean;
  limit: number;
  cursor?: number;
}

export interface IActivityFeedAppliableFilters {
  phases: number[];
  phaseItems: number[];
  profileIds: number[];
}

export interface IGetActivityFeedAppliableFiltersArgs {
  projectId: number;
}

export interface IGetActivityFeedMediaArgs {
  projectId: number;
  offset?: number;
  limit?: number;
}

export interface IActivityFeedMedia extends Omit<IPostUploads, 'path'> {
  createdAt: Date;
  profile: Pick<IProfile, 'id' | 'userId' | 'role' | 'firstName' | 'lastName' | 'role'> & {
    avatar: IUploadedFilesData;
  };
}

export type TGetActivityFeedMediaResponse = IDataList<IActivityFeedMedia>;

export type TActivityFeedMediaWithIndex = IActivityFeedMedia & { index: number };

export type TActivityFeedMediaMapped = IDataList<[string, TActivityFeedMediaWithIndex[]]>;
