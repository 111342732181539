import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  FC,
  SyntheticEvent,
  useCallback,
} from 'react';
import dayjs from 'dayjs';
// components
import { ThumbnailList } from '../thumbnail-list';
import Player from 'components/player';
import ItemDescription from '../item-description';
// types
import { IImagesList, IViewer } from '../../entities';
// icons
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/viewer-arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/viewer-arrow-right.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/viewer-close.svg';
import { ReactComponent as StampIcon } from 'assets/icons/stamp.svg';
// styles
import './styles.css';

type TViewerProps = Omit<IViewer, 'isOpen'>;

const PADDING_X = 24;

const getInputWidthInPx = (imageWidth: number): string => `${imageWidth - 2 * PADDING_X}px`;

export const Viewer: FC<TViewerProps> = ({
  imagesList,
  activeIndex,
  handleClose = () => {},
  editablePostData,
  isDescriptionEditable = false,
  updateDescription,
  fetchingProps,
}) => {
  const { fetchData, isFetching, hasMore } = fetchingProps ?? {};
  const imageRef = useRef<HTMLImageElement | null>(null);

  const imageSourceIndex = useMemo(() => {
    return activeIndex !== undefined
      ? activeIndex && activeIndex > imagesList.length - 1
        ? imagesList.at(-1)!.id
        : imagesList[activeIndex].id
      : imagesList.at(-1)!.id;
  }, []);

  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageSrcIndex, setImageSrcIndex] = useState<number>(imageSourceIndex);
  const isKeyboardRef = useRef<boolean>(false);

  const isLeftDisabled = useMemo(() => imageSrcIndex - 1 < 0, [imageSrcIndex]);
  const isRightDisabled = useMemo(
    () => imageSrcIndex + 1 > imagesList.length - 1 && !hasMore,
    [imageSrcIndex, imagesList.length, hasMore]
  );

  const handleLeft = (index: number) => {
    if (index - 1 < 0) return;
    isKeyboardRef.current = true;
    setImageSrcIndex(index - 1);
  };

  const handleRight = useCallback(
    (index: number) => {
      if (index + 1 > imagesList.length - 1) {
        return;
      }

      if (hasMore && !isFetching && index > imagesList.length - 5) {
        fetchData?.();
      }

      isKeyboardRef.current = true;
      setImageSrcIndex(index + 1);
    },
    [imagesList.length, fetchData, hasMore, isFetching]
  );

  const handleThumbnailPress = (index: number) => {
    isKeyboardRef.current = false;
    setImageSrcIndex(index);
  };

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    if ('width' in event?.target && typeof event?.target?.width === 'number') {
      setImageWidth(event.target.width);
    }
  };

  const renderContent = (item: IImagesList) => {
    if (item?.mimeType?.includes('video')) {
      return <Player url={item.imageSrc} controls={true} playing={true} />;
    }
    return <img ref={imageRef} src={item.imageSrc} onLoad={onImageLoad} />;
  };

  const currentItem = imagesList[imageSrcIndex];

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') handleRight(imageSrcIndex);
      if (e.key === 'ArrowLeft') handleLeft(imageSrcIndex);
      if (e.key === 'Escape') handleClose();
    };

    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [imageSrcIndex, handleRight]);

  return (
    <div className="photo-viewer">
      <div className="photo-viewer-header">
        <CloseIcon className="close" onClick={handleClose} />
      </div>

      <div className="photo-viewer-body">
        {!isLeftDisabled ? (
          <div
            className={`photo-viewer-body-left ${isLeftDisabled ? 'disabled' : ''}`}
            onClick={() => handleLeft(imageSrcIndex)}
          >
            <ArrowLeftIcon className="arrow-left" />
          </div>
        ) : null}
        <div className="photo-viewer-body-middle">
          <div className="image-container">
            {renderContent(currentItem)}
            {imageWidth && (currentItem?.timestamp || currentItem?.location) ? (
              <div
                className="details-container"
                style={{
                  width: `${imageWidth}px`,
                }}
              >
                {currentItem?.timestamp ? (
                  <div className="detail-item">
                    <StampIcon />
                    <p className="detail-item-typo">
                      {dayjs(currentItem.timestamp).format('LLLL')}
                    </p>
                  </div>
                ) : null}
                {currentItem?.location ? (
                  <div className="detail-item">
                    <StampIcon />
                    <p className="detail-item-typo">{currentItem.location}</p>
                  </div>
                ) : null}
              </div>
            ) : null}
            {!currentItem?.mimeType?.includes('video') &&
            imageWidth &&
            (editablePostData || updateDescription) &&
            currentItem?.itemId ? (
              <div
                className="description-container"
                style={{
                  width: getInputWidthInPx(imageWidth),
                }}
              >
                <ItemDescription
                  key={currentItem.id}
                  id={currentItem.itemId}
                  description={currentItem.description}
                  editablePostData={editablePostData}
                  isDescriptionEditable={isDescriptionEditable}
                  updateDescription={updateDescription}
                />
              </div>
            ) : null}
          </div>
        </div>
        {!isRightDisabled ? (
          <div
            className={`photo-viewer-body-right ${isRightDisabled ? 'disabled' : ''}`}
            onClick={() => handleRight(imageSrcIndex)}
          >
            <ArrowRightIcon className="arrow-right" />
          </div>
        ) : null}
      </div>

      <div className="photo-viewer-footer">
        <ThumbnailList
          isKeyboardRef={isKeyboardRef}
          thumbnailList={imagesList}
          activeIndex={imageSrcIndex}
          handleThumbnailPress={handleThumbnailPress}
        />
      </div>
    </div>
  );
};
