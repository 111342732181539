import React, { memo, ReactNode } from 'react';
// mui components
import { Grid, Avatar, Typography, Button } from '@mui/material';
// api
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
// utils
import { getImageBySize, getInitials } from 'utils/helpers';
// types
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
//icons
import { ReactComponent as PhoneIcon } from 'assets/icons/buttons/phone.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/buttons/email.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/buttons/pending.svg';
import { ReactComponent as CheckmarkFilledIcon } from 'assets/icons/buttons/checkmark-filled.svg';
import { ReactComponent as PlusCircleIcon } from 'assets/icons/new/profile/unchecked.svg';
import { ReactComponent as CancelRequestIcon } from 'assets/icons/buttons/cancel-request.svg';
//styles
import { usersCardStyles } from '../../styles/styles';
import { useProjectStepper } from 'hooks/useProjectStepperState';

interface OwnProps {
  avatar?: string;
  role?: string;
  email?: string;
  phone?: string;
  text: string;
  subText: string;
  id: number;
  isAddedInProject?: boolean;
  isInvitedInProject?: boolean;
  avatarPlaceholder?: ReactNode;
  onRemove: () => void;
  onAdd: () => void;
  sx?: SxProps<Theme>;
}

const ExistingUserNewUI: React.FC<OwnProps> = ({
  avatar,
  role,
  email,
  text,
  phone,
  isAddedInProject,
  isInvitedInProject,
  id,
  onRemove,
  onAdd,
  sx,
}) => {
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();

  const { activeStep } = useProjectStepper();

  const userInitials = getInitials(text);

  return (
    <Grid
      container
      sx={{
        ...usersCardStyles.userCard,
        ...sx,
      }}
    >
      <Grid display="flex" alignItems="center" item>
        {avatar ? (
          <Avatar
            alt="avatar"
            src={getImageBySize(avatar, 100, 100)}
            sx={usersCardStyles.userCardAvatar}
          />
        ) : (
          <Avatar alt="avatar abbreviation" sx={usersCardStyles.userCardAvatarAbbreviation}>
            {userInitials}
          </Avatar>
        )}
        <Grid sx={usersCardStyles.userCardInfoContainer}>
          <Grid sx={usersCardStyles.textContainer}>
            <Typography sx={usersCardStyles.name}>{text}</Typography>
            <Typography sx={usersCardStyles.role}>{role}</Typography>
            {isInvitedInProject && (
              <Typography sx={usersCardStyles.pending}>
                <PendingIcon />
                Pending
              </Typography>
            )}
          </Grid>

          <Grid sx={usersCardStyles.textContainer}>
            {phone && (
              <Grid sx={usersCardStyles.textContainer}>
                <PhoneIcon />
                <Typography sx={usersCardStyles.phone}>{phone}</Typography>
              </Grid>
            )}

            {email && (
              <Grid sx={usersCardStyles.textContainer}>
                <EmailIcon />
                <Typography sx={usersCardStyles.email}>{email}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {(userPermissions?.USER_MANAGEMENT?.canUpdate || activeStep) && (
        <Button
          onClick={isInvitedInProject ? onRemove : isAddedInProject ? onRemove : onAdd}
          title={
            isInvitedInProject
              ? 'Cancel Request'
              : isAddedInProject
              ? 'Delete from project'
              : 'Add in project'
          }
          sx={usersCardStyles.addButton}
        >
          {isInvitedInProject ? (
            <CancelRequestIcon />
          ) : isAddedInProject ? (
            <CheckmarkFilledIcon />
          ) : (
            <PlusCircleIcon />
          )}
        </Button>
      )}
    </Grid>
  );
};

export default memo(ExistingUserNewUI);
