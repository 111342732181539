// Popper component cause a type error in production. This is the MUI bug.
// @ts-nocheck
import React, { useCallback, useState, useRef, useMemo, useEffect, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDistanceToNowStrict } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
// mui components
import {
  ClickAwayListener,
  Grow,
  Paper,
  Typography,
  Grid,
  Button,
  Popper,
  CircularProgress,
  Avatar,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// components
import PostItem from './post-item';
// custom hooks
import { useSidePanel } from 'hooks/useSidePanel';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// slices
import { setCommentsArgs } from 'redux/slices/query-args-slice';
// selectors
import { queryArgsSelector } from 'redux/selector';
// api
import { useGetProjectsQuery } from 'redux/api/projects';
import {
  useCreateMessagesViewsBulkMutation,
  useGetRecentActivityQuery,
} from 'redux/api/activity-feed';
// utils
import { getTimeSpend } from 'utils/helpers';
import { getImageBySize } from 'utils/helpers';
// icons
import { ReactComponent as NotificationsMessage } from 'assets/icons/Comment_new_icon.svg';
// styles
import { activityButtonStyles } from './styles/styles';

interface ActivityButtonProps {
  projectId: number;
  unviewedItemsCount?: number;
  handleSeeMore?: () => void;
}

const ActivityButton: FC<ActivityButtonProps> = ({
  projectId,
  unviewedItemsCount,
  handleSeeMore,
}) => {
  const dispatch = useAppDispatch();
  const { projects: projectsArgs } = useAppSelector(queryArgsSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [cursor, setCursor] = useState<number | undefined>(undefined);
  const anchorRef = useRef(null);
  const { handleOpenActivityFeed, setProjectId: setProjectIdCtx } = useSidePanel();

  const { data: projectsData } = useGetProjectsQuery(projectsArgs);

  const { data, isLoading } = useGetRecentActivityQuery(
    {
      projectId: Number(projectId),
      limit: 10,
      cursor,
    },
    { skip: !open }
  );

  const getMore = () => {
    if (data?.meta?.nextCursor) {
      setCursor(data?.meta?.nextCursor);
    }
  };

  const [bulkUpdate] = useCreateMessagesViewsBulkMutation();

  const unviewedCount = useMemo(
    () => (typeof unviewedItemsCount === 'number' ? unviewedItemsCount : 0),
    [unviewedItemsCount]
  );

  const markNotificationsAsViewed = async () => {
    bulkUpdate({
      projectId: Number(projectId),
      unviewedCountArgs:
        window.location.pathname.includes('account-dashboard') && projectsData?.items?.length
          ? projectsData.items.map(({ id }) => id)
          : undefined,
    });
  };

  const handleToggle = useCallback(() => {
    if (open && unviewedCount > 0) {
      markNotificationsAsViewed();
    }
    setOpen((prevOpen) => !prevOpen);
    setCursor(undefined);
  }, [open, unviewedCount]);

  const handleClose = useCallback(() => {
    if (unviewedCount > 0) {
      markNotificationsAsViewed();
    }
    setOpen(false);
    setCursor(undefined);
  }, [unviewedCount]);

  const onSeeMore = () => {
    if (handleSeeMore) {
      handleClose();
      handleSeeMore();
      return;
    }
    handleClose();
    handleOpenActivityFeed();
  };

  const handleOpenPostModal = useCallback(
    (postId: number) => {
      dispatch(setCommentsArgs({ cursor: undefined }));
      searchParams.set('postId', postId.toString());
      searchParams.set('projectId', projectId.toString());
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  useEffect(() => {
    if (open) {
      setProjectIdCtx(projectId);
    }
  }, [projectId, open]);

  return (
    <Grid
      sx={!open ? activityButtonStyles.buttonContainer : activityButtonStyles.buttonContainerOpened}
    >
      <Button
        ref={anchorRef}
        variant="outlined"
        id="companies-list-button"
        aria-controls={open ? 'companies-list-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        startIcon={<NotificationsMessage />}
        onClick={handleToggle}
        sx={activityButtonStyles.container}
      >
        <Typography sx={activityButtonStyles.buttonContent}>{unviewedCount}</Typography>
      </Button>

      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        sx={activityButtonStyles.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={activityButtonStyles.popper}>
            <Paper sx={activityButtonStyles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid sx={activityButtonStyles.paperContentContainer}>
                  <Grid sx={activityButtonStyles.paperTitleContainer}>
                    <Grid sx={activityButtonStyles.paperTitleWrapper}>
                      <Typography sx={activityButtonStyles.paperTitle}>Recent Activity</Typography>
                    </Grid>
                    <Grid sx={activityButtonStyles.seeMoreContainer}>
                      <Typography onClick={onSeeMore} sx={activityButtonStyles.seeMore}>
                        See more
                      </Typography>
                      <ChevronRightIcon sx={activityButtonStyles.chevronIcon} />
                    </Grid>
                  </Grid>
                  <Grid
                    sx={
                      !data?.items?.length
                        ? activityButtonStyles.paperItemsContainerFixed
                        : activityButtonStyles.paperItemsContainer
                    }
                  >
                    {data?.items?.length === 0 && open && !isLoading ? (
                      <Grid sx={activityButtonStyles.emptyStateContainer}>
                        <Grid sx={activityButtonStyles.emptyStateWrapper}>
                          <Typography sx={activityButtonStyles.emptyStateTitle}>
                            No Activity
                          </Typography>
                          <Typography sx={activityButtonStyles.emptyStateSubtitle}>
                            Looks like you have no activity yet
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : !data?.items && open && isLoading ? (
                      <Grid sx={activityButtonStyles.loaderContainer}>
                        <CircularProgress />
                      </Grid>
                    ) : data?.items?.length && open ? (
                      <InfiniteScroll
                        next={getMore}
                        hasMore={!!data?.meta && !!data?.meta?.nextCursor}
                        dataLength={data?.items?.length}
                        height={400}
                        style={activityButtonStyles.infiniteScroll as CSSProperties}
                        loader={
                          <Grid sx={activityButtonStyles.loaderContainer}>
                            <CircularProgress />
                          </Grid>
                        }
                        children={data.items.map((post) => {
                          const avatar =
                            post.type === 'COMMENT'
                              ? post.data?.comment?.profile?.avatar
                              : post.data.profile.avatar;
                          const profile =
                            post.type === 'COMMENT'
                              ? post.data?.comment?.profile
                              : post.data.profile;

                          return (
                            <Grid key={post.id} sx={activityButtonStyles.paperItem}>
                              <Grid sx={activityButtonStyles.contentContainer}>
                                <Grid sx={activityButtonStyles.postContainer}>
                                  <Grid sx={activityButtonStyles.customAvatarContainer}>
                                    {avatar ? (
                                      <>
                                        {!post.isViewed ? (
                                          <Grid sx={activityButtonStyles.imageGreenDot}></Grid>
                                        ) : null}
                                        <Avatar
                                          alt="avatar"
                                          sx={{
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '4px',
                                          }}
                                          src={getImageBySize(avatar, 24, 24)}
                                          variant="square"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {!post.isViewed ? (
                                          <Grid sx={activityButtonStyles.imageGreenDot}></Grid>
                                        ) : null}
                                        <Grid sx={activityButtonStyles.avatarContainer}>
                                          <Typography
                                            sx={activityButtonStyles.avatar}
                                          >{`${profile?.firstName[0]}${profile?.lastName[0]}`}</Typography>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>

                                  <PostItem post={post} handleOpenPostModal={handleOpenPostModal} />
                                </Grid>
                              </Grid>

                              <Grid sx={activityButtonStyles.dateContainer}>
                                <Typography sx={activityButtonStyles.dateText}>
                                  {getTimeSpend(
                                    formatDistanceToNowStrict(new Date(post.createdAt))
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default ActivityButton;
