import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import EditProjectModalNewUI from './new-ui';
import { SelectChangeEvent } from '@mui/material';
// custom hooks
import useToast from 'hooks/useToast';
import { useValidationNotification } from 'hooks/useValidationNotification';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
// validations
import { CreateProjectSchemaNewUI } from 'layouts/create-project-form/validation';
// api
import { IProject, useUpdateProjectMutation, ProjectStatusesEnum } from 'redux/api/projects';
// slices
import { setDefaultArgs } from 'redux/slices/query-args-slice';
import { Coords, Address } from 'components/google-map';

interface OwnProps {
  open: boolean;
  project: IProject;
  coordinates?: Coords;
  closeProjectModal: () => void;
  updateCoordinates: (newCoordinates: Coords) => void;
}

const EditProjectModal: React.FC<OwnProps> = ({
  open,
  project,
  coordinates,
  updateCoordinates,
  closeProjectModal,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(true);
  const [address, setAddress] = useState<Address | undefined>(null);

  const [updateProject, { isLoading: loading }] = useUpdateProjectMutation();
  const showToast = useToast();

  const updateAddress = (newAddress: Address) => {
    setAddress(newAddress);
  };

  const defaultProjectValues = useMemo(
    () => ({
      secondAddress: project?.secondAddress || '',
      subDivision: project?.subDivision || '',
      country: project?.country || '',
      address: project?.address || '',
      state: project?.state || '',
      name: project?.name || '',
      type: project?.type || '',
      city: project?.city || '',
      zip: project?.zip || '',
      lot: project?.lot || '',
      lat: project?.lat || '',
      lng: project?.lng || '',
    }),
    [project]
  );

  const form = useForm({
    defaultValues: defaultProjectValues,
    resolver: yupResolver(CreateProjectSchemaNewUI),
  });

  const country = form.watch('country');

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    form.setValue('country', e.target.value);
    form.setValue('address', '');
    form.setValue('state', '');
    form.setValue('city', '');
    form.setValue('zip', '');
  };

  useEffect(() => {
    if (project && isInit) {
      form.setValue('secondAddress', project?.secondAddress || '');
      form.setValue('subDivision', project?.subDivision || '');
      form.setValue('country', project?.country || '');
      form.setValue('address', project?.address || '');
      form.setValue('state', project?.state || '');
      form.setValue('name', project?.name || '');
      form.setValue('type', project?.type || '');
      form.setValue('city', project?.city || '');
      form.setValue('zip', project?.zip || '');
      form.setValue('lot', project?.lot || '');
      form.setValue('lat', project?.lat || '');
      form.setValue('lng', project?.lng || '');
    } else if (address) {
      form.setValue('country', address?.country || '');
      form.setValue('address', address?.formattedAddress || '');
      form.setValue('state', address?.state || '');
      form.setValue('city', address?.city || '');
      form.setValue('zip', address?.postCode || '');
      form.setValue('lat', address?.lat || '');
      form.setValue('lng', address?.lng || '');
    }
  }, [project, address]);

  useEffect(() => {
    if (project) {
      setIsInit(false);
    }
  }, [project]);

  const handleUpdateProject = useCallback(async () => {
    const formData = form.getValues();

    updateProject({
      id: project.id,
      data: formData,
    })
      .unwrap()
      .then(() => {
        showToast({
          message: 'Updated successfully',
          type: 'success',
        });

        closeProjectModal();
      })
      .catch((err) => {
        showToast({
          message:
            typeof err.data?.message === 'string'
              ? err.data?.message
              : 'Something went wrong.  Please try again or contact support for help.',
          type: 'error',
        });
      });
  }, [form, project, closeProjectModal, showToast, updateProject]);

  const isProjectDashboard = pathname?.includes('project-dashboard');

  const handleArchiveProject = useCallback(async () => {
    updateProject({
      id: project.id,
      withoutRefetching: !!isProjectDashboard,
      data: { status: ProjectStatusesEnum.ARCHIVED },
    })
      .unwrap()
      .then(() => {
        closeProjectModal();
        if (!!isProjectDashboard) {
          dispatch(setDefaultArgs());
          navigate('/account-dashboard');
        }
      })
      .catch((err) => {
        showToast({
          message:
            typeof err.data?.message === 'string'
              ? err.data?.message
              : 'Something went wrong.  Please try again or contact support for help.',
          type: 'error',
        });
      });
  }, [project.id, updateProject, showToast, closeProjectModal, isProjectDashboard]);

  useValidationNotification(form.formState.isSubmitSuccessful, form.formState.submitCount);

  const props = {
    open,
    project,
    coordinates,
    form,
    loading,
    updateCoordinates,
    updateAddress,
    closeProjectModal,
    country,
    handleUpdateProject,
    handleArchiveProject,
    handleCountryChange,
  };

  return <EditProjectModalNewUI {...props} handleArchiveProject={handleArchiveProject} />;
};

export default EditProjectModal;
