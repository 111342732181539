import React, { useEffect, useMemo, FC } from 'react';
// layouts
import AuthStepper from 'layouts/auth-stepper';
// custom hooks
import { useProjectStepper } from 'hooks/useProjectStepperState';

type ProjectSetupProps = {
  handleClose: () => void;
  isFirstProject: boolean;
};

const ProjectSetup: FC<ProjectSetupProps> = ({ isFirstProject }) => {
  const { activeStep, currentStep, resetForm } = useProjectStepper();

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  const steps = useMemo(
    () => [
      {
        label: 'Project Details',
        description: '',
      },
      {
        label: 'Set Schedule',
        description: '',
      },
      {
        label: 'Invite Users',
        description: '',
      },
    ],
    [activeStep]
  );

  return (
    <AuthStepper
      isProjectStepper
      isFirstProject={isFirstProject}
      activeStep={activeStep}
      currentStep={currentStep}
      steps={steps}
    />
  );
};

export default ProjectSetup;
