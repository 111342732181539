const typo = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWight: 400,
  lineHeight: '146%',
  color: '#23252F',
};

export const styles = {
  container: {
    padding: '24px',
    paddingTop: '0',
    marginTop: '24px',
    height: 'calc(100vh - 249px)',
    background: '#fff',
    overflowY: 'scroll',
    overflowX: 'hidden',

    '&.in-modal': {
      padding: '24px 0',
    },

    '&::-webkit-scrollbar': {
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      border: 'none',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },
  tableContainer: {
    border: '1px solid #EDEFF7',
    borderRadius: '12px',
    backgroundColor: '#fff',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '11px',
      height: '11px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
    },
  },
  tableHeadItem: {
    ...typo,
    fontWeight: '500',
    textAlign: 'left',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    padding: '14px 16px',
    borderRight: '1px solid #F3F4F9',
    borderBottom: '1px solid #F3F4F9',
    gap: '16px',
    alignItems: 'center',

    '&:last-child': {
      borderRight: '0',
    },
    '&.aligned-right': {
      textAlign: 'right',
    },
  },
  tableRow: {
    position: 'relative',

    '& .first': {
      zIndex: '2',
    },

    '&:hover .rowBackground': {
      border: '1px solid #4D6EF5',
    },

    '&:hover > .MuiTableCell-root': {
      background: '#FAFBFC',
    },

    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 0,

        '& .rowBackground': {
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
        },
      },
    },
  },

  tableCellWithTooltip: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',

    '& svg': {
      cursor: 'pointer',
    },
  },

  tooltip: {
    '&.MuiTooltip-tooltip': {
      backgroundColor: '#FFF',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '10px 20px',
      borderRadius: '12px',
      border: '1px solid var(--grey-300, #EDEFF7)',
      boxShadow: '1px 2px 2px 0px rgba(35, 37, 47, 0.04), 0px 4px 10px 0px rgba(35, 37, 47, 0.10)',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%',
      color: '#23252F',
    },
  },

  rowBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transition: '300ms',
  },

  tableCell: {
    ...typo,
    lineHeight: 1,
    padding: '0 16px',
    height: '48px',
    borderBottom: '1px solid #F3F4F9',
    borderRight: '1px solid #F3F4F9',
    whiteSpace: 'nowrap',

    '& .MuiTypography-root': {
      ...typo,
    },
    '&:last-child': {
      borderRight: 0,
    },
    '&.actions-wrapper': {
      width: '20px',
    },
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    textDecoration: 'none',
    cursor: 'pointer',
    zIndex: 2,
  },
};
