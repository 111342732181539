import { lazy } from 'react';

export const SignInPage = lazy(() => import('pages/auth/sign-in'));
export const SignUpPage = lazy(() => import('pages/auth/sign-up'));
export const SignUpInvitePage = lazy(() => import('pages/auth/sign-up-invite'));
export const ResetPasswordPage = lazy(() => import('pages/auth/reset-password'));
export const ForgotPasswordPage = lazy(() => import('pages/auth/forgot-password'));
export const AccountSetupPage = lazy(() => import('pages/auth/account-setup'));
export const WithoutPlanPage = lazy(() => import('pages/without-plan'));
export const InviteCanceledPage = lazy(() => import('pages/invite-canceled/invite-canceled'));
export const AccountDashboardPage = lazy(() => import('pages/account-dashboard'));
export const ProjectSetupPage = lazy(() => import('pages/project-setup'));
export const ErrorPage = lazy(() => import('pages/error-page'));
export const ProductsUpdatesPage = lazy(
  () => import('pages/iframes/product-updates/productUpdates')
);
export const HelpCenterPage = lazy(() => import('pages/iframes/help-center/helpCenter'));
export const FeaturesRequestsPage = lazy(
  () => import('pages/iframes/features-requests/featuresRequests')
);
export const CurrentRoadmapPage = lazy(
  () => import('pages/iframes/current-roadmap/currentRoadmap')
);
export const ProjectDashboardPage = lazy(() => import('pages/project-dashboard'));
export const SelectionsPage = lazy(() => import('pages/selections'));
export const FeedbacksPage = lazy(() => import('pages/feedback'));
export const ProjectSettingsPage = lazy(() => import('pages/project-settings'));
export const ProfilePage = lazy(() => import('pages/profile'));
export const AccountSectionPage = lazy(() => import('pages/profile/sections/account-section'));
export const DefaultSettingsSectionPage = lazy(
  () => import('pages/profile/sections/default-settings-section')
);
export const DefaultSettingsPage = lazy(
  () => import('pages/profile/sections/default-settings-section/pages/default-settings')
);
export const CostCodesSectionPages = lazy(
  () =>
    import(
      'pages/profile/sections/default-settings-section/pages/default-settings/sections/cost-codes'
    )
);
export const UsersSectionPage = lazy(() => import('pages/profile/sections/users-section'));
export const UsersPage = lazy(() => import('pages/profile/sections/users-section/tabs/users-tab'));
export const UserViewPage = lazy(
  () => import('pages/profile/sections/users-section/tabs/users-tab/user-view')
);
export const RolesPage = lazy(() => import('pages/profile/sections/users-section/tabs/roles-tab'));
export const RoleViewPage = lazy(
  () => import('pages/profile/sections/users-section/tabs/roles-tab/role-view')
);
export const CreateRolePage = lazy(
  () => import('pages/profile/sections/users-section/tabs/roles-tab/create-role')
);
export const BillingSectionPage = lazy(() => import('pages/profile/sections/billing-section'));
export const SubscriptionPlansPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/components/new-ui/subscription-plans')
);
export const PaymentMethodsPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/components/new-ui/payment-methods')
);
export const UpdatePaymentMethodPage = lazy(
  () =>
    import('pages/profile/sections/billing-section/modals/components/new-ui/update-payment-method')
);
export const CompanyDetailsPage = lazy(() => import('pages/profile/sections/company-details'));
export const FileCabinetPage = lazy(
  () =>
    import(
      'pages/profile/sections/default-settings-section/pages/default-settings/sections/file-cabinet'
    )
);
export const PublicFileCabinetPage = lazy(() => import('pages/public-file-cabinet'));
export const PublicFileCabinetByIdPage = lazy(() => import('pages/public-file-cabinet-by-id'));
export const GalleryPage = lazy(() => import('pages/project-gallery'));
export const AlbumPage = lazy(() => import('pages/project-gallery/pages/album'));
export const GuestGalleryPage = lazy(() => import('pages/project-gallery/pages/guest-gallery'));
export const InviteModalPage = lazy(
  () => import('pages/profile/sections/users-section/tabs/users-tab/invite-modal/invite-modal')
);
export const ChangePaymentMethodModalPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/change-payment-method-modal')
);
export const SelectPaymentPlanModalPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/select-plan-modal')
);
export const ChangePaymentPlanModalPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/change-plan-modal')
);
export const AddPaymentMethodModalPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/add-payment-method')
);
export const DeletePaymentMethodModalPage = lazy(
  () => import('pages/profile/sections/billing-section/modals/delete-payment-method')
);
export const EditScheduleTemplate = lazy(() => import('pages/new-schedule-template'));

export const ChecklistTemplateByIdPage = lazy(
  () =>
    import(
      'pages/profile/sections/default-settings-section/pages/default-settings/sections/checklist-template-by-id'
    )
);

export const ChecklistsTemplatesPage = lazy(
  () =>
    import(
      'pages/profile/sections/default-settings-section/pages/default-settings/sections/checklists'
    )
);

export const LocationsPage = lazy(
  () =>
    import(
      'pages/profile/sections/default-settings-section/pages/default-settings/sections/locations'
    )
);

export const SelectionLibraryPage = lazy(
  () =>
    import(
      'pages/profile/sections/default-settings-section/pages/default-settings/sections/selection-library'
    )
);
