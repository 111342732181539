export const manageUsersFormStyles = {
  inputsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    borderBottom: '1px solid #F3F4F9',
    padding: '20px 0',
  },

  emailContainer: {
    width: '252px !important',
  },

  selectContainer: {
    width: '150px !important',
  },

  inviteButton: {
    padding: '14px 12px',
    backgroundColor: '#4D6EF5',
    textTransform: 'unset',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#fff',
    boxShadow: 'unset',
    marginTop: '28px',
  },

  inviteButtonDisabled: {
    padding: '14px 12px',
    backgroundColor: '#C4CFFF',
    textTransform: 'unset',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#fff',
    boxShadow: 'unset',
    pointerEvents: 'none',
    marginTop: '28px',
  },

  searchInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
  },

  searchInput: {
    height: '48px',
    border: '1px solid #EDEFF7',
    borderRadius: '12px',
  },

  inputAdornment: {
    margin: 0,
    '& div': {
      display: 'flex',
    },
  },

  searchIconContainer: {
    marginRight: '12px',
  },

  usersContainer: {
    paddingRight: '10px',
    //marginRight: '15px',
    overflowY: 'scroll',
    height: 'auto',
    maxHeight: '255px',

    '&::-webkit-scrollbar': {
      border: 'none',
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EDEFF7',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },

  noUsers: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px 0 20px 0',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#667085',
  },
};

export const usersCardStyles = {
  userCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '10px',
    paddingBottom: '20px',
    borderBottom: '1px solid #F3F4F9',
    marginBottom: '20px',
  },

  userCardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '52px',
  },

  userCardAvatar: {
    width: '52px',
    height: '52px',
    borderRadius: '8px',
    marginRight: '12px',
  },

  userCardAvatarAbbreviation: {
    width: '52px',
    height: '52px',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#EDEFF7',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6E707A',
    marginRight: '12px',
  },

  textContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
    marginRight: '8px',
    marginBottom: '10px',
  },

  role: {
    background: '#E7ECFF',
    borderRadius: '8px',
    padding: '2px 4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
    marginRight: '8px',
    marginBottom: '10px',
  },
  pending: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    background: '#FFF2E0',
    borderRadius: '8px',
    padding: '2px 4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',
    marginRight: '8px',
    marginBottom: '10px',
    '& svg': { height: '16px', width: '16px' },
  },

  phone: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    textAlign: 'start',
    color: '#23252F',
    marginRight: '16px',
    marginLeft: '8px',
  },

  email: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    textAlign: 'start',
    color: '#23252F',
    marginRight: '16px',
    marginLeft: '8px',
  },

  addButton: {
    width: '26px',
    minWidth: '26px',
    maxWidth: '26px',
    height: '26px',
    padding: '0',
    borderRadius: '50px',
    textTransform: 'unset',
  },
};
