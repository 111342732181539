import { IDataList, IUploadedFilesData, IFileUploadingUtilityProps } from 'redux/types';
import { IProjectLocation } from '../project-location';

export interface ISelectionUploadedFile extends Omit<IUploadedFilesData, 'id'> {
  id: number;
  mimeType: string;
}

export interface ISelectionUploadingFile
  extends ISelectionUploadedFile,
    Omit<IFileUploadingUtilityProps, 'mimeType'> {}

interface IAvatar {
  id: string;
  name: string;
  url: string;
}

export enum SelectionPhaseStatusesEnum {
  APPROVED_STATUS = 'Approved',
  OVERDUE = 'Overdue',
  NOT_DECIDED = 'NotDecided',
  DUE_SOON = 'DueSoon',
  SELECTION_NEEDED = 'SelectionNeeded',
}

export enum SelectionStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'NEEDS_APPROVAL',
}

export enum SelectionStatusOptionsEnum {
  APPROVED = 'APPROVED',
  PENDING = 'NEEDS_APPROVAL',
  SELECTION_NEEDED = 'SELECTION_NEEDED',
  OVERDUE = 'OVERDUE',
  DUE_SOON = 'DUE_SOON',
  ALL_BUT_APPROVED = 'ALL_BUT_APPROVED',
}

export enum ReminderStatusEnum {
  CUSTOM = 'CUSTOM',
  SCHEDULE_PHASE = 'SCHEDULE_PHASE',
  UNASSIGNED = 'UNASSIGNED',
}

export interface IApproverProfile {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  avatar?: IAvatar | string | null;
}

export interface ILibrarySelectionOption {
  id: number;
  orderNo: number;
  title: string;
  quantity: number;
  amount: string;
  url?: string | null;
  description: string | null;
  logo: IUploadedFilesData | null;
  uploads?: ISelectionUploadedFile[];
}

export interface ISelectionOption extends ILibrarySelectionOption {
  isSelected: boolean;
}

export interface ISelectionBase<T = ISelectionOption> {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  createdByProfile: IApproverProfile;
  createdByProfileId?: number;
  profileId: number;
  options: T[];
}

export interface ILibrarySelection extends ISelectionBase<ILibrarySelectionOption> {}

export interface ISelectionReminder {
  endDate?: string;
  phaseStatus?: SelectionPhaseStatusesEnum;
  reminderStatus: ReminderStatusEnum;
  remindersProfiles: IApproverProfile[];
  approvedAt?: string | null;
  approvedByProfile: IApproverProfile;
  projectPhaseId?: number | null;
  projectPhaseItemId?: number | null;
}

export interface ISelectionItem extends ISelectionBase, ISelectionReminder {
  costCode: Pick<ICostCode, 'id' | 'name' | 'number'>;
  room: Pick<IProjectLocation, 'id' | 'title'>;
  status: SelectionStatusEnum;
  fileCabinetId: number;
}

export interface ILibrarySelectionOptionBody
  extends Omit<ILibrarySelectionOption, 'logo' | 'uploads' | 'id'> {}

export interface ISelectionOptionBody
  extends ILibrarySelectionOptionBody,
    Pick<ISelectionOption, 'isSelected'> {}

export interface ICreateSelectionItemBody
  extends Pick<
    ISelectionItem,
    'title' | 'status' | 'endDate' | 'projectPhaseId' | 'projectPhaseItemId'
  > {
  projectId: number;
  costCodeId: number;
  roomId: number;
  profileIds: number[];
  options: ISelectionOptionBody[];
  saveToLibrary?: boolean;
}

export interface IUpdateSelectionItemBody {
  selectionId: number;
  oldCategoryId: number;
  oldTotalAmount?: number;
  oldStatus: SelectionStatusEnum;
  newStatus: SelectionStatusEnum;
  updatedData: Omit<ICreateSelectionItemBody, 'saveToLibrary'>;
}

export interface IDeleteSelectionItemBody {
  id: number;
  categoryId: number;
  projectId: number;
  status: SelectionStatusEnum;
  totalAmount?: number;
}

export interface IDeleteManySelectionItemsBody extends Pick<IDeleteSelectionItemBody, 'projectId'> {
  ids: number[];
}

export interface IUpdateBulkSelectionItemsBody {
  ids: number[];
  projectId: number;
  roomId?: number;
  categoryId?: number;
}

export interface IApproveSelectionItemBody {
  id: number;
  optionIds: number[];
  projectId: number;
  categoryId: number;
  approvedAt: string;
  totalAmount: number;
  approverProfile: IApproverProfile;
}

export interface IApproveManySelectionItemsBody {
  ids: number[];
  projectId: number;
}

export interface IProjectSelectionsSummary {
  totalAllowance: string;
  approvedSelectionsAmount: string;
  approvedSelectionsCount: number;
  neededItemCount: number;
  overdueItemCount: number;
  notDecidedItemCount: number;
}

export interface IGetProjectSelectionsSummaryArgs {
  projectId: number;
}

export interface IUpdateReminderItemBody
  extends Pick<ISelectionItem, 'status' | 'endDate' | 'projectPhaseId' | 'projectPhaseItemId'> {
  selectionId: number;
  profileIds: number[];
}

export interface IUpdateRemindersBulkBody {
  projectId: number;
  data: IUpdateReminderItemBody[];
}

export interface IDeleteSelectionItemAttachmentBody {
  projectId: number;
  selectionId: number;
  options: ICreateSelectionItemBody['options'];
}

export interface IUnapproveSelectionBody {
  selectionId: number;
  categoryId: number;
  projectId: number;
}

export interface ICostCode {
  id: number;
  categoryId: number;
  name: string;
  number: string;
  budget?: string;
  approvedSelectionAmountDecimalForm: string;
  category: Pick<ICostCodeCategory, 'name' | 'number'>;
  fileCabinetId?: number | string;
  itemsCount?: number;
}

export interface ICostCodeWithSelections extends ICostCode {
  selections: ISelectionItem[];
  canRead?: boolean;
}

export interface ICostCodeCategory {
  id: number;
  projectId: number;
  name: string;
  number: string;
}

export interface ICostCodeCategoryWithCodes extends ICostCodeCategory {
  costCodes: ICostCode[];
}

export type TGetCostCodeCategoriesResponse = IDataList<ICostCodeCategory>;

export interface IGetCostCategoriesArgs {
  projectId: number;
  search?: string;
  cursor?: number;
  limit?: number;
  withCostCodes?: boolean;
}

export interface ICreateCostCodeCategoryBody extends Omit<ICostCodeCategory, 'id'> {}

export interface IUpdateCostCodeCategoryBody extends ICostCodeCategory {}

export interface IDeleteCostCodeCategoryBody {
  projectId: number;
  categoryId: number;
}

export type TGetCostCodesWithSelectionsResponse = IDataList<ICostCodeWithSelections>;

export type TGetCostCodesResponse = IDataList<ICostCode>;

export interface IGetCostCodesArgs {
  projectId: number;
  search?: string;
  cursor?: number;
  limit?: number;
}

export interface IGetCostCodesWithSelectionsArgs extends IGetCostCodesArgs {
  roomId?: number;
  status?: SelectionStatusOptionsEnum;
  toDate?: Date;
  fromDate?: Date;
  costCodeId?: number;
  reminderStatus?: SelectionReminderEnum;
}

export interface ICreateCostCodeBody
  extends Omit<ICostCode, 'id' | 'approvedSelectionAmountDecimalForm' | 'category'> {
  projectId: number;
}

export interface IUpdateCostCodeBody
  extends Partial<Omit<ICostCode, 'id' | 'approvedSelectionAmountDecimalForm' | 'category'>> {
  costCodeId: number;
  projectId: number;
}

export interface IDeleteCostCodeBody {
  costCodeId: number;
  projectId: number;
}

export enum PreLoadedCostCodeTemplatesEnum {
  NAHB = 'NAHB',
  CSI = 'CSI',
}

export interface IPreLoadedCostCodeTemplateSubject {
  id: number;
  title: PreLoadedCostCodeTemplatesEnum;
  subTitle: string;
}

export type TGetPreLoadedCostCodeTemplatesResponse = IDataList<IPreLoadedCostCodeTemplateSubject>;

interface IPreLoadedCostCode extends Pick<ICostCode, 'id' | 'name' | 'number'> {
  costCategoryId: number;
}

export interface IPreLoadedCostCodeTemplateBody
  extends Pick<ICostCodeCategory, 'id' | 'name' | 'number'> {
  templateId: number;
  costCodes: IPreLoadedCostCode[];
}

export type TGetPreLoadedCostCodeTemplateByIdResponse = {
  items: IPreLoadedCostCodeTemplateBody[];
};

export interface IGetPreLoadedCostCodeTemplateByIdArgs {
  templateId: number;
}

export interface ICreateCostCodesByTemplateIdBody {
  templateId: number;
}

export enum IDefaultCostCodeStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface IDefaultCostCode extends Pick<ICostCode, 'id' | 'name' | 'number'> {
  description: string | null;
  costCategoryId: number;
  status: IDefaultCostCodeStatusEnum;
}

export interface IDefaultCostCodeCategory
  extends Pick<ICostCodeCategory, 'id' | 'name' | 'number'> {
  companyId: number;
  status: IDefaultCostCodeStatusEnum;
  companyCostCodes?: IDefaultCostCode[];
}

export type TGetDefaultCostCodeCategoriesResponse = IDataList<IDefaultCostCodeCategory>;

export interface IGetDefaultCostCodeCategoriesArgs {
  status?: IDefaultCostCodeStatusEnum;
  search?: string;
  withCostCodes?: boolean;
}

export interface ICreateDefaultCategoryBody
  extends Pick<ICreateCostCodeCategoryBody, 'name' | 'number'> {}

export interface IUpdateDefaultCategoryBody
  extends Pick<IUpdateCostCodeCategoryBody, 'id' | 'name' | 'number'> {}

export interface IArchiveDefaultCategory {
  costCategoryId: number;
}

export interface ICreateDefaultCostCodeBody extends Pick<ICreateCostCodeBody, 'name' | 'number'> {
  costCategoryId: number;
}

export interface IUpdateDefaultCostCodeBody
  extends Pick<IUpdateCostCodeBody, 'name' | 'number' | 'costCodeId'> {
  costCategoryId: number;
}

export interface IArchiveDefaultCostCodeBody {
  costCodeId: number;
  categoryId: number;
}

export enum CreateByTemplateResponseTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface IMappedCustomTemplateRow {
  costCategoryNumber: string;
  costCategoryName: string;
  costCodeNumber?: string;
  costCodeName?: string;
  costCodeDescription?: string;
}

export interface ICreateCostCodesByCustomTemplateBody {
  data: IMappedCustomTemplateRow[];
}

export interface ICreateCostCodesByCustomTemplateResponse {
  data: Record<number, Partial<IMappedCustomTemplateRow>>;
  type: CreateByTemplateResponseTypeEnum;
}

export interface IImportCostCodesBody {
  costCodes: Pick<ICostCode, 'id' | 'budget'>[];
  projectId: number;
}

export enum SelectionReminderEnum {
  DUE_DATE_NEEDED = 'DUE_DATE_NEEDED',
  REMINDER_NEEDED = 'REMINDER_NEEDED',
}

export interface IGetLibrarySelectionsArgs {
  limit?: number;
  offset?: number;
  search?: string;
}

export type TGetLibrarySelectionsResponse = IDataList<ILibrarySelection>;

export interface ICreateLibrarySelectionBody {
  title: string;
  options: ILibrarySelectionOptionBody[];
}

export interface IUpdateLibrarySelectionBody extends ICreateLibrarySelectionBody {
  id: number;
}

export interface IDeleteLibrarySelectionBody {
  id: number;
}

export interface IBulkCreateSelectionsBody {
  items: Array<Omit<ICreateSelectionItemBody, 'saveToLibrary'>>;
  projectId: number;
}
