import React, { memo } from 'react';
// mui components
import { Button, Grid, Typography } from '@mui/material';
// icons
import { ReactComponent as LogoDark } from 'assets/icons/logo_dark.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/Alert_New_icon.svg';
import { useNavigate } from 'react-router-dom';
import { useLogOut } from 'hooks/useLogOut';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { handleSetInactiveInitial } from 'redux/slices/auth-slice';

const InactiveAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { onLogOut } = useLogOut();

  return (
    <Grid
      sx={{
        height: '100vh',
        background: '#F2F5FF',
      }}
    >
      <Grid
        sx={{
          paddingBottom: '0px',
          textAlign: 'center',
          '& svg': {
            width: '130px',
            height: '25px',
            textAlign: 'center',
            margin: '44px auto 0',
          },
        }}
      >
        <LogoDark />
      </Grid>
      <Grid
        container
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ margin: '158px auto' }}
      >
        <AlertIcon style={{ width: '100px', height: '100px' }} />

        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '400',
            fontFamily: 'Inter',
            color: '#23252F',
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          Account Inactive
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: 'Inter',
            color: '#23252F',
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          No active BuilderPad accounts found. <br /> Contact your account owner or create a new
          account.
          {/* Please reactivate your BuilderPad account using the web version <br /> to start accessing
          your projects */}
        </Typography>
        <Grid container sx={{ justifyContent: 'center', gap: '15px' }}>
          <Button
            onClick={() => {
              dispatch(handleSetInactiveInitial());
              navigate('/account-setup');
            }}
            variant="contained"
            sx={{
              width: '200px',
              height: '36px',
              background: '#4D6EF5',
              borderRadius: '8px',
              border: '1px solid #4D6EF5',
              marginTop: '30px',
              padding: '8px 16px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '146%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              transition: '300ms',
              textTransform: 'none',
            }}
          >
            Create New Account
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default memo(InactiveAccountPage);
