export const appPromtStyles = {
  container: {
    alignItems: 'center',
    textAlign: 'center',
    gap: '20px',
    width: '285px',
    height: '270px',
    background: '#F2F5FF',
    border: '1px solid #E7ECFF',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    borderRadius: '12px',
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    padding: '40px 35px 0 35px',
  },
  textStyle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    mt: '10px',
  },
  iconContainer: {
    width: '90px',
    gap: '10px',
    marginTop: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconBox: {
    boxSizing: 'border-box',
    padding: '8px',
    width: '100%',
    height: '90px',
    background: '#FFFFFF',
    border: '1px solid #E7ECFF',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  smallText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    mt: '15px',
    color: '#AEB0BC',
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
    cursor: 'pointer',
  },
  footer: {
    justifyContent: 'center',
    gap: '24px',
  },
};
