import React, { useCallback, FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
// mui components
import { Grid, Box, Switch, Typography, Button } from '@mui/material';
// components
import User from 'components/file-cabinet-folders/modals/components/user';
import CustomModal from '../components/custom-modal';
import ActionButton from 'components/buttons/index';
// controllers
import OutlinedInputController from 'controllers/outlined-input-controller';
// hooks
import {
  postProjectCabinetUsersList,
  projectCabinetUsersList,
} from 'utils/helpers/fileCabinetHelpers';
import useToast from 'hooks/useToast';
// api
import { TModalProps } from 'components/file-cabinet-folders/types';
import { useGetProjectByIdQuery } from 'redux/api/projects';
import {
  useCreateFileCabinetFolderMutation,
  useLazyGetNewFolderPublicKeyQuery,
} from 'redux/api/file-cabinet';
// icons
import { ReactComponent as CopyIcon } from 'assets/icons/cabinet/copy.svg';
// styles
import { styles } from './styles';

type Props = TModalProps & {
  title: string;
  subtitle: string;
  fromModal?: boolean;
  projectFolderId?: string | number;
  projectId: string | number;
};

const CreateProjectCabinetFolder: FC<Props> = ({
  open,
  title,
  subtitle,
  handleClose,
  fromModal,
  projectFolderId,
  projectId,
}) => {
  const [isInit, setIsInit] = useState(true);

  const { folderId } = useParams();
  const { hash } = useLocation();
  const showToast = useToast();

  const [handleCreateFoler, { isLoading }] = useCreateFileCabinetFolderMutation();
  const { data: project } = useGetProjectByIdQuery({ id: Number(projectId) }, { skip: !projectId });
  const [getPublicKey, { isLoading: isPublicKeyLoading, data: publicKeyData }] =
    useLazyGetNewFolderPublicKeyQuery();

  const form = useForm({
    defaultValues: {
      name: '',
      users: project?.profiles?.length ? projectCabinetUsersList(project?.profiles) : [],
      isPublic: false,
    },
    resolver: yupResolver(yup.object({ name: yup.string().required('Please fill the field') })),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!!project?.profiles?.length && isInit) {
      form.setValue('users', projectCabinetUsersList(project?.profiles));
      setIsInit(false);
    }
  }, [project, isInit]);

  const fields = form.watch('users');

  const { handleSubmit, clearErrors, reset } = form;

  const checkParentFolderId = useCallback(() => {
    if (!!folderId) {
      return folderId;
    }

    if (fromModal && !!hash) {
      return hash?.substring(1);
    }

    if (fromModal && !!projectFolderId) {
      return projectFolderId;
    }

    return 0;
  }, [folderId, hash, projectFolderId]);

  const toggleMakePublic = useCallback((e) => {
    getPublicKey();
    form.setValue('isPublic', e.target.checked);
  }, []);

  const handleCustomSubmit = (data) => {
    const formData = {
      name: data?.name,
      permissions: postProjectCabinetUsersList(data?.users),
      parentFolderId: checkParentFolderId(),
      isPublic: data?.isPublic,
      ...(!!publicKeyData?.accessKey && { accessKey: publicKeyData?.accessKey }),
    };

    if (fromModal) {
      handleCreateFoler({ ...formData, projectId: projectId })
        .unwrap()
        .then(() => {
          clearErrors();
          handleClose();
          reset();
        })
        .catch((err) => {
          showToast({
            message: !!err.data.message ? err.data.message : 'Something went wrong!',
            type: 'error',
          });
        });
    }
  };

  const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  };

  const handleCopy = async () => {
    try {
      if (publicKeyData?.accessKey) {
        await navigator.clipboard.writeText(
          `${process.env.REACT_APP_PUBLIC_URL}public-file-cabinet?accessKey=${publicKeyData?.accessKey}`
        );
        showToast({ type: 'success', message: 'Link copied' });
      }
    } catch (err) {
      showToast({ type: 'error', message: 'Something went wrong' });
    }
  };

  return (
    <CustomModal open={open} title={title} subtitle={subtitle} handleClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleCustomSubmit)}>
          <OutlinedInputController name="name" label="Name" placeholder="Enter Name" withError />

          <Box sx={styles.usersContainer}>
            <Grid sx={styles.rolesTableHead}>
              <Grid sx={styles.roleTableTitle}>User</Grid>
              <Grid sx={styles.roleTableItem}>View</Grid>
              <Grid sx={styles.roleTableItem}>Add/Edit</Grid>
              <Grid sx={styles.roleTableItem}>Delete</Grid>
              <Grid sx={styles.roleTableEmptyItem}></Grid>
            </Grid>

            {fields?.map((user, index) => (
              <User
                key={`${user?.permissions?.canRead}+${user?.permissions?.canAddUpdate}+${user?.permissions?.canDelete}+${index}`}
                user={user}
                index={index}
                getInitials={getInitials}
              />
            ))}
          </Box>

          <Grid sx={styles.makePublicWrapper}>
            <Grid>
              <Typography sx={styles.makePublicTitle}>Make public</Typography>
              <Typography sx={styles.makePublicSubTitle}>
                Copy link and send to your friend.
              </Typography>
            </Grid>

            <Switch
              name="isPublic"
              sx={styles.switch}
              onChange={toggleMakePublic}
              disabled={isPublicKeyLoading}
              defaultChecked={false}
            />

            {!!form.getValues('isPublic') && (
              <Grid sx={styles.copyBtnWrapper}>
                <Typography sx={styles.copyUrl}>
                  {publicKeyData?.accessKey
                    ? `${process.env.REACT_APP_PUBLIC_URL}public-file-cabinet?accessKey=${publicKeyData?.accessKey}`
                    : ''}
                </Typography>

                <Button
                  startIcon={<CopyIcon />}
                  sx={styles.copyBtn}
                  disableRipple
                  onClick={handleCopy}
                  disabled={!publicKeyData?.accessKey}
                >
                  Copy
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid sx={styles.actionButtonsConatiner}>
            <ActionButton
              loading={isLoading}
              variant="secondary"
              onClick={handleClose}
              type="button"
              label="Cancel"
            />

            <ActionButton loading={isLoading} variant="primary" type="submit" label="Save" />
          </Grid>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateProjectCabinetFolder;
