import React, { useCallback, FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// mui components
import { Grid } from '@mui/material';
// api
import { handleSetBreadCrumbs, handleSetSearchValue } from 'redux/slices/file-cabinet-slice';
import { fileCabinetSelector } from 'redux/selector';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// types
import { FileCabinetTypeEnum, IFolder } from 'redux/api/file-cabinet';

type Props = {
  folder?: IFolder;
  handleOpenFile?: () => void;
  style: React.CSSProperties;
  children: React.ReactNode;
  type: string;
  publicFolders: boolean;
};

const LinkContainer: FC<Props> = ({
  folder,
  handleOpenFile,
  style,
  type,
  children,
  publicFolders,
}) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { breadCrumbs } = useAppSelector(fileCabinetSelector);

  const handleChangePath = useCallback(
    (id: string | number) => {
      if (id) {
        dispatch(handleSetSearchValue(null));

        if (
          pathname.includes('selections') ||
          pathname.includes('account-dashboard') ||
          pathname.includes('project-dashboard')
        ) {
          let newBreadCrumbs = [...breadCrumbs, { id: folder?.id, name: folder?.name }];

          dispatch(handleSetBreadCrumbs(newBreadCrumbs));
          localStorage.setItem('fileCabinetBreadCrumbs', JSON.stringify(newBreadCrumbs));

          navigate(`${pathname}#${id}`);
        } else if (publicFolders) {
          let newBreadCrumbs = [...breadCrumbs, { id: folder?.accessKey, name: folder?.name }];

          dispatch(handleSetBreadCrumbs(newBreadCrumbs));
          localStorage.setItem('fileCabinetBreadCrumbs', JSON.stringify(newBreadCrumbs));

          navigate(`/public-file-cabinet/${folder?.accessKey}`);
        } else {
          return;
        }
      }
    },
    [folder, pathname, breadCrumbs]
  );

  if (type === FileCabinetTypeEnum.Folder) {
    return (
      <Grid
        sx={{ ...style, position: 'relative' }}
        onClick={() => {
          handleChangePath(folder.id);
        }}
      >
        {children}
      </Grid>
    );
  }

  return (
    <Grid onClick={handleOpenFile} sx={style}>
      {children}
    </Grid>
  );
};

export default LinkContainer;
