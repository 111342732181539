import { useEffect } from 'react';
// custom hooks
import { useLogOut } from './useLogOut';
// api
import { authApi } from 'redux/api/auth';

const BROADCAST_CHANNEL_NAME = 'broadcast_channel';

export enum BroadcastChannelMessagesEnum {
  LOG_OUT = 'log_out',
  LOG_IN = 'log_in',
}

export interface IBroadcastChannelData {
  message: BroadcastChannelMessagesEnum;
}

const channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

export const useBroadcastChannel = () => {
  const { onLogOut } = useLogOut();
  const { data: userMe } = authApi.endpoints.getMe.useQueryState();

  useEffect(() => {
    const eventListener = (receivedMessage: MessageEvent<IBroadcastChannelData>) => {
      switch (receivedMessage?.data?.message) {
        case BroadcastChannelMessagesEnum.LOG_OUT:
          onLogOut({ withoutPostMessage: true });
          break;
        case BroadcastChannelMessagesEnum.LOG_IN:
          if (!userMe) {
            window.location.reload();
          }
          break;
        default:
          return;
      }
    };

    channel.onmessage = eventListener;

    return () => {
      if (eventListener) {
        channel.removeEventListener('message', eventListener);
      }
    };
  }, [userMe, onLogOut]);

  return null;
};

export const postMessage = (message: IBroadcastChannelData) => {
  try {
    channel.postMessage(message);
  } catch (err) {
    console.log(err);
  }
};
