import React, { useCallback, useMemo, useState, useRef, FC, useLayoutEffect } from 'react';
// mui components
import { Grid } from '@mui/material';
import { styled } from '@mui/styles';
// components
import PhotoViewer, { IGalleryProps } from 'components/photo-viewer';
import Player from 'components/player';
import MediaItem from './components/media-item';
import RecentActivityItem from './components/recent-activity-item';
import ActivityFeedMediaItems from './components/activity-feed-items';
// types
import { IPostUploads, IUpdatePostBody, IUploadingFiles } from 'redux/api/activity-feed';
import { IStyles, IUploadedFilesData } from 'redux/types';
// helpers
import { isAudio, isUploadsFromPost, isVideo } from './helpers';
// constants
import { DOCUMENT_FORMATS } from 'utils/constants';
// styles
import { uploadedImagesStyles } from './styles/styles';

const HEIGHT_MULTIPLIER = 30;

interface IUploadedImagesProps {
  type: 'recent_activity' | 'activity_feed' | 'new_images';
  uploads?: IUploadedFilesData[] | IPostUploads[];
  uploadingFiles?: IUploadingFiles[];
  visibleImagesCount?: number;
  imageStyles?: IStyles;
  containerStyles?: IStyles;
  handleDeleteUploadedData?: (fileId: string) => void;
  editablePostBody?: IUpdatePostBody;
  isDescriptionEdtable?: boolean;
  updateDescription?: (id: string, description: string) => void;
}

const UploadedImages: FC<IUploadedImagesProps> = ({
  uploads,
  uploadingFiles,
  containerStyles,
  visibleImagesCount,
  type,
  handleDeleteUploadedData,
  editablePostBody,
  isDescriptionEdtable,
  updateDescription,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [activePhoto, setActivePhoto] = useState(0);

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (containerRef.current?.offsetWidth) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    if (containerRef.current?.offsetHeight) {
      setContainerHeight(containerRef.current.offsetHeight);
    }
  }, []);

  const handleOpenModal = useCallback((index: number) => {
    setOpenImageViewer(true);
    setActivePhoto(index);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenImageViewer(false);
  }, []);

  const multimedia: IPostUploads[] | IUploadedFilesData[] = useMemo(() => {
    if (!uploads?.length) {
      return [];
    }

    if (isUploadsFromPost(uploads)) {
      if (type === 'activity_feed') {
        return uploads.filter(
          ({ name, mimeType }) =>
            !DOCUMENT_FORMATS.includes(name.substring(name.lastIndexOf('.'))) && !isAudio(mimeType)
        );
      }

      return uploads.filter(({ name }) => {
        return !DOCUMENT_FORMATS.includes(name.substring(name.lastIndexOf('.')));
      });
    }

    return uploads.filter(
      ({ name }) => !DOCUMENT_FORMATS.includes(name.substring(name.lastIndexOf('.')))
    );
  }, [uploads, type]);

  const uploadingImages = useMemo(
    () =>
      uploadingFiles
        ? uploadingFiles.filter(
            ({ name }) => !DOCUMENT_FORMATS.includes(name.substring(name.lastIndexOf('.')))
          )
        : [],
    [uploadingFiles]
  );

  const hiddenImagesCount = useMemo(
    () => multimedia.length - visibleImagesCount,
    [multimedia.length, visibleImagesCount]
  );

  const galleryPhotos: IGalleryProps['imagesList'] = useMemo(
    () =>
      uploadingImages.length
        ? uploadingImages
            .filter(({ mimeType }) => !isAudio(mimeType))
            .map(({ url, mimeType, previewUrl, description, location, timestamp, id }, index) => ({
              id: index,
              imageSrc: url,
              thumbnail: url,
              previewUrl: isVideo(mimeType) ? previewUrl : '',
              mimeType,
              itemId: id,
              description,
              location,
              timestamp,
            }))
        : isUploadsFromPost(multimedia)
        ? multimedia
            .filter(({ mimeType }) => !isAudio(mimeType))
            .map(({ mimeType, url, options, id, description, location, timestamp }, index) => ({
              id: index,
              imageSrc: url,
              thumbnail: url,
              mimeType,
              previewUrl: isVideo(mimeType) ? options?.previewUrl : '',
              itemId: id,
              description,
              location,
              timestamp,
            }))
        : multimedia.map((image, index) => ({
            id: index,
            imageSrc: image.url,
            thumbnail: image.url,
            itemId: image.id,
            ...('description' in image &&
              typeof image.description === 'string' && { description: image.description }),
          })),
    [uploadingImages, multimedia]
  );

  const StyledBigImage = styled('img')(() => ({
    maxWidth: `${containerWidth}px`,
    maxHeight: `${containerWidth * HEIGHT_MULTIPLIER}px`,
    ...(uploadedImagesStyles.bigImage as IStyles),
    objectFit: 'cover',
  }));

  const recentActivityMedia =
    type === 'recent_activity'
      ? multimedia.slice(0, visibleImagesCount + 1).map((item, index) => {
          return (
            <RecentActivityItem
              key={item.id}
              item={item}
              handleOpenModal={handleOpenModal}
              index={index}
              hiddenImagesCount={hiddenImagesCount}
              visibleImagesCount={visibleImagesCount}
            />
          );
        })
      : null;

  const activityFeedMedia =
    type === 'activity_feed' && containerWidth && containerHeight ? (
      <ActivityFeedMediaItems
        multimedia={multimedia}
        visibleImagesCount={visibleImagesCount}
        hiddenImagesCount={hiddenImagesCount}
        handleOpenModal={handleOpenModal}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ) : null;

  const entityMedia =
    type === 'new_images'
      ? uploadingImages.map((item) => {
          const index = galleryPhotos?.find(({ itemId }) => itemId === item.id)?.id;
          return (
            <MediaItem
              key={item.tempId}
              item={item}
              onDelete={handleDeleteUploadedData}
              index={index}
              handleOpenModal={handleOpenModal}
            />
          );
        })
      : null;

  if (!multimedia.length && !uploadingImages.length) {
    return null;
  }

  if (multimedia.length === 1 && type === 'activity_feed') {
    if (isUploadsFromPost(multimedia)) {
      const currentItem = multimedia[0];
      if (currentItem?.mimeType && isVideo(currentItem.mimeType)) {
        return (
          <Grid ref={containerRef} sx={uploadedImagesStyles.bigImageContainer}>
            {containerWidth ? <Player url={currentItem.url} controls={true} /> : null}
          </Grid>
        );
      }
    }

    const currentImage = multimedia[0];

    return (
      <Grid ref={containerRef} sx={{ ...uploadedImagesStyles.bigImageContainer, height: '300px' }}>
        {containerWidth ? (
          <>
            <StyledBigImage
              src={currentImage.url}
              alt={currentImage.name}
              onClick={() => handleOpenModal(0)}
            />
            <PhotoViewer
              isOpen={openImageViewer}
              handleClose={handleCloseModal}
              activeIndex={activePhoto}
              imagesList={galleryPhotos}
              editablePostData={editablePostBody}
              isDescriptionEditable={isDescriptionEdtable}
              updateDescription={updateDescription}
            />
          </>
        ) : null}
      </Grid>
    );
  }

  return (
    <Grid ref={containerRef} sx={containerStyles ?? uploadedImagesStyles.container}>
      {type === 'recent_activity'
        ? recentActivityMedia
        : type === 'activity_feed' && containerWidth && containerHeight
        ? activityFeedMedia
        : type === 'new_images'
        ? entityMedia
        : null}
      <PhotoViewer
        isOpen={openImageViewer}
        handleClose={handleCloseModal}
        activeIndex={activePhoto}
        imagesList={galleryPhotos}
        editablePostData={editablePostBody}
        isDescriptionEditable={isDescriptionEdtable}
        updateDescription={updateDescription}
      />
    </Grid>
  );
};

export default UploadedImages;
