import React, { FC, useMemo } from 'react';
// mui components
import { Grid, Typography } from '@mui/material';
// components
import UploadedImages from 'components/uploaded-images';
import UploadedFiles from 'components/uploaded-files';
// api
import { TRecentActivity } from 'redux/api/activity-feed';
import { useGetMeQuery } from 'redux/api/auth';
// constants
import { urlRegex } from 'utils/constants';
// styles
import { activityButtonStyles } from '../styles/styles';
import { createContent } from 'utils/helpers';
import { isAudio, isVideo } from 'components/uploaded-images/helpers';

interface IPostItemProps {
  post: TRecentActivity;
  handleOpenPostModal: (postid: number) => void;
}

const PostItem: FC<IPostItemProps> = ({ post, handleOpenPostModal }) => {
  const { data: userMe } = useGetMeQuery();

  const htmlContent = useMemo(
    () =>
      post.type === 'POST'
        ? post.data.content
        : post.type === 'COMMENT'
        ? post.data?.comment?.content
        : '',
    [post.type, post.data]
  );

  const content = createContent(htmlContent, post?.data?.mentions ?? []);

  const uploads = useMemo(
    () => (post.type === 'COMMENT' ? post.data?.comment?.uploads : post.data.uploads),
    [post.type, post.data]
  );

  const eventType = useMemo(
    () =>
      post.type === 'POST' &&
      !post.data.content &&
      post.data.uploads.every(({ mimeType }) => isVideo(mimeType))
        ? 'posted a video'
        : post.type === 'POST' &&
          !post.data.content &&
          post.data.uploads.every(({ mimeType }) => isAudio(mimeType))
        ? 'posted an audio message'
        : (post.type === 'POST' && !post.data.content) ||
          (post.type === 'COMMENT' && !post.data?.comment?.content)
        ? 'uploaded a file'
        : post.type === 'POST' &&
          post.data?.mentions?.find(({ profileId }) => profileId === userMe.id)
        ? 'mentioned you on post'
        : post.type === 'POST'
        ? 'posted to the activity'
        : post.type === 'COMMENT' &&
          post.data?.mentions?.find(({ profileId }) => profileId === userMe.id)
        ? 'mentioned you on comment'
        : post.type === 'COMMENT'
        ? 'commented on a post'
        : '',
    [post.type, post.data]
  );

  const profile = useMemo(
    () => (post.type === 'COMMENT' ? post.data?.comment?.profile : post.data.profile),
    [post.type, post.data]
  );

  return (
    <Grid sx={activityButtonStyles.postItemContainer}>
      <Grid sx={activityButtonStyles.postItemProfileContainer}>
        <Typography sx={activityButtonStyles.postItemProfile}>
          {`${profile?.firstName ?? ''} ${profile?.lastName ?? ''} `}
          <span
            onClick={() => {
              if (post?.type === 'COMMENT') {
                handleOpenPostModal(post?.data?.comment?.postId);
              } else if (post?.type === 'POST') {
                handleOpenPostModal(post.id);
              }
            }}
          >
            {eventType}
          </span>
        </Typography>
      </Grid>
      <Grid sx={activityButtonStyles.postItemContent}>
        {content ? (
          <div
            dangerouslySetInnerHTML={{
              __html: content.replace(urlRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
              }),
            }}
          />
        ) : null}
      </Grid>
      <UploadedImages uploads={uploads} type="recent_activity" visibleImagesCount={5} />
      <UploadedFiles uploads={uploads} type="recent_activity" />
    </Grid>
  );
};

export default PostItem;
