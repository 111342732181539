import { api } from 'redux/api';

import {
  ICompanyRole,
  ICompanyRolePermission,
  TCompanyRolePermissionsMe,
  ICreateCompanyRolesBody,
  ICreateRolePermissionsBody,
  IDeleteCompanyRolesBody,
  IGetCompanyRolePermissionsArgs,
  IUpdateRolePermissionsBody,
  IUpdateRolePermissionsResponse,
  TCompanyRolePermissionsResponse,
  IUpdateRoleFolderPermissionsBody,
  IUpdateCompanyRolesBody,
} from './';

import { companyRolePermissionsMapping } from 'redux/mappings/company-role-permissions';
import { fileCabinetApi, FILE_CABINET_DEFAULT_FOLDERS } from '../file-cabinet';

enum Endpoints {
  COMPANY_ROLES = 'company-roles/',
  COMPANY_ROLE_PERMISSIONS = 'company-role-permissions/',
}

export const companyRolesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyRoles: build.query<ICompanyRole[], void>({
      query: () => ({
        url: Endpoints.COMPANY_ROLES,
      }),
      providesTags: ['Company Roles'],
    }),
    createCompanyRoles: build.mutation<ICompanyRole, ICreateCompanyRolesBody | any>({
      query: (data) => ({
        url: Endpoints.COMPANY_ROLES,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: (res) => (res ? ['Company Roles'] : []),
    }),
    deleteCompanyRoles: build.mutation<undefined, IDeleteCompanyRolesBody>({
      query: ({ id, ...data }) => ({
        url: `${Endpoints.COMPANY_ROLES}${id}`,
        body: data,
        method: 'DELETE',
      }),
      invalidatesTags: (_, err) => (!err ? ['Company Roles', 'User'] : []),
    }),

    updateCompanyRoleByID: build.mutation<undefined, IUpdateCompanyRolesBody>({
      query: ({ id, data }) => ({
        url: `${Endpoints.COMPANY_ROLES}${id}`,
        body: data,
        method: 'PUT',
      }),

      invalidatesTags: (_, err) => (!err ? ['Company Roles', 'User'] : []),
    }),

    getCompanyRolePermissions: build.query<
      TCompanyRolePermissionsResponse,
      IGetCompanyRolePermissionsArgs
    >({
      query: (queryArgs) => ({
        url: Endpoints.COMPANY_ROLE_PERMISSIONS,
        params: queryArgs,
      }),
      transformResponse: (response: ICompanyRolePermission[]) =>
        companyRolePermissionsMapping(response),
      providesTags: ['Role Permissions'],
    }),

    getCompanyRolePermissionsMe: build.query<TCompanyRolePermissionsMe, void>({
      query: () => ({
        url: `${Endpoints.COMPANY_ROLE_PERMISSIONS}me`,
      }),
      providesTags: ['Company', 'Role Permissions'],
    }),

    createCompanyRolePermissions: build.mutation<
      ICompanyRolePermission,
      ICreateRolePermissionsBody
    >({
      query: (data) => ({
        url: Endpoints.COMPANY_ROLE_PERMISSIONS,
        body: data,
        method: 'POST',
      }),
    }),

    updateCompanyRolePermissions: build.mutation<
      IUpdateRolePermissionsResponse,
      IUpdateRolePermissionsBody
    >({
      query: (data) => ({
        url: Endpoints.COMPANY_ROLE_PERMISSIONS,
        body: data,
        method: 'PATCH',
      }),

      invalidatesTags: (_, err) => (!err ? ['Role Permissions'] : []),
    }),

    getCompanyRoleFolderPermissions: build.query<TCompanyRolePermissionsMe, void>({
      query: () => ({
        url: `${Endpoints.COMPANY_ROLE_PERMISSIONS}me/file-cabinet`,
      }),
      providesTags: ['Company', 'Role Permissions', 'Folder Permissions'],
    }),

    updateCompanyRoleFolderPermissions: build.mutation<
      IUpdateRolePermissionsResponse,
      IUpdateRoleFolderPermissionsBody
    >({
      query: ({ itemId, roles }) => ({
        url: `${Endpoints.COMPANY_ROLE_PERMISSIONS}default-folders/${itemId}`,
        body: { roles: roles },
        method: 'PUT',
      }),

      onQueryStarted: async (
        { withOptimisticUpdate, roleId, access, itemId, value },
        { dispatch, queryFulfilled }
      ) => {
        if (withOptimisticUpdate) {
          const fetch = dispatch(
            fileCabinetApi.util.updateQueryData(
              'getFileCabinetDefaultFolders',
              FILE_CABINET_DEFAULT_FOLDERS as undefined,
              (draft) => {
                const folderIndex = draft?.items?.findIndex(({ id }) => id === itemId);

                if (folderIndex >= 0) {
                  const roleIndex = draft?.[folderIndex]?.roles?.findIndex(
                    (role) => role?.id === roleId
                  );

                  if (roleIndex >= 0) {
                    draft[folderIndex].roles[roleIndex].permissions[access] = value;
                  }
                }
              }
            )
          );

          try {
            await queryFulfilled;
          } catch (error) {
            fetch?.undo();
          }
        }
      },

      invalidatesTags: (_, err) =>
        !err ? ['Role Permissions', 'File Cabinet', 'File Cabinet Folder'] : [],
    }),
  }),
});

export const {
  useGetCompanyRolesQuery,
  useLazyGetCompanyRolesQuery,
  useCreateCompanyRolesMutation,
  useDeleteCompanyRolesMutation,
  useUpdateCompanyRoleByIDMutation,
  useGetCompanyRolePermissionsQuery,
  useLazyGetCompanyRolePermissionsQuery,
  useGetCompanyRolePermissionsMeQuery,
  useLazyGetCompanyRolePermissionsMeQuery,
  useCreateCompanyRolePermissionsMutation,
  useUpdateCompanyRolePermissionsMutation,
  useGetCompanyRoleFolderPermissionsQuery,
  useUpdateCompanyRoleFolderPermissionsMutation,
} = companyRolesApi;
