import React, { FC, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
// mui compoonents
import { Grid } from '@mui/material';
// styles
import { styles } from './styles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
  cMapPacked: true,
};

type TViewPDFProps = {
  src?: string;
  name?: string;
};

const ViewPDFs: FC<TViewPDFProps> = ({ src }) => {
  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages: nextNumPages }: any): void {
    setNumPages(nextNumPages);
  }

  return (
    <Grid sx={styles.container}>
      <Document file={src} options={options} onLoadSuccess={onDocumentLoadSuccess}>
        {Array?.from(new Array(numPages), (el, index) => (
          <Page key={index} pageNumber={index + 1} width={800} />
        ))}
      </Document>
    </Grid>
  );
};

export default ViewPDFs;
