import React, { useCallback, useMemo, useState, memo, useEffect } from 'react';
// libs
import { useFormContext, useController } from 'react-hook-form';
import without from 'lodash.without';
import upperFirst from 'lodash.upperfirst';
// components
import ExistingUser from './components/existing-user';
import ManageUsersFormNewUI from './new-ui';
// icons
import { ReactComponent as AvatarIcon } from 'assets/icons/avatar-placeholder.svg';
// custom hooks
import useToast from 'hooks/useToast';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
// api
import api from 'utils/api';
import { useGetMeQuery } from 'redux/api/auth';
import {
  useGetCompanyRolePermissionsMeQuery,
  useGetCompanyRolesQuery,
} from 'redux/api/company-roles';
import { useGetProjectsProfilesManagementQuery } from 'redux/api/projects';
import { api as reduxApi } from 'redux/api';
// types
import { ICompanyUser, Invite } from 'utils/types';
import { makeStringUpperFirst } from 'utils/helpers';
import { useProjectStepper } from 'hooks/useProjectStepperState';

export interface IAddUsersForm {
  email: string;
  role: number | undefined;
  invites: {
    id?: number;
    email: string;
    role: string;
    delete?: boolean;
    companyRole: { name: string; id: number };
    title: string;
  }[];
  profilesIds: number[];
  title: string;
}

interface OwnProps {
  projectId?: number;
  isFirstProject?: boolean;
  profiles: Array<ICompanyUser | Invite>;
  initialValues?: Partial<IAddUsersForm>;
}

const ManageUsersForm: React.FC<OwnProps> = ({ profiles, initialValues, projectId }) => {
  const dispatch = useAppDispatch();
  const showToast = useToast();
  const { activeStep } = useProjectStepper();

  const [searchValue, setSearchValue] = useState('');

  const { data: companyProfile } = useGetMeQuery();
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const { data: companyRoles } = useGetCompanyRolesQuery();
  const { data: profilesManagement } = useGetProjectsProfilesManagementQuery({
    id: Number(projectId),
  });

  const {
    control,
    getValues,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useFormContext<IAddUsersForm>();

  const {
    field: { onChange: onInvitesChange, value: invitesValue },
  } = useController({ control, name: 'invites', defaultValue: [] });

  const {
    field: { onChange, value },
  } = useController({ control, name: 'profilesIds', defaultValue: [] });

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [reset, initialValues]);

  useEffect(() => {
    if (
      profilesManagement?.assignedInvites &&
      profilesManagement?.assignedInvites?.length !== invitesValue?.length
    ) {
      onInvitesChange(
        profilesManagement.assignedInvites.map(({ id, email, companyRole, title }) => ({
          id,
          companyRole,
          email: email,
          title,
        }))
      );
    }
  }, [profilesManagement?.assignedInvites?.length]);

  const handleSearchUser = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchValue(event.target.value);
    },
    []
  );

  const handleAddUser = async () => {
    if (isValid) {
      const [email, role, title] = getValues(['email', 'role', 'title']);
      try {
        if (invitesValue.findIndex((i) => i.email === email) === -1 && role) {
          await api.auth.invitesBulk({
            data: {
              invites: [
                {
                  email,
                  roleId: role,
                  inviteProjects: [{ projectId: projectId, delete: false }],
                  title,
                },
              ],
            },
          });
          dispatch(reduxApi.util.invalidateTags([{ type: 'Projects', id: Number(projectId) }]));
        }
        setValue('email', '');
        setValue('role', undefined);
        setValue('title', '');
        showToast({ message: 'Invite sent successfully.', type: 'success' });
        if (errors.invites) clearErrors('invites');
      } catch (err) {
        showToast({
          type: 'error',
          message:
            typeof err?.data?.message === 'string'
              ? err?.data?.message
              : 'Something went wrong. Please try again or contact support for help.',
        });
      }
    }
  };

  const handleRemoveInvite = async (item: Invite) => {
    try {
      await api.auth.invitesBulk({
        data: {
          invites: [
            {
              id: item.id,
              delete: true,
            },
          ],
        },
      });
      dispatch(reduxApi.util.invalidateTags([{ type: 'Projects', id: Number(projectId) }]));
      showToast({ message: 'Invite cancelled successfully.', type: 'success' });
    } catch (err) {
      showToast({
        message: 'Something went wrong. Please try again or contact support for help.',
        type: 'error',
      });
    }
  };

  const handleAddInvite = (item: Invite | Omit<Invite, 'id' | 'inviteProjects'>) => {
    if ('id' in item) {
      const isExist = invitesValue.findIndex(({ id }) => id === item.id) > -1;
      const newInvitesValue = isExist
        ? invitesValue.map((invite) =>
            invite.id === item.id ? { ...invite, delete: false } : invite
          )
        : [...invitesValue, { ...item, delete: false }];
      onInvitesChange(newInvitesValue);
    }
  };

  const rolesOptions = useMemo(
    () =>
      companyRoles
        ? companyRoles.map(({ name, id }) => ({
            label: upperFirst(name.toLowerCase()),
            value: id,
          }))
        : [],
    [companyRoles]
  );

  const filteredExistingUsers = useMemo(
    () =>
      [...invitesValue.filter((i) => !i.id), ...profiles]
        .filter((profile) => {
          const searchSubjects: string[] =
            'userId' in profile
              ? [
                  `${profile.firstName} ${profile.lastName}`.toLocaleLowerCase(),
                  profile.email.toLocaleLowerCase(),
                ]
              : [profile.email.toLocaleLowerCase()];
          return searchSubjects.some((subject) =>
            subject.includes(searchValue.toLocaleLowerCase())
          );
        })
        ?.sort((a, b) =>
          a?.id === companyProfile?.id ? -1 : Number(b?.id === companyProfile?.id)
        ) ?? [],
    [searchValue, profiles, invitesValue]
  );

  const renderProfile = (item: ICompanyUser | Invite) =>
    'userId' in item ? (
      <ExistingUser
        key={item.email}
        text={[item.firstName, item.lastName].join(' ')}
        email={item.email ?? ''}
        phone={item.phone ?? ''}
        avatar={item.avatar ?? ''}
        subText={item.roleName ?? ''}
        role={makeStringUpperFirst(item.role) ?? ''}
        id={item.id}
        isAddedInProject={value.includes(item.id)}
        onRemove={() => onChange(without(value, item.id))}
        onAdd={() => onChange([...value, item.id])}
      />
    ) : (
      <ExistingUser
        sx={{ svg: { path: { fill: '#ffffff' } } }}
        key={item.email}
        text={item.email}
        email={item.email}
        subText={upperFirst(item.companyRole.name.toLowerCase())}
        avatarPlaceholder={<AvatarIcon />}
        //isAddedInProject={invitesValue.some((invite) => invite.email === item.email)}
        isInvitedInProject={invitesValue.some(
          (invite) => invite.email === item.email && !invite.delete
        )}
        onRemove={() => handleRemoveInvite(item)}
        onAdd={() => handleAddInvite(item)}
      />
    );

  const props = {
    userPermissions,
    rolesOptions,
    filteredExistingUsers,
    companyProfile,
    renderProfile,
    handleAddUser,
    handleSearchUser,
    isValid,
    initialValues,
    activeStep,
  };

  return <ManageUsersFormNewUI {...props} />;
};

export default memo(ManageUsersForm);
