import React from 'react';
import { useDropzone, ErrorCode, FileRejection } from 'react-dropzone';
// mui components
import { Grid, Typography } from '@mui/material';
// hooks
import useToast from 'hooks/useToast';
// utils
import { CABINET_FILE_TYPES, MAX_FILE_SIZE, MAX_FILE_COUNT } from 'utils/constants';
// icon
import { ReactComponent as UploadIcon } from 'assets/icons/new/selection/upload-button-icon.svg';
// styles
import { styles } from './styles';

type Props = {
  files: File[];
  rejectedFiles: FileRejection[];
  handleFileChange: (f: File[]) => void;
  handleRejectedFilesChange: (f: FileRejection[]) => void;
};

const FileUpload: React.FC<Props> = ({ files, handleFileChange, handleRejectedFilesChange }) => {
  const showToast = useToast();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noDragEventsBubbling: true,
    noClick: true,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    accept: CABINET_FILE_TYPES,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles?.length) {
        const isInvalidFile = rejectedFiles.find(({ errors }) =>
          errors.find(({ code }) => code === ErrorCode.FileInvalidType)
        );
        if (isInvalidFile) {
          showToast({
            type: 'error',
            message: `File type(s) are not supported.`,
          });
        }
        const filteredRejectedFiles = rejectedFiles.filter(({ errors }) =>
          errors.find(({ code }) => code === ErrorCode.FileTooLarge)
        );
        if (filteredRejectedFiles) {
          handleRejectedFilesChange(filteredRejectedFiles.map((item) => item));
        }
      }
      if (acceptedFiles?.length) {
        if (acceptedFiles.length > MAX_FILE_COUNT) {
          showToast({ type: 'error', message: 'Too many files.' });
        }

        const names = files.map((file) => file.name);
        const newFiles = acceptedFiles.filter((f) => {
          if (!names.includes(f.name)) {
            return f;
          } else {
            showToast({
              type: 'error',
              message: `File with ${f.name} name already exists`,
            });
            return null;
          }
        });
        handleFileChange(newFiles);
      }
    },
  });

  return (
    <Grid {...getRootProps()} sx={styles.dragZone} className={isDragActive ? 'isActive' : ''}>
      <Typography sx={styles.dragMainText}>Drag & drop your file here</Typography>
      <Typography sx={styles.dragText}>or</Typography>
      <Grid sx={styles.uploadLabelWrapper}>
        <input
          type="file"
          id="cabinet-file"
          {...getInputProps()}
          multiple
          accept={CABINET_FILE_TYPES}
        />
        <Grid sx={styles.uploadLabel} htmlFor="cabinet-file" component="label">
          <UploadIcon />
          <Typography sx={styles.dragMainText} className="isBlue">
            Upload
          </Typography>
        </Grid>
      </Grid>
      <Typography sx={styles.dragText} className="isGray">
        Maximum file size 50 MB
      </Typography>
    </Grid>
  );
};

export default FileUpload;
