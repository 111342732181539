import { FC } from 'react';
// mui components
import { Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/styles';
// helpers
import { getImageBySize } from 'utils/helpers';
import { getImageUrl, isAudio, isVideo, isVideoOrAudio } from '../helpers';
// types
import { IUploadingFiles } from 'redux/api/activity-feed';
import { IStyles } from 'redux/types';
// icons
import { ReactComponent as CancelIcon } from 'assets/icons/buttons/cancel-request.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/audio-icon.svg';
// styles
import { uploadedImagesStyles as styles } from '../styles/styles';

interface IProps {
  item: IUploadingFiles;
  onDelete: (id: string) => void;
  handleOpenModal: (index: number) => void;
  index?: number;
}

const StyledImage = styled('img')(() => styles.mediaItem as IStyles);

const MediaItem: FC<IProps> = ({ item, onDelete, handleOpenModal, index }) => {
  const onOpen = () => {
    if (typeof index === 'number' && !isAudio(item?.mimeType)) {
      handleOpenModal(index);
    }
  };

  const itemUrl = getImageUrl(item);

  const renderCard = isAudio(item?.mimeType) ? (
    <Grid sx={styles.audioContainer}></Grid>
  ) : itemUrl ? (
    <StyledImage src={getImageBySize(itemUrl, 64, 64, false, true)} alt={item.name} />
  ) : (
    <Grid sx={styles.newImageProgressContainer}>
      <Typography sx={styles.newImageProgressTypo}>{`${item?.percent ?? 100}%`}</Typography>
    </Grid>
  );

  const renderVideoAudioIcon = isVideoOrAudio(item?.mimeType) ? (
    <Grid sx={styles.videoAudioIconContainer}>
      {isVideo(item?.mimeType) ? <VideoIcon /> : <AudioIcon />}
    </Grid>
  ) : null;

  const renderDuration =
    isVideoOrAudio(item?.mimeType) && item?.duration ? (
      <Typography sx={styles.duration}>{item.duration}</Typography>
    ) : null;

  const renderDeleteButton = (
    <IconButton
      sx={styles.newImageDeleteButton}
      onClick={(event) => {
        event?.stopPropagation();
        onDelete(item.id);
      }}
    >
      <CancelIcon />
    </IconButton>
  );

  return (
    <Grid key={item.tempId} sx={styles.newImagesContainer} onClick={onOpen}>
      {renderCard}
      {renderVideoAudioIcon}
      {renderDuration}
      {renderDeleteButton}
    </Grid>
  );
};

export default MediaItem;
