import React, { FC, ReactNode, useCallback } from 'react';
// mui components
import { Grid, MenuItem, Select, SelectChangeEvent, Typography, SelectProps } from '@mui/material';
// components
import OutlinedInput from 'components/inputs/outlined-input';
// icons
import { ReactComponent as AngleDownNewIcon } from 'assets/icons/Chevron-down_New_Icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check_icon.svg';

// types
import { FieldError } from 'react-hook-form';
import { ICommonSelectValues } from '../utils/types';
import { CSSProperties } from '@mui/styles';

export interface IProps extends Omit<SelectProps, 'error'> {
  addNewItem?: boolean;
  options: ICommonSelectValues[];
  optionStyles?: { [key: string]: string };
  required?: boolean;
  withError?: boolean;
  withoutHelperText?: boolean;
  maxWidth?: string;
  additionalOnChange?: (event: SelectChangeEvent<any>) => void;
  onOptionSelect?: (option: IProps['options'][0]) => void;
  error?: FieldError;
  label?: string;
  isCapitalize?: boolean;
  renderOption?: (item: ICommonSelectValues) => ReactNode | null;
  onAddNewItem?: () => void;
  paperStyles?: CSSProperties;
  withoutBorder?: boolean;
  addBtnLabel?: string;
  boxWidth?: string;
}

const SelectComponent: FC<IProps> = ({
  addNewItem,
  options,
  optionStyles,
  placeholder,
  maxWidth,
  name,
  value,
  label,
  error,
  required,
  withError,
  onChange,
  additionalOnChange,
  sx,
  isCapitalize,
  renderOption,
  disabled,
  withoutBorder,
  onAddNewItem,
  addBtnLabel,
  ...props
}) => {
  const renderItemTitle = useCallback(
    (itemLabel: string, color?: string) => (
      <Typography
        sx={{
          textTransform: isCapitalize ? 'capitalize' : 'unset',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          fontSize: '14px',
          lineHeight: '146%',
          fontWeight: '400',
          fontFamily: 'Inter, sans-serif !important',
          overflow: 'hidden',
          w: 'ellipsis',
          whiteSpace: 'nowrap',
          color: color ? color : 'unset',
        }}
      >
        {isCapitalize ? itemLabel.toLowerCase() : itemLabel}
        <span>
          <CheckIcon />
        </span>
      </Typography>
    ),
    [isCapitalize]
  );

  return (
    <Select
      {...props}
      name={name}
      displayEmpty
      value={value}
      IconComponent={AngleDownNewIcon}
      input={
        <OutlinedInput
          readOnly={disabled}
          name={name}
          label={label}
          error={!!error}
          required={!!required}
          helperText={withError ? error?.message || ' ' : ' '}
          withoutHelperText={!withError}
          sx={{ maxWidth: maxWidth || '100%' }}
        />
      }
      onChange={(event, child: React.ReactNode) => {
        onChange?.(event, child);
        additionalOnChange && additionalOnChange(event);
      }}
      sx={{
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',

          '& .MuiGrid-root': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px',

            '& p': {
              width: 'auto',
              gap: '10px',
            },
          },

          '& span': {
            display: 'none',
          },
        },

        '& fieldset': {
          border: withoutBorder ? 'none !important' : '',
        },
      }}
    >
      {!!placeholder && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {options?.map((item) => (
        <MenuItem
          sx={{
            ...optionStyles,
            '&:hover': {
              background: '#F2F5FF',
            },
            '& span': { display: 'none' },
            '&.Mui-selected': {
              '&[aria-selected="false"]': {
                background: '#fff',
                '&:hover': {
                  background: '#F2F5FF',
                },
              },
              '&[aria-selected="true"]': {
                background: '#F2F5FF',
                '& span': { display: 'inline-block' },
              },
            },

            '& .MuiGrid-container': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',

              '& p': {
                width: 'auto',
                gap: '10px',
              },
            },
          }}
          key={`${item.value}-${item.label}`}
          value={item.value}
          disabled={item.disabled}
        >
          {renderOption ? (
            renderOption(item)
          ) : item.color ? (
            <Grid container alignItems="center">
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '14px',
                  borderRadius: '50%',
                  backgroundColor: item.color,
                }}
              />
              {renderItemTitle(item.label, item?.optionColor)}
            </Grid>
          ) : (
            <>{renderItemTitle(item.label, item?.optionColor)}</>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectComponent;
