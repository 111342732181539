export const setScheduleFormStyles = {
  setSchedule: {
    fontSize: '24px',
    lineHeight: '136%',
    fontWeight: 400,
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
    marginBottom: '5px',
  },
  selectSchedule: {
    fontSize: '13px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#6E707A',
    fontFamily: 'Inter, sans-serif !important',
    marginBottom: '20px',
  },
  selectScheduleBody: { marginBottom: '20px' },
  templatesBody: { marginTop: '20px' },
  circularProgress: { minHeight: '200px', justifyContent: 'center', alignItems: 'center' },
};
