export const styles = {
  container: {
    width: '100%',
    overflow: 'hidden',
    userSelect: 'none',

    '& canvas': {
      position: 'relative',
      left: '-20px',
    },
  },
};
