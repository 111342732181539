// api
import { useGetMeQuery } from 'redux/api/auth';
import { IProfileRole } from 'redux/api/profiles';
// custom hooks
import { useAppSelector } from 'redux/hooks/useAppSelector';
// selectors
import { authSelector } from 'redux/selector';

export const useUserInfo = () => {
  const { companyId, isAuth } = useAppSelector(authSelector);

  const { data: userMe } = useGetMeQuery(undefined, {
    skip: !isAuth,
  });

  const getProfileCurrentRole = (roles: IProfileRole[]) =>
    roles.find(({ companyId: id }) => companyId === id.toString());

  const currentRole =
    userMe?.roles?.length && companyId
      ? userMe.roles.find(({ companyId: id }) => companyId === id.toString())
      : null;

  const isOwner = currentRole?.isOwner ? currentRole.isOwner : null;

  return {
    currentRole,
    isOwner,
    getProfileCurrentRole,
  };
};
