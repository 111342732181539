import React, { FC, useCallback } from 'react';
// mui components
import { Grid, Typography } from '@mui/material';
// components
import ActionButton from 'components/buttons';
// styles
import { styles } from './styles';

type TNotSupportedFilesProps = {
  src: string;
};

const NotSupportedFiles: FC<TNotSupportedFilesProps> = ({ src }) => {
  const handleDownloadFile = useCallback(() => {
    window.open(src, '_blank');
  }, []);

  return (
    <Grid sx={styles.container}>
      <Typography sx={styles.title}>This file type is not supported for view</Typography>
      <ActionButton type="button" label="Download and view" onClick={handleDownloadFile} />
    </Grid>
  );
};

export default NotSupportedFiles;
