export const styles = {
  modalHeader: {
    width: '100%',
    padding: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px solid #F3F4F9',
  },

  icon: {
    width: '28px',
    height: '28px',
    color: 'black',
    cursor: 'pointer',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
  },
};
