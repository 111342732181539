import { ComponentType } from 'react';
// pages
import {
  ErrorPage,
  SignInPage,
  SignUpPage,
  SignUpInvitePage,
  ResetPasswordPage,
  ForgotPasswordPage,
  WithoutPlanPage,
  AccountSetupPage,
  InviteCanceledPage,
  AccountDashboardPage,
  ProjectSetupPage,
  ProductsUpdatesPage,
  HelpCenterPage,
  FeaturesRequestsPage,
  CurrentRoadmapPage,
  ProjectDashboardPage,
  SelectionsPage,
  FeedbacksPage,
  ProjectSettingsPage,
  ProfilePage,
  AccountSectionPage,
  DefaultSettingsSectionPage,
  DefaultSettingsPage,
  CostCodesSectionPages,
  UsersSectionPage,
  UsersPage,
  CreateRolePage,
  RoleViewPage,
  RolesPage,
  UserViewPage,
  BillingSectionPage,
  PaymentMethodsPage,
  SubscriptionPlansPage,
  UpdatePaymentMethodPage,
  CompanyDetailsPage,
  FileCabinetPage,
  PublicFileCabinetByIdPage,
  PublicFileCabinetPage,
  AlbumPage,
  GalleryPage,
  GuestGalleryPage,
  AddPaymentMethodModalPage,
  ChangePaymentMethodModalPage,
  DeletePaymentMethodModalPage,
  ChangePaymentPlanModalPage,
  SelectPaymentPlanModalPage,
  InviteModalPage,
  EditScheduleTemplate,
  ChecklistTemplateByIdPage,
  ChecklistsTemplatesPage,
  LocationsPage,
  SelectionLibraryPage,
} from 'app/async-pages';
import Redirect from 'app/components/redirect';
// api
import { PERMISSIONS_SUBJECT_ENUM } from 'redux/api/company-roles';

export enum AppPaths {
  ERROR_PAGE = '/404',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SIGN_UP_INVITE = '/sign-up-invite',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  WITHOUT_PLAN = '/without-plan',
  ACCOUNT_SETUP = '/account-setup',
  INVITE_CANCELED = '/invite-canceled',
  ACCOUNT_DASHBOARD = '/account-dashboard',
  PROJECT_SETUP = '/project-setup',
  PRODUCT_UPDATES = '/updates',
  HELP_CENTER = '/help-center',
  FEATURE_REQUESTS = '/feature-requests',
  CURRENT_ROADMAP = '/current-roadmap',
  PROJECT_DASHBOARD = '/project-dashboard/:projectId',
  SELECTIONS = '/selections/:projectId',
  FEEDBACKS = '/feedbacks/:projectId',
  PROJECT_SETTINGS = '/project-settings/:projectId',
  PROFILE = 'profile',
  CONTACT_DETAILS = 'contact-details',
  LOGIN = 'login',
  NOTIFICATIONS = 'notifications',
  DEFAULT_SETTINGS = 'default-settings',
  COST_CODES_SECTION = 'cost-codes',
  COST_CODE_CATEGORIES = 'categories',
  COST_CODES = 'codes',
  USERS = 'users',
  USER_VIEW = ':id',
  ROLES = 'roles',
  ROLE_VIEW = 'roles/:id',
  CREATE_ROLE = 'roles/new',
  BILLING = 'billing',
  SUBSCRIPTION_PLAN = 'subscription-plan',
  PAYMENT_METHODS = 'payment-methods',
  UPDATE_PAYMENT_METHOD = 'update-payment-method',
  COMPANY_DETAILS = 'company-details',
  FILE_CABINET = 'file-cabinet',
  PUBLIC_FILE_CABINET = 'public-file-cabinet',
  PUBLIC_FILE_CABINET_BY_ID = 'public-file-cabinet/:id',
  GALLERY = 'gallery/:projectId',
  GALLERY_ALBUM = ':albumName',
  GUEST_GALLERY = 'guest-gallery/:id',
  INVITE_MODAL = 'users/invite/:id',
  BILLING_PAYMENT_METHOD = 'billing/payment-method',
  BILLING_SELECT_PAYMENT_PLAN = 'billing/select-payment-plan',
  BILLING_CHANGE_PAYMENT_PLAN = 'billing/change-payment-plan',
  BILLING_ADD_PAYMENT_METHOD = 'billing/add-payment-method',
  BILLING_DELETE_PAYMENT_METHOD = 'billing/delete-payment-method',
  EDIT_SCHEDULE_TEMPLATE = '/schedule-template/:id',
  CHECKLIST_TEMPLATE_BY_ID = 'checklist-template/:id',
  CHECKLIST_TEMPLATES_LIST = 'checklist-templates',
  COMPANY_LOCATIONS = 'locations',
  SELECTIONS_LIBRARY = 'selections-library',
  REDIRECT = '*',
}

export enum AppLayouts {
  PROFILE_LAYOUT = 'PROFILE_LAYOUT',
}

export enum RouteRole {
  COMMON = 'common',
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized',
}

export interface IRoute {
  path?: AppPaths;
  role?: RouteRole;
  component: ComponentType;
  index?: true;
  accessPermissions?: PERMISSIONS_SUBJECT_ENUM[];
  withWrapper?: boolean;
  protectedRoute?: boolean;
  withBackgroundLocation?: boolean;
  childRoutes?: IRoute[];
}

type TRouteConfigKey = AppPaths | AppLayouts;

export type TRouteConfig = {
  [key in TRouteConfigKey]?: IRoute;
};

export const baseRoutesConfig: TRouteConfig = {
  [AppPaths.ERROR_PAGE]: {
    path: AppPaths.ERROR_PAGE,
    role: RouteRole.UNAUTHORIZED,
    component: ErrorPage,
    withWrapper: true,
  },

  [AppPaths.SIGN_IN]: {
    path: AppPaths.SIGN_IN,
    role: RouteRole.UNAUTHORIZED,
    component: SignInPage,
    withWrapper: true,
  },

  [AppPaths.SIGN_UP]: {
    path: AppPaths.SIGN_UP,
    role: RouteRole.UNAUTHORIZED,
    component: SignUpPage,
    withWrapper: true,
  },

  [AppPaths.SIGN_UP_INVITE]: {
    path: AppPaths.SIGN_UP_INVITE,
    role: RouteRole.COMMON,
    component: SignUpInvitePage,
    withWrapper: true,
  },

  [AppPaths.RESET_PASSWORD]: {
    path: AppPaths.RESET_PASSWORD,
    role: RouteRole.UNAUTHORIZED,
    component: ResetPasswordPage,
    withWrapper: true,
  },

  [AppPaths.FORGOT_PASSWORD]: {
    path: AppPaths.FORGOT_PASSWORD,
    role: RouteRole.UNAUTHORIZED,
    component: ForgotPasswordPage,
    withWrapper: true,
  },

  [AppPaths.WITHOUT_PLAN]: {
    path: AppPaths.WITHOUT_PLAN,
    role: RouteRole.AUTHORIZED,
    component: WithoutPlanPage,
    withWrapper: true,
  },

  [AppPaths.ACCOUNT_SETUP]: {
    path: AppPaths.ACCOUNT_SETUP,
    role: RouteRole.AUTHORIZED,
    component: AccountSetupPage,
    withWrapper: true,
  },

  [AppPaths.INVITE_CANCELED]: {
    path: AppPaths.INVITE_CANCELED,
    role: RouteRole.UNAUTHORIZED,
    component: InviteCanceledPage,
    withWrapper: true,
  },

  [AppPaths.ACCOUNT_DASHBOARD]: {
    path: AppPaths.ACCOUNT_DASHBOARD,
    role: RouteRole.AUTHORIZED,
    component: AccountDashboardPage,
    withWrapper: true,
  },

  [AppPaths.PROJECT_SETUP]: {
    path: AppPaths.PROJECT_SETUP,
    role: RouteRole.AUTHORIZED,
    component: ProjectSetupPage,
    withWrapper: true,
  },

  [AppPaths.PRODUCT_UPDATES]: {
    path: AppPaths.PRODUCT_UPDATES,
    role: RouteRole.AUTHORIZED,
    component: ProductsUpdatesPage,
    withWrapper: true,
  },

  [AppPaths.HELP_CENTER]: {
    path: AppPaths.HELP_CENTER,
    role: RouteRole.AUTHORIZED,
    component: HelpCenterPage,
    withWrapper: true,
  },

  [AppPaths.FEATURE_REQUESTS]: {
    path: AppPaths.FEATURE_REQUESTS,
    role: RouteRole.AUTHORIZED,
    component: FeaturesRequestsPage,
    withWrapper: true,
  },

  [AppPaths.CURRENT_ROADMAP]: {
    path: AppPaths.CURRENT_ROADMAP,
    role: RouteRole.AUTHORIZED,
    component: CurrentRoadmapPage,
    withWrapper: true,
  },

  [AppPaths.PROJECT_DASHBOARD]: {
    path: AppPaths.PROJECT_DASHBOARD,
    role: RouteRole.AUTHORIZED,
    component: ProjectDashboardPage,
    accessPermissions: [PERMISSIONS_SUBJECT_ENUM.MANAGE_PROJECT],
    withWrapper: true,
  },

  [AppPaths.SELECTIONS]: {
    path: AppPaths.SELECTIONS,
    role: RouteRole.AUTHORIZED,
    component: SelectionsPage,
    accessPermissions: [PERMISSIONS_SUBJECT_ENUM.SELECTION_MANAGE],
    withWrapper: true,
  },

  [AppPaths.FEEDBACKS]: {
    path: AppPaths.FEEDBACKS,
    role: RouteRole.AUTHORIZED,
    component: FeedbacksPage,
    withWrapper: true,
  },

  [AppPaths.PROJECT_SETTINGS]: {
    path: AppPaths.PROJECT_SETTINGS,
    role: RouteRole.AUTHORIZED,
    component: ProjectSettingsPage,
    withWrapper: true,
  },

  [AppPaths.EDIT_SCHEDULE_TEMPLATE]: {
    path: AppPaths.EDIT_SCHEDULE_TEMPLATE,
    role: RouteRole.AUTHORIZED,
    component: EditScheduleTemplate,
    withWrapper: true,
  },

  [AppLayouts.PROFILE_LAYOUT]: {
    role: RouteRole.AUTHORIZED,
    component: ProfilePage,
    protectedRoute: true,
    childRoutes: [
      {
        path: AppPaths.PROFILE,
        component: AccountSectionPage,
        childRoutes: [
          {
            path: AppPaths.CONTACT_DETAILS,
            component: null,
          },
          {
            path: AppPaths.LOGIN,
            component: null,
          },
          {
            path: AppPaths.NOTIFICATIONS,
            component: null,
          },
        ],
      },

      {
        path: AppPaths.DEFAULT_SETTINGS,
        component: DefaultSettingsSectionPage,
        accessPermissions: [
          PERMISSIONS_SUBJECT_ENUM.SCHEDULE_TEMPLATE,
          PERMISSIONS_SUBJECT_ENUM.WORK_DAYS,
          PERMISSIONS_SUBJECT_ENUM.COST_CODE,
          PERMISSIONS_SUBJECT_ENUM.FILE_CABINET_DEFAULT_FOLDERS,
          PERMISSIONS_SUBJECT_ENUM.SELECTION_MANAGE,
        ],

        childRoutes: [
          {
            component: DefaultSettingsPage,
            index: true,
          },

          {
            path: AppPaths.COST_CODES_SECTION,
            component: CostCodesSectionPages,
            accessPermissions: [PERMISSIONS_SUBJECT_ENUM.COST_CODE],
            childRoutes: [
              {
                path: AppPaths.COST_CODE_CATEGORIES,
                component: null,
              },
              {
                path: AppPaths.COST_CODES,
                component: null,
              },
            ],
          },

          {
            path: AppPaths.CHECKLIST_TEMPLATES_LIST,
            component: ChecklistsTemplatesPage,
            accessPermissions: [PERMISSIONS_SUBJECT_ENUM.MANAGE_CHECKLIST_ITEMS],
          },

          {
            path: AppPaths.CHECKLIST_TEMPLATE_BY_ID,
            component: ChecklistTemplateByIdPage,
            accessPermissions: [PERMISSIONS_SUBJECT_ENUM.MANAGE_CHECKLIST_ITEMS],
          },

          {
            path: AppPaths.COMPANY_LOCATIONS,
            component: LocationsPage,
            accessPermissions: [PERMISSIONS_SUBJECT_ENUM.COST_CODE],
          },
        ],
      },

      {
        path: AppPaths.USERS,
        component: UsersSectionPage,
        accessPermissions: [PERMISSIONS_SUBJECT_ENUM.USER_MANAGEMENT],
        childRoutes: [
          {
            component: UsersPage,
            index: true,
          },
          {
            path: AppPaths.USER_VIEW,
            component: UserViewPage,
          },
          {
            path: AppPaths.ROLES,
            component: RolesPage,
          },
          {
            path: AppPaths.ROLE_VIEW,
            component: RoleViewPage,
          },
          {
            path: AppPaths.CREATE_ROLE,
            component: CreateRolePage,
          },
        ],
      },

      {
        path: AppPaths.BILLING,
        component: BillingSectionPage,
        accessPermissions: [PERMISSIONS_SUBJECT_ENUM.BILLING],
        childRoutes: [
          {
            path: AppPaths.SUBSCRIPTION_PLAN,
            component: SubscriptionPlansPage,
          },
          {
            path: AppPaths.PAYMENT_METHODS,
            component: PaymentMethodsPage,
          },
          {
            path: AppPaths.UPDATE_PAYMENT_METHOD,
            component: UpdatePaymentMethodPage,
          },
        ],
      },

      {
        path: AppPaths.COMPANY_DETAILS,
        component: CompanyDetailsPage,
        accessPermissions: [PERMISSIONS_SUBJECT_ENUM.COMPANY_MANAGEMENT],
      },
    ],
  },

  [AppPaths.SELECTIONS_LIBRARY]: {
    path: AppPaths.SELECTIONS_LIBRARY,
    component: SelectionLibraryPage,
    accessPermissions: [PERMISSIONS_SUBJECT_ENUM.SELECTION_MANAGE],
    protectedRoute: true,
  },

  [AppPaths.FILE_CABINET]: {
    path: AppPaths.FILE_CABINET,
    component: FileCabinetPage,
    protectedRoute: true,
  },

  [AppPaths.PUBLIC_FILE_CABINET]: {
    path: AppPaths.PUBLIC_FILE_CABINET,
    component: PublicFileCabinetPage,
  },

  [AppPaths.PUBLIC_FILE_CABINET_BY_ID]: {
    path: AppPaths.PUBLIC_FILE_CABINET_BY_ID,
    component: PublicFileCabinetByIdPage,
  },

  [AppPaths.GALLERY]: {
    path: AppPaths.GALLERY,
    role: RouteRole.AUTHORIZED,
    component: GalleryPage,
    withWrapper: true,
    childRoutes: [
      {
        path: AppPaths.GALLERY_ALBUM,
        component: AlbumPage,
      },
    ],
  },

  [AppPaths.GUEST_GALLERY]: {
    path: AppPaths.GUEST_GALLERY,
    component: GuestGalleryPage,
  },

  [AppPaths.REDIRECT]: {
    path: AppPaths.REDIRECT,
    component: Redirect,
  },
};

export const modalRoutesConfig = {
  [AppPaths.INVITE_MODAL]: {
    path: AppPaths.INVITE_MODAL,
    component: InviteModalPage,
    withBackgroundLocation: true,
  },

  [AppPaths.BILLING_PAYMENT_METHOD]: {
    path: AppPaths.BILLING_PAYMENT_METHOD,
    component: ChangePaymentMethodModalPage,
    withBackgroundLocation: true,
  },

  [AppPaths.BILLING_SELECT_PAYMENT_PLAN]: {
    path: AppPaths.BILLING_SELECT_PAYMENT_PLAN,
    component: SelectPaymentPlanModalPage,
    withBackgroundLocation: true,
  },

  [AppPaths.BILLING_CHANGE_PAYMENT_PLAN]: {
    path: AppPaths.BILLING_CHANGE_PAYMENT_PLAN,
    component: ChangePaymentPlanModalPage,
    withBackgroundLocation: true,
  },

  [AppPaths.BILLING_ADD_PAYMENT_METHOD]: {
    path: AppPaths.BILLING_ADD_PAYMENT_METHOD,
    component: AddPaymentMethodModalPage,
    withBackgroundLocation: true,
  },

  [AppPaths.BILLING_DELETE_PAYMENT_METHOD]: {
    path: AppPaths.BILLING_DELETE_PAYMENT_METHOD,
    component: DeletePaymentMethodModalPage,
    withBackgroundLocation: true,
  },
};
