export enum ProjectsOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ProjectsSortEnum {
  NAME = 'NAME',
  CREATED_AT = 'CREATED_AT',
  OVERDUE = 'OVERDUE',
  ACTIVITY = 'ACTIVITY',
  ACTIVITY_COUNT = 'ACTIVITY_COUNT',
}

export interface IProjectsSorting {
  orderBy: ProjectsOrderEnum;
  sortBy: ProjectsSortEnum;
}

export enum ProjectsViewTypeEnum {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum DonwloadMobileAppEnum {
  REMIND_LATER = 'REMIND_LATER',
  DOWNLOADED = 'DOWNLOADED',
  OFFER_DOWNLOAD = 'OFFER_DOWNLOAD',
  DISMISS = 'DISMISS',
}

export enum ScheduleViewType {
  LIST = 'LIST',
  GANTT = 'GANTT',
}

export type IProfilePreference = {
  sorting: IProjectsSorting;
  downloadMobileApp: string;
  remindDelaysStart: string;
  viewType: ProjectsViewTypeEnum;
  isProgressTrackOpen: boolean;
  isActivityFeedExpanded: boolean;
  scheduleViewType: ScheduleViewType;
};
