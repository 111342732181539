import React, { useMemo, useRef } from 'react';
// components
import HeaderNewUI from './new-ui';

// custom hooks
import { useUserInfo } from 'hooks/useUserInfo';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// api
import { useGetMeQuery } from 'redux/api/auth';
import { useGetCompanyRolePermissionsMeQuery } from 'redux/api/company-roles';
import { useGetCompanyAllQuery, useGetCompanyQuery } from 'redux/api/company';
import { useGetProjectByIdQuery, useGetUnviewedCountsQuery } from 'redux/api/projects';
// selectors
import { authSelector } from 'redux/selector';
// redux
import { useGetCurrentPaymentPlanQuery } from 'redux/api/billing';

const Header: React.FC = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { isAuth, companyId } = useAppSelector(authSelector);

  const { data: userMe } = useGetMeQuery(undefined, {
    skip: !isAuth,
  });
  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery(undefined, {
    skip: !userMe || !companyId,
  });
  const { data: companySetup } = useGetCompanyQuery(undefined, {
    skip: !userPermissions?.COMPANY_MANAGEMENT?.canRead,
  });
  const { currentRole } = useUserInfo();

  const pathname = window.location.pathname;

  const projectId = pathname.split('/')?.[2];

  const { data: project } = useGetProjectByIdQuery(
    { id: Number(projectId) },
    {
      skip: !(
        Number(projectId) &&
        (pathname.includes('project-dashboard') || pathname.includes('selections'))
      ),
    }
  );

  const { data: unviewedItems } = useGetUnviewedCountsQuery(
    { projectIds: [Number(projectId)] },
    {
      skip:
        pathname.split('/')?.[1] === 'users' ||
        !Number(projectId) ||
        pathname.includes('file-cabinet'),
    }
  );

  const isShowBillingInfo = userPermissions?.BILLING?.canRead;

  const { data: currentPaymentPlan } = useGetCurrentPaymentPlanQuery();

  const { data: companyAllData } = useGetCompanyAllQuery();

  const companyData = useMemo(
    () =>
      companyAllData?.length
        ? companyAllData.filter((company) => {
            const role = userMe?.roles?.find((userRole) => company?.id === userRole?.companyId);

            return role?.isOwner || role?.isSubscription;
          })
        : [],
    [companyAllData, userMe]
  );

  const unviewedItemCounts = unviewedItems?.items[Number(projectId)];

  const props = {
    isShowBillingInfo,
    currentRole,
    currentPaymentPlan,
    companyData,
    unviewedItemCounts,
    anchorRef,
    userMe,
  };

  return <HeaderNewUI {...props} />;
};

export default Header;
