// custom hooks
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// slices
import {
  TSidePanelContentType,
  handleOpenSidePanel,
  handleSetProjectId,
  handleSetSidePanelContent,
  setCollapseFullScreen,
  setFullScreen,
  handleSetMentionedProfile,
  handleResetMentionedProfile,
  handleSetEditorExpanded,
  handleSetActivityFeedContent,
  ActivityFeedContentEnum,
} from 'redux/slices/side-panel-slice';
// api
import { useUpdateProfilePreferenceMutation } from 'redux/api/profile-preference';
import { IProjectProfiles } from 'redux/api/projects';
import { RootState } from 'redux/store';

export const useSidePanel = () => {
  const dispatch = useAppDispatch();

  const isSidePanelOpen = useAppSelector((state: RootState) => state.sidePanel.isOpen);
  const projectId = useAppSelector((state: RootState) => state.sidePanel.projectId);
  const sidePanelContent = useAppSelector((state: RootState) => state.sidePanel.sidePanelContent);
  const isEditorExpanded = useAppSelector((state: RootState) => state.sidePanel.isEditorExpanded);
  const activityFeedContent = useAppSelector(
    (state: RootState) => state.sidePanel.activityFeedContent
  );
  const isFullScreen = useAppSelector((state: RootState) => state.sidePanel.isFullScreen);
  const mentionedProfile = useAppSelector((state: RootState) => state.sidePanel.mentionedProfile);
  const editorKey = useAppSelector((state: RootState) => state.sidePanel.editorKey);

  const [updateProfilePreference] = useUpdateProfilePreferenceMutation();

  const handleSidePanelOpen = (open: boolean) => {
    dispatch(handleOpenSidePanel(open));
  };

  const handleSidePanelContent = (contentType: TSidePanelContentType) => {
    dispatch(handleSetSidePanelContent(contentType));
  };

  const handleSetFullScreen = () => {
    dispatch(setFullScreen());
  };

  const handleCollapseFullScreen = () => {
    dispatch(setCollapseFullScreen());
  };

  const setProjectId = (id: number) => {
    dispatch(handleSetProjectId(id));
  };

  const handleExpandeEditor = (expanded: boolean) => {
    dispatch(handleSetEditorExpanded(expanded));
  };

  const onSetMentionedProfile = (mentionedUser: IProjectProfiles) => {
    dispatch(handleSetMentionedProfile(mentionedUser));
  };

  const onResetMentionedProfile = () => {
    dispatch(handleResetMentionedProfile());
  };

  const handleOpenActivityFeed = () => {
    dispatch(handleSetSidePanelContent('activity_feed'));
    dispatch(handleOpenSidePanel(true));
    updateProfilePreference({ isActivityFeedExpanded: true });
  };

  const handleCloseActivityFeed = () => {
    dispatch(handleOpenSidePanel(false));
    updateProfilePreference({ isActivityFeedExpanded: false });
  };

  const toggleActivityFeedContent = (target?: ActivityFeedContentEnum) => {
    if (target !== activityFeedContent) {
      dispatch(
        handleSetActivityFeedContent(
          activityFeedContent === ActivityFeedContentEnum.MEDIA
            ? ActivityFeedContentEnum.FEED
            : ActivityFeedContentEnum.MEDIA
        )
      );
    }
  };

  return {
    handleSidePanelOpen,
    handleSidePanelContent,
    handleSetFullScreen,
    handleCollapseFullScreen,
    projectId,
    setProjectId,
    handleOpenActivityFeed,
    handleCloseActivityFeed,
    isSidePanelOpen,
    isFullScreen,
    sidePanelContent,
    handleExpandeEditor,
    onResetMentionedProfile,
    onSetMentionedProfile,
    mentionedProfile,
    isEditorExpanded,
    editorKey,
    activityFeedContent,
    toggleActivityFeedContent,
  };
};
