import { MutableRefObject, useEffect, useMemo, useRef, useState, FC } from 'react';
// icons
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/viewer-thumb-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/viewer-thumb-right.svg';
// types
import { IImagesList } from '../../entities';
// styles
import './styles.css';

interface IThumbnailListProps {
  thumbnailList: IImagesList[];
  activeIndex: number;
  isKeyboardRef: MutableRefObject<boolean>;
  handleThumbnailPress: (index: number) => void;
}

export const ThumbnailList: FC<IThumbnailListProps> = ({
  thumbnailList,
  activeIndex,
  isKeyboardRef,
  handleThumbnailPress,
}) => {
  const photoWidth = 90;
  const gapWidth = 12;

  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const prevIndexRef = useRef<number>(0);
  const mountOnce = useRef<boolean>(false);

  const isLeftDisabled = useMemo(
    () => scrollPosition === 0 || thumbnailList?.length === 1,
    [scrollPosition]
  );

  const isRightDisabled = useMemo(
    () =>
      scrollPosition >= containerRef.current?.scrollWidth! - containerRef.current?.clientWidth! ||
      thumbnailList?.length === 1,
    [scrollPosition, containerRef]
  );

  useEffect(() => {
    if (!mountOnce.current && activeIndex !== 0) {
      const newScrollPosition = Math.min(
        scrollPosition + (photoWidth * activeIndex + gapWidth * (activeIndex - 1)),
        containerRef?.current?.scrollWidth! - containerRef?.current?.clientWidth!
      );
      handleScroll(newScrollPosition);
      mountOnce.current = true;
    }
  }, [mountOnce.current]);

  useEffect(() => {
    if (!isKeyboardRef.current) {
      return;
    }

    if (prevIndexRef.current < activeIndex) {
      const newScrollPosition = Math.min(
        scrollPosition + (photoWidth + gapWidth),
        containerRef?.current?.scrollWidth! - containerRef?.current?.clientWidth!
      );
      handleScroll(newScrollPosition);
      prevIndexRef.current = activeIndex;
    } else if (prevIndexRef.current > activeIndex) {
      const newScrollPosition = Math.max(scrollPosition - (photoWidth + gapWidth), 0);
      handleScroll(newScrollPosition);
      prevIndexRef.current = activeIndex;
    }
  }, [activeIndex, isKeyboardRef, scrollPosition]);

  const handleScroll = (newScrollPosition: number) => {
    setScrollPosition(newScrollPosition);
    containerRef.current!.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  };

  const handleLeft = () => {
    const newScrollPosition = Math.max(scrollPosition - (photoWidth + gapWidth), 0);
    handleScroll(newScrollPosition);
  };

  const handleRight = () => {
    const newScrollPosition = Math.min(
      scrollPosition + (photoWidth + gapWidth),
      containerRef?.current?.scrollWidth! - containerRef?.current?.clientWidth!
    );
    handleScroll(newScrollPosition);
  };

  const onThumbnailPress = (index: number) => {
    prevIndexRef.current = index;
    handleThumbnailPress(index);
  };

  return (
    <div className="thumbnail-list-container">
      {!isLeftDisabled ? (
        <div
          onClick={handleLeft}
          aria-disabled={isLeftDisabled}
          className={`thumbnail-arrow-left ${isLeftDisabled ? 'disabled' : ''}`}
        >
          <ArrowLeftIcon width={20} height={20} />
        </div>
      ) : null}
      <div className="thumbnail-list-wrapper" ref={containerRef}>
        <div className="thumbnail-list">
          {thumbnailList.map((item) => (
            <img
              onClick={() => onThumbnailPress(item.id)}
              key={item.id}
              src={item?.previewUrl ? item?.previewUrl : item.thumbnail}
              className={`thumbnail-item ${activeIndex === item.id ? 'isActive' : ''}`}
            />
          ))}
        </div>
      </div>
      {!isRightDisabled ? (
        <div
          onClick={handleRight}
          aria-disabled={isRightDisabled}
          className={`thumbnail-arrow-right ${isRightDisabled ? 'disabled' : ''}`}
        >
          <ArrowRightIcon width={20} height={20} />
        </div>
      ) : null}
    </div>
  );
};
