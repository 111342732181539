import React, { FC, useState } from 'react';
// mui components
import { Grid, Typography, Dialog } from '@mui/material';
// components
import ActionButton from '../../buttons/index';
import OutlinedInput from 'components/inputs/outlined-input';
// styles
import { styles } from './styles';

interface IJobTitleModalProps {
  open: boolean;
  closeModal: () => void;
  onSubmit: (specificTitle: string) => void;
}

const JobTitleModal: FC<IJobTitleModalProps> = ({ closeModal, open, onSubmit }) => {
  const [value, setValue] = useState<string>('');

  const handleSubmit = () => {
    onSubmit(value);
  };

  return (
    <Dialog open={open} onClose={closeModal} sx={styles.modal}>
      <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Grid sx={styles.titleContainer}>
          <Typography sx={styles.title}>Create New Job Title</Typography>
          <Typography sx={styles.subTitle}>
            Create a new job title and assign to this user.
          </Typography>
        </Grid>

        <Grid sx={styles.inputContainer}>
          <Typography sx={styles.inputLabel}>Job Title</Typography>
          <OutlinedInput
            fullWidth
            withoutHelperText
            placeholder="Enter job title"
            sx={styles.input}
            value={value}
            onChange={({ target }) => {
              setValue(target.value);
            }}
          />
        </Grid>

        <Grid sx={styles.buttonsContainer}>
          <ActionButton variant="secondary" type="button" label="Cancel" onClick={closeModal} />
          <ActionButton
            type="button"
            label="Save"
            disabled={!value || value.length > 50}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default JobTitleModal;
