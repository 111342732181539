import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActivityFeedItemsArgs, IGetCommentsArgs } from 'redux/api/activity-feed';
import { IGetProjectsArgs, ProjectStatusesEnum } from 'redux/api/projects';
import {
  IDefaultCostCodeStatusEnum,
  IGetCostCodesWithSelectionsArgs,
  IGetDefaultCostCodeCategoriesArgs,
} from 'redux/api/selections';

export interface IQueryArgs {
  projects: Partial<IGetProjectsArgs>;
  comments: Partial<IGetCommentsArgs>;
  posts: Partial<IActivityFeedItemsArgs>;
  selectionCategories: Partial<IGetCostCodesWithSelectionsArgs>;
  defaultCostCodes: Partial<IGetDefaultCostCodeCategoriesArgs>;
}

const initialState: IQueryArgs = {
  projects: {
    page: 1,
    itemsPerPage: 5,
    status: ProjectStatusesEnum.ACTIVE,
    search: undefined,
  },
  comments: {
    limit: 5,
    cursor: undefined,
  },
  posts: {
    limit: 10,
    search: undefined,
    cursor: undefined,
  },
  selectionCategories: {
    cursor: undefined,
  },
  defaultCostCodes: {
    status: IDefaultCostCodeStatusEnum.ACTIVE,
    withCostCodes: false,
    search: undefined,
  },
};

export const queryArgsSlice = createSlice({
  name: 'queryArgs',
  initialState,
  reducers: {
    setDefaultArgs: (state) => {
      state.projects = initialState.projects;
      state.posts = initialState.posts;
      state.selectionCategories = initialState.selectionCategories;
      state.defaultCostCodes = initialState.defaultCostCodes;
    },
    setCommentsArgs: (state, action: PayloadAction<Partial<IGetCommentsArgs>>) => {
      Object.assign(state.comments, action.payload);
    },
    setProjectsArgs: (state, action: PayloadAction<Partial<IGetProjectsArgs>>) => {
      Object.assign(state.projects, action.payload);
    },
    setPostsArgs: (state, action: PayloadAction<Partial<IActivityFeedItemsArgs>>) => {
      Object.assign(state.posts, action.payload);
    },
    setSelectionCategoriesArgs: (
      state,
      action: PayloadAction<Partial<IGetCostCodesWithSelectionsArgs>>
    ) => {
      Object.assign(state.selectionCategories, action.payload);
    },
    setDefaultCostCodesArgs: (state, action: PayloadAction<IGetDefaultCostCodeCategoriesArgs>) => {
      Object.assign(state.defaultCostCodes, action.payload);
    },
  },
});

export const {
  setCommentsArgs,
  setProjectsArgs,
  setPostsArgs,
  setSelectionCategoriesArgs,
  setDefaultArgs,
  setDefaultCostCodesArgs,
} = queryArgsSlice.actions;

export default queryArgsSlice.reducer;
