import React, { memo } from 'react';
// mui components
import { Grid, Typography } from '@mui/material';
// Component
import ActionButton from 'components/buttons';
// icons
import { ReactComponent as LogoDark } from 'assets/icons/logo_dark.svg';
import { ReactComponent as WrenchIcon } from 'assets/icons/wrench.svg';
import { ReactComponent as Errormessage } from 'assets/icons/error-msg.svg';

const DeploymentPage: React.FC = () => {
  return (
    <Grid
      sx={{
        height: '100vh',
        background: '#F2F5FF',
      }}
    >
      <Grid
        sx={{
          paddingBottom: '0px',
          textAlign: 'center',
          '& svg': {
            width: '130px',
            height: '25px',
            textAlign: 'center',
            margin: '44px auto 0',
          },
        }}
      >
        <LogoDark />
      </Grid>
      <Grid
        container
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ margin: '158px auto' }}
      >
        <WrenchIcon />

        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '400',
            padding: '80px 0 30px 0',
            fontFamily: 'Inter',
            color: '#23252F',
            textAlign: 'center',
          }}
        >
          Sorry, we are deploying an update <br />
          Please check back in a few minutes
        </Typography>

        <Grid container sx={{ justifyContent: 'center', gap: '15px' }}>
          {/* <Button variant="text" className="support-1">
            <Link to={isAuthorized ? '/account-dashboard' : '/sign-in'}>Go to the Main Page</Link>
          </Button> */}
          <ActionButton
            label="Contact Support"
            variant="tertiary"
            onClick={() => {
              window.open('https://support.builderpad.com', '_blank');
            }}
            startIcon={<Errormessage />}
            size="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default memo(DeploymentPage);
