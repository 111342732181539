export const uploadedFilesStyles = {
  container: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
    justifyContent: 'flex-start',
    gap: '8px',
    flexDirection: 'row',
    height: '32px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    backgroundColor: '#F3F4F9',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '0 6px',
  },
  fileName: {
    fontFamily: 'Inter',
    fontStyles: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#424550',
    wordBreak: 'break-all',
  },
  hiddenFilesContainer: {
    display: 'flex',
    width: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F4F9',
    borderRadius: '4px',
    marginLeft: '8px',
  },
  hiddenFiles: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '150%',
    color: '#8C8F9D',
  },
  postContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '8px',
  },
  postFileItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    border: '1px solid #EDEFF7',
    borderRadius: '8px',
    gap: '8px',
  },
  postFileInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '8px',
    justifyContent: 'flex-start',
  },
  postIconContainer: {
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  postFileInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    justifyContent: 'flex-start',
  },
  postFileName: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    margin: '0',
    WebkitLineClamp: '2',
    'line-clamp': '2',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
  },
  postFileSize: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#6E707A',
  },
  postSettingsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    minWidth: '56px',
  },
  newFilesContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '12px',
    marginTop: '16px',
  },
  newFilesItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    border: '1px solid #EDEFF7',
    borderRadius: '8px',
    gap: '8px',
    padding: '8px 4px 8px 8px',
  },
  newFileInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-start',
  },
  newFileIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
  },
  newFileInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    justifyContent: 'flex-start',
  },
  deleteBtn: {
    width: '20px',
    height: '20px',
    padding: 0,
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  wrapperContainer: {
    // width: '100%',
    display: 'flex',
    cursor: 'pointer',
  },
};
