import React from 'react';
// mui components
import { Grid } from '@mui/material';
// api
import { fromMobileSelector } from 'redux/selector';
import { useAppSelector } from 'redux/hooks/useAppSelector';

interface OwnProps {
  backgroundColor?: string;
  isProjectStepper?: boolean;
  component?: any;
}

const AuthLayout: React.FC<OwnProps> = ({
  children,
  backgroundColor = '#EAEFFB',
  isProjectStepper,
  component,
}) => {
  const { isMobile } = useAppSelector(fromMobileSelector);

  return (
    <Grid
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        maxWidth: '100vw',
        height: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        paddingBottom: isProjectStepper ? 'unset' : isMobile ? 'unset' : '96px',
        backgroundColor,
        overflow: 'hidden',
        overflowY: 'auto',
        'scroll-behavior': 'smooth',
      }}
    >
      <Grid container flexDirection="column" alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
