import React, { createContext, useContext, useState } from 'react';

interface IContextProps {
  projectDetails: Object;
  updateGanttProject: boolean;
  updateProject: (values: Object) => void;
  updateUtils: (values: Object) => void;
  projectDashboardUtils: object;
  handleUpdateGanttProject: () => void;
}

const projectDetailsContext = createContext({} as IContextProps);
export const useProjectDetails = () => useContext(projectDetailsContext);

export const ProjectProvider: React.FC = ({ children }) => {
  const [projectDetails, setProjectDetails] = useState<{}>();
  const [projectDashboardUtils, setprojectDashboardUtils] = useState<{}>();
  const [updateGanttProject, setUpdateGanttProject] = useState<boolean>(true);

  const updateProject = (values) => {
    setProjectDetails(values);
  };

  const updateUtils = (values) => {
    setprojectDashboardUtils(values);
  };

  const handleUpdateGanttProject = () => {
    setUpdateGanttProject((prev) => !prev);
  };

  return (
    <projectDetailsContext.Provider
      value={{
        projectDetails,
        updateGanttProject,
        projectDashboardUtils,
        updateProject,
        updateUtils,
        handleUpdateGanttProject,
      }}
    >
      {children}
    </projectDetailsContext.Provider>
  );
};
