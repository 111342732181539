import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import PlacesAutocompleteInput from 'components/inputs/places-autocomplete-input';

interface OwnProps {
  name: string;
  label: string;
  country?: string;
  placeholder?: string;
  defaultValue?: string;
  additionalOnChange?: (value: string) => void;
  [x: string]: any;
}

const PlacesAutocompleteInputController: React.FC<OwnProps> = ({
  name = '',
  defaultValue = '',
  country,
  withError = false,
  ...props
}) => {
  const { control } = useFormContext();

  const currentCountry = useMemo(() => country?.toLowerCase() || null, [country]);

  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <PlacesAutocompleteInput
          name={name}
          value={value || defaultValue}
          {...props}
          error={!!error}
          currentCountry={currentCountry}
          helperText={withError ? error?.message || ' ' : undefined}
          onChange={onChange}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default memo(PlacesAutocompleteInputController);
