import React, { memo, ReactNode } from 'react';
//components
import ExistingUserNewUI from '../components/new-ui';
// types
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
interface OwnProps {
  avatar?: string;
  role?: string;
  email?: string;
  phone?: string;
  text: string;
  subText: string;
  id?: number;
  isAddedInProject?: boolean;
  isInvitedInProject?: boolean;
  avatarPlaceholder?: ReactNode;
  onRemove: () => void;
  onAdd: () => void;
  sx?: SxProps<Theme>;
}

const ExistingUser: React.FC<OwnProps> = ({
  avatar,
  role,
  avatarPlaceholder,
  text,
  email,
  subText,
  id,
  phone,
  isAddedInProject,
  isInvitedInProject,
  onRemove,
  onAdd,
  sx,
}) => {
  const props = {
    avatar,
    role,
    email,
    phone,
    avatarPlaceholder,
    text,
    subText,
    id,
    isAddedInProject,
    isInvitedInProject,
    onRemove,
    onAdd,
    sx,
  };

  return <ExistingUserNewUI {...props} />;
};

export default memo(ExistingUser);
