import { FormProvider } from 'react-hook-form';
// mui components
import { Typography } from '@mui/material';
// layouts
import ManageUsersForm from 'layouts/manage-users-form';
// components
import StepperButtons from '../../../components/stepper-buttons';
import StyledForm from 'components/styled-form';

const AddUsersNewUI = ({
  form,
  profiles,
  onSubmit,
  formIsEmpty,
  loading,
  handleClose,
  initialValues,
  projectId,
}) => {
  return (
    <FormProvider {...form}>
      <StyledForm onSubmit={onSubmit} sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: '136%',
            fontWeight: 400,
            color: '#23252F',
            fontFamily: 'Inter, sans-serif !important',
            marginBottom: '5px',
          }}
        >
          Add users to your project
        </Typography>
        <Typography
          sx={{
            fontSize: '13px',
            lineHeight: '146%',
            fontWeight: 400,
            color: '#6E707A',
            fontFamily: 'Inter, sans-serif !important',
            marginBottom: '40px',
          }}
        >
          Adding users will allow you to start communicating and collaborating with employees and
          clients.
        </Typography>
        <ManageUsersForm projectId={projectId} profiles={profiles} initialValues={initialValues} />
        <StepperButtons
          loading={loading}
          formIsEmpty={formIsEmpty}
          onSubmit={onSubmit}
          handleClose={handleClose}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default AddUsersNewUI;
