import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileCabinetLocation, TBreadCrumbs } from 'redux/api/file-cabinet';

export interface IFileCabinetSlice {
  isGridDefaultFolders: boolean;
  breadCrumbs: TBreadCrumbs;
  searchValue: string | null;
  cabinetLocation: FileCabinetLocation;
}

const initialState: IFileCabinetSlice = {
  isGridDefaultFolders: true,
  breadCrumbs: [],
  searchValue: '',
  cabinetLocation: null,
};

export const fileCabinetSlice = createSlice({
  name: 'file-cabinet',
  initialState,
  reducers: {
    handleSetIsGridDefaultFolders: (state, action: PayloadAction<boolean>) => {
      state.isGridDefaultFolders = action.payload;
    },

    handleSetBreadCrumbs: (state, action: PayloadAction<TBreadCrumbs>) => {
      state.breadCrumbs = action.payload;
    },

    handleClearBreadCrumbs: (state, action: PayloadAction<[]>) => {
      state.breadCrumbs = action.payload;
    },

    handleSetSearchValue: (state: IFileCabinetSlice, action: PayloadAction<string | null>) => {
      state.searchValue = action.payload;
    },

    handleSetCabinetLocation: (
      state: IFileCabinetSlice,
      action: PayloadAction<FileCabinetLocation>
    ) => {
      state.cabinetLocation = action.payload;
    },
  },
});

export const {
  handleSetIsGridDefaultFolders,
  handleClearBreadCrumbs,
  handleSetBreadCrumbs,
  handleSetSearchValue,
  handleSetCabinetLocation,
} = fileCabinetSlice.actions;

export default fileCabinetSlice.reducer;
