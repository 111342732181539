import React, { useCallback, FC } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
// mui components
import { Grid } from '@mui/material';
// components
import CustomModal from '../components/custom-modal';
import ActionButton from 'components/buttons/index';
// controllers
import OutlinedInputController from 'controllers/outlined-input-controller';
// hooks
import useToast from 'hooks/useToast';
// api
import { TModalProps } from 'components/file-cabinet-folders/types';
import {
  useCreateFileCabinetFolderMutation,
  useEditFileCabinetItemsMutation,
  useEditDefaultFolderItemsMutation,
} from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type Props = TModalProps & {
  title: string;
  subtitle: string;
  defValue?: string;
  isEdit?: boolean;
  itemId?: number | string;
  fromModal?: boolean;
  projectFolderId?: string | number;
  projectId: string | number;
};

const CreateOrEditItemModal: FC<Props> = ({
  open,
  title,
  subtitle,
  handleClose,
  defValue = '',
  isEdit = false,
  itemId,
  fromModal,
  projectFolderId,
  projectId,
}) => {
  const { folderId } = useParams();
  const { hash } = useLocation();
  const showToast = useToast();

  const [handleCreateFoler, { isLoading }] = useCreateFileCabinetFolderMutation();
  const [handleUpdateFolder, { isLoading: updateLoading }] = useEditFileCabinetItemsMutation();
  const [handleUpdateDefaultFolder, { isLoading: defaultFolderLoading }] =
    useEditDefaultFolderItemsMutation();

  const form = useForm({
    resolver: yupResolver(yup.object({ name: yup.string().required('Please fill the field') })),
  });

  const { handleSubmit, clearErrors, reset } = form;

  const checkParentFolderId = useCallback(() => {
    if (!!folderId) {
      return folderId;
    }

    if (fromModal && !!hash) {
      return hash?.substring(1);
    }

    if (fromModal && !!projectFolderId) {
      return projectFolderId;
    }

    return 0;
  }, [folderId, hash, projectFolderId]);

  const handleCustomSubmit = (data) => {
    if (isEdit) {
      if (fromModal) {
        handleUpdateFolder({ ...data, itemId: itemId, projectId: projectId })
          .unwrap()
          .then(() => {
            clearErrors();
            handleClose();
            reset();
          })
          .catch((err) => {
            showToast({
              message: !!err.data.message ? err.data.message : 'Something went wrong!',
              type: 'error',
            });
          });
      }

      if (!fromModal) {
        handleUpdateDefaultFolder({ ...data, itemId: itemId })
          .unwrap()
          .then(() => {
            clearErrors();
            handleClose();
            reset();
          })
          .catch((err) => {
            showToast({
              message: !!err.data.message ? err.data.message : 'Something went wrong!',
              type: 'error',
            });
          });
      }
    } else {
      const formData = {
        name: data?.name,
        parentFolderId: checkParentFolderId(),
      };

      if (fromModal) {
        handleCreateFoler({ ...formData, projectId: projectId })
          .unwrap()
          .then(() => {
            clearErrors();
            handleClose();
            reset();
          })
          .catch((err) => {
            showToast({
              message: !!err.data.message ? err.data.message : 'Something went wrong!',
              type: 'error',
            });
          });
      }
    }
  };

  return (
    <CustomModal open={open} title={title} subtitle={subtitle} handleClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleCustomSubmit)}>
          <OutlinedInputController
            name="name"
            label="Name"
            placeholder="Enter Name"
            defaultValue={defValue}
            withError
          />

          <Grid sx={styles.actions}>
            <ActionButton
              loading={isLoading || updateLoading || defaultFolderLoading}
              variant="secondary"
              onClick={handleClose}
              type="button"
              label="Cancel"
            />

            <ActionButton
              loading={isLoading || updateLoading || defaultFolderLoading}
              variant="primary"
              type="submit"
              label="Save"
            />
          </Grid>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateOrEditItemModal;
