export const styles = (fromModal?: boolean) => {
  return {
    wrapper: {
      height: '100%',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },

    container: {
      boxSizing: 'border-box',
      marginTop: '24px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: 'auto',
      flexWrap: 'wrap',
      gap: '16px',
      padding: '0 24px',
      maxHeight: 'calc(100% - 60px)',
      overflowY: 'auto',
      paddingBottom: '40px !important',

      '&.in-modal': {
        padding: 0,
      },
      '&::-webkit-scrollbar': {
        width: '11px',
        height: '11px',
        padding: '5px 0',
      },
      '&::-webkit-scrollbar-track': {
        background: '#fff',
        border: 'none',
        height: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D4D6E0',
        width: '2px',
        borderRadius: '6px',
        cursor: 'pointer',
      },
    },
  };
};
