import { HEADER_HEIGHT, SIDE_PANEL_WIDTH } from 'utils/constants';

export const typoSm = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '150%',
};

export const typoM = {
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '146%',
  color: '#23252F',
};

export const contentTypo = {
  color: ' #23252F',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '150%',
};

export const selectionsStyles = {
  projectDashboardContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  projectDashboard: {
    width: '100%',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'width 0.3s',
    '&.sm': {
      width: `calc(100% - ${SIDE_PANEL_WIDTH}px)`,
    },
    '&::-webkit-scrollbar': {
      width: '11px',
      height: '11px',
      padding: '5px 0',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      border: 'none',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D4D6E0',
      width: '2px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: '0',
    backgroundColor: '#FAFBFC',
    zIndex: '10',
    padding: '48px 32px 0',
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '0 32px 32px',
  },
  actions: {
    margin: '20px 0 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',

    '&.without-margin': {
      margin: '0',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },

  title: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '122%',
    display: 'flex',
    alignItems: 'center',
    color: '#23252F',
    wordBreack: 'keep-all',
  },
  itemsCount: {
    ...typoM,
    fontWeight: 500,
    '& span': {
      display: 'inline-block',
      minWidth: '9px',
    },
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    textDecoration: 'none',
    padding: '15px 0',
    width: 'max-content',
  },

  actionsContainer: {
    background: 'red',
    display: 'flex',
  },

  searchInputContainer: {
    width: '100%',
    maxWidth: '200px',
  },

  inputAdornment: {
    margin: 0,
    marginRight: '8px',
    '& path': {
      stroke: '#8C8F9D',
    },
  },

  searchInput: {
    ...typoM,
    textAlign: 'start',
    '&.MuiOutlinedInput-root': {
      height: '36px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
    },
    '& fieldset': {
      border: 'none',
    },
  },

  tableContainer: {
    borderRadius: '16px',
    border: '1px solid #F3F4F9',
    backgroundColor: '#fff',
  },

  tableHeadCell: {
    whiteSpace: 'nowrap',
    borderRight: '1px solid #F3F4F9',
    borderBottom: '1px solid #F3F4F9',
    padding: '14px 16px',
  },

  noItemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '50vh',
  },

  emptyCell: {
    borderBottom: 0,
  },

  noItem: {
    ...typoM,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  tableHeadCellItem: {
    ...typoM,
    fontWeight: '500',
  },

  textEditorStyles: {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
      height: '195px',
      backgroundColor: '#FFFFFF',
      borderRadius: '12px',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '148%',
      color: '#23252F',
    },
    subtitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '150%',
      color: '#6E707A',
    },
  },
  customButtonStyle: {
    boxSizing: 'border-box',
    flexDirection: 'row',
    border: '1px solid #4d6ef5',
    outline: 'none',
    borderRadius: '8px',
    backgroundolor: 'transparent',
    fontWeight: 400,
    paddingInline: '15px',
    fontFamily: 'Inter',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridGap: '10px',
    gap: '10px',
    cursor: 'pointer',
    color: '#4d6ef5',
    minWidth: '100px',
    padding: '7px',
  },
  actionButton: {
    ...typoM,
    transition: '300ms',
  },
  actionBtnIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  postIconButton: {
    position: 'absolute',
    background: '#23252F',
    padding: '12px',
    borderRadius: '12px 0 0 12px',
    right: 0,
    bottom: '52px',
    '&:hover': {
      background: '#23252F',
      opacity: '0.8',
    },
  },

  iconBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    border: '1px solid #379552',
    borderRadius: '8px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& path': {
      stroke: '#379552',
    },
    '&.blue': {
      borderColor: '#3652C6',
      '& path': {
        stroke: '#3652C6',
      },
    },
    '&.locked': {
      borderColor: '#6E707A',
      '& path': {
        stroke: '#6E707A',
      },
    },
    '&:disabled': {
      cursor: 'default',
      opacity: '0.4',
    },
  },

  divider: {
    border: 0,
    borderLeft: '1px solid #F3F4F9',
    width: '1px',
    height: '36px',
    marginRight: 'auto',
  },

  openButtonContainer: { display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' },
  openButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    padding: '0',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& svg, img': {
      width: '24px',
      height: '24px',
    },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '48px',
  },
  noResultContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '72px',
  },
  noResultTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },
  noResultSubTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#23252F',
  },
};
