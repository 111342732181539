import React, { useState, createContext, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// mui components
import { Box, CircularProgress } from '@mui/material';
// actions
import MainActions from '../main-actions';
import FolderGridView from './folder-grid-view';
import FolderListView from './folder-list-view';
// api
import {
  handleSetBreadCrumbs,
  handleSetCabinetLocation,
  handleSetIsGridDefaultFolders,
} from 'redux/slices/file-cabinet-slice';
import { FolderActions } from '../../types';
import { FileCabinetLocation, FileCabinetUserPermission, IFolder } from 'redux/api/file-cabinet';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { fileCabinetSelector } from 'redux/selector';
// styles
import { styles } from './styles';

export const ActionsContext = createContext<null | FolderActions>(null);

type Props = {
  containerStyles?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  folders?: IFolder[];
  isGrid: boolean;
  fromModal: boolean;
  backUrl?: string | number;
  projectFolderId?: string | number;
  projectId?: string | number;
  publicFolders?: boolean;
  projectName?: string;
  folderName?: string;
  myPermissions?: FileCabinetUserPermission;
};

const FolderView: React.FC<Props> = ({
  isGrid,
  folders,
  containerStyles,
  className = '',
  loading,
  fromModal,
  backUrl,
  projectFolderId,
  projectId,
  publicFolders,
  projectName,
  folderName,
  myPermissions,
}) => {
  const dispatch = useAppDispatch();
  const { pathname, hash } = useLocation();
  const { breadCrumbs } = useAppSelector(fileCabinetSelector);

  const [imageId, setImageId] = useState<number | null>(null);
  const [editingItemId, setEditingItemId] = useState<number | null>(null);
  const [managingPermissionsItemId, setManagingPermissionsItemId] = useState<number | null>(null);
  const [changingFolderItemId, setChangingFolderItemId] = useState<number | null>(null);
  const [deletingItemId, setDeletingItemId] = useState<number | null>(null);
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState<boolean>(null);

  const handleCreateProjectCabinetFolder = (val: boolean) => setOpenCreateFolderModal(val);
  const handleChangeEditingItemId = (val: null | number) => setEditingItemId(val);
  const handleChangeDeletigItemId = (val: null | number) => setDeletingItemId(val);
  const handleChangeChangingFolderItemId = (val: null | number) => setChangingFolderItemId(val);
  const handleChangeManagingPermissionsItemId = (val: null | number) =>
    setManagingPermissionsItemId(val);

  useEffect(() => {
    const savedBreadCrumbs = localStorage.getItem('fileCabinetBreadCrumbs');

    if (!!savedBreadCrumbs) {
      const parsedBreadCrumbs = JSON.parse(savedBreadCrumbs);

      if (!breadCrumbs?.length && !!parsedBreadCrumbs.length) {
        dispatch(handleSetBreadCrumbs(parsedBreadCrumbs));
      }
    }
  }, [breadCrumbs, localStorage]);

  const handleShowImage = (val: null | number) => {
    setImageId(val);
  };

  useEffect(() => {
    if (fromModal && hash) {
      dispatch(handleSetCabinetLocation(FileCabinetLocation.INSIDE_FOLDER));
    }

    if (fromModal && !hash) {
      dispatch(handleSetCabinetLocation(FileCabinetLocation.INSIDE_PROJECT_FOLDERS));
    }

    if (publicFolders) {
      dispatch(handleSetCabinetLocation(FileCabinetLocation.INSIDE_PUBLIC_FOLDERS));
    }

    if (pathname?.includes('file-cabinet')) {
      dispatch(handleSetCabinetLocation(FileCabinetLocation.INSIDE_DEFAULT_FOLDERS));
    }
  }, [hash, fromModal, pathname]);

  const actions: FolderActions = {
    editingItemId,
    managingPermissionsItemId,
    changingFolderItemId,
    deletingItemId,
    imageId,
    openCreateFolderModal,
    handleChangeEditingItemId,
    handleCreateProjectCabinetFolder,
    handleChangeManagingPermissionsItemId,
    handleChangeChangingFolderItemId,
    handleChangeDeletigItemId,
    handleShowImage,
  };

  const changeView = useCallback(
    (value: boolean) => {
      dispatch(handleSetIsGridDefaultFolders(value));
    },
    [isGrid]
  );

  const cabinetTitle = useCallback(() => {
    if (
      pathname.includes('selections') ||
      pathname.includes('account-dashboard') ||
      pathname.includes('project-dashboard')
    ) {
      return 'Project Storage';
    }

    if (publicFolders) {
      if (!!projectName && !!folderName) {
        return `${projectName} - ${folderName}`;
      }

      return ' ';
    }

    return 'File Cabinet Default Folders';
  }, [pathname, projectName]);

  if (loading) {
    return (
      <Box sx={fromModal ? styles.modalLoadingContainer : styles.loadingContainer}>
        <CircularProgress size={70} />
      </Box>
    );
  }

  return (
    <Box sx={containerStyles}>
      <MainActions
        isGrid={isGrid}
        className={className}
        fromModal={fromModal}
        defaultBreadCrumbs={breadCrumbs}
        changeView={changeView}
        title={cabinetTitle()}
        projectFolderId={projectFolderId}
        projectId={projectId}
        publicFolders={publicFolders}
        myPermissions={myPermissions}
      />

      <ActionsContext.Provider value={actions}>
        {isGrid ? (
          <FolderGridView
            folders={folders}
            className={className}
            backUrl={backUrl}
            fromModal={fromModal}
            projectFolderId={projectFolderId}
            projectId={projectId}
            publicFolders={publicFolders}
            myPermissions={myPermissions}
          />
        ) : (
          <FolderListView
            folders={folders}
            className={className}
            backUrl={backUrl}
            fromModal={fromModal}
            projectFolderId={projectFolderId}
            projectId={projectId}
            publicFolders={publicFolders}
            myPermissions={myPermissions}
          />
        )}
      </ActionsContext.Provider>
    </Box>
  );
};

export default FolderView;
