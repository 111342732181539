import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
// mui components
import {
  Select,
  SelectChangeEvent,
  SelectProps,
  MenuItem,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  ListSubheader,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
  Typography,
} from '@mui/material';
// components
import OutlinedInput from 'components/inputs/outlined-input';
import DefaultAvatar from '../default-avatar';
// custom hooks
import { useOnScreen } from 'hooks/useOnScreen';
// icons
import { ReactComponent as AngleDownNewIcon } from 'assets/icons/Chevron-down_New_Icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check_icon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/buttons/search.svg';
import { ReactComponent as PlusOutline } from 'assets/icons/buttons/plus-circle.svg';
// types
import { FieldError } from 'react-hook-form';
import { ICommonSelectValues } from '../../utils/types';
//styles
import { selecNativeStyles } from '../styles';
import { CSSProperties } from '@mui/styles';

export type TAdditionalOption = {
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
};

export interface IProps extends Omit<SelectProps, 'error'> {
  options: ICommonSelectValues[];
  optionStyles?: { [key: string]: string };
  required?: boolean;
  withError?: boolean;
  withoutHelperText?: boolean;
  maxWidth?: string;
  additionalOnChange?: (event: SelectChangeEvent<any>) => void;
  onOptionSelect?: (option: IProps['options'][0]) => void;
  error?: FieldError;
  label?: string;
  isCapitalize?: boolean;
  renderOption?: (item: ICommonSelectValues) => ReactNode | null;
  addNewItem?: boolean;
  onAddNewItem?: () => void;
  paperStyles?: CSSProperties;
  inputStyles?: CSSProperties;
  avatarStyles?: CSSProperties;
  addBtnLabel?: string;
  withIcons?: boolean;
  withDataFetching?: boolean;
  withoutBorder?: boolean;
  hideIcon?: boolean;
  isFetching?: boolean;
  withAvatar?: boolean;
  onIntersection?: () => void;
  onSearchChange?: (text: string) => void;
  additionalOption?: TAdditionalOption;
  boxWidth?: string;
}

const SearchSelect: FC<IProps> = ({
  options,
  optionStyles,
  placeholder,
  maxWidth,
  name,
  value,
  label,
  error,
  required,
  withError,
  onChange,
  additionalOnChange,
  onOptionSelect,
  sx,
  isCapitalize,
  renderOption,
  addNewItem,
  onAddNewItem,
  paperStyles,
  addBtnLabel,
  avatarStyles,
  withIcons,
  isFetching,
  onIntersection,
  withDataFetching,
  withoutBorder,
  hideIcon,
  withAvatar,
  onSearchChange,
  additionalOption,
  inputStyles,
  boxWidth,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const [focus, setFocus] = useState(true);

  const {
    label: additionalOptionLabel,
    icon: additionalOptionIcon,
    onClick: onAdditionalOptionClick,
  } = additionalOption ?? {};

  const { bottomRef, isIntersecting } = useOnScreen();

  const MenuProps = paperStyles
    ? { autoFocus: true, PaperProps: { sx: paperStyles } }
    : {
        autoFocus: focus,
      };

  const displayedOptions = useMemo(
    () =>
      withDataFetching
        ? options
        : options?.filter((item) =>
            String(item?.label?.toLowerCase())?.includes(String(searchText)?.toLowerCase()?.trim())
          ),
    [searchText, options]
  );

  const handleChangeAutoFocus = useCallback(() => {
    if (!!focus) {
      setFocus(false);
    }
  }, [focus]);

  const handleFocusChange = useCallback(() => {
    if (!focus) {
      setFocus(true);
    }
  }, [focus]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onSearchChange) {
      onSearchChange(e.target.value);
    } else {
      setSearchText(e.target.value);
    }
  };

  useEffect(() => {
    if (isIntersecting && onIntersection) {
      onIntersection();
    }
  }, [isIntersecting, onIntersection]);

  return (
    <Box sx={{ width: boxWidth ?? '100%' }}>
      <FormControl fullWidth>
        <Select
          {...props}
          MenuProps={MenuProps}
          name={name}
          displayEmpty
          defaultValue={value}
          value={value}
          sx={{
            ...selecNativeStyles.selecNative,
            ...inputStyles,
            '& fieldset': {
              border: withoutBorder ? 'none !important' : '',
            },

            '& svg': {
              display: hideIcon ? 'none !important' : '',
            },
          }}
          onFocus={handleFocusChange}
          IconComponent={AngleDownNewIcon}
          input={
            <OutlinedInput
              name={name}
              label={label}
              error={!!error}
              required={!!required}
              helperText={withError ? error?.message || ' ' : ' '}
              withoutHelperText={!withError}
              sx={{ maxWidth: maxWidth || '100%' }}
            />
          }
          onChange={(event, child: React.ReactNode) => {
            onChange?.(event, child);
            additionalOnChange?.(event);
            if (onOptionSelect) {
              const option = options?.find(
                ({ value: optionValue }) => optionValue === event?.target?.value
              );

              onOptionSelect(option);
            }
          }}
        >
          {(withDataFetching || options?.length > 6) && (
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                placeholder="Search"
                fullWidth
                sx={selecNativeStyles.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isFetching ? <CircularProgress size={20} /> : <SearchIcon />}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  handleSearchChange(e);
                  handleChangeAutoFocus();
                }}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
          )}

          {!!placeholder && (
            <MenuItem
              value=""
              sx={{
                ...optionStyles,
                ...selecNativeStyles?.option,
              }}
              disabled
            >
              {placeholder}
            </MenuItem>
          )}

          {!withDataFetching
            ? displayedOptions?.map((item) => {
                const initials = item?.label
                  ? item.label
                      .split(' ')
                      .map((itemLabel) => itemLabel?.[0]?.toUpperCase())
                      .join('')
                  : '';

                return item?.disabled ? (
                  <MenuItem
                    value={item?.value}
                    disabled={!!item?.disabled}
                    sx={{
                      ...optionStyles,
                      ...selecNativeStyles?.option,
                    }}
                  >
                    <Grid sx={selecNativeStyles?.selectValue}>
                      {item.icon ? (
                        <img
                          src={item?.icon}
                          alt={item?.label}
                          style={selecNativeStyles?.selecNativeOptionIcon}
                        />
                      ) : withIcons ? (
                        <DefaultAvatar
                          initials={initials}
                          variant="square"
                          alt="avatar"
                          sx={{
                            height: '28px',
                            width: '28px',
                            marginRight: '12px',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            fontSize: '16px',
                            ...avatarStyles,
                          }}
                        />
                      ) : null}

                      {item?.color ? (
                        <div
                          style={{
                            ...selecNativeStyles?.selecNativeOptionColor,
                            background: item?.color,
                          }}
                        />
                      ) : null}

                      {item?.label}
                    </Grid>

                    <span className="checkIcon">
                      <CheckIcon />
                    </span>

                    {!!item?.disabled && (
                      <Tooltip
                        componentsProps={{ tooltip: { sx: selecNativeStyles.tooltip } }}
                        placement="bottom"
                        title={
                          !!item?.disabledItemMessage
                            ? item?.disabledItemMessage
                            : 'Item already completed'
                        }
                      >
                        <Grid sx={selecNativeStyles.tooltipContent} />
                      </Tooltip>
                    )}
                  </MenuItem>
                ) : (
                  <MenuItem
                    value={item?.value}
                    disabled={!!item?.disabled}
                    key={item?.value}
                    sx={{
                      ...optionStyles,
                      ...selecNativeStyles?.option,
                    }}
                  >
                    <Grid
                      sx={
                        withAvatar
                          ? selecNativeStyles?.selectValueWithAvatar
                          : selecNativeStyles?.selectValue
                      }
                    >
                      {item.icon ? (
                        <img
                          src={item?.icon}
                          alt={item?.label}
                          style={selecNativeStyles?.selecNativeOptionIcon}
                        />
                      ) : withIcons ? (
                        <DefaultAvatar
                          initials={initials}
                          variant="square"
                          alt="avatar"
                          sx={{
                            height: '28px',
                            width: '28px',
                            marginRight: '12px',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            fontSize: '16px',
                            ...avatarStyles,
                          }}
                        />
                      ) : null}

                      {withAvatar &&
                        (item.avatar ? (
                          <img
                            src={item?.avatar}
                            alt="avatar"
                            className="avatarIcon"
                            style={selecNativeStyles?.selecNativeOptionAvatarIcon}
                          />
                        ) : (
                          <Grid className="avatarIcon" sx={selecNativeStyles?.withAvatarInitials}>
                            {item.avatarInitials}
                          </Grid>
                        ))}

                      {item?.color ? (
                        <div
                          style={{
                            ...selecNativeStyles?.selecNativeOptionColor,
                            background: item?.color,
                          }}
                        />
                      ) : null}

                      <Grid className="avatarlabelContainer" sx={selecNativeStyles?.labelContainer}>
                        <Grid sx={selecNativeStyles?.withAvatarLabel}>
                          <Typography
                            className={withAvatar ? 'avatarLabel' : ''}
                            sx={selecNativeStyles?.label}
                          >
                            {item?.label}
                          </Typography>
                          {withAvatar && (
                            <Grid className="avatarRole" sx={selecNativeStyles?.role}>
                              {item?.role}
                            </Grid>
                          )}
                        </Grid>

                        {withAvatar && (
                          <Tooltip
                            title={item?.email}
                            placement="bottom"
                            componentsProps={{ tooltip: { sx: selecNativeStyles.tooltip } }}
                            className="avatarEmail"
                          >
                            <Grid sx={selecNativeStyles.withAvatarEmail}>{item?.email}</Grid>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>

                    <span className="checkIcon">
                      <CheckIcon />
                    </span>
                  </MenuItem>
                );
              })
            : displayedOptions?.map((item) => {
                const initials = item?.label
                  ? item.label
                      .split(' ')
                      .map((itemLabel) => itemLabel?.[0]?.toUpperCase())
                      .join('')
                  : '';

                return (
                  <MenuItem
                    value={item?.value}
                    disabled={!!item?.disabled}
                    key={item?.value}
                    sx={{
                      ...optionStyles,
                      ...selecNativeStyles?.option,
                    }}
                  >
                    <Grid sx={selecNativeStyles?.selectValue}>
                      {item.icon ? (
                        <img
                          src={item?.icon}
                          alt={item?.label}
                          style={selecNativeStyles?.selecNativeOptionIcon}
                        />
                      ) : withIcons ? (
                        <DefaultAvatar
                          initials={initials}
                          variant="square"
                          alt="avatar"
                          sx={{
                            height: '28px',
                            width: '28px',
                            marginRight: '12px',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            fontSize: '16px',
                            ...avatarStyles,
                          }}
                        />
                      ) : null}

                      {item?.color ? (
                        <div
                          style={{
                            ...selecNativeStyles?.selecNativeOptionColor,
                            background: item?.color,
                          }}
                        />
                      ) : null}

                      {item?.label}
                    </Grid>

                    <span className="checkIcon">
                      <CheckIcon />
                    </span>
                  </MenuItem>
                );
              })}

          {isFetching && (
            <Grid
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 0',
              }}
            >
              <CircularProgress />
            </Grid>
          )}

          {!isFetching && onIntersection ? (
            <Grid sx={{ visibility: 'hidden', height: '2px' }} ref={bottomRef}></Grid>
          ) : null}

          {addNewItem && (
            <ListSubheader
              sx={{
                top: 'unset',
                bottom: additionalOption ? '48px' : '0',
              }}
              className="add-new"
            >
              <Button
                disableRipple
                onClick={onAddNewItem}
                startIcon={<PlusOutline />}
                className="add-new-btn"
              >
                {addBtnLabel || 'add new'}
              </Button>
            </ListSubheader>
          )}

          {additionalOption && (
            <ListSubheader
              sx={{
                top: 'unset',
                bottom: '0',
              }}
              className="additional-option"
            >
              <Button
                disableRipple
                onClick={onAdditionalOptionClick}
                startIcon={additionalOptionIcon ?? null}
                className="additional-option-btn"
              >
                {additionalOptionLabel ?? ''}
              </Button>
            </ListSubheader>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SearchSelect;
