import React, { FC } from 'react';
// mui components
import { Grid, Typography } from '@mui/material';
// api
import { IPostUploads } from 'redux/api/activity-feed';
import { IUploadedFilesData } from 'redux/types';
// helpers
import { isVideo, hasMimeType } from '../helpers';
import { getImageBySize } from 'utils/helpers';
// icons
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';
import { ReactComponent as PlayIconBackground } from 'assets/icons/play-icon-background.svg';
// styles
import { uploadedImagesStyles as styles } from '../styles/styles';

interface IProps {
  multimedia: IPostUploads[] | IUploadedFilesData[];
  visibleImagesCount: number;
  hiddenImagesCount: number;
  containerWidth: number;
  containerHeight: number;
  handleOpenModal: (idx: number) => void;
}

const ActivityFeedMediaItems: FC<IProps> = ({
  multimedia,
  visibleImagesCount,
  hiddenImagesCount,
  containerHeight,
  containerWidth,
  handleOpenModal,
}) => {
  const renderPlayIcon = (
    <Grid sx={styles.playIconBackgroundContainer}>
      <PlayIconBackground />
      <Grid sx={styles.playIconContainer}>
        <PlayIcon />
      </Grid>
    </Grid>
  );

  return (
    <Grid sx={styles.activityFeedImagesContainer}>
      {multimedia.length <= 2 ? (
        multimedia.map((item, index) => {
          if (hasMimeType(item)) {
            if (item.mimeType && isVideo(item?.mimeType)) {
              return (
                <Grid
                  key={item.id}
                  sx={{
                    ...styles.bigImageBackground,
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `url(${getImageBySize(
                      item.options?.previewUrl,
                      (containerWidth - 8 - 2) / 2,
                      containerHeight - 2,
                      false
                    )})`,
                  }}
                  onClick={() => handleOpenModal(index)}
                >
                  {renderPlayIcon}
                </Grid>
              );
            }
          }

          return (
            <Grid
              key={item.id}
              sx={{
                ...styles.bigImageBackground,
                background: `url(${getImageBySize(
                  item.url,
                  (containerWidth - 8 - 2) / 2,
                  containerHeight - 2,
                  false
                )})`,
              }}
              onClick={() => handleOpenModal(index)}
            ></Grid>
          );
        })
      ) : multimedia.length > 2 ? (
        <>
          {multimedia.slice(0, 1).map((item, index) => {
            if (hasMimeType(item)) {
              if (item.mimeType && isVideo(item?.mimeType)) {
                return (
                  <Grid
                    key={item.id}
                    sx={{
                      ...styles.smallImageBackground,
                      background: `url(${getImageBySize(
                        item?.options?.previewUrl,
                        (containerWidth - 8 - 2) / 2,
                        containerHeight - 2,
                        false
                      )})`,
                    }}
                    onClick={() => handleOpenModal(index)}
                  >
                    {renderPlayIcon}
                  </Grid>
                );
              }
            }

            return (
              <Grid
                key={item.id}
                sx={{
                  ...styles.smallImageBackground,
                  background: `url(${getImageBySize(
                    item.url,
                    (containerWidth - 8 - 2) / 2,
                    containerHeight - 2,
                    false
                  )})`,
                }}
                onClick={() => handleOpenModal(index)}
              ></Grid>
            );
          })}

          <Grid sx={styles.activityFeedImagesSmallContainer}>
            {typeof visibleImagesCount === 'number' && visibleImagesCount > 2
              ? multimedia.slice(0, visibleImagesCount).map((item, index) => {
                  if (index === 0) {
                    return;
                  }

                  if (index === visibleImagesCount - 1 && hiddenImagesCount > 0) {
                    if (hasMimeType(item)) {
                      if (item.mimeType && isVideo(item?.mimeType)) {
                        return (
                          <Grid
                            key={item.id}
                            sx={{
                              ...styles.activityFeedLastImage,
                              background: `linear-gradient(0deg, rgba(35, 37, 47, 0.4), rgba(35, 37, 47, 0.4)), url(${getImageBySize(
                                item?.options?.previewUrl,
                                ((containerWidth - 8) / 2 - 8) / 2,
                                (containerHeight - 8) / 2,
                                false
                              )})`,
                            }}
                            onClick={() => handleOpenModal(index)}
                          >
                            {renderPlayIcon}
                            <Typography
                              sx={{
                                ...styles.activityFeedLastImageTypo,
                                color: 'black',
                              }}
                            >{`+${hiddenImagesCount}`}</Typography>
                          </Grid>
                        );
                      }
                    }

                    return (
                      <Grid
                        key={item.id}
                        sx={{
                          ...styles.activityFeedLastImage,
                          background: `linear-gradient(0deg, rgba(35, 37, 47, 0.4), rgba(35, 37, 47, 0.4)), url(${getImageBySize(
                            item.url,
                            ((containerWidth - 8) / 2 - 8) / 2,
                            (containerHeight - 8) / 2,
                            false
                          )})`,
                        }}
                        onClick={() => handleOpenModal(index)}
                      >
                        <Typography
                          sx={styles.activityFeedLastImageTypo}
                        >{`+${hiddenImagesCount}`}</Typography>
                      </Grid>
                    );
                  }

                  if (hasMimeType(item)) {
                    if (item.mimeType && isVideo(item?.mimeType)) {
                      return (
                        <Grid
                          key={item.id}
                          sx={{
                            ...styles.activityFeedImageBackground,
                            background: `url(${getImageBySize(
                              item?.options?.previewUrl,
                              ((containerWidth - 8) / 2 - 8) / 2,
                              (containerHeight - 8) / 2,
                              false
                            )})`,
                          }}
                          onClick={() => handleOpenModal(index)}
                        >
                          {renderPlayIcon}
                        </Grid>
                      );
                    }
                  }

                  return (
                    <Grid
                      key={item.id}
                      sx={{
                        ...styles.activityFeedImageBackground,
                        background: `url(${getImageBySize(
                          item.url,
                          ((containerWidth - 8) / 2 - 8) / 2,
                          (containerHeight - 8) / 2,
                          false
                        )})`,
                      }}
                      onClick={() => handleOpenModal(index)}
                    ></Grid>
                  );
                })
              : null}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default ActivityFeedMediaItems;
