import { FC } from 'react';

import Dialog from '@mui/material/Dialog';
import ProjectSetup from 'pages/project-setup';
import { useProjectStepper } from 'hooks/useProjectStepperState';

interface IProps {
  open: boolean;
  handleClose: () => void;
  isFirstProject: boolean;
}

const CreateProjectModal: FC<IProps> = ({ open, handleClose, isFirstProject }) => {
  const { setOpenModal, openModal, setRedirect } = useProjectStepper();

  return (
    <Dialog
      className="create-project-modal"
      fullWidth={true}
      maxWidth={'md'}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        setRedirect(false);
      }}
    >
      <ProjectSetup handleClose={handleClose} isFirstProject={isFirstProject} />
    </Dialog>
  );
};

export default CreateProjectModal;
