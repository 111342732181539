import { memo } from 'react';
// mui components
import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInputContainerNewUI = styled((props: GridProps) => (
  <Grid container flexDirection="column" {...props} />
))(() => ({
  marginBottom: '20px',
}));

export default memo(StyledInputContainerNewUI);
