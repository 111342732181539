import React, { useState, useContext, useCallback, FC } from 'react';
import { useLocation } from 'react-router-dom';
// mui components
import { Button, IconButton, Popover } from '@mui/material';
// components
import { ActionsContext } from 'components/file-cabinet-folders/components/folder-view';
// hooks
import useToast from 'hooks/useToast';
// api
import { FileCabinetTypeEnum, IFolder } from 'redux/api/file-cabinet';
import {
  useGetCompanyRoleFolderPermissionsQuery,
  useGetCompanyRolePermissionsMeQuery,
} from 'redux/api/company-roles';
import { useLazyGetShowCabinetFileURLQuery } from 'redux/api/file-cabinet';
import { useGetMeQuery } from 'redux/api/auth';
// icons
import { ReactComponent as VerticalDotsIcon } from 'assets/icons/buttons/table-dots.svg';
import { ReactComponent as EditIcon } from 'assets/icons/buttons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/buttons/delete.svg';
import { ReactComponent as PermissionIcon } from 'assets/icons/cabinet/permission-icon.svg';
import { ReactComponent as ChangeLocationIcon } from 'assets/icons/cabinet/change-location-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/cabinet/download-icon.svg';
// styles
import { styles } from '../styles';

type Props = {
  className?: string;
  optionBtnClassName?: string;
  folder?: IFolder;
  type: string;
  fromModal: boolean;
  projectId: string | number;
};

const ActionsPopper: FC<Props> = ({
  className = '',
  optionBtnClassName = '',
  folder,
  type,
  fromModal,
  projectId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const location = useLocation();
  const showToast = useToast();

  const { data: userPermissions } = useGetCompanyRolePermissionsMeQuery();
  const [getDownloadURL] = useLazyGetShowCabinetFileURLQuery();
  const { data: userMe } = useGetMeQuery();

  const {
    handleChangeDeletigItemId,
    handleChangeChangingFolderItemId,
    handleChangeEditingItemId,
    handleChangeManagingPermissionsItemId,
  } = useContext(ActionsContext);

  const actionsList = useCallback(() => {
    let list = [
      {
        id: 1,
        label: 'edit',
        icon: <EditIcon />,
        disabled: !folder?.canDelete,
        handleClick: handleChangeEditingItemId,
      },
      {
        id: 2,
        label: 'manage permissions',
        icon: <PermissionIcon />,
        handleClick: handleChangeManagingPermissionsItemId,
      },
      {
        id: 3,
        label: 'change folder',
        icon: <ChangeLocationIcon />,
        disabled: !folder?.canDelete,
        handleClick: handleChangeChangingFolderItemId,
      },
      {
        id: 4,
        label: 'delete',
        icon: <DeleteIcon />,
        disabled: !folder?.canDelete,
        handleClick: handleChangeDeletigItemId,
      },
    ];

    const foldersMyPermissions = folder?.users?.find(({ id }) => {
      return String(id) === String(userMe?.id);
    });

    const foldersMyRolePermissions = folder?.roles?.find(({ name }) => {
      return String(name) === String(userMe?.role);
    });

    const folderPermissions = () => {
      if (!fromModal || location?.pathname?.includes('file-cabinet')) {
        return foldersMyRolePermissions;
      }

      return foldersMyPermissions;
    };

    if (!!folderPermissions()) {
      if (!!folderPermissions()?.permissions) {
        if (!folderPermissions()?.permissions?.canAddUpdate) {
          list = list?.filter((item) => {
            return item?.id !== 1;
          });
        }

        if (!userPermissions?.MANAGE_FILE_CABINET?.canRead) {
          list = list?.filter((item) => {
            return item?.id !== 2;
          });
        }

        if (
          !folderPermissions()?.permissions?.canAddUpdate ||
          !fromModal ||
          location?.pathname?.includes('file-cabinet')
        ) {
          list = list?.filter((item) => {
            return item?.id !== 3;
          });
        }

        if (!folderPermissions()?.permissions?.canDelete) {
          list = list?.filter((item) => {
            return item?.id !== 4;
          });
        }

        return list;
      }
    }

    return list;
  }, [folder, userMe, userPermissions]);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleLinkClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    getDownloadURL({ fileId: folder?.id, projectId: projectId })
      .unwrap()
      .then((res) => {
        window.open(res?.url, '_blank');
      })
      .catch(() => {
        showToast({
          type: 'error',
          message: 'Something went wrong!',
        });
      });
  };

  const handleBtnClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    handleClick: (val: null | number) => void
  ) => {
    handleClose(e);
    handleClick && handleClick(folder?.id);
  };

  const open = Boolean(anchorEl);

  if (!actionsList()?.length) {
    return null;
  }

  return (
    <>
      <IconButton sx={styles.iconBtn} onClick={handleOpen} className={className} disableRipple>
        <VerticalDotsIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={styles.popover}
      >
        {type === FileCabinetTypeEnum.File && (
          <Button
            sx={styles.actionBtn}
            className={optionBtnClassName}
            startIcon={<DownloadIcon />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleLinkClose(e)}
            type="button"
          >
            download
          </Button>
        )}

        {actionsList()?.map((action) => (
          <Button
            key={action.id}
            sx={styles.actionBtn}
            className={optionBtnClassName}
            startIcon={action.icon}
            disabled={action?.disabled}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleBtnClick(e, action.handleClick)
            }
          >
            {action.label}
          </Button>
        ))}
      </Popover>
    </>
  );
};

export default ActionsPopper;
