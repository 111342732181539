import React from 'react';
// mui components
import { Grid } from '@mui/material';
// components
import CustomCard from '../../card';
import EmptyList from '../../empty-list';
// api
import { FileCabinetUserPermission, IFolder } from 'redux/api/file-cabinet';
// styles
import { styles } from './styles';

type Props = {
  folders?: IFolder[];
  className?: string;
  backUrl?: string | number;
  fromModal: boolean;
  projectFolderId?: string | number;
  projectId?: string | number;
  publicFolders?: boolean;
  myPermissions: FileCabinetUserPermission;
};

const FolderGridView: React.FC<Props> = ({
  folders,
  className = '',
  fromModal,
  projectFolderId,
  projectId,
  publicFolders,
  myPermissions,
}) => {
  return (
    <Grid sx={styles(fromModal).wrapper}>
      {!!folders?.length ? (
        <Grid sx={styles(fromModal).container} className={className}>
          {folders?.map((item, index) => {
            return (
              <CustomCard
                key={`${item?.id}${index}`}
                folder={item}
                fromModal={fromModal}
                projectFolderId={projectFolderId}
                type={item?.type}
                projectId={projectId}
                publicFolders={publicFolders}
              />
            );
          })}
        </Grid>
      ) : (
        !publicFolders && (
          <EmptyList
            fromModal={fromModal}
            projectFolderId={projectFolderId}
            projectId={projectId}
            myPermissions={myPermissions}
          />
        )
      )}
    </Grid>
  );
};

export default FolderGridView;
