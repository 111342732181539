import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFromMobileSlice {
  isMobile: boolean;
  showDownloadScreen: boolean;
  allowShowDownloadModal: boolean;
  showArrow: boolean;
  bottomArrow: boolean;
  topArrow: boolean;
}

const initialState: IFromMobileSlice = {
  isMobile: false,
  showDownloadScreen: false,
  allowShowDownloadModal: false,
  showArrow: false,
  bottomArrow: true,
  topArrow: false,
};

export const fromMobileSlice = createSlice({
  name: 'manage_gantt',
  initialState,
  reducers: {
    handleSetIsMobiile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },

    handleShowDownloadApp: (state, action: PayloadAction<boolean>) => {
      state.showDownloadScreen = action.payload;
    },

    handleSetAllowShowDownloadModal: (state, action: PayloadAction<boolean>) => {
      state.allowShowDownloadModal = action.payload;
    },

    handleShowArrow: (state, action: PayloadAction<boolean>) => {
      state.showArrow = action.payload;
    },

    handleDisableBottomArrow: (state, action: PayloadAction<boolean>) => {
      state.bottomArrow = action.payload;
    },

    handleDisableTopArrow: (state, action: PayloadAction<boolean>) => {
      state.topArrow = action.payload;
    },

    handleResetArrows: (state) => {
      state.topArrow = false;
      state.bottomArrow = true;
    },
  },
});

export const {
  handleSetIsMobiile,
  handleShowDownloadApp,
  handleShowArrow,
  handleDisableTopArrow,
  handleDisableBottomArrow,
  handleResetArrows,
  handleSetAllowShowDownloadModal,
} = fromMobileSlice.actions;

export default fromMobileSlice.reducer;
