export const styles = {
  container: {
    padding: '40px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '14px',
    height: 'calc(100% - 180px)',
  },

  typo: {
    fontFamily: 'Inter',
    fontSize: '14px',
  },

  backLink: {
    color: '#fff',
    background: '#4D6EF5',
    fontFamily: 'Inter',
    fontSize: '14px',
    textDecoration: 'none',
    borderRadius: '6px',
    padding: '10px 20px',
    cursor: 'pointer',

    '&:hover': {
      opacity: '0.9',
    },
  },
};
