import { IDataList, IUserMe, IInvite, IPhase } from 'redux/types';

export enum ProjectTypesEnum {
  PRE_SALE = 'PRE_SALE',
  SPEC = 'SPEC',
}

export enum ProjectStatusesEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface IUnviewedItems {
  notifications: number;
  posts: number;
  messages: number;
}

export interface IWeather {
  id: number;
  lat: number;
  lng: number;
  createdAt: string;
  updatedAt: string | null;
  data: {
    daily: {
      dt: number;
      temp: {
        max: number;
        min: number;
      };
      weather: {
        icon: string;
      }[];
    }[];
  };
}

export interface IProjectProfiles {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  role: string;
  avatar: string | null;
  jobTitle: string;
  isOwner?: boolean;
}

export interface IAssignedProfiles extends Omit<IUserMe, 'isSuperAdmin' | 'hasAccessToBeta'> {}

export interface IAssignedInvites extends Omit<IInvite, 'inviteProjects'> {}

export interface IProjectProfilesManagement {
  assignedProfiles: IAssignedProfiles[];
  otherProfiles: IAssignedProfiles[];
  assignedInvites: IAssignedInvites[];
  otherInvites: IAssignedInvites[];
}

export interface IProject {
  id: number;
  type: string;
  name: string;
  subDivision: string | null;
  lot: string | null;
  address: string;
  secondAddress: string | null;
  city: string;
  state: string;
  stateAbbr: string;
  zip: string;
  country: string;
  lat: number | string;
  lng: number | string;
  status: ProjectStatusesEnum;
  image: string | null;
  startDate: string | null;
  createdAt: string | null;
  canBeEmpty: boolean;
  tz: string;
  activityCount: number;
  lastUpdatedAt: string;
  unviewedItemCounts: IUnviewedItems;
  weather: IWeather | null;
  phases: IPhase[];
  profiles: IProjectProfiles[];
  templateId?: string | number;
  folderId?: string | number;
}

export type TemplateChanges = {
  added: number;
  deleted: number;
};

export interface IProjectShort extends Pick<IProject, 'id' | 'name' | 'city' | 'address' | 'zip'> {}

export interface IGetProjectsArgs {
  page?: number;
  itemsPerPage?: number;
  search?: string;
  nearbyLat?: number;
  nearbyLng?: number;
  status?: ProjectStatusesEnum;
}

export interface IGetAllProjectsArgs {
  profileId: number;
}

export interface IGetProjectByIdArgs {
  id: number;
}

export interface IGetPhasesArgs {
  projectId: number;
}

export interface IUpdateProjectBody {
  data: {
    profilesIds?: number[];
  } & Partial<IProject>;
  id: number;
  withoutRefetching?: boolean;
}

export interface IUpdateProjectTemplateBody {
  projectId: number;
  templateId: number;
}

export interface IGetProjectProfilesManagementArgs {
  id: number;
}

export interface IGetUnviewedCountsArgs {
  projectIds: number[];
}

export interface IUnviewedItem {
  projectId: number;
  posts: number;
  notifications: number;
  messages: number;
  overdue: number;
  unviewedOverdue: number;
}

export interface IGetUnviewedItemsResponse {
  items: { [key: number]: IUnviewedItem };
}

export enum OverdueItemTypeEnum {
  SELECTION = 'SELECTION',
  PHASE = 'PHASE',
}

export interface IOverdueItem {
  id: number;
  title: string;
  endDate: string;
  isViewed: boolean;
  type: OverdueItemTypeEnum;
}

export interface IGetOverdueItemsResponse {
  overdue: IOverdueItem[];
}

export interface IGetOverdueItemsArgs {
  projectId: number;
}

export type TGetProjectsResponse = IDataList<IProject>;

export type GetTemplateChangesArgs = {
  templateId: string | number;
  projectId: string | number;
};

export type UpdateTemplateBody = {
  projectId: string | number;
  data: { isNew: boolean; title?: string };
};
