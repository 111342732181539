import React, { FC } from 'react';
import stringToColor from 'string-to-color';
import { Avatar, AvatarProps } from '@mui/material';

interface IDefaultAvatarProps extends AvatarProps {
  initials: string;
}

const DefaultAvatar: FC<IDefaultAvatarProps> = ({ initials, sx, ...props }) => {
  return (
    <Avatar
      {...props}
      sx={{
        ...sx,
        backgroundColor: stringToColor(initials),
      }}
    >
      {initials}
    </Avatar>
  );
};

export default DefaultAvatar;
