export const styles = {
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderRadius: '8px',
    border: '0.5px solid #D0D1D9',
  },
  firstSectionContainer: {
    width: 'calc(100% - 24px)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    padding: '10px 0 15px',
    borderBottom: '1px solid #D0D1D9',
    margin: '0 12px',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '12px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '6px',
  },
  nameContainer: { display: 'flex', flexDirection: 'column', gap: '2px' },
  name: {
    color: '#23252F',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '146%',
  },
  role: {
    display: 'flex',
    width: 'fit-content',
    padding: '2px 6px',
    borderRadius: '8px',
    background: '#E7ECFF',
    color: '23252F',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
  },
  commentIconContainer: {
    display: 'flex',
    height: '36px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #4D6EF5',
  },
  contactsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '16px 12px',
    gap: '4px',
  },
  contactSectionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '4px',
  },
  userContact: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    color: '#23252F',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  messageIcon: {
    borderRadius: '8px',
    border: '1px solid #4D6EF5',
    height: '36px',
    padding: '8px',
  },
};
