import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';
import { Provider } from 'react-redux';
// root app page
import App from 'app';
// providers
import { ProjectStepperContextProvider } from './hooks/useProjectStepperState';
import { ToastContextProvider } from 'hooks/useToast';
import { ProjectProvider } from 'hooks/useProjectDetails';
// store
import { store } from 'redux/store';
// worker and web vitals
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// styles
import './index.css';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ProjectProvider>
        <ProjectStepperContextProvider>
          <ToastContextProvider>
            <SWRConfig value={{ provider: () => new Map() }}>
              <App />
            </SWRConfig>
          </ToastContextProvider>
        </ProjectStepperContextProvider>
      </ProjectProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
