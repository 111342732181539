import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
// custom hooks
import { BroadcastChannelMessagesEnum, postMessage } from './useBroadcastChannel';
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
// api
import { authApi } from 'redux/api/auth';
// slices
import {
  handleAuth,
  handleDeleteCompanyId,
  handleSetInactiveInitial,
} from 'redux/slices/auth-slice';
import { handleSetCompleted } from 'redux/slices/account-stepper-slice';
// utils
import { clearAuthData } from 'utils/authData';

interface IOptions {
  preventNavigation?: boolean;
  withoutPostMessage?: boolean;
  withoutResetState?: boolean;
}

export const useLogOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cache } = useSWRConfig();

  const onLogOut = (options?: IOptions) => {
    localStorage.clear();
    sessionStorage.removeItem('dashly-auth');
    clearAuthData();
    //@ts-ignore
    cache.clear();
    dispatch(handleAuth(false));
    dispatch(handleDeleteCompanyId());
    dispatch(handleSetCompleted(null));
    dispatch(handleSetInactiveInitial());
    if (!options?.withoutResetState) {
      dispatch(authApi.util.resetApiState());
    }
    if (!options?.withoutPostMessage) {
      postMessage({ message: BroadcastChannelMessagesEnum.LOG_OUT });
    }
    if (!options?.preventNavigation) {
      navigate('/sign-in');
    }
  };

  return { onLogOut };
};
